import { validate as uuidValidate, NIL as NIL_UUID } from 'uuid';
import { NetworkRequest } from '../network';

type PayloadInfo = NetworkRequest['body']['payloads'][number]['info'];

function anonymizeInfo(info: PayloadInfo): PayloadInfo {
  return {
    app_version: info.app_version,
    ...Object.fromEntries(
      Object.entries(info).map(([k, v]) => [k, uuidValidate(v) ? NIL_UUID : v])
    ),
  };
}

export default function anonymizeRequest(r: NetworkRequest): NetworkRequest {
  return {
    url: r.url,
    body: {
      sent_at: r.body.sent_at,
      user_id: r.body.user_id,
      payloads: r.body.payloads.map((p) => ({
        id: p.id,
        severity: p.severity,
        categories: p.categories,
        description: p.description,
        error_code: p.error_code,
        info: anonymizeInfo(p.info),
        created_at: p.created_at,
      })),
    },
  };
}
