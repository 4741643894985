import { useSetRecoilState } from 'recoil';
import React, { useEffect, useRef } from 'react';
import { closeModalSelector } from '@/stores/modalStore';
import { StaticImageData } from 'next/image';

interface Props {
  message?: string;
  icon?: StaticImageData | null;
  id: string;
  children?: React.ReactNode;
  duration?: number;
  onClose?: CallableFunction;
  onEnd?: CallableFunction;
}

export default function Toast({
  message,
  icon,
  id,
  children,
  duration = 2000,
  onEnd,
  onClose,
}: Props) {
  const closeModal = useSetRecoilState(closeModalSelector);

  const hasClosedRef = useRef(false);

  useEffect(() => {
    const onCloseModal = () => {
      hasClosedRef.current = true;
      closeModal(id);
      if (onClose) onClose();
    };
    const timeout = setTimeout(onCloseModal, duration);
    return () => {
      if (hasClosedRef.current) return;
      clearTimeout(timeout);
      onCloseModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="w-full rounded-xl top-4 bg-white absolute py-4 px-6 md:rounded-full left-1/2 -translate-x-2/4 m-auto text-center md:w-max z-10 flex items-center slide-in-out"
      onAnimationEnd={() => {
        setTimeout(() => {
          closeModal(id);
          if (onEnd) onEnd();
        }, duration);
      }}
    >
      {icon && <img {...icon} alt="" className="w-4 h-4 me-2" />}
      {message && <p className="text-black text-base">{message}</p>}
      {children}
    </div>
  );
}
