import onlyOnceRunner from '@/core/concurrency/onlyOnceRunner';
import { authFetcher } from '@/core/http';
import { useImmutableAuthSWR } from '@/core/http/useAuthSWR';
import {
  trackCompletedEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '@/core/monitoring/trackEvents';
import { usePaymentService } from '@/layout/appWrapper/ServiceProvider';
import settingsState from '@/stores/settingsStore';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { PaymentStrategiesIds } from '../paymentService';
import { UserFromBE } from './userTypes';

// SWR fetcher key
type UserKey = [string, string];

type UserFetcherProps = {
  onSuccess: CallableFunction;
  onError: CallableFunction;
  onStart: CallableFunction;
};

// Transform user fields from BE to FE.
export const userFetcher =
  ({ onStart, onSuccess, onError }: UserFetcherProps) =>
  ([url, token]: UserKey) => {
    onStart();
    return authFetcher([url, token])
      .then((data: UserFromBE) => {
        onSuccess();
        return {
          ...data,
          id: data.firebaseUid,
          webUserId: data.userId,
          email: data.email ?? undefined,
          giftCode: {
            ...data.giftCode,
            expiryAt: data.giftCode.expiryAt
              ? new Date(data.giftCode.expiryAt)
              : undefined,
          },
          monetization: {
            ...data.monetization,
            subscriptionIncludesTrial:
              !!data.monetization.subscriptionIncludesTrial,
            subscriptionWithIntroPrice:
              !!data.monetization.subscriptionWithIntroPrice,
          },
          createdAt: new Date(data.createdAt),
          nextRenewImageDownloadLimitsDatetime:
            data.nextRenewImageDownloadLimitsDatetime
              ? new Date(data.nextRenewImageDownloadLimitsDatetime)
              : undefined,
          marketingConsent: !!data.marketingConsent,
          imageTrainingConsent: !!data.imageTrainingConsent,
          isFree: !!data.isFree,
        } as const;
      })
      .catch((e) => {
        onError(e);
        throw e;
      });
  };

// Infer correct types to SWR (also needed for "mutate")
export type SWRUser = Awaited<ReturnType<ReturnType<typeof userFetcher>>>;

// Infer correct types to exported "data". When refactor is complete, we'll export this type only.
export type NewUserType = SWRUser & {
  paymentStrategy?: PaymentStrategiesIds;
};

const trackUserStart = onlyOnceRunner(() =>
  trackStartedEvent(['setup', 'user'])
);
const trackUserError = onlyOnceRunner((e: Error) =>
  trackFailedEvent(['setup', 'user'], { error: e.message })
);
const trackUserSuccess = onlyOnceRunner(() =>
  trackCompletedEvent(['setup', 'user'])
);

export function useUser() {
  const { getUserPlanFromPriceId } = usePaymentService();

  const resp = useImmutableAuthSWR<SWRUser>(
    '/users/@me',
    userFetcher({
      onStart: trackUserStart,
      onError: trackUserError,
      onSuccess: trackUserSuccess,
    })
  );

  const settings = useRecoilValue(settingsState);

  const userPaymentStrategy = getUserPlanFromPriceId(
    settings.values.paymentStrategies,
    resp.data?.monetization.priceId || ''
  );

  const user: NewUserType | undefined = useMemo(
    () =>
      resp.data && {
        ...resp.data,
        paymentStrategy: userPaymentStrategy || undefined,
      },
    [resp.data, userPaymentStrategy]
  );

  return { ...resp, data: user };
}
