import { useLocalization } from '@/services/localizationService';
import { StaticImageData } from 'next/image';

interface Props {
  className?: string;
  icon?: StaticImageData;
  name?: string;
  isLoading?: boolean;
  isSelected?: boolean;
  style?: { [key: string]: string };
  onClick?: () => void;
}

export default function LoginProviderButton({
  className = '',
  icon,
  name,
  isLoading,
  isSelected,
  style = {},
  ...rest
}: Props) {
  const { t, data } = useLocalization();

  return (
    <button
      type="button"
      className={`w-full flex space-s-2 items-center contrast-high M18 rounded-full ${
        isLoading && !isSelected ? 'shadow-none' : 'shadow-white-button'
      } ps-3 py-3 pe-5 lg:pe-9 ${className}`}
      disabled={isLoading}
      style={{
        ...style,
        opacity: isSelected ? 1 : undefined,
      }}
      {...rest}
    >
      <img {...icon} alt="" />
      {isSelected && isLoading ? (
        <span className="block w-full text-center">
          {t(data.onboarding.loggingIn)}
        </span>
      ) : (
        <span className="block w-full text-center">
          {t(data.onboarding.continueWith)} {name}
        </span>
      )}
    </button>
  );
}
