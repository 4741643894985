import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { isLoggedInSelector } from '@/stores/authStore';
import { useUser } from '@/services/userService';
import { useConsentService } from '@/layout/appWrapper/ServiceProvider';
import { MarketingConsentModal, ImageTrainingConsentModal } from './modals';

export default function useConsent() {
  const pushModal = useSetRecoilState(pushModalSelector);
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const { hasSeenMarketingConsentPopup, hasSeenImageTrainingConsentPopup } =
    useConsentService();
  const { data: user } = useUser();

  const shouldShowMarketingConsentModal = useCallback(() => {
    if (user?.marketingConsent) return;

    if (hasSeenMarketingConsentPopup(true)) {
      return;
    }

    pushModal({ id: 'marketing-consent-modal', Modal: MarketingConsentModal });
  }, [hasSeenMarketingConsentPopup, pushModal, user?.marketingConsent]);

  const shouldShowImageTrainingConsentModal = useCallback(() => {
    if (!isLoggedIn || user?.imageTrainingConsent) return;

    if (hasSeenImageTrainingConsentPopup(true)) {
      return;
    }

    pushModal({
      id: 'image-training-consent-modal-[prevent-pop]',
      Modal: ImageTrainingConsentModal,
    });
  }, [
    hasSeenImageTrainingConsentPopup,
    isLoggedIn,
    pushModal,
    user?.imageTrainingConsent,
  ]);

  return {
    shouldShowMarketingConsentModal,
    shouldShowImageTrainingConsentModal,
  };
}
