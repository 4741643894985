import { getMonitoringAPI } from '../monitoring';

type CookieBannerElements = {
  closeBtn: Element | null;
  acceptBtns: Element[];
  customizeBtn: Element | null;
  customizeBtnCircle: Element;
  rejectAllBtn: Element;
  confirmChoicesBtn: Element;
};

export const getCookieBannerElements: () => Promise<CookieBannerElements> =
  () =>
    new Promise((resolve, reject) => {
      if (typeof document === 'undefined') {
        reject();
        return;
      }
      const { logCookieBannerElementsMissing } = getMonitoringAPI();
      const cleanupTimeout = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        clearInterval(cookieBannerInterval);
        logCookieBannerElementsMissing();
      }, 10000);

      const cookieBannerInterval = setInterval(() => {
        const closeBtn = document.querySelector(
          '#onetrust-close-btn-container > button'
        );
        const acceptBtns = Array.from(
          document.querySelectorAll(
            '#onetrust-accept-btn-handler, #accept-recommended-btn-handler'
          )
        );
        const customizeBtn = document.querySelector('#onetrust-pc-btn-handler');
        const customizeBtnCircle = document.querySelector(
          '.ot-floating-button__open'
        );
        const rejectAllBtn = document.querySelector(
          '.ot-pc-refuse-all-handler'
        );
        const confirmChoicesBtn = document.querySelector(
          '.save-preference-btn-handler.onetrust-close-btn-handler'
        );

        // Check only the buttons in the customize panel and the bottom-left green button,
        // since the banner is rendered only at the first time.
        if (
          !customizeBtnCircle ||
          !acceptBtns.length ||
          !rejectAllBtn ||
          !confirmChoicesBtn
        ) {
          return;
        }

        clearInterval(cookieBannerInterval);
        clearTimeout(cleanupTimeout);
        resolve({
          closeBtn,
          acceptBtns,
          customizeBtn,
          customizeBtnCircle,
          rejectAllBtn,
          confirmChoicesBtn,
        });
      }, 500);
    });
