import Spinner from '@/ui/content/Spinner';
import * as Sentry from '@sentry/nextjs';

import caretLeft from '@/public/images/icons/caret-left.svg';
import { useLocalization } from '@/services/localizationService';
import { isBusySelector } from '@/stores/appStore';
import { onboardingState } from '@/stores/onboardingStore';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  usePaymentService,
  usePriceService,
} from '@/layout/appWrapper/ServiceProvider';
import paymentWarning from '@/public/images/icons/payment-warning.png';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PaymentStrategiesIds,
  UpgradePreviewInfo,
} from '@/services/paymentService';
import { useUser } from '@/services/userService';
import { Toast } from '@/ui/toast';
import { pushModalSelector } from '@/stores/modalStore';
import PayPalUpgradeButton from '@/core/paypal/PayPalUpgradeButton';
import { useTracker } from '@/core/tracking';
import YourPluginKeyModal from '@/features/account/accountModal/pluginKeySection/modals/YourPluginKeyModal';
import settingsState from '@/stores/settingsStore';
import {
  trackCompletedEvent,
  trackEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '@/core/monitoring/trackEvents';

interface Props {
  onSubmit: CallableFunction;
  offBadge?: number;
}

export default function UpgradeStep2({ onSubmit, offBadge }: Props) {
  const [upgradeInfo, setUpgradeInfo] = useState<UpgradePreviewInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(
    upgradeInfo !== undefined
  );

  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);

  const [onboarding, setOnboarding] = useRecoilState(onboardingState);
  const settings = useRecoilValue(settingsState);
  const { usePaywallPrices, useFormatRecurringPrice } = usePriceService();
  const paywallPrices = usePaywallPrices();
  const { t, data } = useLocalization();
  const isBusy = useRecoilValue(isBusySelector);
  const { data: user, mutate: mutateUser } = useUser();
  const pushModal = useSetRecoilState(pushModalSelector);
  const { previewSubscriptionUpgrade, upgradeStripeSubscription } =
    usePaymentService();

  const { track } = useTracker();

  const { selectedPeriod, selectedPaymentStrategy } = onboarding;
  const price = useMemo(
    () => paywallPrices?.[selectedPaymentStrategy?.id || '']?.[selectedPeriod],
    [paywallPrices, selectedPaymentStrategy?.id, selectedPeriod]
  );
  const formattedPrice = useFormatRecurringPrice(price);

  useEffect(() => {
    if (!user || upgradeInfo !== undefined) return;

    const { subscriptionProvider } = user.monetization;
    if (!subscriptionProvider) return;

    const newPriceId =
      paywallPrices?.[selectedPaymentStrategy?.id || '']?.[selectedPeriod]
        .priceId;

    previewSubscriptionUpgrade(subscriptionProvider, newPriceId).then(
      (upgradeInfo) => {
        setUpgradeInfo(upgradeInfo);
        setIsLoading(false);
      }
    );
  }, [
    paywallPrices,
    previewSubscriptionUpgrade,
    selectedPaymentStrategy?.id,
    selectedPeriod,
    upgradeInfo,
    user,
  ]);

  const trackOnButtonClick = useCallback(() => {
    track('upgrade_modal', 'confirmed', {
      subscriptionProvider: user?.monetization?.subscriptionProvider,
      refundAmount: upgradeInfo?.remainingAmountCents,
      daysLeft: upgradeInfo?.daysLeft,
      wasTrialing: upgradeInfo?.isTrialing,
    });
  }, [track, upgradeInfo, user?.monetization?.subscriptionProvider]);

  const trackOnUpgradeFailed = useCallback(() => {
    track('upgrade_modal', 'upgrade_failed', {
      subscriptionProvider: user?.monetization?.subscriptionProvider,
      wasTrialing: upgradeInfo?.isTrialing,
    });
  }, [track, upgradeInfo, user?.monetization?.subscriptionProvider]);

  const trackOnUpgradeSuccess = useCallback(() => {
    track('upgrade_modal', 'upgrade_success', {
      subscriptionProvider: user?.monetization?.subscriptionProvider,
      refundAmount: upgradeInfo?.remainingAmountCents,
      wasTrialing: upgradeInfo?.isTrialing,
    });
  }, [track, upgradeInfo, user?.monetization?.subscriptionProvider]);

  const onSubmitComplete = useCallback(() => {
    trackOnUpgradeSuccess();
    onSubmit();
  }, [trackOnUpgradeSuccess, onSubmit]);

  const onStripeUpgradeClick = useCallback(async () => {
    setIsLoadingPayment(true);
    trackOnButtonClick();

    const payTrackingInfo = {
      paymentPlatform: 'stripe',
      paymentStrategy: 'upgrade',
    };
    trackStartedEvent(['payment'], payTrackingInfo);

    try {
      await upgradeStripeSubscription(price.id);
      trackCompletedEvent(['payment'], payTrackingInfo);
    } catch (err) {
      trackFailedEvent(['payment'], {
        ...payTrackingInfo,
        error: err.toString(),
      });
      Sentry.captureException(err);
      trackOnUpgradeFailed();
    }

    mutateUser();
    setIsLoadingPayment(false);

    pushModal({
      id: 'subscriptionToast',
      Modal: Toast,
      message: data.account.congratsBusiness,
      duration: 2000,
      onClose: () =>
        settings.values.psPlugin &&
        pushModal({
          id: 'generate-plugin-key-modal',
          Modal: YourPluginKeyModal,
        }),
    });

    onSubmitComplete();
  }, [
    trackOnButtonClick,
    mutateUser,
    pushModal,
    data.account.congratsBusiness,
    onSubmitComplete,
    upgradeStripeSubscription,
    price.id,
    trackOnUpgradeFailed,
    settings.values.psPlugin,
  ]);

  useEffect(() => {
    if (!paywallPrices || isLoading || upgradeInfo === undefined) {
      // avoid track spinner
      return;
    }
    trackEvent(['paywall', 'shown'], { type: 'upgrade_modal' });
  }, [paywallPrices, isLoading, upgradeInfo]);

  if (!paywallPrices || isLoading || upgradeInfo === undefined) {
    return <Spinner className="px-32 py-48" inline={false} />;
  }

  return (
    <div className="relative text-black py-10 max-w-80">
      <div className="bg-white rounded-3xl border-2 border-gray-100 shadow-white-button overflow-hidden">
        <div className="flex">
          <div>
            <button
              type="button"
              className="mt-8 mx-6"
              onClick={() => {
                track('upgrade_modal', 'dismissed');

                setOnboarding((prev) => ({
                  ...prev,
                  selectedPaymentStrategy: null,
                }));
              }}
              disabled={isBusy}
            >
              <img {...caretLeft} className="rtl:scale-x-[-1]" alt="" />
            </button>
          </div>
          <div className="me-7 mt-4 mb-6 flex-grow">
            <div>
              <p className="SB16 mb-1 text-gray-400">
                <span className="M16">
                  {selectedPaymentStrategy?.title || ''}
                </span>
              </p>
            </div>
            <div className="flex justify-between items-center">
              <p>
                -
                {formattedPrice.split('/').map((text, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={i} className={i ? 'M14' : 'SB20'}>
                    {i ? `/` : ''}
                    {text}
                  </span>
                ))}
              </p>
              {!!offBadge && (
                <p className="B10 ms-2 text-black text-center bg-secondary-yellow py-1 px-2 rounded-full">
                  {t(data.onboarding.discount, { n: offBadge }, 'uppercase')}
                </p>
              )}
            </div>
            {!upgradeInfo.isTrialing && (
              <>
                <hr className="border-gray-100 my-4" />
                <div>
                  <p className="SB16 mb-1 text-gray-400">
                    <span className="M16">{t(data.account.refundAmount)}</span>
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-secondary-green">
                    <span className="SB20">{`+${formattedPrice.charAt(0)}${(
                      upgradeInfo.remainingAmountCents / 100
                    ).toFixed(2)}`}</span>
                  </p>
                  <p className="B10 ms-2 text-[#1daa63] text-center bg-secondary-green-30 py-1 px-2 rounded-full">
                    {t(
                      data.account.nDaysLeft,
                      { n: upgradeInfo.daysLeft },
                      'uppercase'
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bg-gray-100 bg-opacity-40 flex p-4 px-6 items-center">
          <img className="me-2 w-4 h-4" {...paymentWarning} alt="" />
          <div className="ms-3 text-overlay-black-60">
            <p className="M12">
              {t(
                upgradeInfo.isTrialing
                  ? data.account.businessUpgradeFreeTrial
                  : data.account.upgradeExplanation
              )}
            </p>
          </div>
        </div>
      </div>
      {user?.monetization?.subscriptionProvider === 'stripe' ? (
        <button
          className="w-full mt-6 btn btn--xl shadow-dark-button btn--black md:px-4 lg:px-[inherit] !SB18"
          disabled={isLoadingPayment}
          onClick={onStripeUpgradeClick}
        >
          {t(data.account.upgradeToBusiness)}
          {isLoadingPayment && <Spinner color="white" size={20} />}
        </button>
      ) : (
        // TODO remove after migration
        <PayPalUpgradeButton
          paypalPlanId={price.paypalPlanNoFtId}
          onButtonClick={trackOnButtonClick}
          onRequestCancel={trackOnUpgradeFailed}
          onPaymentSubmit={onSubmitComplete}
        />
      )}
      {selectedPaymentStrategy?.id === PaymentStrategiesIds.Business && (
        <p className="M12 mt-[16px] text-overlay-black-40 text-center mx-[26px]">
          {t(data.common.acceptTosAndPrivacyPolicy1)}{' '}
          <a href="/docs/remini-business-additional-terms.pdf" target="_blank">
            <u>{t(data.common.additionalTerms)}</u>
          </a>
          .
        </p>
      )}
    </div>
  );
}
