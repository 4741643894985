import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import useCallbackReference from '@/core/stateManager/useCallbackReference';

export interface ModalWrapperProps {
  className?: string;
  label?: string;
  children?: React.ReactNode;
  isForeground?: boolean;
  onFocusTrap?: CallableFunction;
  onClose?: any;
  additionalStyle?: { [key: string]: string };
}

export default function ModalWrapper({
  className = '',
  label,
  children,
  isForeground,
  onFocusTrap,
  onClose,
  additionalStyle,
}: ModalWrapperProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const callOnClose = useCallbackReference(onClose);
  const callOnFocusTrap = useCallbackReference(onFocusTrap);

  useEffect(() => callOnClose, [callOnClose]);

  return (
    <div className="w-full h-full p-4 flex justify-center items-center pointer-events-none">
      {/* Focus trap */}
      <div onFocus={callOnFocusTrap} tabIndex={0} />
      <motion.div
        layout
        className={`max-h-full p-5 section-foreground rounded-3xl shadow-md ${className} ${
          isForeground ? 'pointer-events-auto' : ''
        }`}
        style={additionalStyle}
        ref={containerRef}
        role="dialog"
        aria-modal="true"
        aria-label={label}
      >
        {children}
      </motion.div>
      {/* Focus trap */}
      <div onFocus={callOnFocusTrap} tabIndex={0} />
    </div>
  );
}
