import {
  EventBuffer,
  EventMetadata,
  EventMetadataProvider,
} from '@/core/monitoring/spidersense/src/event';

export enum Severity {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

export type SpiderSenseDebugEvent = {
  severity: Severity;
  categories: string[];
  description?: string;
  errorCode?: string;
  info: Record<string, unknown>;
};

export type EventManager = {
  trackDebugEvent: (event: SpiderSenseDebugEvent) => void;
  trackStartedEvent: (event: SpiderSenseDebugEvent) => void;
  trackCompletedEvent: (event: SpiderSenseDebugEvent) => void;
  trackFailedEvent: (event: SpiderSenseDebugEvent) => void;
  trackCanceledEvent: (event: SpiderSenseDebugEvent) => void;
};

export type SpiderSenseDebugEventWithMetadata = SpiderSenseDebugEvent &
  EventMetadata;

type CreateEventManagerOptions = {
  eventBuffer: EventBuffer;
  eventMetadataProvider: EventMetadataProvider;
};

export const createEventManager = ({
  eventBuffer,
  eventMetadataProvider,
}: CreateEventManagerOptions): EventManager => {
  const startedEventsTimestamps: Record<string, number> = {};

  const toStringCategory = (categories: string[]) => categories.join('/');

  const track = (event: SpiderSenseDebugEvent) => {
    const eventMetadata = eventMetadataProvider.getEventMetadata();
    eventBuffer.enqueueEvent({
      ...event,
      ...eventMetadata,
    });
  };

  const trackFailableOperationStarted = (event: SpiderSenseDebugEvent) => {
    const category = toStringCategory(event.categories);
    const isDuplicateStartedEvent = Boolean(
      category in startedEventsTimestamps
    );

    if (isDuplicateStartedEvent) {
      track({
        categories: [
          'spidersense',
          'error',
          'failable_operation',
          'multiple_starts',
        ],
        description:
          'The app tried to start an operation that was already started',
        info: {
          event_category: category,
        },
        severity: Severity.WARNING,
      });
    }

    const categoriesWithStarted = [...event.categories, 'started'];
    startedEventsTimestamps[category] = Date.now(); // in any case, we save the most updated started timestamp
    track({
      ...event,
      categories: categoriesWithStarted,
    });
  };

  const trackFailableOperationCompleted = (
    event: SpiderSenseDebugEvent,
    completedState: 'completed' | 'failed' | 'canceled'
  ) => {
    const categoriesWithStarted = [...event.categories, completedState];
    const category = toStringCategory(event.categories);

    if (!(category in startedEventsTimestamps)) {
      track({
        categories: [
          'spidersense',
          'error',
          'failable_operation',
          'completing_not_started_operation',
          completedState,
        ],
        description: `The app tried to set state to "${completedState}" for an operation that was not started`,
        info: {
          event_category: category,
        },
        severity: Severity.WARNING,
      });
      return;
    }

    const startedTimestamp = startedEventsTimestamps[category];
    delete startedEventsTimestamps[category];
    track({
      ...event,
      categories: categoriesWithStarted,
      info: {
        ...event.info,
        failable_operation_duration: Date.now() - startedTimestamp,
      },
    });
  };

  return {
    trackDebugEvent: track,
    trackStartedEvent: trackFailableOperationStarted,
    trackCompletedEvent: (event: SpiderSenseDebugEvent) =>
      trackFailableOperationCompleted(event, 'completed'),
    trackFailedEvent: (event: SpiderSenseDebugEvent) =>
      trackFailableOperationCompleted(event, 'failed'),
    trackCanceledEvent: (event: SpiderSenseDebugEvent) =>
      trackFailableOperationCompleted(event, 'canceled'),
  };
};
