import { isBusySelector } from '@/stores/appStore';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalState, popModalSelector } from '@/stores/modalStore';
import useEventListener from '@/core/listeners/useEventListener';

export default function Root() {
  const modals = useRecoilValue(modalState);
  const isBusy = useRecoilValue(isBusySelector);
  const popModal = useSetRecoilState(popModalSelector);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isBusy && e.key === 'Escape') {
        popModal(null);
      }
    },
    [isBusy, popModal]
  );

  useEventListener(null, 'keydown', onKeyDown);

  return (
    <AnimatePresence>
      {modals.map(({ id, Modal, ...rest }, index: number, array) => {
        const isForeground = index === array.length - 1;
        return (
          <motion.div
            key={id}
            className={`fixed inset-0 z-10 ${
              rest.avoidBackgroundOverlay ? '' : 'bg-overlay-black-70'
            }`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <motion.div
              className="absolute overflow-y-auto w-full h-full inset-0"
              onClick={(e) => {
                if (!isBusy && e.target === e.currentTarget) {
                  popModal(null);
                }
              }}
              initial={{ scale: rest.initialModalScaleSize || 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: rest.initialModalScaleSize || 0.8 }}
              transition={{ duration: 0.2 }}
            >
              <Modal isForeground={isForeground} id={id} {...rest} />
            </motion.div>
          </motion.div>
        );
      })}
    </AnimatePresence>
  );
}
