import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import Spinner from '@/ui/content/Spinner';
import paymentWarning from '@/public/images/icons/payment-warning.png';
import calendar from '@/public/images/icons/calendar.svg';
import caretLeft from '@/public/images/icons/caret-left.svg';
import { useLocalization } from '@/services/localizationService';
import { Price } from '@/services/priceService';
import { isBusySelector } from '@/stores/appStore';
import {
  onboardingState,
  selectedPeriodState,
  showFreeTrialSelector,
} from '@/stores/onboardingStore';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';
import {
  FREE_TRIAL_DAYS,
  PaymentStrategiesIds,
  STRIPE_APPEARANCE,
} from '@/services/paymentService';
import { mediaShownInComparerState } from '@/stores/mediaStore';
import { useStripeProvider } from '@/core/stripe/useStripeProvider';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { useUser } from '@/services/userService';
import settingsState from '@/stores/settingsStore';
import PaymentRatio from '../PaymentRatio';
import PaymentInformationForm from '../../PaymentInformationForm';

interface Props {
  selectedPrice: Price;
  onSubmit?: CallableFunction;
}

export default function PaymentStrategiesStep2({
  selectedPrice,
  onSubmit,
}: Props) {
  const [onboarding, setOnboarding] = useRecoilState(onboardingState);
  const { usePaywallPrices, useFormatRecurringPrice } = usePriceService();
  const paywallPrices = usePaywallPrices();
  const { t, data } = useLocalization();
  const isBusy = useRecoilValue(isBusySelector);
  const mediaInComparer = useRecoilValue(mediaShownInComparerState);
  const willDownloadAutomatically = Boolean(mediaInComparer.hasWatermark);

  const { data: user } = useUser();
  const settings = useRecoilValue(settingsState);

  const { selectedPeriod, selectedPaymentStrategy } = onboarding;
  const showFreeTrial = useRecoilValue(showFreeTrialSelector);

  const price = useFormatRecurringPrice(
    paywallPrices?.[selectedPaymentStrategy?.id || '']?.[selectedPeriod]
  );
  const { stripePromise } = useStripeProvider();

  const resetSelectedPeriod = useResetRecoilState(selectedPeriodState);
  const isOneTimePayment =
    selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime;

  if (!paywallPrices || !user) return <Spinner inline={false} />;

  const off =
    selectedPeriod === 'monthly' ? 35 : selectedPeriod === 'yearly' ? 50 : 0;

  const options: StripeElementsOptions = {
    mode: isOneTimePayment ? 'payment' : 'setup',
    currency: selectedPrice.currency,
    amount: isOneTimePayment ? selectedPrice.amountCents : undefined,
  };

  const hasIntroPrice =
    user.monetization.trialAvailable &&
    !settings.values.isFreeTrialEnabled &&
    settings.values.isIntroPriceEnabled;

  return (
    <div className="relative w-full bg-white text-black p-10">
      <div className="bg-white rounded-3xl border-2 border-gray-100 shadow-white-button overflow-hidden">
        <div className="p-6 pt-5 pb-4 flex justify-between items-center">
          <div className="flex items-center">
            <button
              type="button"
              className="p-2 -ms-2 me-2"
              onClick={() => {
                setOnboarding((prev) => ({
                  ...prev,
                  selectedPaymentStrategy: null,
                }));
                resetSelectedPeriod();
              }}
              disabled={isBusy}
            >
              <img {...caretLeft} className="rtl:scale-x-[-1]" alt="" />
            </button>
            <div>
              <p className="SB16 mb-1 text-gray-400">
                {showFreeTrial && (
                  <span>
                    <span className="text-primary-accent">
                      🎁{' '}
                      {t(data.onboarding.daysFreeAlt, { n: FREE_TRIAL_DAYS })}
                    </span>
                    {' · '}
                  </span>
                )}
                {hasIntroPrice && (
                  <span>
                    <span className="text-primary-accent">
                      🎁{' '}
                      <span className="text-gray-400 line-through SB18">
                        {price.split('/')[0]}
                      </span>
                      <span className="SB18 text-primary-accent ms-1">
                        {t(data.onboarding.daysIntro)}
                      </span>
                    </span>
                    {' · '}
                  </span>
                )}
                <span className="M16">
                  {selectedPaymentStrategy?.title || ''}
                </span>
              </p>
              <p>
                {!hasIntroPrice && (
                  <PaymentRatio
                    price={price}
                    className="M14"
                    firstSpanClassName="SB20"
                    hasCredits={
                      selectedPaymentStrategy?.id ===
                      PaymentStrategiesIds.OneTime
                    }
                    creditCount={selectedPaymentStrategy?.exports}
                    hasIntroPrice={hasIntroPrice}
                  />
                )}
              </p>
            </div>
          </div>
          {!!off && (
            <p className="B10 ms-2 text-black text-center bg-secondary-yellow py-1 px-2 rounded-full">
              {t(data.onboarding.discount, { n: off }, 'uppercase')}
            </p>
          )}
        </div>
        {selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime ? (
          willDownloadAutomatically && (
            <div className="bg-gray-50 px-7 py-5 flex items-center">
              <img className="block me-2 w-4 h-4" {...paymentWarning} alt="" />
              <p className="M12 text-overlay-black-60">
                {(selectedPaymentStrategy?.exports ?? 0) > 1
                  ? t(data.onboarding.creditsWarning)
                  : t(data.onboarding.oneTimeWarning)}
              </p>
            </div>
          )
        ) : (
          <div className="bg-gray-100 bg-opacity-40 flex p-4 px-6">
            <img {...calendar} alt="" />
            <div className="ms-3 text-overlay-black-60">
              {hasIntroPrice ? (
                <p className="M12 whitespace-pre-line">
                  {t(data.onboarding.automaticRenewalTerms, {
                    formattedPrice: price,
                  })}
                </p>
              ) : (
                <>
                  <p className="B12">{t(data.onboarding.emailReminder)}:</p>
                  <p className="M12">
                    {showFreeTrial
                      ? t(data.onboarding.emailReminderSubtitleWithFT)
                      : t(data.onboarding.emailReminderSubtitle)}
                  </p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <hr className="border-gray-100 my-8" />
      <p className="SB18 tracking-normal mb-4">
        {t(data.onboarding.paymentMethod)}
      </p>
      <Elements
        stripe={stripePromise}
        options={{
          ...options,
          appearance: STRIPE_APPEARANCE,
        }}
      >
        <PaymentInformationForm
          selectedPrice={selectedPrice}
          onSubmit={onSubmit || (() => {})}
          willDownloadAutomatically={willDownloadAutomatically}
          isIntroPriceActive={hasIntroPrice}
        />
      </Elements>
    </div>
  );
}
