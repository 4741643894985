export default function getDeviceType() {
  const { userAgent } = navigator;
  const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(
    userAgent
  );
  if (isTablet) return 'tablet';
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  return isMobile ? 'mobile' : 'desktop';
}
