import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';
import settingsState from '@/stores/settingsStore';
import {
  OneTimePayments,
  PaymentDetails,
  RecurringPayments,
} from '../settingsService';
import { PaywallPrices } from './priceTypes';

export function usePaywallPrices(): PaywallPrices | undefined {
  const settings = useRecoilValue(settingsState);

  const { paymentStrategies } = settings.values;

  const allPriceIds: string[] = [];
  Object.values(paymentStrategies).forEach(
    (paymentStrategy: RecurringPayments | OneTimePayments) => {
      Object.values(paymentStrategy).forEach(
        (paymentDetails: PaymentDetails) => {
          if (paymentDetails.price) allPriceIds.push(paymentDetails.price);
        }
      );
    }
  );

  const { usePricesByIds } = usePriceService();
  const { data } = usePricesByIds(allPriceIds);

  return useMemo(
    () =>
      data && {
        base: {
          weekly: {
            id: paymentStrategies.base.weekly.price,
            exports: paymentStrategies.base.weekly.exports,
            deprecatedPrices: paymentStrategies.base.weekly.deprecatedPrices,
            ...data.find(
              (price) => price.priceId === paymentStrategies.base.weekly.price
            )!,
          },
          monthly: {
            id: paymentStrategies.base.monthly.price,
            exports: paymentStrategies.base.monthly.exports,
            deprecatedPrices: paymentStrategies.base.monthly.deprecatedPrices,
            ...data.find(
              (price) => price.priceId === paymentStrategies.base.monthly.price
            )!,
          },
          yearly: {
            id: paymentStrategies.base.yearly.price,
            exports: paymentStrategies.base.yearly.exports,
            deprecatedPrices: paymentStrategies.base.yearly.deprecatedPrices,
            ...data.find(
              (price) => price.priceId === paymentStrategies.base.yearly.price
            )!,
          },
        },
        personal: {
          weekly: {
            id: paymentStrategies.personal.weekly.price,
            exports: paymentStrategies.personal.weekly.exports,
            deprecatedPrices:
              paymentStrategies.personal.weekly.deprecatedPrices,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.personal.weekly.price
            )!,
          },
          monthly: {
            id: paymentStrategies.personal.monthly.price,
            exports: paymentStrategies.personal.monthly.exports,
            deprecatedPrices:
              paymentStrategies.personal.monthly.deprecatedPrices,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.personal.monthly.price
            )!,
          },
          yearly: {
            id: paymentStrategies.personal.yearly.price,
            exports: paymentStrategies.personal.yearly.exports,
            deprecatedPrices:
              paymentStrategies.personal.yearly.deprecatedPrices,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.personal.yearly.price
            )!,
          },
        },
        business: {
          weekly: {
            id: paymentStrategies.business.weekly.price,
            exports: paymentStrategies.business.weekly.exports,
            deprecatedPrices:
              paymentStrategies.business.weekly.deprecatedPrices,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.business.weekly.price
            )!,
          },
          monthly: {
            id: paymentStrategies.business.monthly.price,
            exports: paymentStrategies.business.monthly.exports,
            deprecatedPrices:
              paymentStrategies.business.monthly.deprecatedPrices,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.business.monthly.price
            )!,
          },
          yearly: {
            id: paymentStrategies.business.yearly.price,
            exports: paymentStrategies.business.yearly.exports,
            deprecatedPrices:
              paymentStrategies.business.yearly.deprecatedPrices,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.business.yearly.price
            )!,
          },
        },
        oneTime: {
          singleExport: {
            id: paymentStrategies.oneTime.singleExport.price,
            exports: paymentStrategies.oneTime.singleExport.exports,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.oneTime.singleExport.price
            )!,
          },
          threeExports: {
            id: paymentStrategies.oneTime.singleExport.price,
            exports: paymentStrategies.oneTime.threeExports.exports,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.oneTime.threeExports.price
            )!,
          },
          tenExports: {
            id: paymentStrategies.oneTime.tenExports.price,
            exports: paymentStrategies.oneTime.tenExports.exports,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.oneTime.tenExports.price
            )!,
          },
          twentyExports: {
            id: paymentStrategies.oneTime.twentyExports.price,
            exports: paymentStrategies.oneTime.twentyExports.exports,
            ...data.find(
              (price) =>
                price.priceId === paymentStrategies.oneTime.twentyExports.price
            )!,
          },
        },
      },
    [paymentStrategies, data]
  );
}
