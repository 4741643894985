import { isAxiosError } from 'axios';
import onlyOnceRunner from '../concurrency/onlyOnceRunner';
import whenInBrowser from '../environment/whenInBrowser';
import { getMonitoringAPI } from './monitoringAPI';
import { Severity } from './spidersense/src/event';

export function trackEvent(
  categories: string[],
  info: Record<string, unknown> = {},
  severity: Severity = Severity.INFO
) {
  whenInBrowser(() => {
    const { client } = getMonitoringAPI();
    client.trackDebugEvent({
      categories,
      info,
      severity,
    });
  })();
}

export function trackStartedEvent(
  categories: string[],
  info: Record<string, unknown> = {},
  severity: Severity = Severity.INFO
) {
  whenInBrowser(() => {
    const { client } = getMonitoringAPI();
    client.trackStartedEvent({
      categories,
      info,
      severity,
    });
  })();
}

export function trackCompletedEvent(
  categories: string[],
  info: Record<string, unknown> = {},
  severity: Severity = Severity.INFO
) {
  whenInBrowser(() => {
    const { client } = getMonitoringAPI();
    client.trackCompletedEvent({
      categories,
      info,
      severity,
    });
  })();
}

export function trackFailedEvent(
  categories: string[],
  info: Record<string, unknown> = {},
  severity: Severity = Severity.INFO
) {
  whenInBrowser(() => {
    const { client } = getMonitoringAPI();
    client.trackFailedEvent({
      categories,
      info,
      severity,
    });
  })();
}

export function trackCanceledEvent(
  categories: string[],
  info: Record<string, unknown> = {},
  severity: Severity = Severity.INFO
) {
  whenInBrowser(() => {
    const { client } = getMonitoringAPI();
    client.trackCanceledEvent({
      categories,
      info,
      severity,
    });
  })();
}

export function generateTracker<
  Info extends Record<string, unknown> = Record<string, unknown>
>(
  categories: string[],
  options: { globalSeverity?: Severity; trackEventsOnlyOnce?: boolean } = {}
) {
  const { globalSeverity = Severity.INFO, trackEventsOnlyOnce = false } =
    options;
  let _info: Info | Record<string, never> = {};
  let _isStarted = false;
  let _isCompleted = false;
  let _isFailed = false;
  let _isCanceled = false;

  const setInfo = (info: Info) => {
    _info = info;
  };

  const start = (severity: Severity = globalSeverity) => {
    _isStarted = true;
    trackStartedEvent(categories, _info, severity);
  };

  const complete = (severity: Severity = globalSeverity) => {
    _isCompleted = true;
    trackCompletedEvent(categories, _info, severity);
  };

  const fail = (error: unknown, severity: Severity = globalSeverity) => {
    _isFailed = true;
    const failureInfo = {
      ..._info,
      error: stringifyUnknownError(error),
    };
    trackFailedEvent(categories, failureInfo, severity);
  };

  const cancel = (severity: Severity = globalSeverity) => {
    _isCanceled = true;
    trackCanceledEvent(categories, _info, severity);
  };

  return {
    setInfo,
    start: trackEventsOnlyOnce ? onlyOnceRunner(start) : start,
    complete: trackEventsOnlyOnce ? onlyOnceRunner(complete) : complete,
    fail: trackEventsOnlyOnce ? onlyOnceRunner(fail) : fail,
    cancel: trackEventsOnlyOnce ? onlyOnceRunner(cancel) : cancel,
    isStarted: () => _isStarted,
    isCompleted: () => _isCompleted,
    isFailed: () => _isFailed,
    isCanceled: () => _isCanceled,
  };
}

function stringifyUnknownError(e: unknown): string {
  // if it's an Error, return its toString
  if (e instanceof Error) {
    return e.toString();
  }

  // if it's an Axios Error, print its cause
  if (isAxiosError(e)) {
    return `${e.cause}`;
  }

  // if it's a normal object, try to stringify it
  if (e && typeof e === 'object') {
    try {
      return JSON.stringify(e);
    } catch (err) {
      console.debug('Error while stringifying the object.');
    }
  }

  // if not anything else, fallback to interpolation
  return `${e}`;
}
