import { StaticImageData } from 'next/image';
import {
  AiPipelineChoices,
  SectionName,
} from '@/services/settingsService/aiTypes';
import { ConvertedImage, TaskStatus } from '../uploaderService';

export enum ImageEditorElements {
  Toggle = 'toggle',
  Filters = 'filters',
  Dropdown = 'dropdown',
}

export interface EnhanceAdditionalParams {
  removeCap?: boolean;
  faceDetectorMaxInputRes?: number;
  jpegQuality?: number;
}

export interface ImageSelectorMedia {
  id: string;
  outputUrl: string;
  inputUrl?: string;
  convertedImages?: ConvertedImage[];
  contentType?: string;
  hasWatermark?: boolean;
  bulkPosition?: number;
  status?: TaskStatus;
  aiPipeline?: AiPipelineChoices;
}

export interface EditorPipelineStepStyle {
  key: string;
  title: string;
  value: object;
  disabled: boolean;
  thumbnailPath: string;
}

export interface EditorPipelineStep {
  tool: string;
  title: string | null;
  visualization: ImageEditorElements;
  defaultModel: string | null;
  iconPath: string | null;
  tooltip?: { title: string; body: string; mediaUrl: string };
  models: EditorPipelineStepStyle[];
  isBlockedForNonSubscribers?: boolean;
  isBlockedForPersonalUsers?: boolean;
}

export interface EditorSection {
  key: SectionName;
  copy: { title: string; subtitle: string };
  icon: StaticImageData;
  pipelineSteps: EditorPipelineStep[];
}
