import {
  fromCamelCaseToSnakeCaseRecursive,
  fromSnakeCaseToCamelCaseRecursive,
} from '@/core/objectManipulation/objectManipulation';

class HttpError extends Error {
  public status: number;

  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export interface TokenService {
  getToken: () => string | undefined;
}

export const createHttpService = (tokenService: TokenService) => {
  const fetch = async (...args: [string, { [key: string]: any }]) => {
    const res: Response = await window.fetch(...args);
    const contentType = res.headers.get('Content-Type');
    const isJSON: boolean =
      typeof contentType === 'string' &&
      contentType.includes('application/json');
    const body = isJSON
      ? fromSnakeCaseToCamelCaseRecursive(await res.json())
      : await res.text();
    if (res.status >= 400) {
      const message = isJSON
        ? body.errorDescription
        : 'An unknown error occurred.';
      throw new HttpError(res.status, message);
    } else {
      return body;
    }
  };

  const buildUrl = (path: string, queryParams = {}) => {
    const baseHost: string =
      typeof process.env.NEXT_PUBLIC_BACKEND_DOMAIN !== 'undefined'
        ? `https://${process.env.NEXT_PUBLIC_BACKEND_DOMAIN}`
        : document.location.origin;

    const url = new URL(`${baseHost}/api${path}`);
    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      url.searchParams.set(key, value);
    }
    return url.toString();
  };

  const getBaseHeaders = () =>
    tokenService.getToken()
      ? {
          authorization: `Bearer ${tokenService.getToken()}`,
        }
      : {};

  const get = (path: string, queryParams?: { [key: string]: string }) =>
    fetch(buildUrl(path, queryParams), {
      method: 'GET',
      headers: getBaseHeaders(),
    });

  const post = (
    path: string,
    body?: { [key: string]: any },
    headers?: { [key: string]: any }
  ) =>
    fetch(buildUrl(path), {
      method: 'POST',
      headers: {
        ...getBaseHeaders(),
        ...headers,
        'Content-Type': 'application/json',
      },
      body: body && JSON.stringify(fromCamelCaseToSnakeCaseRecursive(body)),
    });

  const put = (
    path: string,
    body?: { [key: string]: any },
    headers?: { [key: string]: any }
  ) =>
    fetch(buildUrl(path), {
      method: 'PUT',
      headers: {
        ...getBaseHeaders(),
        ...headers,
        'Content-Type': 'application/json',
      },
      body: body && JSON.stringify(fromCamelCaseToSnakeCaseRecursive(body)),
    });

  const deleteRequest = (path: string, body?: { [key: string]: any }) =>
    fetch(buildUrl(path), {
      method: 'DELETE',
      headers: { ...getBaseHeaders(), 'Content-Type': 'application/json' },
      body: body && JSON.stringify(fromCamelCaseToSnakeCaseRecursive(body)),
    });

  return { put, get, deleteRequest, post };
};

export type HttpService = ReturnType<typeof createHttpService>;
