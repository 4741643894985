import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

type LinkProps = React.ComponentProps<typeof Link>;

interface Props {
  path: string;
  query?: Record<string, any>;
  preserveParams?: string[] | 'all';
}

export default function LinkWithQuery({
  path,
  query,
  preserveParams = ['v'], // By default, preserve "v" param only
  ...rest
}: Props & Omit<LinkProps, 'href'>) {
  const router = useRouter();

  const mergedQuery = { ...router.query, ...query };

  // Remove empty params (e.g. `param=&param2=`), just to clean up
  // Object.keys(mergedQuery).forEach(
  //   (key) => !mergedQuery[key] && delete mergedQuery[key]
  // );

  const finalQuery =
    preserveParams === 'all'
      ? mergedQuery
      : preserveParams.reduce(
          (acc, curr) => Object.assign(acc, { [curr]: mergedQuery[curr] }),
          {}
        );

  return <Link href={{ pathname: path, query: finalQuery }} {...rest} />;
}
