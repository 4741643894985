import { queryParamsAllowList } from './landingQueryAllowList';

export const filterLandingQueryParams = (paramsFound: {
  [k: string]: string;
}) => {
  const entries = queryParamsAllowList
    .filter((key) => typeof paramsFound[key] !== 'undefined')
    .map((key) => [key, paramsFound[key]]);
  return Object.fromEntries(entries);
};
