import { usePaywallPrices } from '@/services/priceService/usePaywallPrices';
import settingsState from '@/stores/settingsStore';
import { useRecoilValue } from 'recoil';

export default function useHighlightedCreditPack() {
  const settings = useRecoilValue(settingsState);
  const prices = usePaywallPrices();
  const exists =
    settings.values.highlightedOtpStrategy &&
    prices?.oneTime[settings.values.highlightedOtpStrategy];
  return exists ? settings.values.highlightedOtpStrategy : undefined;
}
