import { useAuthService } from '@/layout/appWrapper/ServiceProvider';
import { OAuthProvider } from 'firebase/auth';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { AccountLinkingNeededModal } from './modals';

export default function useAccountLinking() {
  const pushModal = useSetRecoilState(pushModalSelector);
  const { isAccountLinkingNeededError } = useAuthService();
  return useCallback(
    (error: Error) => {
      if (isAccountLinkingNeededError(error)) {
        if (!error.customData) throw new Error('Account linking error');
        const userEmail = error.customData.email;
        const pendingCredential = OAuthProvider.credentialFromError(error);
        window.sessionStorage.setItem(
          'auth:pendingCredential',
          JSON.stringify(pendingCredential)
        );
        pushModal({
          id: 'account-linking-needed',
          Modal: AccountLinkingNeededModal,
          email: userEmail,
        });
        return true;
      }
      return false;
    },
    [isAccountLinkingNeededError, pushModal]
  );
}
