import { generateTracker } from '@/core/monitoring/trackEvents';

type EnhanceFlowTrackerInfo = {
  media_type: 'video' | 'image';
  from: 'browse' | 'drag' | 'addPhotos';
  has_multiple_medias: boolean;
};

const flowTracker = generateTracker<EnhanceFlowTrackerInfo>([
  'enhance',
  'flow',
]);

const uploadTracker = generateTracker<EnhanceFlowTrackerInfo>([
  'enhance',
  'upload',
]);

const processTracker = generateTracker<EnhanceFlowTrackerInfo>([
  'enhance',
  'process',
]);

export const enhanceFlowTracker = {
  setInfo: (info: EnhanceFlowTrackerInfo) => {
    flowTracker.setInfo(info);
    uploadTracker.setInfo(info);
    processTracker.setInfo(info);
  },
  startUpload: () => {
    flowTracker.start();
    uploadTracker.start();
  },
  completeUpload: () => uploadTracker.complete(),
  failUpload: (error: unknown) => {
    uploadTracker.fail(error);
    flowTracker.fail(error);
  },
  startProcess: () => processTracker.start(),
  completeProcess: () => {
    processTracker.complete();
    flowTracker.complete();
  },
  failProcess: (error: unknown) => {
    processTracker.fail(error);
    flowTracker.fail(error);
  },
  cancelProcess: () => {
    processTracker.cancel();
    flowTracker.cancel();
  },
  isCanceled: () => flowTracker.isCanceled(),
};

export const reprocessTracker = generateTracker(['reprocess']);
