import { ModalWrapper } from '@/core/modal';
import { useLocalization } from '@/services/localizationService';
import {
  MaxFileQuantityForUpload,
  TaskError,
} from '@/services/uploaderService';
import { closeModalSelector } from '@/stores/modalStore';
import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import useContactFormUrl from '@/services/supportService/useContactFormUrl';

export default function UploadMediaErrorModal({
  id,
  error,
  mediaSize,
  mediaDuration,
  maxFileQuantityLeftForUpload,
  maxPeriodSizeMb,
  maxPeriodLength,
  ...rest
}: {
  id: string;
  error: TaskError;
  mediaSize?: number;
  mediaDuration?: number;
  maxPeriodSizeMb?: number;
  maxPeriodLength?: number;
  maxFileQuantityLeftForUpload?: number;
}) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { t, data } = useLocalization();
  const closeModal = useSetRecoilState(closeModalSelector);
  const contactFormUrl = useContactFormUrl({
    source: 'upload_media_error_alert',
  });

  const focusFirstElement = () => closeButtonRef.current?.focus();

  const messages = {
    size: {
      title: t(data.processing.videoFileIsTooLarge),
      body: t(data.processing.videoFileIsTooLargeBodyText, {
        mediaSize,
      }),
    },
    duration: {
      title: t(data.processing.videoFileIsTooLong),
      body: t(data.processing.videoFileIsTooLongBodyText, {
        mediaDuration,
      }),
    },
    size_business: {
      title: t(data.processing.videoFileIsTooLarge),
      body: t(data.processing.limitVideoSizeReachedBusiness, {
        businessVideoWeeklySizeLimit: maxPeriodSizeMb,
      }),
    },
    duration_business: {
      title: t(data.processing.videoFileIsTooLong),
      body: t(data.processing.limitVideoDurationReachedBusiness, {
        businessVideoWeeklyDurationLimit: maxPeriodLength,
      }),
    },
    too_many_files: {
      title: t(data.common.limitReached),
      body: t(data.common.limitReachedWithQuantityLeftDescription, {
        MaxFileQuantityForUpload,
        MaxFileQuantityLeftForUpload: maxFileQuantityLeftForUpload,
      }),
    },
    wrong_file_format: {
      title: t(data.common.formatError),
      body: t(data.common.formatErrorDescription),
    },
  };

  useEffect(focusFirstElement, []);

  return (
    <ModalWrapper
      label="Upload media error"
      className="text-center max-w-md"
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      <p className="SB20 text-black">{messages[error].title}</p>
      <p className="M16 text-black mt-3">{messages[error].body}</p>
      <button
        ref={closeButtonRef}
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6 SB16"
        onClick={() => closeModal(id)}
      >
        {error === TaskError.TooManyFiles
          ? t(data.common.selectPhotos)
          : t(data.common.okGotIt)}
      </button>
      {error === TaskError.TooManyFiles ? (
        <a
          className="leading-none py-4 mt-4 SB16 block contrast-high cursor-pointer"
          onClick={() => closeModal(id)}
        >
          {t(data.common.dismiss)}
        </a>
      ) : (
        <a
          className="leading-none py-4 mt-4 SB16 block contrast-high"
          href={contactFormUrl}
          target="_blank"
          rel="noreferrer"
        >
          {t(data.common.contactUs)}
        </a>
      )}
    </ModalWrapper>
  );
}
