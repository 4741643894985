import { GenericModal } from '@/ui/content';
import { useUser } from '@/services/userService';

interface Props {
  id: string;
  [x: string]: any;
}

export default function UpdateCompletedModal({ id, ...rest }: Props) {
  const { mutate: mutateUser } = useUser();

  return (
    <GenericModal
      id={id}
      showCloseIcon={false}
      className="text-center max-w-xs"
      {...rest}
    >
      <p className="SB20 text-black">Done!</p>
      <p className="M16 text-gray-400 mt-3">
        Please, reload the page to get the correct settings and user data after
        the changes.
      </p>
      <button
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6"
        onClick={() => {
          mutateUser();
          window.location.reload();
        }}
      >
        Reload
      </button>
    </GenericModal>
  );
}
