import {
  contactFormBody,
  contactFormEmail,
  contactFormName,
  contactFormSubject,
} from '@/stores/contactFormStore';
import { useRecoilValue } from 'recoil';

export default function useIsRequestDataComplete() {
  const name = useRecoilValue(contactFormName);
  const email = useRecoilValue(contactFormEmail);
  const subject = useRecoilValue(contactFormSubject);
  const body = useRecoilValue(contactFormBody);
  return name && email && subject && body;
}
