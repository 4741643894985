import { completedSurveysState } from '@/stores/completedSurveysStore';
import { useRecoilState } from 'recoil';
import { useUser } from '../userService';

export const useFeatureRankingSurvey = () => {
  const [completedSurveys, setCompletedSurveys] = useRecoilState(
    completedSurveysState
  );
  const { data: user } = useUser();

  const shouldShowSurvey =
    user?.monetization.isSubscribed &&
    !user.monetization.subscriptionIncludesTrial;

  return {
    shouldShowSurvey,
    isCompleted: completedSurveys.featureRanking,
    complete: () =>
      setCompletedSurveys((prev) => ({ ...prev, featureRanking: true })),
  };
};
