type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;
type SnakeToCamelCaseNested<T> = T extends object
  ? {
      [K in keyof T as SnakeToCamelCase<K & string>]: SnakeToCamelCaseNested<
        T[K]
      >;
    }
  : T;
type CamelToSnakeCaseNested<T> = T extends object
  ? {
      [K in keyof T as CamelToSnakeCase<K & string>]: CamelToSnakeCaseNested<
        T[K]
      >;
    }
  : T;

export function fromCamelCaseToSnakeCase(text: string): string {
  return text.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function fromCamelCaseToSnakeCaseRecursive<T>(
  obj: T
): T extends any[]
  ? CamelToSnakeCaseNested<T[number]>[]
  : T extends object
  ? CamelToSnakeCaseNested<T>
  : T {
  if (typeof obj === 'object' && obj) {
    if (Array.isArray(obj)) {
      return obj.map((value) =>
        fromCamelCaseToSnakeCaseRecursive(value)
      ) as any;
    }

    const newObj = {};
    for (const key of Object.keys(obj)) {
      const snakeCaseKey = fromCamelCaseToSnakeCase(key);
      const value = obj[key];
      newObj[snakeCaseKey] = fromCamelCaseToSnakeCaseRecursive(value);
    }
    return newObj as any;
  }

  return obj as any;
}

export function fromSnakeCaseToCamelCaseRecursive<T>(
  obj: T
): T extends any[]
  ? SnakeToCamelCaseNested<T[number]>[]
  : T extends object
  ? SnakeToCamelCaseNested<T>
  : T {
  if (typeof obj === 'object' && obj) {
    if (Array.isArray(obj)) {
      return obj.map((value) =>
        fromSnakeCaseToCamelCaseRecursive(value)
      ) as any;
    }

    const newObj = {};
    for (const key of Object.keys(obj)) {
      const keyParts = key.split('_');
      const uppercaseKeyParts = keyParts
        .slice(1)
        .map((keyPart) => keyPart.charAt(0).toUpperCase() + keyPart.slice(1));
      const camelCaseKey = keyParts[0] + uppercaseKeyParts.join('');

      const value = obj[key];
      newObj[camelCaseKey] = fromSnakeCaseToCamelCaseRecursive(value);
    }
    return newObj as any;
  }

  return obj as any;
}
