import greenCheckIcon from '@/public/images/icons/check--green.svg';
import { useLocalization } from '@/services/localizationService';
import { pushModalSelector } from '@/stores/modalStore';
import { Toast } from '@/ui/toast';
import router from 'next/router';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

export default function useSuccessToast() {
  const pushModal = useSetRecoilState(pushModalSelector);
  const { data } = useLocalization();
  return useCallback(() => {
    pushModal({
      id: 'requestSendToast',
      Modal: Toast,
      message: data.support.customerSupportRequestSentSuccess,
      icon: greenCheckIcon,
      duration: 4000,
      onEnd: () => router.push('/'),
    });
  }, [data.support.customerSupportRequestSentSuccess, pushModal]);
}
