import { useLocalization } from '@/services/localizationService';
import {
  FREE_TRIAL_DAYS,
  PaymentStrategiesIds,
  PaymentStrategy,
} from '@/services/paymentService';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { useTracker } from '@/core/tracking';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';
import { PricePeriod } from '@/services/priceService';
import { OneTimePayments } from '@/services/settingsService';
import { CheckIcon, CloseIcon } from '@/ui/icons';
import { SelectionButtons } from '@/ui/selectionButtons';
import settingsState from '@/stores/settingsStore';
import { useRecoilValue } from 'recoil';
import PaymentRatio from './PaymentRatio';
import CreditPackSelection from '../credits/creditPackSelection/CreditPackSelection';

interface Props {
  paymentStrategy: PaymentStrategy;
  defaultPeriod: PricePeriod;
  isFreeTrialEnabled?: boolean;
  isUpgrade?: boolean;
  TopSection?: ReactNode;
  MidSection?: ReactNode;
  BottomSection?: ReactNode;
  onBtnClick?: (event: PaymentStrategy) => void;
  setSelectedPeriod: CallableFunction;
  userPricePeriod?: PricePeriod;
  otpVariants?: OneTimePayments;
  hasIntroPrice?: boolean;
  isWebOnboarding?: boolean;
}

export default function PaymentStrategyPriceCard({
  paymentStrategy,
  defaultPeriod,
  isFreeTrialEnabled = false,
  isUpgrade = false,
  TopSection,
  MidSection,
  BottomSection,
  onBtnClick,
  setSelectedPeriod,
  userPricePeriod,
  otpVariants,
  hasIntroPrice,
  isWebOnboarding = false,
}: Props) {
  const { track } = useTracker();
  const settings = useRecoilValue(settingsState);

  const { usePaywallPrices, useFormatRecurringPrice, useUserPrice } =
    usePriceService();
  const userPrice = useUserPrice();
  const paywallPrices = usePaywallPrices();

  const { t, data } = useLocalization();

  const [period, setPeriod] = useState<PricePeriod>(defaultPeriod);

  const [currentPaymentStrategy, setCurrentPaymentStrategy] =
    useState<PaymentStrategy>(paymentStrategy);

  useEffect(() => {
    if (currentPaymentStrategy.id === PaymentStrategiesIds.OneTime) return;
    setPeriod(defaultPeriod);
  }, [currentPaymentStrategy.id, defaultPeriod, setPeriod]);

  const isUpgradeAndPersonalPlan = useMemo(
    () =>
      isUpgrade && currentPaymentStrategy.id === PaymentStrategiesIds.Personal,
    [isUpgrade, currentPaymentStrategy.id]
  );
  const isUpgradeAndBusinessPlan = useMemo(
    () =>
      isUpgrade && currentPaymentStrategy.id === PaymentStrategiesIds.Business,
    [isUpgrade, currentPaymentStrategy.id]
  );
  const shouldShowBusinessPeriodSelection = useMemo(
    () => isUpgradeAndBusinessPlan && userPricePeriod !== 'yearly',
    [isUpgradeAndBusinessPlan, userPricePeriod]
  );

  const price = isUpgradeAndPersonalPlan
    ? userPrice
    : paywallPrices?.[currentPaymentStrategy.id]?.[period];

  const formattedPrice = useFormatRecurringPrice(price);

  if (!paywallPrices || (isUpgrade && !userPrice)) return null;

  const CTAlabel =
    paymentStrategy.id === PaymentStrategiesIds.OneTime
      ? data.common.letsGoExclamation
      : isFreeTrialEnabled && settings.values.isFreeTrialEnabled
      ? data.common.tryForFree
      : settings.values.expFreeTrialPaywallCtaLabel
      ? data.onboarding.proceedToPayment
      : data.common.letsGoExclamation;

  return (
    <div
      className={`max-w-md border-gray-100 border-2 rounded-3xl overflow-hidden flex-1 flex flex-col ${
        isUpgrade ? 'md:-mt-48 bg-white' : ''
      }`}
    >
      <div
        className={`lg:min-h-min mt-8 mx-10 ${
          isUpgradeAndPersonalPlan || !shouldShowBusinessPeriodSelection
            ? 'pb-8'
            : 'pb-2'
        } border-b border-gray-200`}
      >
        {TopSection || (
          <>
            <p className="SB30 flex">
              <img
                {...currentPaymentStrategy.titleIcon}
                className="me-2 w-[25px]"
                alt="icon"
              />
              {currentPaymentStrategy.title}
            </p>
            <p className="M16 mt-4 text-gray-400">
              {currentPaymentStrategy.subtitle}
            </p>
            {shouldShowBusinessPeriodSelection && (
              <SelectionButtons
                options={
                  userPricePeriod === 'weekly'
                    ? [
                        { key: 'weekly', label: data.common.weekly },
                        {
                          key: 'monthly',
                          label: data.common.monthly,
                          offBadge: 35,
                        },
                        {
                          key: 'yearly',
                          label: data.common.yearly,
                          offBadge: 50,
                        },
                      ]
                    : [
                        { key: 'monthly', label: data.common.monthly },
                        {
                          key: 'yearly',
                          label: data.common.yearly,
                          offBadge: 16,
                        },
                      ]
                }
                mainDivClassName={`${
                  userPricePeriod === 'monthly' ? 'w-52' : 'max-w-xs'
                } bg-gray-100 rounded-full my-6 p-0.5 flex justify-between text-center`}
                selectedOptionExternal={period}
                setSelectedOptionExternal={setSelectedPeriod}
                buttonSelectionClassName="bg-white bg-opacity-100 border-2 border-black"
                labelTextClassName="SB14 text-black"
                additionalOnClick={() => {
                  track('paywall_price', 'selected', {
                    selectedPeriod: period,
                    isUpgrade,
                  });
                }}
              />
            )}
          </>
        )}
      </div>
      <div className="w-full relative pt-6 px-10 pb-13 flex flex-col">
        <CreditPackSelection
          currentPaymentStrategy={currentPaymentStrategy}
          setCurrentPaymentStrategy={setCurrentPaymentStrategy}
          otpVariants={otpVariants}
          period={period}
          setPeriod={setPeriod}
        />
        <div
          className={`flex ${
            otpVariants &&
            currentPaymentStrategy.id === PaymentStrategiesIds.OneTime
              ? 'border-t border-gray-200 mt-6 pt-6'
              : ''
          }`}
        >
          {MidSection || (
            <>
              {currentPaymentStrategy.customPrice ? (
                <div>
                  <p className="SB23 lg:SB25">
                    {t(data.onboarding.customPricing)}
                  </p>
                  {(isFreeTrialEnabled || period !== 'weekly') && (
                    <p className="M16 text-gray-400 mt-1 lg:mt-0">
                      {t(data.onboarding.getInTouch)}!
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  {isFreeTrialEnabled && (
                    <p className="mb-2 B12 text-primary-accent py-[6px] px-3 bg-primary-accent-150 bg-opacity-50 rounded-full w-content">
                      🎁{' '}
                      {t(
                        data.onboarding.daysFreeAlt,
                        { n: FREE_TRIAL_DAYS },
                        'uppercase'
                      )}
                    </p>
                  )}
                  {!!price && (
                    <p>
                      {isFreeTrialEnabled ? (
                        <span className="SB18">
                          {t(data.common.then, null, 'lowercase')}{' '}
                        </span>
                      ) : (
                        settings.values.expFreeTrialPaywallPayNowLabel && (
                          <span className="text-secondary-accent">
                            {t(data.common.payNow, null, 'lowercase')}{' '}
                          </span>
                        )
                      )}
                      <span className="SB25">
                        <PaymentRatio
                          price={formattedPrice}
                          hasCredits={
                            currentPaymentStrategy?.id ===
                            PaymentStrategiesIds.OneTime
                          }
                          creditCount={currentPaymentStrategy?.exports}
                          className="lowercase text-gray-300"
                          firstSpanClassName="lowercase"
                          hasIntroPrice={hasIntroPrice || false}
                          isWebOnboarding={isWebOnboarding}
                        />
                      </span>
                    </p>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {!!onBtnClick && (
          <div className="absolute start-0 bottom-0 w-full px-10 translate-y-1/2 z-[3]">
            <button
              type="button"
              className="w-full btn btn--xl shadow-dark-button btn--black md:px-4 lg:px-[inherit] capitalize py-[14px] !M16"
              disabled={isUpgradeAndPersonalPlan}
              onClick={() => onBtnClick(currentPaymentStrategy)}
            >
              {t(CTAlabel)}
            </button>
          </div>
        )}
      </div>
      <div
        className={`space-y-3 rounded-t-[40px] ${
          isUpgrade ? '' : 'bg-gray-50'
        } ${
          currentPaymentStrategy.id === PaymentStrategiesIds.Personal
            ? 'bg-[#FFF7F6]'
            : currentPaymentStrategy.id === PaymentStrategiesIds.Business
            ? 'bg-[#F8F5FF]'
            : 'bg-gray-50'
        } pt-15 px-10 pb-9`}
        style={{ flexGrow: 1 }}
      >
        {BottomSection ||
          currentPaymentStrategy.features.map((feature, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="flex items-center text-black">
              {feature.check ? (
                <span className="text-green">
                  <CheckIcon
                    width={24}
                    height={24}
                    fill="currentColor"
                    title="Included"
                    alt="Included"
                  />
                </span>
              ) : (
                <span className="text-primary-accent">
                  <CloseIcon
                    width={24}
                    height={24}
                    fill="currentColor"
                    title="Not included"
                    alt="Not included"
                  />
                </span>
              )}
              <p className="R16 ms-3 tracking-[-0.01em] flex-1">
                {feature.text}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}
