import { Toast } from '@/ui/toast';
import alertRedIcon from '@/public/images/icons/alert--red.svg';
import { useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { useCallback } from 'react';
import { useLocalization } from '@/services/localizationService';

export default function useErrorToast() {
  const pushModal = useSetRecoilState(pushModalSelector);
  const { data } = useLocalization();
  return useCallback(() => {
    pushModal({
      id: 'requestSendToast',
      Modal: Toast,
      message: data.support.customerSupportRequestSentError,
      icon: alertRedIcon,
      duration: 10000,
    });
  }, [data.support.customerSupportRequestSentError, pushModal]);
}
