import { GenericErrorAlert } from '@/features/genericErrorAlert';
import * as Sentry from '@sentry/nextjs';
import React from 'react';

type Props = React.PropsWithChildren;
type State = {
  hasError: boolean;
  eventId: string;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, eventId: '' };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI. Trigger deploy
    const eventId = Sentry.captureException(error);
    return { hasError: true, eventId };
  }

  render() {
    return this.state.hasError ? (
      <GenericErrorAlert eventId={this.state.eventId} />
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
