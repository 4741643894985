import checkYellowEmptyIcon from '@/public/images/icons/check--yellow-empty.svg';
import { useEditorSettings } from '@/services/editorService';
import { useLocalization } from '@/services/localizationService';
import List from '@/ui/list/List';

export default function UpgradeCard() {
  const { t, data } = useLocalization();

  const { isAnyAiModelBusinessOnly } = useEditorSettings();

  const listItems = [
    isAnyAiModelBusinessOnly
      ? {
          id: 'ai-models',
          icon: checkYellowEmptyIcon,
          label: t(data.tiers.featAllAiModels),
        }
      : null,
    {
      id: 'business-limits',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.featLimitBusiness),
    },
    {
      id: 'business-output',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.featOutputBusiness),
    },
    {
      id: 'bulk-upload',
      icon: checkYellowEmptyIcon,
      label: t(data.common.batchProcessing),
    },
  ];

  return (
    <div className="space-y-3 min-w-80">
      <h3 className="SB30 text-inherit white whitespace-normal">
        {t(data.account.businessPlan, null, 'capitalize')}
      </h3>
      <List items={listItems} />
    </div>
  );
}
