import { api } from '@/core/http';
import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import { serviceSingletonGetter } from '@/services/serviceGetter';

export type ConsentService = {
  hasSeenMarketingConsentPopup: (shouldIncreaseTimesShown?: boolean) => boolean;
  hasSeenImageTrainingConsentPopup: (
    shouldIncreaseTimesShown?: boolean
  ) => boolean;
  grantImageTrainingConsent: (requestUrl: string) => Promise<any>;
  revokeImageTrainingConsent: (requestUrl: string) => Promise<any>;
  grantMarketingConsent: (requestUrl: string) => Promise<any>;
  revokeMarketingConsent: (requestUrl: string) => Promise<any>;
};

export const createConsentService = (): ConsentService => {
  const _hasSeenConsentPopup = (
    localStorageKey: string,
    shouldIncreaseTimesShown: boolean
  ): boolean => {
    const timesShown = +(safeLocalStorage.getItem(localStorageKey) || 0);
    if (timesShown === 0 && shouldIncreaseTimesShown) {
      safeLocalStorage.setItem(localStorageKey, `${timesShown + 1}`);
    }

    return timesShown >= 1;
  };

  const hasSeenMarketingConsentPopup = (
    shouldIncreaseTimesShown = false
  ): boolean =>
    _hasSeenConsentPopup(
      'marketingConsentModal:timesShown',
      shouldIncreaseTimesShown
    );

  const hasSeenImageTrainingConsentPopup = (
    shouldIncreaseTimesShown = false
  ): boolean =>
    _hasSeenConsentPopup(
      'imageTrainingConsentModal:timesShownV2',
      shouldIncreaseTimesShown
    );

  const grantImageTrainingConsent = async (requestUrl: string) =>
    api.post('/users/@me/consents/image-training', {
      requestUrl,
    });

  const revokeImageTrainingConsent = async (requestUrl: string) =>
    api.delete('/users/@me/consents/image-training', {
      data: { requestUrl },
    });

  const grantMarketingConsent = async (requestUrl: string) =>
    api.post('/users/@me/consents/marketing', {
      requestUrl,
    });

  const revokeMarketingConsent = async (requestUrl: string) =>
    api.delete('/users/@me/consents/marketing', {
      data: { requestUrl },
    });

  return {
    hasSeenMarketingConsentPopup,
    hasSeenImageTrainingConsentPopup,
    grantImageTrainingConsent,
    revokeImageTrainingConsent,
    grantMarketingConsent,
    revokeMarketingConsent,
  };
};

export default serviceSingletonGetter(
  Symbol('consentService'),
  createConsentService
);
