import { HttpService } from '@/core/http/httpService';
import { fromCamelCaseToSnakeCaseRecursive } from '@/core/objectManipulation/objectManipulation';
import { serviceSingletonGetter } from '@/services/serviceGetter';
import {
  AiPipelineChoices,
  AiToolsChoices,
} from '@/services/settingsService/aiTypes';
import { useEditorSettings } from './useEditorSettings';
import { Settings } from '../settingsService';
import { DEFAULT_ADDITIONAL_PARAMS } from './editorConstants';

export type EditorService = {
  useEditorSettings: typeof useEditorSettings;
  convert: (taskId: string, imageFormat: string) => Promise<any>;
  getAdditionalPipelineParams: (settings: Settings) => {
    [pipelineStep: string]: string | number | boolean;
  };
  includeAdditionalParamsInPipeline: (
    aiPipelineChoices: AiPipelineChoices,
    newParameters?: { [key: string]: string }
  ) => AiPipelineChoices;
  extractAiPipelineFromAiToolsChoices: (
    aiToolsChoices: AiToolsChoices,
    settings: Settings,
    additionalPipelineParams?: { [key: string]: string }
  ) => AiPipelineChoices;
};

export const createEditorService = ({ post }: HttpService): EditorService => {
  const convert = async (taskId: string, imageFormat: string) =>
    post(`/v1/web/tasks/${taskId}/convert`, {
      outputContentType: imageFormat,
    });

  const getAdditionalPipelineParams = (
    settings: Settings
  ): {
    [pipelineStep: string]: string | number | boolean;
  } => {
    const additionalPipelineParams: {
      [pipelineStep: string]: number | boolean;
    } = {};
    const { removeCap, faceDetectorMaxInputRes, jpegQuality } = settings;

    const additionalParamsTransformed = fromCamelCaseToSnakeCaseRecursive({
      removeCap,
      faceDetectorMaxInputRes,
      jpegQuality,
    });
    const defaultParamsTransformed = fromCamelCaseToSnakeCaseRecursive(
      DEFAULT_ADDITIONAL_PARAMS
    );

    Object.keys(additionalParamsTransformed).forEach((key) => {
      if (additionalParamsTransformed[key] !== defaultParamsTransformed[key]) {
        additionalPipelineParams[key] = additionalParamsTransformed[key];
      }
    });

    return additionalPipelineParams;
  };

  const includeAdditionalParamsInPipeline = (
    aiPipelineChoices: AiPipelineChoices,
    newParameters?: { [key: string]: string }
  ): AiPipelineChoices => {
    if (typeof newParameters === 'undefined') {
      return aiPipelineChoices;
    }

    return {
      ...aiPipelineChoices,
      ...newParameters,
    };
  };

  const extractAiPipelineFromAiToolsChoices = (
    aiToolsChoices: AiToolsChoices,
    settings: Settings,
    additionalPipelineParams?: { [key: string]: string }
  ): AiPipelineChoices => {
    const remoteAdditionalParams = getAdditionalPipelineParams(settings);

    const aiPipelineWithRemoteAdditionalParams: AiPipelineChoices = {
      ...aiToolsChoices,
      ...remoteAdditionalParams,
    };

    return includeAdditionalParamsInPipeline(
      aiPipelineWithRemoteAdditionalParams,
      additionalPipelineParams
    );
  };

  return {
    extractAiPipelineFromAiToolsChoices,
    includeAdditionalParamsInPipeline,
    getAdditionalPipelineParams,
    convert,
    useEditorSettings,
  };
};

export default serviceSingletonGetter(
  Symbol('editorService'),
  createEditorService
);
