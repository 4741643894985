import { createHttpService } from '@/core/http/httpService';
import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import { createAuthService } from '@/services/authService/authService';
import { TokenState } from '@/services/authService/authTypes';
import createTokenService from '@/services/tokenService';
import * as Sentry from '@sentry/nextjs';
import { atom, selector } from 'recoil';
import whenInBrowser from '../core/environment/whenInBrowser';

function setInitialTokenState({ setSelf }) {
  try {
    const persistedData = JSON.parse(safeLocalStorage.getItem('user:token')!);
    const persistedToken =
      persistedData && persistedData[process.env.NEXT_PUBLIC_BACKEND_DOMAIN!];
    if (persistedToken) {
      setSelf(persistedToken);
    } else {
      const authService = createAuthService(
        createHttpService(createTokenService())
      );
      authService.loginAsGuest().then(setSelf).catch(Sentry.captureException);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function storeTokenState({ onSet }) {
  onSet((newTokenState: TokenState) => {
    safeLocalStorage.removeItem('user:cachedData');

    const persistedObject = JSON.parse(safeLocalStorage.getItem('user:token')!);
    const partialPersistedObject = {
      [process.env.NEXT_PUBLIC_BACKEND_DOMAIN!]: newTokenState,
    };
    const newPersistedObject = {
      ...persistedObject,
      ...partialPersistedObject,
    };
    safeLocalStorage.setItem('user:token', JSON.stringify(newPersistedObject));
  });
}

export const authState = atom({
  key: 'user:token',
  default: {
    token: '',
    isGuestToken: true,
  },
  effects_UNSTABLE: [whenInBrowser(setInitialTokenState), storeTokenState],
});

export const isLoggedInSelector = selector({
  key: 'user:isLoggedIn',
  get: ({ get }) => {
    const authData = get(authState);
    return !authData.isGuestToken;
  },
});
