import React, { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { ModalWrapper } from '@/core/modal';
import { closeModalSelector } from '@/stores/modalStore';
import { useLocalization } from '@/services/localizationService';

export default function CountryNotAllowedModal({
  id,
  ...rest
}: {
  id: string;
}) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();

  const focusFirstElement = () => closeButtonRef.current?.focus();

  useEffect(focusFirstElement, []);

  return (
    <ModalWrapper
      className="text-center max-w-sm"
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      <p className="M16 mt-3 whitespace-pre-line">
        {t(data.account.blockedPaymentsMessage, {
          app: (text) => (
            <a
              className="contrast-high underline"
              href="https://remini.ai/try-remini"
              target="_blank"
              rel="noreferrer nofollow"
            >
              {text}
            </a>
          ),
          email: () => (
            <a
              className="contrast-high underline"
              href="mailto:remini-web-requests@remini.ai"
              target="_blank"
              rel="noreferrer nofollow"
            >
              remini-web-requests@remini.ai
            </a>
          ),
        })}
      </p>
      <button
        ref={closeButtonRef}
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6"
        onClick={() => closeModal(id)}
      >
        {t(data.common.okGotIt)}
      </button>
    </ModalWrapper>
  );
}
