import { fromCamelCaseToSnakeCaseRecursive } from '@/core/objectManipulation/objectManipulation';
import { generateViralityParam } from '@/core/virality/viralityParam';
import { SettingsInfo } from '@/services/settingsService';
import Cookies from 'js-cookie';
import {
  EventType,
  SessionSubtype,
  TechnicalEventsInterceptor,
} from './pico/event';
import { getPico } from './pico';

let FBP;
let FBC;
const pico = getPico();

generateViralityParam().then((viralityParam) => {
  if (!viralityParam) return;
  track(
    'v_value',
    'generated',
    {},
    {
      value: viralityParam,
    }
  );
});

function getFbp() {
  if (FBP) return FBP;

  FBP = Cookies.get('_fbp') || undefined;
  return FBP;
}

function getFbc() {
  if (FBC) return FBC;

  const fbcCookie = Cookies.get('_fbc');
  if (fbcCookie) return fbcCookie;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { fbclid } = params;

  FBC = fbclid === undefined ? undefined : `fb.1.${Date.now()}.${fbclid}`;
  return FBC;
}

function toCallbackParams(params) {
  const snakeCaseParams = {
    ...fromCamelCaseToSnakeCaseRecursive(params),
  };
  return Object.keys(snakeCaseParams)
    .map((key) => {
      const rawValue = snakeCaseParams[key];
      // Pass-through by default
      let value = rawValue;
      // Then, serialize objects and convert everything but undefined values to string
      if (typeof rawValue === 'object' && rawValue !== null) {
        value = JSON.stringify(rawValue);
      } else if (typeof rawValue !== 'undefined') {
        value = String(rawValue);
      }
      return { key, value };
      // Finally, remove the undefined values
    })
    .filter(({ value }) => typeof value !== 'undefined');
}

type TrackingUserMetadata = {
  firebaseUid: string | undefined;
  webUserId: string | undefined;
  createdAt: Date | undefined;
  backendId: string | undefined;
  isFree: boolean | undefined;
};

let technicalEventsInterceptorAdded = false;
export function initPico(
  settings: SettingsInfo,
  userMetadata: TrackingUserMetadata
) {
  const { firebaseUid, webUserId, backendId, createdAt, isFree } = userMetadata;
  pico.setFirebaseID(firebaseUid);
  pico.setWebUserID(webUserId);
  pico.setBackendID(backendId);
  pico.setUserCreatedAt(createdAt);
  pico.setIsFree(isFree);
  if (!technicalEventsInterceptorAdded) {
    pico.addInterceptor(TechnicalEventsInterceptor, {
      eventMatcher: [
        {
          type: EventType.USER_ACTION,
          kind: 'payment_completed',
        },
        {
          type: EventType.SESSION,
          subtype: SessionSubtype.START,
        },
      ],
    });
    technicalEventsInterceptorAdded = true;
  }
  pico.start();
}

export function setPicoWebOnboardingInfo(
  webOnboardingUserId?: string,
  webOnboardingLocalStorageId?: string
) {
  pico.setWebOnboardingUserID(webOnboardingUserId);
  pico.setWebOnboardingLocalStorageID(webOnboardingLocalStorageId);
}

export function trackUserSettingsAndExperiments({
  settings,
  experiments,
}: {
  settings: { [key: string]: any };
  experiments: { [key: string]: number };
}) {
  pico.setSettings(settings);
  pico.setExperiments(experiments);
}

export default function track(
  category: string,
  action: string,
  params: Record<string, any> = {},
  additionalParams?: Record<string, any>,
  label?: string
) {
  const categoryParams = params?.categoryParams?.[category];
  const fbp = getFbp();
  const fbc = getFbc();

  const enrichedAdditionalParams = {
    ...additionalParams,
    url: window?.location.href,
  };

  const finalParams = {
    ...params.globalParams,
    ...categoryParams,
    ...enrichedAdditionalParams,
    fbp,
    fbc,
    ...(window.location.pathname === '/redeem'
      ? {
          backendId: new URLSearchParams(window.location.search).get(
            'backend_id'
          ),
        }
      : {}),
  };

  if (typeof process.env.NEXT_PUBLIC_DEBUG_EVENTS !== 'undefined') {
    pico.trackUserAction({
      kind: `${category}_${action}`,
      data: finalParams,
    });

    return console.table(
      toCallbackParams({
        actionKind: `${category}_${action}`,
        googleAnalyticsLabel: label,
        ...finalParams,
      })
    );
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'app.event',
    category,
    action,
    label,
  });
  pico.trackUserAction({
    kind: `${category}_${action}`,
    data: finalParams,
  });
}
