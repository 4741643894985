import { StaticImageData } from 'next/image';

export default function List({
  items,
}: {
  items: ({ id: string; icon: StaticImageData; label: JSX.Element } | null)[];
}) {
  return (
    <ul className="space-y-2">
      {items.map(
        (item, i) =>
          item && (
            <li
              key={item.label.key || i}
              className="flex space-s-2 M14 items-center"
            >
              <img {...item.icon} alt="Included" />
              <span>{item.label}</span>
            </li>
          )
      )}
    </ul>
  );
}
