import { PaymentStrategiesIds } from '@/services/paymentService';

export const surveys = [
  {
    id: 'survey1',
    weight: 1,
    questions: [
      {
        step: 0,
        id: 'editing-level',
        text: 'What is your photo editing level?',
        image: './images/survey/camera.webp',
        randomized: false,
        options: [
          {
            id: 'new',
            type: 'radio-button',
            text: '👋  I’m new to photo editing',
          },
          { id: 'basic', type: 'radio-button', text: '👌  I know the basics' },
          {
            id: 'enthusiast',
            type: 'radio-button',
            text: '📖   I’m an editing enthusiast',
          },
          {
            id: 'advanced',
            type: 'radio-button',
            text: '🔥   I’m an advanced photo editor',
          },
        ],
      },
      {
        step: 1,
        id: 'familiarity',
        text: 'Do you know what Remini does?',
        image: './images/survey/donut.webp',
        randomized: false,
        options: [
          { id: 'yes', type: 'radio-button', text: '👍   Yes' },
          { id: 'no', type: 'radio-button', text: '👎   No' },
          {
            id: 'not-sure',
            type: 'radio-button',
            text: '😕   I think so, but I’m not sure',
          },
        ],
      },
      {
        step: 2,
        id: 'photo-type',
        text: 'What kind of photo are you going to enhance with Remini now?',
        image: './images/survey/processing.webp',
        randomized: true,
        options: [
          {
            id: 'old-photo',
            type: 'radio-button',
            text: '🖼️  An old photo (restoration)',
          },
          {
            id: 'portrait-selfie',
            type: 'radio-button',
            text: '🤳  A portrait/selfie',
          },
          {
            id: 'no-people',
            type: 'radio-button',
            text: '🌇  A photo with no people',
          },
          { id: 'group', type: 'radio-button', text: '👨‍👩‍👦  A group photo' },
          {
            id: 5,
            type: 'input',

            placeholder: 'Please specify',
            text: '⚡  Something else',
            fixed: true,
          },
        ],
      },
      {
        step: 3,
        id: 'purpose',
        text: 'What will you use Remini for?',
        image: './images/survey/polaroids.webp',
        randomized: true,
        options: [
          {
            id: PaymentStrategiesIds.Personal,
            type: 'radio-button',
            text: '✍️  Personal use, for my socials/hobbies',
          },
          {
            id: 'professional',
            type: 'radio-button',
            text: '📷  Professional use, I need it for work',
          },
          {
            id: 'not-sure',
            type: 'radio-button',
            text: '😇  Not sure, I’m just trying it out',
          },
          {
            id: 'other',
            type: 'input',
            placeholder: 'Please specify',
            text: '💡  I’ll use it for something else',
            fixed: true,
          },
        ],
      },
      {
        step: 4,
        id: 'attribution',
        text: 'Where did you first discover us?',
        image: './images/survey/paper_plane.webp',
        randomized: true,
        options: [
          { id: 'ad', type: 'radio-button', text: '👀  I saw an ad' },
          {
            id: 'search-engine',
            type: 'radio-button',
            text: '🔍  Search engine (Google, Bing, etc.)',
          },
          {
            id: 'someone',
            type: 'radio-button',
            text: '🗣️  Someone I know/follow recommended it',
          },
          {
            id: 'somebody-online',
            type: 'input',
            placeholder:
              'Could you tell us where you found us (more specifically)?',
            text: '🖥️  Somebody mentioned it online',
          },
          {
            id: 'remini-mobile',
            type: 'radio-button',
            text: '📱️  I know/use the mobile app',
          },
          {
            id: 'other',
            type: 'input',
            placeholder: 'Please specify',
            text: '⚡  Somewhere else',
            fixed: true,
          },
        ],
      },
    ],
  },
];
