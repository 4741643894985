import { v4 as uuidv4 } from 'uuid';

export type SessionManager = {
  setOnStartCallback: (c: CallableFunction) => void;
  startSession: () => void;
  getSessionID: () => string;
  getSecondsFromSessionStart: () => number;
};

export const createSessionManager = (): SessionManager => {
  let sessionID: string;
  let sessionStart: Date;
  let onStartCallback: CallableFunction;

  return {
    setOnStartCallback(callback: () => void) {
      onStartCallback = callback;
    },
    startSession() {
      sessionID = uuidv4();
      sessionStart = new Date();
      onStartCallback();
    },

    getSessionID() {
      return sessionID;
    },
    getSecondsFromSessionStart() {
      return Math.round((new Date().getTime() - sessionStart.getTime()) / 1000);
    },
  };
};
