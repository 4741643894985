import Pico from './pico/index';

let pico: ReturnType<typeof Pico>;

export function getPico() {
  if (pico) return pico;
  pico = Pico({
    isProduction: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production',
    isTester: !!process.env.NEXT_PUBLIC_DEBUG_EVENTS,
    appID: 'remini_website',
    appVersion: process.env.appVersion!,
    mockRequest: !!process.env.NEXT_PUBLIC_PICO_MOCK,
  });
  return pico;
}
