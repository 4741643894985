import {
  createEventBuffer,
  createEventManager,
  createEventMetadataProvider,
  createEventSender,
  SpiderSenseDebugEvent,
} from '@/core/monitoring/spidersense/src/event';
import {
  createNetworkClient,
  NetworkClient,
  createNetworkAdapter,
} from '@/core/monitoring/spidersense/src/network';

export type SpiderSenseClient = {
  trackDebugEvent: (event: SpiderSenseDebugEvent) => void;
  trackStartedEvent: (event: SpiderSenseDebugEvent) => void;
  trackCompletedEvent: (event: SpiderSenseDebugEvent) => void;
  trackFailedEvent: (event: SpiderSenseDebugEvent) => void;
  trackCanceledEvent: (event: SpiderSenseDebugEvent) => void;
  stop: () => void;
};

export type SpiderSenseConfiguration = {
  appId: string;
  appVersion: string;
  mockRequest: boolean;
  isProduction: boolean;
  userId: string;
  bufferDuration?: number;
  networkClient?: NetworkClient;
};

export const SpiderSense = (
  config: SpiderSenseConfiguration
): SpiderSenseClient => {
  const networkClient =
    config.networkClient ??
    createNetworkClient({
      mockRequest: config.mockRequest,
    });
  const networkAdapter = createNetworkAdapter({
    isProduction: config.isProduction,
    appId: config.appId,
    appVersion: config.appVersion,
    userId: config.userId,
  });
  const eventSender = createEventSender({ networkAdapter, networkClient });
  const eventBuffer = createEventBuffer({
    eventSender,
    bufferDuration: config.bufferDuration,
  });
  eventBuffer.startFlushing();
  const eventMetadataProvider = createEventMetadataProvider();
  const eventManager = createEventManager({
    eventBuffer,
    eventMetadataProvider,
  });
  return {
    trackDebugEvent: (event: SpiderSenseDebugEvent) => {
      eventManager.trackDebugEvent(event);
    },
    trackStartedEvent: (event: SpiderSenseDebugEvent) => {
      eventManager.trackStartedEvent(event);
    },
    trackCompletedEvent: (event: SpiderSenseDebugEvent) => {
      eventManager.trackCompletedEvent(event);
    },
    trackFailedEvent: (event: SpiderSenseDebugEvent) => {
      eventManager.trackFailedEvent(event);
    },
    trackCanceledEvent: (event: SpiderSenseDebugEvent) => {
      eventManager.trackCanceledEvent(event);
    },
    stop: () => eventBuffer.endFlushing(),
  };
};
