import { useRecoilValue } from 'recoil';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';
import settingsState from '@/stores/settingsStore';
import {
  OneTimePayments,
  PaymentDetails,
  RecurringPayments,
} from '../settingsService';
import { useUser } from '../userService';

export function useUserPrice() {
  const settings = useRecoilValue(settingsState);
  const { data: user } = useUser();
  const { usePricesByIds } = usePriceService();

  const { priceId } = user?.monetization || {};

  const { paymentStrategies } = settings.values;

  let exports: number | undefined;
  Object.values(paymentStrategies).forEach(
    (paymentStrategy: RecurringPayments | OneTimePayments) => {
      Object.values(paymentStrategy).forEach(
        (paymentDetails: PaymentDetails) => {
          if (paymentDetails.price === priceId) {
            exports = paymentDetails.exports;
          }
        }
      );
    }
  );

  const { data: pricesFromBackend } = usePricesByIds(priceId ? [priceId] : []);

  return pricesFromBackend?.length
    ? {
        id: priceId,
        exports,
        ...pricesFromBackend[0],
      }
    : null;
}

export type UserPrice = ReturnType<typeof useUserPrice>;
