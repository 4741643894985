import { TaskState } from '@/services/uploaderService';
import { atomFamily, SerializableParam } from 'recoil';

const taskStateFamily = atomFamily<TaskState, SerializableParam>({
  key: 'uploader:taskStateFamily',
  default: {
    id: null,
    status: 'pristine',
    fileProgress: '',
    queueWaitNum: 0,
  },
});

export default taskStateFamily;
