import { useCallback, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import {
  useEditorService,
  useUploaderService,
} from '@/layout/appWrapper/ServiceProvider';
import { editorLoadingState } from '@/stores/editorStore';
import { isReprocessingMediaState } from '@/stores/isReprocessingMediaStore';
import { mediaState } from '@/stores/mediaStore';
import { useTracker } from '@/core/tracking';
import { BulkUpload, TaskStatus, TaskType } from '../uploaderService';

export const useMedia = () => {
  const setMedia = useSetRecoilState(mediaState);
  const router = useRouter();
  const { convert } = useEditorService();
  const { track } = useTracker();
  const { taskId, mediaType, sharingId } = router.query;
  const isBulkCompleted = useRef<boolean>(false);
  const setIsLoadingEditor = useSetRecoilState(editorLoadingState);
  const { getMediaResponseOrSharing, getBulkUpload } = useUploaderService();
  const setIsReprocessingMedia = useSetRecoilState(isReprocessingMediaState);

  const getMediaAndUpdateStore = useCallback(
    async (type: 'tasks' | 'sharings' = 'tasks') => {
      const data = await getMediaResponseOrSharing({
        type,
        taskId: (taskId || sharingId) as string,
        isVideo: mediaType === TaskType.Video,
        hasWatermark: true,
        track,
        convertFn: convert,
      });

      setMedia((prev) => ({
        ...prev,
        ...data,
        id: (taskId || sharingId) as string,
        type,
        mediaType: mediaType as string,
      }));
      return data;
    },
    [
      convert,
      getMediaResponseOrSharing,
      mediaType,
      setMedia,
      sharingId,
      taskId,
      track,
    ]
  );

  const getBulkUploadWithPolling = useCallback(
    async ({
      isBulkProcessingCompleted,
    }: {
      isBulkProcessingCompleted: boolean;
    }): Promise<BulkUpload> => {
      while (
        (!isBulkCompleted.current || !isBulkProcessingCompleted) &&
        taskId
      ) {
        await new Promise((resolve) => {
          window.setTimeout(resolve, 2000);
        });

        const bulkUpload = await getBulkUpload({
          bulkUploadId: taskId as string,
        });

        setMedia((prev) => ({
          ...prev,
          ...bulkUpload,
        }));

        const bulkStatus = bulkUpload.taskList?.every(
          (t) =>
            t.status === TaskStatus.Completed ||
            t.status === TaskStatus.Failed ||
            t.status === TaskStatus.Exported
        )
          ? TaskStatus.Completed
          : TaskStatus.Processing;

        isBulkCompleted.current = bulkStatus === TaskStatus.Completed;

        if (isBulkCompleted.current) {
          track(TaskStatus.Processing, TaskStatus.Completed, {
            actionValue: 'browse',
            mediaType: TaskType.Image,
            bulkUploadId: bulkUpload.bulkUploadId,
            mediaQuantity: bulkUpload.taskList.length,
            aiPipelines: bulkUpload.taskList.map(
              (t) => t.result.outputs[0]?.aiPipeline
            ),
          });

          setIsReprocessingMedia(false);
          setIsLoadingEditor(false);
          return bulkUpload;
        }
      }

      // FIXME hack to make TS not complaining, but here we're assuming we'll always successfully return from the above while
      // A cleaner solution should be the one of handling the case in which we get stuck in the while forever.
      return Promise.resolve({} as BulkUpload);
    },
    [
      taskId,
      getBulkUpload,
      setMedia,
      setIsReprocessingMedia,
      setIsLoadingEditor,
    ]
  );

  return {
    getMediaAndUpdateStore,
    getBulkUploadWithPolling,
  };
};
