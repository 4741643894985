import hi from '@/public/images/survey/hi-yellow.webp';
import thanks from '@/public/images/survey/thanks-red.webp';
import { AnimatePresence, motion } from 'framer-motion';
import Script from 'next/script';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTracker } from '@/core/tracking';
import { useLocalization } from '@/services/localizationService';
import settingsState from '@/stores/settingsStore';
import { SurveyKind, SurveyState } from '@/services/surveyService';
import { closeModalSelector } from '@/stores/modalStore';
import { SurveyRoot } from './SurveyRoot';

type Props = {
  id: string;
  trackEvent?: string;
  closeAfterMS?: number;
  onClose?: () => any;
};

export function PopupSurveyModal({
  id,
  closeAfterMS = 0,
  trackEvent = 'survey',
  onClose,
}: Props) {
  const [surveyState, setSurveyState] = useState(SurveyState.Start);
  const { track } = useTracker();
  const settings = useRecoilValue(settingsState);
  const kind = settings.values.popupSurveyKind || SurveyKind.Native;
  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();

  const onModalClose = useCallback(async () => {
    if (onClose) onClose();
    closeModal(id);
  }, [onClose, closeModal, id]);

  // Handles user's interactions with a Typeform surveys
  useEffect(() => {
    const onTypeformSubmit = (event) => {
      if (event.data.type === 'form-submit') {
        track(trackEvent, 'completed');
        setTimeout(() => setSurveyState(SurveyState.End), closeAfterMS);
      }
    };
    window.addEventListener('message', onTypeformSubmit);

    const onTypeformDismiss = () => {
      console.log('Typeform dismissed.');
      onModalClose();
    };
    const typeformDismissIcon = window.document.querySelector('.tf-v1-close');
    typeformDismissIcon?.addEventListener('click', onTypeformDismiss);

    return () => {
      window.removeEventListener('message', onTypeformSubmit);
      typeformDismissIcon?.removeEventListener('click', onTypeformDismiss);
    };
  }, [surveyState, track, onModalClose, trackEvent, closeAfterMS]);

  useEffect(() => {
    if (surveyState === SurveyState.Ongoing) {
      track(trackEvent, 'displayed');
    }
  }, [surveyState, track, trackEvent]);

  return (
    <div>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-10"
        >
          {surveyState === SurveyState.Start ? (
            <div className="flex w-full h-full px-2 items-center justify-center section-foreground relative bg-black-background bg-opacity-70">
              <div className="rounded-2xl py-10 px-12 bg-[#121315] space-y-10 text-center relative w-[386px]">
                <img src={hi.src} className="mx-auto" alt="" />
                <h3 className="text-white SB25">
                  {t(data.surveys.featRankingPopupTitle)}
                </h3>
                <p className="text-gray-200 M16">
                  {t(data.surveys.featRankingPopupSubtitle)}
                </p>
                <div className="flex flex-col gap-4">
                  {kind === SurveyKind.Native ? (
                    <button
                      onClick={() => setSurveyState(SurveyState.Ongoing)}
                      type="button"
                      className="btn btn--large btn--white mx-auto w-full"
                    >
                      {t(data.common.letsGo)}
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => setSurveyState(SurveyState.Ongoing)}
                        type="button"
                        className="btn btn--large btn--white mx-auto w-full"
                      >
                        {t(data.common.letsGo)}
                      </button>
                      <Script src="//embed.typeform.com/next/embed.js" />
                    </>
                  )}

                  <button
                    onClick={() => {
                      onModalClose();
                      track(trackEvent, 'dismissed');
                    }}
                    type="button"
                    className="btn--large text-white M18 mx-auto w-full"
                  >
                    {t(data.common.noThanks)}
                  </button>
                </div>
              </div>
            </div>
          ) : surveyState === SurveyState.Ongoing ? (
            <SurveyRoot />
          ) : (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="flex w-full h-screen px-2 items-center justify-center section-foreground relative bg-black bg-opacity-70">
                  <div className="rounded-2xl py-10 text-center w-[345px] max-w-full bg-[#121315] relative">
                    <img src={thanks.src} className="min-w-full" alt="" />
                    <div className="px-9">
                      <h3 className="text-white SB25 mt-2">
                        {t(data.common.thankYou)}
                      </h3>
                      <p className="text-gray-200 M14 mt-4">
                        {t(data.surveys.surveySuccess)}
                      </p>
                      <button
                        onClick={() => onModalClose()}
                        type="button"
                        className="btn btn--large btn--white w-full mx-auto mt-8"
                      >
                        {t(data.surveys.proceedToProcessing)}
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
