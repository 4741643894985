import {
  EventInterceptor,
  EventType,
  PicoEventWithMetadata,
} from '@/core/tracking/pico/event';
import { FBTrackingReady } from '@/core/tracking/pico/attribution';

export const FBPaymentCompletedInterceptor: EventInterceptor = (
  e: PicoEventWithMetadata
) => {
  if (
    e.type === EventType.USER_ACTION &&
    e.kind === 'payment_completed' &&
    FBTrackingReady()
  ) {
    window.fbq(
      'track',
      'PaymentCompleted',
      // improvement: track price value in payment_completed event in order to correcty pass it here
      { value: 9.99, currency: 'USD' },
      { eventID: `${e.id}:payment_completed` }
    );
  }
};
