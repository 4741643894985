import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocalization } from '@/services/localizationService';
import { selectedPaymentStrategyState } from '@/stores/onboardingStore';
import settingsState from '@/stores/settingsStore';
import ftRoadmapStatusBar from '@/public/images/showcase/ft-roadmap-statusbar.svg';
import { FREE_TRIAL_DAYS } from '@/services/paymentService';
import usePaymentStrategies from './paymentStrategies/usePaymentStrategies';

interface Props {
  onContinue?: () => void;
}

export function FreeTrialRecap({ onContinue }: Props) {
  const settings = useRecoilValue(settingsState);
  const selectedPaymentStrategy = useRecoilValue(selectedPaymentStrategyState);
  const paymentStrategies = usePaymentStrategies({
    remotePaymentStrategies: settings.values.paymentStrategies,
  });
  const { t, data } = useLocalization();

  const paymentStrategyTitle =
    paymentStrategies.find(
      (strategy) => strategy.id === selectedPaymentStrategy?.id
    )?.title || 'Web'; // Fallback to "Remini Web" in case something weird happens

  return (
    <div className="h-full flex flex-col justify-between">
      <div className="text-center md:text-left">
        <p className="B10 uppercase tracking-wider text-gray-400">
          {t(data.onboarding.howTo)}
        </p>
        <h2 className="mt-3 text-white B23 md:B36 md:whitespace-pre-line">
          {t(data.onboarding.ftRoadmapTitle)}
        </h2>
        <p className="M16 mt-2 md:mt-4 tracking-tight text-gray-300">
          {t(data.onboarding.ftRoadmapSubtitle, {
            plan: paymentStrategyTitle,
            n: FREE_TRIAL_DAYS,
          })}
        </p>
        <div className="text-left mt-11 md:mt-14 flex">
          <img {...ftRoadmapStatusBar} className="w-9 mr-5 md:mr-6" alt="" />
          <div className="flex-1 space-y-7">
            <StatusBarStep
              title={t(data.common.today)}
              descr={t(data.onboarding.ftRoadmapStart)}
            />
            <StatusBarStep
              title={t(data.common.dayN, { n: 4 })}
              descr={t(data.onboarding.ftRoadmapNotify)}
            />
            <StatusBarStep
              title={t(data.common.dayN, { n: FREE_TRIAL_DAYS })}
              descr={t(data.onboarding.ftRoadmapEnd)}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="w-full mt-11 bg-overlay-white-5 rounded-xl py-4 px-6">
          <p className="SB14">{t(data.onboarding.ftRoadmapHowCancelTitle)}</p>
          <p className="M14 mt-1 text-gray-300">
            {t(data.onboarding.ftRoadmapHowCancelBody)}
          </p>
        </div>
        {!!onContinue && (
          <button
            className="mt-5 btn btn--white btn--medium flex flex-col rounded-full w-full space-x-0 py-2"
            onClick={onContinue}
          >
            <span className="SB15">{t(data.common.continue)}</span>
            <span className="M12">{t(data.onboarding.oneTapToStart)}</span>
          </button>
        )}
      </div>
    </div>
  );
}

function StatusBarStep({
  title,
  descr,
}: {
  title: ReactNode;
  descr: ReactNode;
}) {
  return (
    <div>
      <p className="B18 capitalize">{title}</p>
      <p className="M13 mt-1 text-overlay-white-70">{descr}</p>
    </div>
  );
}
