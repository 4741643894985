import { v4 as uuidv4 } from 'uuid';
import { createCookie, readCookie } from '@/core/tracking/pico/helpers';
import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import { AnonymousTimestampGenerator } from './anonymousTimestampGenerator';

const ANONYMOUS_ID_KEY = '_pico_anonymous_id' as const;
const ANONYMOUS_UUID = '0000-0000-0000-0000' as const;
export type AnonymousUUIDType = typeof ANONYMOUS_UUID;

export type AnonymousInfoProvider = {
  getAnonymousID: () => string;
  getClientID: () => string;
  getLocalStorageId: () => AnonymousUUIDType;
  getIncrementalTimestamp: () => number;
};

type AnonymousInfoProviderParams = {
  timestampGenerator: AnonymousTimestampGenerator;
};

export const createAnonymousInfoProvider = ({
  timestampGenerator,
}: AnonymousInfoProviderParams): AnonymousInfoProvider => {
  const createAnonymousID = () => {
    const clientID = uuidv4();
    createCookie(ANONYMOUS_ID_KEY, clientID);
    safeLocalStorage.setItem(ANONYMOUS_ID_KEY, clientID);
    return clientID;
  };

  return {
    getClientID: () => ANONYMOUS_UUID,
    getLocalStorageId: () => ANONYMOUS_UUID,
    getIncrementalTimestamp: () => timestampGenerator.generateTimestamp(),
    getAnonymousID: () => {
      const fromCookie = readCookie(ANONYMOUS_ID_KEY);
      const fromLocalStorage = safeLocalStorage.getItem(ANONYMOUS_ID_KEY);
      if (fromCookie && fromLocalStorage && fromCookie === fromLocalStorage) {
        return fromCookie;
      }
      if (!fromCookie && fromLocalStorage) {
        createCookie(ANONYMOUS_ID_KEY, fromLocalStorage);
        return fromLocalStorage;
      }
      if (!fromLocalStorage && fromCookie) {
        safeLocalStorage.setItem(ANONYMOUS_ID_KEY, fromCookie);
        return fromCookie;
      }
      if (fromCookie !== fromLocalStorage && fromLocalStorage) {
        createCookie(ANONYMOUS_ID_KEY, fromLocalStorage);
        return fromLocalStorage;
      }
      return createAnonymousID();
    },
  };
};
