import { deviceType } from '@/core/environment/deviceType';
import { SplitModal } from '@/core/modal';
import { ModalWrapperProps } from '@/core/modal/ModalWrapper';
import { useReCaptcha } from '@/core/recaptcha/useReCaptcha';
import UpgradeCard from '@/features/account/accountModal/subscriptionSection/UpgradeCard';
import UpgradeStep2 from '@/features/payment/paymentStrategies/steps/UpgradeStep2';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';
import ftRoadmapCover from '@/public/images/covers/ft-roadmap-cover.webp';
import modalCover from '@/public/images/covers/pink-girl.webp';
import upgradeCover from '@/public/images/covers/upgrade-cover.webp';
import { PaymentStrategiesIds } from '@/services/paymentService';
import { Price, PricePeriod } from '@/services/priceService';
import { isLoggedInSelector } from '@/stores/authStore';
import { closeModalSelector } from '@/stores/modalStore';
import {
  onboardingState,
  showFreeTrialSelector,
} from '@/stores/onboardingStore';
import { CloseIcon } from '@/ui/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { LoginBenefits, LoginForm } from '../../account/login';
import { FreeTrialRecap } from '../FreeTrialRecap';
import PaymentStrategiesStep1 from './steps/PaymentStrategiesStep1';
import PaymentStrategiesStep2 from './steps/PaymentStrategiesStep2';

interface PaymentStrategiesModalProps extends ModalWrapperProps {
  id: string;
  onClose: any;
  onSubmit: any;
  isUpgrade?: boolean;
  autoEnableTrial?: boolean;
}

export default function PaymentStrategiesModal({
  onSubmit,
  id,
  onClose,
  isUpgrade = false,
  autoEnableTrial = false,
  ...rest
}: PaymentStrategiesModalProps) {
  const [onboarding, setOnboarding] = useRecoilState(onboardingState);
  const { usePaywallPrices, useUserPrice } = usePriceService();
  const paywallPrices = usePaywallPrices();
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const [ftMobileStep, setFTMobileStep] = useState(0);

  const ref = useRef({ el: null, scrolled: false });

  const { selectedPeriod, selectedPaymentStrategy } = onboarding;
  const shouldShowFreeTrial = useRecoilValue(showFreeTrialSelector);

  useReCaptcha();

  // this is necessary in case of upgrade to immediately show the correct period
  const [shouldLoadUserPeriod, setShouldLoadUserPeriod] =
    useState<boolean>(isUpgrade);
  const userPrice = useUserPrice();
  const userPricePeriod = useMemo(
    () =>
      userPrice?.recurringInterval === undefined
        ? undefined
        : (`${userPrice?.recurringInterval}ly` as PricePeriod),
    [userPrice]
  );

  useEffect(() => {
    if (autoEnableTrial) {
      // NB: use effect will always try to update state when props change. We should never update the prop.
      setOnboarding((prev) => ({
        ...prev,
        isFreeTrialEnabled: autoEnableTrial,
      }));
    }
  }, [autoEnableTrial]);

  // TODO pragmatism: let's leave it like this atm but remember to make it scalable for other kinds of OTP
  useEffect(() => {
    const period = shouldLoadUserPeriod
      ? userPricePeriod
      : selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
      ? selectedPaymentStrategy.period
      : selectedPeriod;

    if (shouldLoadUserPeriod && userPricePeriod !== undefined) {
      setShouldLoadUserPeriod(false);
    }

    if (period !== undefined) {
      setOnboarding((prev) => ({ ...prev, selectedPeriod: period }));
    }
  }, [
    selectedPeriod,
    selectedPaymentStrategy,
    setOnboarding,
    shouldLoadUserPeriod,
    userPricePeriod,
  ]);

  useEffect(() => {
    if (!selectedPaymentStrategy) setFTMobileStep(0);
  }, [selectedPaymentStrategy]);

  const selectedPrice = (selectedPaymentStrategy &&
    paywallPrices?.[selectedPaymentStrategy.id]?.[selectedPeriod]) as
    | Price
    | null
    | undefined;

  const showLoginStep =
    (selectedPaymentStrategy || selectedPrice) &&
    !isLoggedIn &&
    selectedPeriod !== 'singleExport';

  const showPaymentPaywallFirstStep =
    !selectedPaymentStrategy || !selectedPrice;

  const isDesktop = useMemo(() => deviceType() === 'desktop', []);
  const closeModal = useSetRecoilState(closeModalSelector);

  return (
    <SplitModal
      id={id}
      className={`w-auto ${
        selectedPaymentStrategy ? 'max-w-3xl' : 'max-w-7xl'
      } ${
        !showLoginStep &&
        !showPaymentPaywallFirstStep &&
        (shouldShowFreeTrial
          ? 'w-[860px] !max-w-full'
          : selectedPrice && isUpgrade
          ? '!max-w-5xl'
          : '!max-w-lg')
      } !p-0`}
      closeBtnClassName={`!text-black lg:top-12 lg:start-12 ${
        selectedPaymentStrategy ? 'hidden' : ''
      }`}
      iconClassName="!shadow-none"
      onClose={() => {
        setOnboarding({
          isFreeTrialEnabled: false,
          isReminderEnabled: false,
          selectedPeriod: 'weekly',
          selectedPaymentStrategy: null,
        });
        if (typeof onClose === 'function') onClose();
      }}
      {...rest}
    >
      {showPaymentPaywallFirstStep ? (
        <PaymentStrategiesStep1 isUpgrade={isUpgrade} />
      ) : showLoginStep ? (
        <>
          <SplitModal.LeftPanel
            mobileBackground={modalCover}
            desktopBackground={modalCover}
            rootChildren={
              <div
                className="absolute top-0 start-0 w-full h-full"
                style={{
                  backgroundImage:
                    'linear-gradient(0deg, rgba(0,0,0,0.5),rgba(0,0,0,0) 68.62%)',
                }}
              />
            }
          >
            <div className="relative w-full">
              <LoginBenefits />
            </div>
          </SplitModal.LeftPanel>
          <SplitModal.RightPanel className="overflow-y-auto" sectionRef={ref}>
            <div className="relative w-full" />
            <LoginForm onSubmit={onSubmit} />
          </SplitModal.RightPanel>
        </>
      ) : shouldShowFreeTrial ? (
        isDesktop ? (
          <>
            <SplitModal.LeftPanel
              mobileBackground={ftRoadmapCover}
              desktopBackground={ftRoadmapCover}
              className="md:w-100 !items-start !p-10 !pt-12 !pr-11"
              childrenClassName="h-full"
            >
              <FreeTrialRecap />
            </SplitModal.LeftPanel>
            <SplitModal.RightPanel
              className="overflow-y-auto !p-0 bg-white flex-1"
              sectionRef={ref}
            >
              <PaymentStrategiesStep2
                selectedPrice={selectedPrice}
                onSubmit={onSubmit}
              />
            </SplitModal.RightPanel>
          </>
        ) : ftMobileStep ? (
          <PaymentStrategiesStep2
            selectedPrice={selectedPrice}
            onSubmit={onSubmit}
          />
        ) : (
          <>
            <button
              type="button"
              className="absolute top-5 start-5 text-white z-10"
              onClick={() => {
                setOnboarding((prev) => ({
                  ...prev,
                  selectedPaymentStrategy: null,
                }));
              }}
            >
              <CloseIcon className="drop-shadow" width={24} height={24} />
            </button>
            <SplitModal.LeftPanel
              mobileBackground={ftRoadmapCover}
              desktopBackground={ftRoadmapCover}
              className="md:w-100 !items-start !p-6 !pt-7"
              childrenClassName="h-full"
            >
              <FreeTrialRecap onContinue={() => setFTMobileStep(1)} />
            </SplitModal.LeftPanel>
          </>
        )
      ) : isUpgrade ? (
        <>
          <button
            type="button"
            className="absolute top-6 start-6 text-white z-10"
            onClick={() => {
              closeModal(id);
            }}
          >
            <CloseIcon className="drop-shadow" width={30} height={30} />
          </button>
          <SplitModal.LeftPanel
            mobileBackground={upgradeCover}
            desktopBackground={upgradeCover}
            className="md:w-100"
            rootChildren={
              <div
                className="absolute top-0 start-0 w-full h-full"
                style={{
                  backgroundImage:
                    'linear-gradient(0deg, rgba(0,0,0,0.3),rgba(0,0,0,0))',
                }}
              />
            }
          >
            <UpgradeCard />
          </SplitModal.LeftPanel>
          <SplitModal.RightPanel
            className="overflow-y-auto max-w-xl"
            sectionRef={ref}
          >
            <UpgradeStep2
              onSubmit={() => closeModal(id)}
              offBadge={
                userPricePeriod === 'weekly'
                  ? selectedPeriod === 'monthly'
                    ? 35
                    : selectedPeriod === 'yearly'
                    ? 50
                    : undefined
                  : userPricePeriod === 'monthly' && selectedPeriod === 'yearly'
                  ? 16
                  : undefined
              }
            />
          </SplitModal.RightPanel>
        </>
      ) : (
        <PaymentStrategiesStep2
          selectedPrice={selectedPrice}
          onSubmit={onSubmit}
        />
      )}
    </SplitModal>
  );
}
