import { TokenService } from '@/core/http/httpService';
import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import { TokenState } from './authService/authTypes';

export default function createTokenService(): TokenService {
  const getAuthDataFromLocalStorage = (): {
    [key: string]: TokenState;
  } | null => JSON.parse(safeLocalStorage.getItem('user:token')!);

  const getAuthData = (): TokenState | undefined => {
    const authData = getAuthDataFromLocalStorage();
    if (authData === null) return;
    return authData[process.env.NEXT_PUBLIC_BACKEND_DOMAIN!];
  };

  const getToken = (): string | undefined => getAuthData()?.token;

  return { getToken };
}
