import React, { useCallback, useEffect, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import * as Sentry from '@sentry/nextjs';
import { useLocalization } from '@/services/localizationService';
import { uuid4 } from '@sentry/utils';
import { usePayPalButton } from '@/core/paypal/usePayPalButton';
import { ErrorModal } from '@/features/payment/modals';
import Spinner from '@/ui/content/Spinner';
import { PaymentProvider } from '@/services/paymentService';
import {
  trackCanceledEvent,
  trackCompletedEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '../monitoring/trackEvents';

export interface Props {
  paypalPlanId: string;
  onButtonClick: () => void;
  onRequestCancel: () => void;
  onPaymentSubmit: CallableFunction;
}

export default function PayPalUpgradeButton({
  paypalPlanId,
  onButtonClick,
  onRequestCancel,
  onPaymentSubmit,
}: Props) {
  const { pushModal, onUpgradeApprove, webUserId } = usePayPalButton();

  const { t, data } = useLocalization();
  const [isPaypalButtonLoading, setIsPaypalButtonLoading] = useState(true);

  const [{ options }, dispatch] = usePayPalScriptReducer();
  const [hasDispatchedChange, setHasDispatchedChange] = useState(false);
  useEffect(() => {
    if (hasDispatchedChange) {
      return;
    }

    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        intent: 'subscription',
        vault: true,
      },
    });

    setHasDispatchedChange(true);
  }, [dispatch, options, hasDispatchedChange]);

  const buttonClickHandler = useCallback(() => {
    const payTrackingInfo = {
      paymentPlatform: PaymentProvider.PayPal,
      paymentStrategy: 'upgrade',
    };
    trackStartedEvent(['payment'], payTrackingInfo);
    return onButtonClick();
  }, [onButtonClick]);

  return (
    <div className="relative group">
      <button
        className="w-full mt-6 btn btn--xl shadow-dark-button btn--black md:px-4 lg:px-[inherit] !SB18"
        disabled={isPaypalButtonLoading}
      >
        {t(data.account.upgradeToBusiness)}
        {isPaypalButtonLoading && <Spinner color="white" size={20} />}
      </button>
      <PayPalButtons
        className="absolute top-0 w-full h-full opacity-[.0000000001]"
        forceReRender={[paypalPlanId, isPaypalButtonLoading]}
        style={{
          height: 55,
          shape: 'pill',
          color: 'blue',
          label: 'paypal',
          layout: 'horizontal',
          tagline: false,
        }}
        createSubscription={(data, actions) =>
          actions.subscription.create({
            plan_id: paypalPlanId,
            custom_id: `${webUserId}_${uuid4()}`, // avoid losing subscription data about users
          })
        }
        onClick={buttonClickHandler}
        onApprove={async (data) => {
          await onUpgradeApprove(data.subscriptionID);
          const payTrackingInfo = {
            paymentPlatform: PaymentProvider.PayPal,
            paymentStrategy: 'upgrade',
          };
          trackCompletedEvent(['payment'], payTrackingInfo);
          onPaymentSubmit();
        }}
        onCancel={() => {
          const payTrackingInfo = {
            paymentPlatform: PaymentProvider.PayPal,
            paymentStrategy: 'upgrade',
          };
          trackCanceledEvent(['payment'], payTrackingInfo);
          onRequestCancel();
        }}
        onInit={() => {
          setIsPaypalButtonLoading(false);
        }}
        onError={(error) => {
          const payTrackingInfo = {
            paymentPlatform: PaymentProvider.PayPal,
            paymentStrategy: 'upgrade',
          };
          trackFailedEvent(['payment'], {
            ...payTrackingInfo,
            error: 'Unknown PayPal error',
          });
          onRequestCancel();
          Sentry.captureException(error);
          pushModal({ id: 'paymentError', Modal: ErrorModal, error });
        }}
      />
    </div>
  );
}
