import { AxiosError } from 'axios';
import { SWRResponse } from 'swr';
import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '@/services/serviceGetter';
import { PaywallPrices, PriceFromBackend } from './priceTypes';
import {
  RecurringPriceOptions,
  useFormatRecurringPrice,
} from './useFormatRecurringPrice';
import { usePaywallPrices } from './usePaywallPrices';
import { usePricesByIds } from './usePricesByIds';
import { UserPrice, useUserPrice } from './useUserPrice';

export type PriceService = {
  getPrices: (priceIds: string[]) => Promise<PriceFromBackend[]>;
  getPricesSeenByUser: (
    remotePrices: PaywallPrices,
    isOneTimePaymentActive: boolean
  ) => string[];
  useFormatRecurringPrice: (props?: RecurringPriceOptions) => string;
  usePricesByIds: (
    priceIds?: string[]
  ) => SWRResponse<PriceFromBackend[], AxiosError<unknown, any>>;
  usePaywallPrices: () => PaywallPrices | undefined;
  useUserPrice: () => UserPrice;
};

export const createPriceService = ({ get }: HttpService): PriceService => {
  const getPrices = async (priceIds: string[]): Promise<PriceFromBackend[]> =>
    get(`/v1/web/stripe/prices?price_id=${priceIds.join('&price_id=')}`);

  const getPricesSeenByUser = (
    remotePrices: PaywallPrices,
    isOneTimePaymentActive: boolean
  ): string[] => {
    const priceIds: string[] = [];

    Object.values(remotePrices.personal).forEach((recurringPayment) =>
      priceIds.push(recurringPayment.id)
    );
    Object.values(remotePrices.business).forEach((recurringPayment) =>
      priceIds.push(recurringPayment.id)
    );

    if (isOneTimePaymentActive) {
      Object.values(remotePrices.oneTime).forEach((oneTimePayment) =>
        priceIds.push(oneTimePayment.id)
      );
    }

    return priceIds;
  };

  return {
    getPrices,
    getPricesSeenByUser,
    useFormatRecurringPrice,
    usePricesByIds,
    usePaywallPrices,
    useUserPrice,
  };
};

export default serviceSingletonGetter(
  Symbol('priceService'),
  createPriceService
);
