import { useLocalization } from '@/services/localizationService';

type Props = {
  isSubscriptionHighlighted: boolean;
};

export default function OtherPlansCaption({
  isSubscriptionHighlighted,
}: Props) {
  const { t, data } = useLocalization();
  return isSubscriptionHighlighted
    ? t(data.onboarding.searchingForIdealPlan, {
        bold: (text) => <span className="B16">{text}</span>,
      })
    : t(data.onboarding.lookingForMoreDownloads, {
        bold: (text) => <span className="B16">{text}</span>,
      });
}
