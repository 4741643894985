import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Do not track errors that happen on the localhost, on resources served by the dev server, or on absolute system paths
  denyUrls: [/localhost/, /^webpack-internal:\/\//, /^file:\/\//],
  beforeSend(event) {
    if (event.exception?.values?.length) {
      event.exception.values = event.exception.values.map(
        (e) => ({ ...e, value: e.value.replace(/[?&]X-Goog-\w*=([^&]*)/g, '') }) // Parse and remove all Google sensible data (X-Googl-*)
      );
    }
    return event;
  },
});
