import radioCheckedIcon from '@/public/images/icons/radio-checked.svg';
import radioUncheckedIcon from '@/public/images/icons/radio-unchecked.svg';
import {
  PaymentStrategiesIds,
  PaymentStrategy,
} from '@/services/paymentService';
import { OneTimePriceCardinality, PricePeriod } from '@/services/priceService';
import { OneTimePayments, PaymentDetails } from '@/services/settingsService';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useHighlightedCreditPack from '../useHighlightedCreditPack';

type CreditPackSelectionProps = {
  otpVariants: OneTimePayments | undefined;
  setCurrentPaymentStrategy: Dispatch<SetStateAction<PaymentStrategy>>;
  currentPaymentStrategy: PaymentStrategy;
  setPeriod: Dispatch<SetStateAction<PricePeriod | undefined>>;
  period: PricePeriod;
};

export default function CreditPackSelection({
  otpVariants,
  setCurrentPaymentStrategy,
  currentPaymentStrategy,
  setPeriod,
  period,
}: CreditPackSelectionProps) {
  const [exports, setExports] = useState<number>(
    currentPaymentStrategy.exports ?? 1
  );

  const variant = useHighlightedCreditPack();
  const [selectedOTPVariant, setSelectedOTPVariant] = useState(variant);

  // Use first OTP variant as default
  useEffect(() => {
    if (otpVariants && selectedOTPVariant === undefined) {
      const [creditsPackName, { exports: creditPackCount }] =
        Object.entries(otpVariants)[0];
      setSelectedOTPVariant(creditsPackName as OneTimePriceCardinality);
      setPeriod(creditsPackName as OneTimePriceCardinality);
      setExports(creditPackCount);
    }
  }, [otpVariants, selectedOTPVariant, setPeriod]);

  useEffect(() => {
    setCurrentPaymentStrategy((prev) => ({ ...prev, period, exports }));
  }, [exports, period, setCurrentPaymentStrategy]);

  const otpVariantsFormatted =
    otpVariants &&
    Object.entries(otpVariants).map(([creditsPackName, paymentDetails]) => ({
      name: creditsPackName,
      ...(paymentDetails as PaymentDetails),
    }));

  return (
    <>
      {otpVariantsFormatted &&
        currentPaymentStrategy.id === PaymentStrategiesIds.OneTime &&
        otpVariantsFormatted.map(
          (otp: { name: PricePeriod; price: string; exports: number }) => (
            <label
              key={otp.price}
              htmlFor={otp.price}
              className="flex items-center cursor-pointer mt-3 first-of-type:mt-0"
            >
              <input
                className="me-2"
                type="radio"
                id={otp.price}
                value={otp.price}
                name="otp"
                style={{
                  appearance: 'none',
                  backgroundSize: 'contain',
                  minWidth: '16px',
                  width: '16px',
                  height: '16px',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url('${
                    selectedOTPVariant === otp.name
                      ? radioCheckedIcon.src
                      : radioUncheckedIcon.src
                  }')`,
                }}
                checked={selectedOTPVariant === otp.name}
                onChange={() => {
                  setSelectedOTPVariant(otp.name as OneTimePriceCardinality);
                  setPeriod(otp.name);
                  setExports(otp.exports);
                }}
              />
              <p
                className={`${
                  selectedOTPVariant === otp.price ? 'SB16' : 'M16'
                }`}
              >
                {otp.exports} {otp.exports === 1 ? 'download' : 'downloads'}
              </p>
              {otp.exports === 1 && (
                <div className="ms-4 bg-[#9BEBC3] bg-opacity-20 p-2 rounded-[6px] text-[#13A95E] uppercase B10 leaning-[8px]">
                  no login required
                </div>
              )}
            </label>
          )
        )}
    </>
  );
}
