import { NetworkClient } from '@/core/tracking/pico/network';
import {
  PersistentInfoProvider,
  UserMetadataProvider,
} from '@/core/tracking/pico/providers';
import { SessionManager } from '@/core/tracking/pico/session';
import { AttributionManager } from '@/core/tracking/pico/attribution';
import { TrackingConsentProvider } from '@/core/tracking/pico/privacy/trackingConsentProvider';
import { ActionInfoAnonymizer } from '../privacy/actionInfoAnonymizer';

const PICO_VERSION = 'web-1.0.0';

export type PicoConfiguration = {
  isProduction: boolean;
  isTester: boolean;
  appID: string;
  appVersion: string;
  userID?: string;
  mockRequest: boolean;
  networkClient?: NetworkClient;
  persistentInfoProvider?: PersistentInfoProvider;
  sessionManager?: SessionManager;
  attributionManager?: AttributionManager;
  userMetadataProvider?: UserMetadataProvider;
  bufferDuration?: number;
  consentProvider?: TrackingConsentProvider;
  actionInfoAnonymizer?: ActionInfoAnonymizer;
};

export type InternalConfiguration = {
  isProduction: boolean;
  isTester: boolean;
  appID: string;
  picoVersion: string;
  appVersion: string;
  mockRequest: boolean;
};

export const createInternalConfiguration = ({
  isTester,
  appID,
  appVersion,
  isProduction,
  mockRequest,
}: PicoConfiguration): InternalConfiguration => ({
  isProduction,
  isTester,
  appID,
  picoVersion: PICO_VERSION,
  appVersion,
  mockRequest,
});
