import { useCallback, useRef } from 'react';
import CallToActionModal from '@/features/callToAction/CallToActionModal';
import { UserAction } from '@/services/userService';
import { useTracker } from '@/core/tracking';
import { useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { StaticImageData } from 'next/image';

export const useCallToActionModal = () => {
  const { track } = useTracker();
  const pushModal = useSetRecoilState(pushModalSelector);
  const mainActionRef = useRef<any>(null);

  const showCallToActionModal = useCallback(
    ({
      id,
      previousAction,
      backgroundImage,
      headerImage,
      title,
      body,
      showPhotoEnhanceButton,
      showSubscribeFlowButton,
      showUpgradePlanButton,
      hasFreeTrial,
      showLoginCTA,
      uploaderId,
      usePlainStyle = false,
      subscribeToBusiness = false,
      headerImageStartMargin = '',
    }: {
      id: string;
      previousAction: UserAction;
      backgroundImage?: StaticImageData;
      headerImage?: StaticImageData;
      title: JSX.Element;
      body: JSX.Element;
      showPhotoEnhanceButton: boolean;
      showSubscribeFlowButton: boolean;
      showUpgradePlanButton: boolean;
      hasFreeTrial: boolean;
      showLoginCTA: boolean;
      uploaderId?: string;
      usePlainStyle?: boolean;
      subscribeToBusiness?: boolean;
      headerImageStartMargin?: string;
    }) => {
      pushModal({
        id,
        Modal: CallToActionModal,
        previousAction,
        backgroundImage,
        headerImage,
        title,
        body,
        showPhotoEnhanceButton,
        showSubscribeFlowButton,
        showUpgradePlanButton,
        hasFreeTrial,
        showLoginCTA,
        usePlainStyle,
        subscribeToBusiness,
        headerImageStartMargin,
        onClose: (e: {
          openInputFile?: boolean;
          upgradePlan?: boolean;
          subscribeNow?: boolean;
          login?: boolean;
        }) => {
          if (e.openInputFile && uploaderId) {
            document.getElementById(uploaderId)?.click();
            track(`${previousAction}_cta_popup`, 'single_task_tapped');
          } else if (e.upgradePlan) {
            track(`${previousAction}_cta_popup`, 'upgrade_plan_tapped');
          } else if (e.subscribeNow) {
            track(`${previousAction}_cta_popup`, 'try_subscribe_tapped');
          } else if (e.login) {
            track(`${previousAction}_cta_popup`, 'login_tapped');
          } else {
            track(`${previousAction}_cta_popup`, 'dismissed');
          }
          mainActionRef.current?.focus();
        },
      });
    },
    [pushModal, track]
  );

  return { showCallToActionModal };
};
