import React, { useContext } from 'react';
import FormContext from './FormContext';

interface Props {
  type?: 'submit' | 'button' | 'reset' | undefined;
  disabled?: boolean;
  children?: React.ReactNode;
  [x: string]: any;
}

export default function FormButton({
  type = 'submit',
  disabled,
  children,
  ...rest
}: Props) {
  const { loading, disabled: formDisabled } = useContext(FormContext);
  return (
    <button
      type={type}
      disabled={loading || disabled || formDisabled}
      {...rest}
    >
      {children}
    </button>
  );
}
