import en, { LocData } from './locales/en';

export const supportedLocales = [
  'en',
  'pt_BR',
  'es_419',
  'tr',
  'id',
  'it',
  'de',
  'fr',
  'ar',
  'hi',
  'ja',
  'ru',
  'th',
  'vi',
] as const;
export type SupportedLocale = (typeof supportedLocales)[number];

export const supportedLanguages: {
  name: string;
  code: SupportedLocale;
}[] = [
  { name: 'English', code: 'en' },
  { name: 'Português', code: 'pt_BR' },
  { name: 'Español', code: 'es_419' },
  { name: 'Türkçe', code: 'tr' },
  { name: 'Bahasa Indonesia', code: 'id' },
  { name: 'Italiano', code: 'it' },
  { name: 'Deutsch', code: 'de' },
  { name: 'Français', code: 'fr' },
  { name: 'العربية', code: 'ar' },
  { name: 'हिन्दी', code: 'hi' },
  { name: '日本語', code: 'ja' },
  { name: 'Русский', code: 'ru' },
  { name: 'ภาษาไทย', code: 'th' },
  { name: 'Tiếng Việt', code: 'vi' },
];

type DefaultLocalization = {
  locale: SupportedLocale;
  data: LocData;
};
export const defaultLocalization: DefaultLocalization = {
  locale: 'en',
  data: en,
};
