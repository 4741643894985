import { getMonitoringAPI } from '.';
import { EventType, PicoEventWithMetadata } from '../tracking/pico/event';
import { getPico } from '../tracking/pico';

let isActive = false;

export const applyTrackingMonitoring = () => {
  const { logPicoEventSent, logTrackingMonitoringMultipleActivations } =
    getMonitoringAPI();
  if (isActive) {
    logTrackingMonitoringMultipleActivations();
    return;
  }
  getPico().addInterceptor((e: PicoEventWithMetadata): boolean => {
    /**
     * * Note:
     * * We return a boolean in all the cases, which enumerates all the EventType values.
     * * The default case has been intentionally omitted, so Typescript will be able to warn us
     * * if we add some new EventType, by claiming the return value might be undefined in case
     * * we forget to handle the new type in the switch-case.
     */
    switch (e.type) {
      case EventType.SESSION:
        logPicoEventSent(e.subtype);
        return true;
      case EventType.USER_ACTION:
        logPicoEventSent(e.kind);
        return true;
      case EventType.TECHNICAL_EVENT:
        logPicoEventSent('kind' in e ? e.kind : e.subtype);
        return true;
    }
  });
  isActive = true;
};
