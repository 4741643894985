import { useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { TailSpin } from 'react-loader-spinner';
import { ModalWrapper } from '@/core/modal';
import { closeModalSelector } from '@/stores/modalStore';
import { useLocalization } from '@/services/localizationService';

export default function SubRedeemedModal({
  id,
  onClose,
  keepModalOnClose,
  ...rest
}: {
  id: string;
  onClose: () => void;
  keepModalOnClose: boolean;
}) {
  const [isDone, setIsDone] = useState(false);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();

  const focusFirstElement = () => closeButtonRef.current?.focus();

  useEffect(focusFirstElement, []);

  return (
    <ModalWrapper
      className="text-center max-w-xs"
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      <p className="SB20 text-black">Done!</p>
      <p className="M16 text-gray-400 mt-3">{t(data.onboarding.subRedeemed)}</p>
      <button
        ref={closeButtonRef}
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6"
        disabled={isDone}
        onClick={() => {
          if (keepModalOnClose) setIsDone(true);
          else closeModal(id);
          if (onClose) onClose();
        }}
      >
        {t(data.common.okGotIt)}
        {isDone && (
          <TailSpin color="white" width="20" height="20" wrapperClass="ms-5" />
        )}
      </button>
    </ModalWrapper>
  );
}
