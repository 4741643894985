import { useCallToActionModal } from '@/features/callToAction/useCallToActionModal';
import {
  usePaymentService,
  usePriceService,
} from '@/layout/appWrapper/ServiceProvider';
import autoColorCover from '@/public/images/covers/autoColor-cover.webp';
import beautifyCover from '@/public/images/covers/beautify-cover.webp';
import premiumFormatsCover from '@/public/images/covers/premium-formats-cover.webp';
import autoColorHeader from '@/public/images/showcase/autoColor-header.webp';
import beautifyHeader from '@/public/images/showcase/beautify-header.webp';
import formatsHeader from '@/public/images/showcase/formats-cta-header.webp';
import limitsHeader from '@/public/images/showcase/limits-cta-header.webp';
import periodVideoDuration from '@/public/images/showcase/period-video-duration.webp';
import periodVideoSize from '@/public/images/showcase/period-video-size.webp';
import singleVideoDuration from '@/public/images/showcase/single-video-duration.webp';
import singleVideoSize from '@/public/images/showcase/single-video-size.webp';
import { useEnhanceLimitService } from '@/services/enhanceLimitService/useEnhanceLimitService';
import { useLocalization } from '@/services/localizationService';
import { PaymentStrategiesIds } from '@/services/paymentService';
import { Interval } from '@/services/priceService/priceTypes';
import { VideoLimits } from '@/services/settingsService';
import { SectionName } from '@/services/settingsService/aiTypes';
import { UserAction, useUser } from '@/services/userService';
import { useCallback, useMemo } from 'react';

export const useSubscriptionUpgradePrompts = () => {
  const { t, data } = useLocalization();
  const { showCallToActionModal } = useCallToActionModal();
  const { isBaseUser, isPersonalUser } = usePaymentService();

  const { useUserPrice } = usePriceService();
  const userPrice = useUserPrice();
  const { data: user } = useUser();

  const { currentUserPlanLimits, getSubscriptionPlanLimits } =
    useEnhanceLimitService();

  const isBase = useMemo(() => isBaseUser(user), [isBaseUser, user]);
  const shouldBlockPremiumFormats = useMemo(
    () =>
      isPersonalUser(user) ||
      (isBaseUser(user) && (user?.availableCredits ?? 0) > 0),
    [isBaseUser, isPersonalUser, user]
  );

  const getUserCurrentAndUpgradeVideoLimits = useCallback(() => {
    // try to match business price with same recurring preriodicity
    const userVideoLimits = currentUserPlanLimits?.videoLimits;
    const period: Interval = userPrice?.recurringInterval ?? 'year'; // fallback to year
    const businessVideoLimits: VideoLimits | undefined =
      getSubscriptionPlanLimits(
        PaymentStrategiesIds.Business,
        period
      )?.videoLimits;
    return {
      userVideoLimits,
      businessVideoLimits,
    };
  }, [
    currentUserPlanLimits?.videoLimits,
    getSubscriptionPlanLimits,
    userPrice?.recurringInterval,
  ]);

  const showUpgradeCTAOnPremiumFormatSelection = useCallback(() => {
    showCallToActionModal({
      id: 'user:premiumFormatsOnlyBusiness',
      previousAction: UserAction.FormatConversion,
      backgroundImage: premiumFormatsCover,
      headerImage: formatsHeader,
      title: t(data.result.premiumFormatsAlertTitle),
      body: t(data.result.premiumFormatsAlertBody),
      showPhotoEnhanceButton: false,
      hasFreeTrial: false,
      showSubscribeFlowButton: isBase,
      showLoginCTA: true,
      showUpgradePlanButton: shouldBlockPremiumFormats && !isBaseUser(user),
      usePlainStyle: shouldBlockPremiumFormats,
    });
  }, [
    data.result.premiumFormatsAlertBody,
    data.result.premiumFormatsAlertTitle,
    isBase,
    isBaseUser,
    shouldBlockPremiumFormats,
    showCallToActionModal,
    t,
    user,
  ]);

  const showUpgradeCTAOnSingleVideoError = useCallback(
    (isDurationError: boolean) => {
      const { userVideoLimits, businessVideoLimits } =
        getUserCurrentAndUpgradeVideoLimits();
      showCallToActionModal({
        id: 'user:singleVideoOverLimit',
        previousAction: UserAction.VideoUpload,
        headerImage: isDurationError ? singleVideoDuration : singleVideoSize,
        title: isDurationError
          ? t(data.processing.videoFileIsTooLong)
          : t(data.processing.videoFileIsTooLarge),
        body: isDurationError
          ? t(data.processing.videoFileIsTooLongUpgrade, {
              personalVideoDurationLimit: userVideoLimits.maxLength,
              businessVideoDurationLimit: businessVideoLimits?.maxLength,
            })
          : t(data.processing.videoFileIsTooLargeUpgrade, {
              personalVideoSizeLimit: userVideoLimits.maxSizeMb,
              businessVideoSizeLimit: businessVideoLimits?.maxSizeMb,
            }),
        showPhotoEnhanceButton: false,
        hasFreeTrial: false,
        showSubscribeFlowButton: false,
        showLoginCTA: false,
        showUpgradePlanButton: true,
        usePlainStyle: true,
      });
    },
    [
      data.processing.videoFileIsTooLarge,
      data.processing.videoFileIsTooLargeUpgrade,
      data.processing.videoFileIsTooLong,
      data.processing.videoFileIsTooLongUpgrade,
      showCallToActionModal,
      t,
      getUserCurrentAndUpgradeVideoLimits,
    ]
  );

  const showUpgradeCTAOnPeriodicVideoLimitsError = useCallback(
    (isDurationError: boolean) => {
      const { userVideoLimits, businessVideoLimits } =
        getUserCurrentAndUpgradeVideoLimits();

      showCallToActionModal({
        id: 'user:periodicVideoOverLimit',
        previousAction: UserAction.VideoUpload,
        headerImage: isDurationError ? periodVideoDuration : periodVideoSize,
        title: t(data.common.limitReached),
        body: isDurationError
          ? t(data.processing.limitVideoDurationReachedUpgrade, {
              personalVideoWeeklyDurationLimit: userVideoLimits.maxPeriodLength,
              businessVideoWeeklyDurationLimit:
                businessVideoLimits?.maxPeriodLength,
            })
          : t(data.processing.limitVideoSizeReachedUpgrade, {
              personalVideoWeeklySizeLimit: userVideoLimits.maxPeriodSizeMb,
              businessVideoWeeklySizeLimit:
                businessVideoLimits?.maxPeriodSizeMb,
            }),
        showPhotoEnhanceButton: false,
        hasFreeTrial: false,
        showSubscribeFlowButton: false,
        showLoginCTA: false,
        showUpgradePlanButton: true,
        usePlainStyle: true,
      });
    },
    [
      data.common.limitReached,
      data.processing.limitVideoDurationReachedUpgrade,
      data.processing.limitVideoSizeReachedUpgrade,
      showCallToActionModal,
      t,
      getUserCurrentAndUpgradeVideoLimits,
    ]
  );

  const showUpgradeCTAOnDownloadsLimitReached = useCallback(() => {
    showCallToActionModal({
      id: 'user:downloadsLimitReached',
      previousAction: UserAction.Download,
      headerImage: limitsHeader,
      title: t(data.common.limitReached),
      body: t(data.common.enhancementsLimitReachedBody, {
        personalPlanExports: userPrice!.exports,
        subscriptionPeriodicity: userPrice!.recurringInterval,
      }),
      showPhotoEnhanceButton: false,
      hasFreeTrial: false,
      showSubscribeFlowButton: false,
      showLoginCTA: false,
      showUpgradePlanButton: true,
      usePlainStyle: true,
    });
  }, [
    data.common.enhancementsLimitReachedBody,
    data.common.limitReached,
    showCallToActionModal,
    t,
    userPrice,
  ]);

  const showCTAOnPremiumAIModelsSelection = useCallback(
    ({
      aiToolbarSection,
      previousAction,
    }: {
      aiToolbarSection: SectionName;
      previousAction: UserAction;
    }) => {
      showCallToActionModal({
        id: 'user:premiumAIModels',
        previousAction,
        backgroundImage:
          aiToolbarSection === 'face' ? beautifyCover : autoColorCover,
        title:
          aiToolbarSection === 'face'
            ? t(data.result.beautifySubscribeTitle)
            : t(data.result.autoColorSubscribeTitle),
        body: t(data.result.subscribeForFiltersBody),
        showPhotoEnhanceButton: false,
        hasFreeTrial: false,
        showSubscribeFlowButton: true,
        showLoginCTA: true,
        showUpgradePlanButton: false,
        usePlainStyle: false,
      });
    },
    [
      data.result.autoColorSubscribeTitle,
      data.result.beautifySubscribeTitle,
      data.result.subscribeForFiltersBody,
      showCallToActionModal,
      t,
    ]
  );

  const showCTAOnBusinessAIModelsSelection = useCallback(
    ({
      aiToolbarSection,
      previousAction,
    }: {
      aiToolbarSection: SectionName;
      previousAction: UserAction;
    }) => {
      let title: JSX.Element;

      if (isBase) {
        title =
          aiToolbarSection === 'face'
            ? t(data.result.beautifyBusinessSubscribeTitle, {
                yellow: (text) => (
                  <span className="text-secondary-yellow">{text}</span>
                ),
              })
            : t(data.result.autoColorBusinessSubscribeTitle, {
                yellow: (text) => (
                  <span className="text-secondary-yellow">{text}</span>
                ),
              });
      } else {
        title =
          aiToolbarSection === 'face'
            ? t(data.result.beautifyUpgradeTitle)
            : t(data.result.autoColorUpgradeTitle);
      }

      showCallToActionModal({
        id: 'user:businessAIModels',
        previousAction,
        backgroundImage:
          aiToolbarSection === 'face' ? beautifyCover : autoColorCover,
        headerImage:
          aiToolbarSection === 'face' ? beautifyHeader : autoColorHeader,
        headerImageStartMargin: 'ms-8',
        title,
        body: isBase
          ? t(data.result.businessSubscribeForFiltersBody)
          : t(data.result.filtersUpgradeBody),
        showPhotoEnhanceButton: false,
        hasFreeTrial: false,
        showSubscribeFlowButton: isBase,
        showLoginCTA: true,
        showUpgradePlanButton: !isBase,
        usePlainStyle: !isBase,
        subscribeToBusiness: true,
      });
    },
    [
      data.result.autoColorBusinessSubscribeTitle,
      data.result.autoColorUpgradeTitle,
      data.result.beautifyBusinessSubscribeTitle,
      data.result.beautifyUpgradeTitle,
      data.result.businessSubscribeForFiltersBody,
      data.result.filtersUpgradeBody,
      isBase,
      showCallToActionModal,
      t,
    ]
  );

  return {
    shouldBlockPremiumFormats,
    showCTAOnBusinessAIModelsSelection,
    showCTAOnPremiumAIModelsSelection,
    showUpgradeCTAOnPremiumFormatSelection,
    showUpgradeCTAOnSingleVideoError,
    showUpgradeCTAOnPeriodicVideoLimitsError,
    showUpgradeCTAOnDownloadsLimitReached,
  };
};
