import React, { useState } from 'react';
import { useIsRTL } from '@/services/localizationService/useIsRTL';
import { useLocalization } from '@/services/localizationService';

interface Option {
  key: string;
  label?: string;
  offBadge?: number;
  disabled?: boolean;
}

export default function SelectionButtons({
  options,
  mainDivClassName,
  selectedOptionExternal,
  setSelectedOptionExternal,
  buttonSelectionClassName,
  labelTextClassName,
  selectedLabelClassName,
  additionalOnClick,
  hasSmallButtons = false,
}: {
  options: Option[];
  mainDivClassName: string;
  selectedOptionExternal?: string;
  setSelectedOptionExternal: CallableFunction;
  buttonSelectionClassName: string;
  labelTextClassName: string;
  selectedLabelClassName?: string;
  additionalOnClick?: CallableFunction;
  hasSmallButtons?: boolean;
}) {
  const optionsCount = options.length;
  const [selectedOption, setSelectedOption] = useState(
    selectedOptionExternal || options[0].key
  );

  const { t, data } = useLocalization();
  const isRTL = useIsRTL();

  const discountText = data.onboarding.discount;
  return (
    <div className={mainDivClassName}>
      {options.map((option, index) => {
        const isDefaultOption = index === 0;

        return (
          <button
            key={option.key}
            disabled={option.disabled}
            className={`flex-1 ${
              hasSmallButtons ? 'py-2.5' : 'py-3.5'
            } relative ${isDefaultOption ? '' : 'z-10'}`}
            onClick={() => {
              setSelectedOption(option.key);
              setSelectedOptionExternal(option.key);

              if (additionalOnClick) additionalOnClick();
            }}
          >
            {isDefaultOption && (
              <div
                className={`absolute top-0 start-0 h-full w-full ${buttonSelectionClassName} rounded-3xl z-0 transition-transform ${
                  optionsCount === 2 && selectedOption === options[1].key
                    ? isRTL
                      ? '-translate-x-full'
                      : 'translate-x-full'
                    : ''
                } duration-300 pointer-events-none`}
                style={
                  optionsCount === 3
                    ? {
                        transform: `translateX(${
                          selectedOption === options[1].key
                            ? isRTL
                              ? '-100%'
                              : '100%'
                            : selectedOption === options[2].key
                            ? isRTL
                              ? '-200%'
                              : '200%'
                            : '0'
                        })`,
                      }
                    : undefined
                }
              />
            )}
            <div className={isDefaultOption ? 'relative z-10' : ''}>
              <p
                className={`${labelTextClassName} capitalize ${
                  selectedOption === option.key && selectedLabelClassName
                }`}
              >
                {t(option.label || option.key)}
              </p>
            </div>
            {!!option.offBadge && (
              <div className="absolute -top-1 w-full">
                <p className="flex justify-center -translate-y-1/3">
                  <span
                    className={`${
                      discountText.length >= 18
                        ? 'B9 px-1'
                        : 'B10 px-2 leading-3 align-sub'
                    } text-black bg-secondary-yellow py-1  rounded-full`}
                  >
                    {t(discountText, { n: option.offBadge }, 'uppercase')}
                  </span>
                </p>
              </div>
            )}
          </button>
        );
      })}
    </div>
  );
}
