import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { ModalWrapper } from '@/core/modal';
import { useLocalization } from '@/services/localizationService';
import { closeModalSelector } from '@/stores/modalStore';
import alertBlackIcon from '@/public/images/icons/alert-black.svg';

export default function DownloadsAvailableWarningModal({
  id,
  onClose,
}: {
  id: string;
  onClose: (proceed: boolean) => void;
}) {
  const { t, data } = useLocalization();
  const closeModal = useSetRecoilState(closeModalSelector);

  const [canProceed, setCanProceed] = useState<boolean>(false);

  const onModalClose = useCallback(
    async ({ proceed }: { proceed: boolean }) => {
      onClose(proceed);
      closeModal(id);
    },
    [closeModal, id, onClose]
  );

  return (
    <ModalWrapper
      isForeground
      onClose={() => onModalClose({ proceed: canProceed })}
      className="p-6 section-foreground rounded-3xl w-[313px] flex flex-col justify-center items-center text-center"
    >
      <img {...alertBlackIcon} alt="alert" />
      <p className="SB20 text-black mt-2">
        {t(data.common.youStillHaveDownloadsAvailable)}
      </p>
      <p className="M16 text-black mt-3">
        {t(data.common.downloadsAvailableWarning)}
      </p>
      <button
        type="button"
        className="M16 px-6 w-full btn btn--large border-transparent py-4 mt-6 rounded-full text-white bg-black hover:opacity-90 cursor-pointer"
        onClick={() => {
          setCanProceed(true);
          closeModal(id);
        }}
      >
        {t(data.common.yesProceed)}
      </button>
      <button
        type="button"
        className="leading-none py-4 mt-4 SB16 block contrast-high cursor-pointer"
        onClick={() => {
          setCanProceed(false);
          closeModal(id);
        }}
      >
        {t(data.common.dismiss)}
      </button>
    </ModalWrapper>
  );
}
