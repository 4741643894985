import { StaticImageData } from 'next/image';
import { ReactNode } from 'react';
import { atom, selector } from 'recoil';
import { UserAction } from '@/services/userService';
import { TaskError } from '@/services/uploaderService';

export interface Modal {
  id: string;
  Modal: any;
  previousAction?: UserAction;
  error?: TaskError | Record<string, unknown>;
  stepIndex?: number;
  message?: string;
  duration?: number;
  isGuestToken?: boolean;
  redeemCodeValue?: string;
  mobileBackground?: StaticImageData;
  desktopBackground?: StaticImageData;
  forceLogin?: boolean;
  keepModalOnClose?: boolean;
  backgroundImage?: any;
  title?: ReactNode;
  body?: ReactNode;
  showPhotoEnhanceButton?: boolean;
  onClose?: CallableFunction;
  onEnd?: CallableFunction;
  [x: string]: any;
  language?: string;
  initialModalScaleSize?: number;
  avoidBackgroundOverlay?: boolean;
}

export const modalState = atom<Modal[]>({
  key: 'modal:modalState',
  default: [],
});

export const pushModalSelector = selector<Modal | Modal[]>({
  key: 'modal:pushModal',
  get: () => modalState,
  set: ({ set }, modal) => {
    set(modalState, (modals: Modal[]) => [...modals, modal]);
  },
});

export const closeModalSelector = selector<string | Modal[]>({
  key: 'modal:closeModal',
  get: () => modalState,
  set: ({ set }, id: string) => {
    set(modalState, (modals) => {
      const modalIndex = modals.findIndex((modal) => modal.id === id);
      if (modalIndex === -1) {
        return modals;
      }
      return [...modals.slice(0, modalIndex), ...modals.slice(modalIndex + 1)];
    });
  },
});

export const popModalSelector = selector<Modal[] | null>({
  key: 'modal:popModal',
  get: () => modalState,
  set: ({ set, get }) => {
    const modals = get(modalState).slice();
    const lastModal = modals.pop();
    if (lastModal?.id.includes('[prevent-pop]')) return;
    set(modalState, modals);
  },
});
