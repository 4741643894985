import React, { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CloseIcon } from '@/ui/icons';
import { isBusySelector } from '@/stores/appStore';
import { closeModalSelector } from '../../../stores/modalStore';
import ModalWrapper, { ModalWrapperProps } from '../ModalWrapper';

interface SplitModalProps extends ModalWrapperProps {
  id: string;
  className?: string;
  children?: React.ReactNode;
  label?: string;
  onClose?: (action?: string) => void;
  closeBtnClassName?: string;
  iconClassName?: string;
}

function SplitModal({
  className = '',
  children,
  id,
  closeBtnClassName = '',
  iconClassName = '',
  ...rest
}: SplitModalProps) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const isBusy = useRecoilValue(isBusySelector);
  const closeModal = useSetRecoilState(closeModalSelector);

  const focusFirstElement = () => closeButtonRef.current?.focus();

  useEffect(focusFirstElement, []);

  return (
    <ModalWrapper
      isForeground
      className={`p-0 flex flex-col md:flex-row overflow-hidden relative w-full overflow-y-auto ${className}`}
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      {!id.includes('[prevent-pop]') && (
        <button
          type="button"
          className={`absolute top-6 start-4 md:top-8 md:start-6 text-white z-10 ${closeBtnClassName}`}
          ref={closeButtonRef}
          disabled={isBusy}
          onClick={() => closeModal(id)}
        >
          <CloseIcon className={`drop-shadow ${iconClassName}`} />
        </button>
      )}
      {children}
    </ModalWrapper>
  );
}

SplitModal.LeftPanel = function SplitModalLeftPanel({
  children,
  rootChildren,
  mobileBackground,
  desktopBackground,
  className = '',
  childrenClassName = '',
}: {
  children?: React.ReactNode;
  rootChildren?: React.ReactNode;
  mobileBackground?: { src: string };
  desktopBackground?: { src: string };
  className?: string;
  childrenClassName?: string;
}) {
  return (
    <aside
      className={`relative w-full pt-20 px-6 pb-12 md:px-8 text-white flex items-end ${className}`}
    >
      <img
        className="md:hidden absolute inset-0 w-full h-full object-cover"
        src={mobileBackground?.src}
        alt=""
      />
      <img
        // The border radius is inside the image itself, not removable.
        className="hidden md:block absolute inset-0 w-full h-full object-cover rtl:scale-x-[-1]"
        src={desktopBackground?.src}
        alt=""
      />
      {rootChildren}
      <div className={`relative ${childrenClassName}`}>{children}</div>
    </aside>
  );
};

SplitModal.RightPanel = function SplitModalRightPanel({
  children,
  className = '',
  sectionRef,
}: {
  className?: string;
  children: React.ReactNode;
  sectionRef?: any;
}) {
  return (
    <section
      className={`w-full p-8 px-6 md:p-12 ${className}`}
      ref={sectionRef}
    >
      {children}
    </section>
  );
};

export default SplitModal;
