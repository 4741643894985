import {
  trackCompletedEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '@/core/monitoring/trackEvents';
import { useTracker } from '@/core/tracking';
import { loginState, useLoginCallbacks } from '@/features/account/login';
import {
  useAuthService,
  usePaymentService,
} from '@/layout/appWrapper/ServiceProvider';
import { UserAction } from '@/services/userService';
import { useUser } from '@/services/userService/useUser';
import { lockUISelector, unlockUISelector } from '@/stores/appStore';
import { isLoggedInSelector } from '@/stores/authStore';
import { pushModalSelector } from '@/stores/modalStore';
import * as Sentry from '@sentry/nextjs';
import { FirebaseError } from 'firebase/app';
import { getAuth, getRedirectResult } from 'firebase/auth';
import { ReactNode, useEffect, useRef } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import OnboardingModal from '../../onboarding/OnboardingModal';
import GoogleOneTap from './GoogleOneTap';
import { LoginNotSupportedModal } from './modals';
import useAccountLinking from './useAccountLinking';
import useLogin from './useLogin';

export default function LoginProvider({ children }: { children: ReactNode }) {
  const [login, logout] = useLoginCallbacks();
  const { track, setGlobalParams } = useTracker();
  const { performLogin } = useLogin();
  const mainActionRef = useRef<HTMLButtonElement>(null);
  const { isBaseUser } = usePaymentService();

  const [isPerformingLogin, setIsPerformingLogin] = useRecoilState(
    loginState.isPerformingLoginSelector
  );
  const { isAccountLinkingNeededError, isLoginNotSupportedError } =
    useAuthService();

  const lockUI = useSetRecoilState(lockUISelector);
  const unlockUI = useSetRecoilState(unlockUISelector);

  const providerName = useRecoilValue(loginState.providerNameSelector);
  const resetProviderName = useResetRecoilState(
    loginState.providerNameSelector
  );
  const { error: userError } = useUser();

  useEffect(() => {
    if (isPerformingLogin) {
      lockUI({});
      return () => unlockUI({});
    }
  }, [isPerformingLogin, lockUI, unlockUI]);

  const isAfterRedirect = useRecoilValue(loginState.isAfterRedirectSelector);
  const pushModal = useSetRecoilState(pushModalSelector);

  const { data: user } = useUser();
  const isUserLoggedInButNotPremium = user?.id && isBaseUser(user);

  useEffect(() => {
    if (
      isAfterRedirect &&
      isUserLoggedInButNotPremium &&
      window.location.pathname !== '/redeem'
    ) {
      pushModal({
        id: 'user:onboarding',
        Modal: OnboardingModal,
        previousAction: UserAction.Login,
        stepIndex: 1,
        onClose: () => mainActionRef.current?.focus(),
      });
    }
  }, [pushModal, isUserLoggedInButNotPremium, isAfterRedirect]);

  // Mobile browsers and Safari on Mac cache the login page before the redirect,
  // and because of this if the user clicks on the back button they see a stuck
  // page, since without reloading the selectors are not reset, and it seems
  // like an infinite login.
  //
  // This reloads the page when it is loaded from the cache.
  useEffect(() => {
    const reloadIfPersisted = (event) => {
      if (event.persisted) window.location.reload();
    };
    window.addEventListener('pageshow', reloadIfPersisted, false);
    return () => window.removeEventListener('pageshow', reloadIfPersisted);
  }, []);

  const isLoggedIn = useRecoilValue(isLoggedInSelector);

  const handleAccountLinking = useAccountLinking();

  useEffect(() => {
    if (isLoggedIn) {
      return;
    }

    const auth = getAuth();
    // As per firebase doc https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#getredirectresult
    // it should be triggered only from a signInWithRedirect result. It is placed only in AccountLinkingNeeded.
    getRedirectResult(auth)
      .then(async (result) => {
        if (!result) {
          if (isAfterRedirect) {
            // the user clicked the back button, but no login was performed
            resetProviderName();
          }
          return;
        }
        // as per doc, if we are here ith user result, we completed a first login step.
        trackCompletedEvent(['login'], {
          provider: providerName,
          oneTap: false,
        });
        try {
          // then we start a new one
          trackStartedEvent(['login'], {
            provider: providerName,
            oneTap: false,
          });
          await performLogin(
            auth,
            pushModal,
            setGlobalParams,
            track,
            providerName,
            resetProviderName,
            login
          );
          trackCompletedEvent(['login'], {
            provider: providerName,
            oneTap: false,
          });
        } catch (e) {
          trackFailedEvent(['login'], {
            provider: providerName,
            oneTap: false,
            error: e instanceof FirebaseError ? e.code : e,
          });
        }
        setIsPerformingLogin(false);
      })
      .catch((error) => {
        // always track failure
        trackFailedEvent(['login'], {
          provider: providerName,
          oneTap: false,
          error: error instanceof FirebaseError ? error.code : error,
        });

        // Redirect the user to logging in with the original provider
        // when the email is the same, in order to link the accounts.

        if (!handleAccountLinking(error)) {
          if (isLoginNotSupportedError(error)) {
            pushModal({
              id: 'login-unsupported',
              Modal: LoginNotSupportedModal,
            });
          } else {
            Sentry.captureException(error);
          }
        }
        // adding only a finally to reset the provider name is not enough
        // as it would get reset before actually showing the spinner
        resetProviderName();
      });
  }, [
    isLoggedIn,
    isAfterRedirect,
    login,
    providerName,
    pushModal,
    resetProviderName,
    setGlobalParams,
    setIsPerformingLogin,
    track,
    performLogin,
    isAccountLinkingNeededError,
    isLoginNotSupportedError,
  ]);

  useEffect(() => {
    if (userError?.response?.data?.errorCode === 'users.not_authorized') {
      logout();
    }
  }, [userError?.response?.data?.errorCode]);

  return (
    <>
      {children}
      <GoogleOneTap />
    </>
  );
}
