import { paramsState } from '@/stores/trackingStore';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoggedInSelector } from '@/stores/authStore';
import doTrack from './track';
import useValueReference from '../stateManager/useValueReference';

export const useTracker = () => {
  const [params, setParams] = useRecoilState(paramsState);
  const getParams = useValueReference(params);
  const isLoggedIn = useRecoilValue(isLoggedInSelector);

  const setCategoryParams = useCallback(
    (category, valOrSetter) => {
      setParams((params) => {
        const currentParams = params.categoryParams[category];
        const newParams =
          typeof valOrSetter === 'function'
            ? valOrSetter(currentParams)
            : valOrSetter;
        return {
          ...params,
          categoryParams: {
            ...params.categoryParams,
            [category]: newParams,
          },
        };
      });
    },
    [setParams]
  );

  const setGlobalParams = useCallback(
    (valOrSetter) => {
      setParams((params) => {
        const currentParams = params.globalParams;
        const newParams =
          typeof valOrSetter === 'function'
            ? valOrSetter(currentParams)
            : valOrSetter;
        return {
          ...params,
          globalParams: newParams,
        };
      });
    },
    [setParams]
  );

  const track = useCallback(
    (
      category: string,
      action: string,
      additionalParams?: Record<string, any>,
      label?: string
    ) => {
      const params = getParams();
      const backendId =
        (!isLoggedIn &&
          window.location.pathname === '/redeem' &&
          new URLSearchParams(window.location.search).get('backend_id')) ||
        undefined;
      doTrack(
        category,
        action,
        params,
        { ...additionalParams, backendId },
        label
      );
    },
    [getParams, isLoggedIn]
  );

  return useMemo(
    () => ({
      track,
      setCategoryParams,
      setGlobalParams,
    }),
    [track, setCategoryParams, setGlobalParams]
  );
};
