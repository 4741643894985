import { PaymentStrategiesIds } from '../paymentService';
import { Preferences } from '../preferenceService';
import { NewUserType } from './useUser';

// snake case goes against pattern but Pico events need it
export enum UserAction {
  Account = 'account',
  Login = 'login',
  Download = 'download',
  FreeTrial = 'free_trial',
  OneTimePayment = 'one_time',
  Subscription = 'subscription',
  FormatConversion = 'output_format_conversion',
  VideoUpload = 'video_upload',
  BatchProcessing = 'batch_processing',
  SaveSettings = 'save_settings',
  AiModelsDropdownClick = 'ai_models_dropdown_click',
  AiModelsVariantClick = 'ai_models_variant_click',
}

// Temporary solution to gradually migrate the type. "NewUserType" will become the real "User" type in the future.
export type User =
  | {
      id: string;
      webUserId: string;
      source: string;
      email: string;
      giftCode: {
        isActive: boolean;
        expiryAt: Date | null;
      };
      monetization: {
        isSubscribed: boolean;
        subscriptionProvider: string | null;
        subscriptionId: string | null;
        subscriptionIncludesTrial: boolean;
        subscriptionWithIntroPrice: boolean;
        trialAvailable: boolean;
        priceId?: string;
        hasIncompleteSubscription: boolean;
      };
      paymentStrategy?: PaymentStrategiesIds;
      createdAt: Date | null;
      marketingConsent: boolean;
      imageTrainingConsent: boolean;
      firebaseUid?: string;
      userId?: string;
      mobileOracleBackendId?: string;
      isFree: boolean;
      preferences?: Preferences;
      availableCredits?: number;
      imageDownloadsAvailable?: number;
      hasDownloadLimits: boolean;
      nextRenewImageDownloadLimitsDatetime: Date | null;
    }
  | NewUserType;

// User fields coming from BE (as per API docs).
// For optional fields, it seems we'll always receive "null" from BE:
// but, since they're in fact optional, we have to expect them not to always be present (e.g. "x?: null").
export type UserFromBE = {
  userId: string;
  createdAt: string;
  source: 'anonymous' | 'apple' | 'facebook' | 'google';
  firebaseUid?: null | string;
  email?: null | string;
  monetization: {
    isSubscribed: boolean;
    subscriptionProvider?: null | 'stripe' | 'paypal' | 'mobile';
    subscriptionId?: null | string;
    subscriptionIncludesTrial?: null | boolean;
    subscriptionWithIntroPrice?: null | boolean;
    priceId?: null | string;
    trialAvailable: boolean;
    hasIncompleteSubscription: boolean;
  };
  giftCode: {
    isActive: boolean;
    expiryAt?: null | string;
  };
  marketingConsent?: null | boolean;
  mobileOracleBackendId?: null | string;
  preferences?: null | Preferences;
  imageTrainingConsent?: null | boolean;
  isFree?: null | boolean;
  availableCredits?: null | number;
  imageDownloadsAvailable?: null | number;
  hasDownloadLimits: boolean;
  nextRenewImageDownloadLimitsDatetime?: null | string;
};
