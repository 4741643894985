import { EventMetadata } from '@/core/tracking/pico/providers';
import { PicoEvent } from './eventManager';
import { EventSender } from './eventSender';

type CreateEventBufferProps = {
  eventSender: EventSender;
  bufferDuration: number;
};

export type PicoEventWithMetadata = PicoEvent & EventMetadata;

export type EventBuffer = {
  enqueueEvent: (a: PicoEventWithMetadata) => void;
  startFlushing: () => void;
  endFlushing: () => void;
};

export const createEventBuffer = ({
  eventSender,
  bufferDuration,
}: CreateEventBufferProps): EventBuffer => {
  let queue: PicoEventWithMetadata[] = [];
  let _flushJob: NodeJS.Timer;
  const flush = () => {
    if (queue.length > 0) {
      const toSend = [...queue];
      queue = [];
      eventSender.send(toSend);
    }
  };
  return {
    enqueueEvent(event: PicoEventWithMetadata) {
      queue.push(event);
    },
    startFlushing() {
      _flushJob = setInterval(flush, bufferDuration);
    },
    endFlushing() {
      clearInterval(_flushJob);
    },
  };
};
