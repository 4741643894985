import { NetworkRequest } from '@/core/monitoring/spidersense/src/network';

export type NetworkClient = {
  send: (req: NetworkRequest) => Promise<object>;
};

type NetworkClientOptions = {
  mockRequest: boolean;
};

export const createNetworkClient = (
  config: NetworkClientOptions
): NetworkClient => ({
  async send(request) {
    if (config.mockRequest) {
      console.log(request);
      return {};
    }
    const response = await fetch(request.url, {
      method: 'POST',
      body: JSON.stringify(request.body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  },
});
