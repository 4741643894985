import { AxiosError } from 'axios';
import useSWR from 'swr';
import { useRecoilValue } from 'recoil';
import { authState } from '@/stores/authStore';
import { authFetcher } from '@/core/http';
import { PaymentProvider, SubscriptionInfo } from '.';
import { useUser } from '../userService';

const subscriptionFetcher: typeof authFetcher = ([url, token]) =>
  authFetcher([url, token]).then((data) => ({
    ...data,
    cancellationDate: data.cancellationDate
      ? new Date(data.cancellationDate)
      : null,
  }));

export function useSubscriptionManager() {
  const { token, isGuestToken } = useRecoilValue(authState);
  const { data: user } = useUser();

  const subscriptionProvider =
    !isGuestToken &&
    !!user?.monetization.subscriptionProvider &&
    user.monetization.subscriptionProvider !== PaymentProvider.Mobile &&
    user.monetization.subscriptionProvider;

  const resp = useSWR<SubscriptionInfo, AxiosError>(
    subscriptionProvider && token
      ? [`/${subscriptionProvider}/subscriptions`, token]
      : null,
    subscriptionFetcher,
    {
      fallbackData: {
        subscriptionId: '',
        hasBeenCanceled: false,
        cancellationDate: null,
        portalSessionUrl: '',
      },
    }
  );

  return resp;
}
