import { atom, DefaultValue, selector } from 'recoil';

const providerNameInternal = atom({
  key: 'login:providerName:internal',
  default: null,
});

export const providerNameSelector = selector({
  key: 'login:providerName:selector',
  get: ({ get }) => {
    const providerName = get(providerNameInternal);

    return typeof window !== 'undefined'
      ? window.sessionStorage.getItem('login:providerName')
      : providerName;
  },
  set: ({ set }, key: string | DefaultValue) => {
    if (key instanceof DefaultValue) {
      window.sessionStorage.removeItem('login:providerName');

      // Set the atom to an impossible value to refresh the UI.
      // Using reset() doesn't work because after the redirect the atom
      // has already the default value.
      set(providerNameInternal, -1);
    } else {
      window.sessionStorage.setItem('login:providerName', key);
      set(providerNameInternal, key);
    }
  },
});

export const isAfterRedirectSelector = selector({
  key: 'login:isAfterRedirect',
  get: ({ get }) => {
    const providerName = get(providerNameInternal);
    if (typeof window === 'undefined') {
      return false;
    }

    // if no provider name is in the state, but it is in the session storage
    // we are right after the login redirect
    return !providerName && window.sessionStorage.getItem('login:providerName');
  },
});

const isPerformingLoginStateInternal = atom({
  key: 'login:ongoing',
  default: false,
});

export const isPerformingLoginSelector = selector<boolean>({
  key: 'login:ongoing:selector',
  get: ({ get }) => {
    const isPerformingLogin = get(isPerformingLoginStateInternal);
    const providerName = get(providerNameSelector);

    const isProviderNameDefined = typeof providerName === 'string';

    return isPerformingLogin || isProviderNameDefined;
  },
  set: ({ set }, key) => {
    set(isPerformingLoginStateInternal, key);
  },
});
