import { deviceType } from '@/core/environment/deviceType';
import { fromCamelCaseToSnakeCaseRecursive } from '@/core/objectManipulation/objectManipulation';
import { useTracker } from '@/core/tracking';
import { getPico } from '@/core/tracking/pico';
import UpdateCompletedModal from '@/features/settings/secretMenu/UpdateCompletedModal';
import { useSettingsService } from '@/layout/appWrapper/ServiceProvider';
import { Experiment } from '@/services/settingsService';
import { localeState } from '@/stores/localizationStore';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import Spinner from '@/ui/content/Spinner';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

interface Props {
  modalId: string;
  password: string;
}

export default function ExperimentsHandler({ modalId, password }: Props) {
  const locale = useRecoilValue(localeState);
  const device = deviceType();
  const localStorageId = getPico().getLocalStorageId();

  const closeModal = useSetRecoilState(closeModalSelector);
  const pushModal = useSetRecoilState(pushModalSelector);
  const { retrieveExperiments, forceExperiments } = useSettingsService();

  // TODO get current experiments
  const [currentExperiments, setCurrentExperiments] = useState(
    fromCamelCaseToSnakeCaseRecursive({})
  );

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [compatibleExperiments, setCompatibleExperiments] =
    useState<Experiment[]>();

  const { track } = useTracker();

  useEffect(() => {
    const fetchCompatibleExperiments = async () => {
      setIsFetching(true);

      const experiments = await retrieveExperiments(
        password,
        localStorageId,
        device,
        locale
      );
      setCompatibleExperiments(experiments);

      setIsFetching(false);
    };

    fetchCompatibleExperiments().catch(console.error);
  }, [locale, password, retrieveExperiments]);

  return isFetching ? (
    <div className="my-8 flex justify-center">
      <Spinner />
    </div>
  ) : compatibleExperiments ? (
    <>
      {compatibleExperiments.map(
        ({
          id: experimentId,
          state,
          description,
          name: experimentName,
          segments,
        }) => (
          <div key={`exp_${experimentId}`} className="w-full pb-8">
            <h3 className="SB16 text-gray-900">
              {experimentName} ({state})
            </h3>
            <p className="R12 text-gray-500 mb-4">{description}</p>
            <ul className="w-full bg-white rounded-lg border">
              <li
                key={`${experimentId}_no_segmentation`}
                className="w-full mx-2"
              >
                <div className="flex items-center">
                  <input
                    id={`${experimentId}_no_segmentation`}
                    type="radio"
                    name={experimentId}
                    value="de-segmented"
                    checked={!(experimentName in currentExperiments)}
                    onClick={() => {
                      delete currentExperiments[experimentName];
                    }}
                    onChange={() => {
                      setCurrentExperiments((prev) => {
                        delete prev[experimentName];
                        return prev;
                      });
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor={`${experimentId}_no_segmentation`}
                    className="M14 py-3 ms-2 w-full text-gray-900"
                  >
                    de-segmented (will not be segmented next time)
                  </label>
                </div>
              </li>
              {segments.map(
                ({ id: segmentId, index, name: segmentName, weight }) => (
                  <li key={`seg_${segmentId}`} className="w-full mx-2">
                    <div className="flex items-center">
                      <input
                        id={segmentId}
                        type="radio"
                        name={experimentId}
                        value={segmentName}
                        checked={
                          experimentName in currentExperiments
                            ? currentExperiments[experimentName].index === index
                            : false
                        }
                        onClick={() => {
                          currentExperiments[experimentName] = index;
                        }}
                        onChange={() => {
                          setCurrentExperiments((prev) => ({
                            ...prev,
                            [experimentName]: index,
                          }));
                        }}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                      />
                      <label
                        htmlFor={segmentId}
                        className="M14 py-3 ms-2 w-full text-gray-900"
                      >
                        {segmentName} ({weight! * 100}%)
                      </label>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        )
      )}
      <div className="mt-5 flex justify-end items-center">
        <button
          className="btn btn--large btn--black"
          onClick={async () => {
            await forceExperiments(
              password,
              currentExperiments,
              localStorageId,
              device,
              locale
            );
            track('secret_menu', 'experiments_updated');
            closeModal(modalId);

            pushModal({
              id: 'experiments-update',
              Modal: UpdateCompletedModal,
            });
          }}
        >
          Store new selection
        </button>
      </div>
    </>
  ) : (
    <p className="py-2">No experiments are currently running</p>
  );
}
