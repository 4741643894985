import { serviceSingletonGetter } from '@/services/serviceGetter';
import { useMedia } from './useMedia';

export type MediaService = {
  useMedia: typeof useMedia;
};

export const createMediaService = (): MediaService => ({
  useMedia,
});

export default serviceSingletonGetter(
  Symbol('mediaService'),
  createMediaService
);
