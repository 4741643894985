import { safeLocalStorage } from '../safeLocalStorage/safeLocalStorage';

const localStorageSearchParamsEffect =
  (localStorageKey: string, searchParam: string) =>
  ({ setSelf, onSet }) => {
    const params = new URLSearchParams(window.location.search);
    const currentValue = params.get(searchParam);
    const savedValue = safeLocalStorage.getItem(localStorageKey);

    // Determine the initial value
    let initialValue;

    if (currentValue !== null) {
      initialValue = currentValue;
    } else if (savedValue !== null) {
      initialValue = JSON.parse(savedValue);
    } else {
      initialValue = null;
    }

    // Set the atom's initial state
    if (initialValue !== null) {
      setSelf(initialValue);

      // Manually save to localStorage during initialization
      safeLocalStorage.setItem(localStorageKey, JSON.stringify(initialValue));
    }

    onSet((newValue: string | null, _, isReset: boolean) => {
      if (isReset || newValue === null) {
        safeLocalStorage.removeItem(localStorageKey);
      } else {
        safeLocalStorage.setItem(localStorageKey, JSON.stringify(newValue));
      }
    });
  };

export default localStorageSearchParamsEffect;
