import { safeLocalStorage } from '../safeLocalStorage/safeLocalStorage';

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }) => {
    const savedValue = safeLocalStorage.getItem(key);
    if (savedValue !== null) setSelf(JSON.parse(savedValue));

    onSet((newValue: string | null, _, isReset: boolean) => {
      if (isReset || newValue === null) {
        safeLocalStorage.removeItem(key);
      } else {
        safeLocalStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export default localStorageEffect;
