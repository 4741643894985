import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { closeModalSelector } from '@/stores/modalStore';
import { TabPanelsModal } from '@/core/modal';
import { isLoggedInSelector } from '@/stores/authStore';
import { useLocalization } from '@/services/localizationService';
import { useTracker } from '@/core/tracking';
import { useUser } from '@/services/userService';
import { usePaymentService } from '@/layout/appWrapper/ServiceProvider';
import settingsState from '@/stores/settingsStore';

const PrivacySettingsPanel = dynamic(
  () => import('./generalSection/PrivacySettingsPanel'),
  { ssr: false }
);
const GeneralPanel = dynamic(() => import('./generalSection/GeneralPanel'), {
  ssr: false,
});
const SubscriptionPanel = dynamic<{ id: string }>(
  () => import('./subscriptionSection/SubscriptionPanel'),
  { ssr: false }
);
const LimitPanel = dynamic<{ id: string }>(
  () => import('./limitsSection/LimitTrackerPanel'),
  { ssr: false }
);
const PluginKeyPanel = dynamic<{ id: string }>(
  () => import('./pluginKeySection/PluginKeyPanel'),
  { ssr: false }
);

export type AccountModalTabId =
  | 'general'
  | 'subscription'
  | 'limits'
  | 'pluginKey';
type AccountModalTabDef = {
  id: AccountModalTabId;
  label: string | JSX.Element;
};

export default function AccountModal({
  id,
  startTabId,
  ...rest
}: {
  id: string;
  startTabId?: AccountModalTabId;
}) {
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const settings = useRecoilValue(settingsState);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { data: user } = useUser();
  const { data, t } = useLocalization();
  const { isPremiumUser, isBusinessUser } = usePaymentService();

  useEffect(() => {
    if (!isLoggedIn) {
      closeModal(id);
    }
  }, [isLoggedIn, closeModal, id]);

  const { track } = useTracker();

  const [hasNavigatedToPrivacySettings, setHasNavigatedToPrivacySettings] =
    useState<boolean>(false);

  const subscriptionTab: AccountModalTabDef = {
    id: 'subscription',
    label: t(data.account.subscription),
  };
  const generalTab: AccountModalTabDef = {
    id: 'general',
    label: t(data.account.general),
  };
  const limitsTab: AccountModalTabDef = {
    id: 'limits',
    label: t(data.account.limits),
  };
  const pluginKeyTab: AccountModalTabDef = {
    id: 'pluginKey',
    label: t(data.account.pluginKey, null, 'capitalize'),
  };

  const tabs: AccountModalTabDef[] = [generalTab];
  if (user) {
    if (!user.isFree) tabs.unshift(subscriptionTab);
    if (
      settings.values.psPlugin &&
      isBusinessUser(user) &&
      !user?.monetization?.hasIncompleteSubscription
    ) {
      tabs.push(pluginKeyTab);
    }
    if (
      (isPremiumUser(user) || (!!user && (user.availableCredits ?? 0) > 0)) &&
      !user?.monetization.hasIncompleteSubscription
    ) {
      tabs.push(limitsTab);
    }
  }
  return (
    <TabPanelsModal
      className="max-w-3xl min-h-[610px]"
      label="User account"
      id={id}
      tabs={tabs}
      startTabId={startTabId}
      onTabChange={(tab) => {
        track('settings', `${tab}_tapped`, {
          actionValue: `${tab} (CTA)`,
        });
      }}
      {...rest}
    >
      {Boolean(user?.isFree) === false && (
        <TabPanelsModal.TabPanel tab={subscriptionTab.id}>
          <SubscriptionPanel id={id} />
        </TabPanelsModal.TabPanel>
      )}
      <TabPanelsModal.TabPanel tab={generalTab.id}>
        {hasNavigatedToPrivacySettings ? (
          <PrivacySettingsPanel
            onBackButtonPressed={() => setHasNavigatedToPrivacySettings(false)}
          />
        ) : (
          <GeneralPanel
            onPrivacySettingsNavigation={() =>
              setHasNavigatedToPrivacySettings(true)
            }
          />
        )}
      </TabPanelsModal.TabPanel>
      {(isPremiumUser(user) || (!!user && user.availableCredits! > 0)) &&
        !user?.monetization.hasIncompleteSubscription && (
          <TabPanelsModal.TabPanel tab={limitsTab.id}>
            <LimitPanel id={id} />
          </TabPanelsModal.TabPanel>
        )}
      {!!settings.values.psPlugin &&
        isBusinessUser(user) &&
        !user?.monetization?.hasIncompleteSubscription && (
          <TabPanelsModal.TabPanel tab={pluginKeyTab.id}>
            <PluginKeyPanel id={id} />
          </TabPanelsModal.TabPanel>
        )}
    </TabPanelsModal>
  );
}
