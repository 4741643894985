import faceEnhanceIcon from '@/public/images/icons/face-enhance.svg';
import sceneEnhanceIcon from '@/public/images/icons/scene-enhance.svg';
import colorCorrectionIcon from '@/public/images/icons/color-correction.svg';
import { useRecoilValue } from 'recoil';
import { useLocalization } from '@/services/localizationService';
import settingsState from '@/stores/settingsStore';
import { useCallback, useMemo } from 'react';
import {
  AiToolsChoices,
  RemoteAiConfigPair,
  RemoteAiPipelineStep,
  SectionName,
} from '@/services/settingsService/aiTypes';
import {
  EditorPipelineStep,
  EditorPipelineStepStyle,
  EditorSection,
  ImageEditorElements,
} from './editorTypes';

export const useEditorSettings = () => {
  const _BASE_ICONS_PATH = '/images/icons';
  const _BASE_TOOLTIPS_PATH = '/images/tooltips';
  const _BASE_THUMBNAILS_PATH = '/images/thumbnails';

  const settings = useRecoilValue(settingsState);
  const { data } = useLocalization();
  const { aiPipelineConfig, remoteAiPipelineConfig } = settings.values;

  const buildEditorPipelineStepStyle = useCallback(
    ({
      remoteModel,
      disabledModels,
    }: {
      remoteModel: RemoteAiConfigPair;
      disabledModels: string[];
    }): EditorPipelineStepStyle => ({
      key: remoteModel.label,
      title: data.result[remoteModel.label] || remoteModel.label,
      value: remoteModel.value,
      disabled: disabledModels.includes(remoteModel.label),
      thumbnailPath: `${_BASE_THUMBNAILS_PATH}/${remoteModel.label}.webp`,
    }),
    [data.result]
  );

  const buildEditorPipelineStep = useCallback(
    ({
      remotePipelineStep,
    }: {
      remotePipelineStep: RemoteAiPipelineStep;
    }): EditorPipelineStep => {
      const pipelineStepInfo = aiPipelineConfig.find(
        (step) => step.tool === remotePipelineStep.tool
      )!;

      const models = pipelineStepInfo.availableModels.map((modelLabel) => {
        const remoteModel = remotePipelineStep.models.find(
          (remoteModel) => remoteModel.label === modelLabel
        );

        return buildEditorPipelineStepStyle({
          remoteModel: remoteModel!,
          disabledModels: pipelineStepInfo.disabledModels || [],
        });
      });

      const shouldUseFilters = !pipelineStepInfo.title;
      let visualization =
        pipelineStepInfo.availableModels.length > 1
          ? ImageEditorElements.Dropdown
          : ImageEditorElements.Toggle;
      if (shouldUseFilters) {
        visualization = ImageEditorElements.Filters;
      }

      return {
        tool: remotePipelineStep.tool,
        title: shouldUseFilters ? null : data.result[pipelineStepInfo.title!],
        visualization,
        defaultModel: pipelineStepInfo.defaultModel,
        iconPath: shouldUseFilters
          ? null
          : `${_BASE_ICONS_PATH}/${pipelineStepInfo.title}.svg`,
        tooltip: pipelineStepInfo.isTooltipAvailable
          ? {
              title: data.result[pipelineStepInfo.title!],
              body: data.result[`${pipelineStepInfo.title}TooltipCopy`],
              mediaUrl: `${_BASE_TOOLTIPS_PATH}/${pipelineStepInfo.title}.mp4`,
            }
          : undefined,
        models,
        isBlockedForNonSubscribers: pipelineStepInfo.isBlockedForNonSubscribers,
        isBlockedForPersonalUsers: pipelineStepInfo.isBlockedForPersonalUsers,
      };
    },
    [aiPipelineConfig, buildEditorPipelineStepStyle, data.result]
  );

  const buildEditorPipelineSteps = useCallback(
    (section: SectionName): EditorPipelineStep[] => {
      const remotePipelineSteps = remoteAiPipelineConfig.filter(
        (remotePipelineStep) => remotePipelineStep.mainSection === section
      );

      return remotePipelineSteps
        .filter((remotePipelineStep) =>
          aiPipelineConfig
            .map((step) => step.tool)
            .includes(remotePipelineStep.tool)
        )
        .map((remotePipelineStep) =>
          buildEditorPipelineStep({ remotePipelineStep })
        );
    },
    [aiPipelineConfig, buildEditorPipelineStep, remoteAiPipelineConfig]
  );

  const buildEditorSettingsVisualization = useCallback(
    (): EditorSection[] => [
      {
        key: 'face',
        copy: {
          title: data.result.faceEnhance,
          subtitle: data.result.faceEnhanceSub,
        },
        icon: faceEnhanceIcon,
        pipelineSteps: buildEditorPipelineSteps('face'),
      },
      {
        key: 'background',
        copy: {
          title: data.result.backgroundEnhance,
          subtitle: data.result.sceneEnhanceSub,
        },
        icon: sceneEnhanceIcon,
        pipelineSteps: buildEditorPipelineSteps('background'),
      },
      {
        key: 'color',
        copy: {
          title: data.result.colorCorrection,
          subtitle: data.result.colorCorrectionSub,
        },
        icon: colorCorrectionIcon,
        pipelineSteps: buildEditorPipelineSteps('color'),
      },
    ],
    [
      buildEditorPipelineSteps,
      data.result.backgroundEnhance,
      data.result.colorCorrection,
      data.result.colorCorrectionSub,
      data.result.faceEnhance,
      data.result.faceEnhanceSub,
      data.result.sceneEnhance,
      data.result.sceneEnhanceSub,
    ]
  );

  const buildDefaultAiToolsChoices = useCallback((): AiToolsChoices => {
    const choices: AiToolsChoices = {};

    aiPipelineConfig.forEach((pipelineStepInfo) => {
      if (pipelineStepInfo.defaultModel === null) {
        return;
      }

      const { models } = remoteAiPipelineConfig.find(
        (step) => step.tool === pipelineStepInfo.tool
      )!;

      const { value: modelValue } = models.find(
        (model) => model.label === pipelineStepInfo.defaultModel
      )!;
      choices[pipelineStepInfo.tool] = modelValue;
    });

    return choices;
  }, [aiPipelineConfig, remoteAiPipelineConfig]);

  const aiToolbarSettings = useMemo(
    () => buildEditorSettingsVisualization(),
    [buildEditorSettingsVisualization]
  );

  const isAnyAiModelSubscribersOnly = useMemo(
    () =>
      aiToolbarSettings.some((section) =>
        section.pipelineSteps.some(
          (pipelineStep) =>
            pipelineStep.isBlockedForNonSubscribers &&
            !pipelineStep.isBlockedForPersonalUsers
        )
      ),
    [aiToolbarSettings]
  );

  const isAnyAiModelBusinessOnly = useMemo(
    () =>
      aiToolbarSettings.some((section) =>
        section.pipelineSteps.some(
          (pipelineStep) => pipelineStep.isBlockedForPersonalUsers
        )
      ),
    [aiToolbarSettings]
  );

  return {
    buildEditorSettingsVisualization,
    buildDefaultAiToolsChoices,
    isAnyAiModelBusinessOnly,
    isAnyAiModelSubscribersOnly,
  };
};
