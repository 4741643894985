import whenInBrowser from '@/core/environment/whenInBrowser';
import localStorageEffect from '@/core/stateManager/localStorageEffect';
import { atom } from 'recoil';

export const completedSurveysState = atom({
  key: 'completedSurveys',
  default: {
    featureRanking: false,
  },
  effects_UNSTABLE: [whenInBrowser(localStorageEffect('completedSurveys'))],
});
