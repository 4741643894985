import { ReactNode } from 'react';
import { StaticImageData } from 'next/image';
import { PricePeriod } from '../priceService';

export enum PaymentProvider {
  Stripe = 'stripe',
  PayPal = 'paypal',
  Mobile = 'mobile',
}

export type PaymentPlatform = 'apple' | 'google' | 'paypal' | 'card';

export interface Intents {
  clientSecret: string;
  customerId: string;
}

export interface PaymentIntents {
  paymentId: string;
  status: string;
  clientSecret: string;
}

export type StripeSubscriptionStatus =
  | 'active'
  | 'trialing'
  | 'past_due'
  | 'incomplete'
  | 'incomplete_expired'
  | 'unpaid'
  | 'canceled';

export interface Subscriptions {
  subscriptionId: string;
  status: StripeSubscriptionStatus;
}

export enum PaymentType {
  OneTimePayment = 'one_time',
  Subscription = 'subscribe',
}

export enum PaymentStrategiesIds {
  Base = 'base',
  Personal = 'personal',
  Business = 'business',
  OneTime = 'oneTime',
}

export interface PaypalCharge {
  chargeId: string | unknown;
  amountCents: number;
  currency: string;
  taskId: string;
  paypalEmailAddress?: string;
  exports?: number;
}

export interface PaymentStrategy {
  id: PaymentStrategiesIds;
  title: ReactNode;
  titleIcon: StaticImageData;
  subtitle: ReactNode;
  features: { text: ReactNode; check?: boolean }[];
  customPrice?: boolean;
  period?: PricePeriod;
  exports?: number;
}

export interface SubscriptionInfo {
  subscriptionId: string;
  hasBeenCanceled: boolean;
  cancellationDate?: Date | null;
  portalSessionUrl: string;
}

export interface UpgradePreviewInfo {
  remainingAmountCents: number;
  daysLeft: number;
  isTrialing: boolean;
}

export interface PortalSession {
  url: string;
}

export interface PortalSessionV2 {
  portalSessionUrl?: string;
}
