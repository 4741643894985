import { AiPipelineChoices } from '@/services/settingsService/aiTypes';
import { SettingsInfo, VideoLimits } from '../settingsService';
import { User } from '../userService';
import { TaskStatus, TaskType } from './uploaderConstants';

export interface InternalImage {
  type: string;
  id?: string;
}

export interface ConvertedImage {
  outputUri: string;
  imageContentType: string;
}

export enum TaskMimeType {
  PNG = 'image/png',
  JPG = 'image/jpeg',
  TIFF = 'image/tiff',
}

export const formatsNotSupportedInBrowsers = [TaskMimeType.TIFF];
export const defaultOutputForConversion = TaskMimeType.JPG;

export enum VideoTaskMimeType {
  MOV = 'video/quicktime',
  MP4 = 'video/mp4',
}

export enum OtherImageTaskMimeType {
  WEBP = 'image/webp',
  PSD = 'image/vnd.adobe.photoshop',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
  AVIF = 'image/avif',
}

export const allMediaTaskMimeType = {
  ...TaskMimeType,
  ...VideoTaskMimeType,
  ...OtherImageTaskMimeType,
};

export interface ImageTaskSubmitPayload {
  imageContentType?: string;
  outputContentType?: string;
  aiPipeline: AiPipelineChoices;
}

export interface VideoTaskSubmitPayload {
  videoContentType?: string;
  eligibilityLimits: {
    maxSizeMb: number;
    maxLength: number;
    period?: string;
    maxPeriodSizeMb: number;
    maxPeriodLength: number;
  };
  videoMetadata: {
    size: number;
    length: number;
  };
}

export interface TaskOptions {
  taskId: string;
  isVideo: boolean;
  hasWatermark?: boolean;
}

export interface BulkUploadOptions {
  bulkUploadId?: string;
  hasWatermark?: boolean;
}

export interface GetSharingPayload {
  sharingId: string;
}

export interface Sharing {
  inputUrl: string;
  outputUrl: string;
  xinaTracker: string;
}

type TaskOutput = {
  contentType: string;
  convertedImages: ConvertedImage[];
  hasWatermark: boolean;
  url: string;
  aiPipeline?: AiPipelineChoices;
};

export interface Task {
  result: {
    inputUrl: string;
    outputUrl?: string;
    outputs: [TaskOutput, ...TaskOutput[]]; // at least one
  };
  status:
    | TaskStatus.Processing
    | TaskStatus.Completed
    | TaskStatus.Failed
    | TaskStatus.Exported;
}

export interface TaskInfo {
  id?: string;
  status?: string;
  reason?: string;
  progress?: number;
  fileProgress?: string;
}

export interface BulkUploadTask extends Task {
  taskId: string;
}

export interface TaskResult {
  taskList?: BulkUploadTask[];
  bulkUploadId?: string;
  type?: string;
  inputUrl?: string;
  outputUrl?: string;
  outputContentType?: string;
  hasWatermark?: boolean;
  convertedImages?: ConvertedImage[];
  id?: string;
  isUnavailable?: boolean;
  inputImage?: HTMLImageElement | null;
  outputImage?: HTMLImageElement | null;
  mediaType?: TaskType | string;
  aiPipeline?: AiPipelineChoices;
  status?: TaskStatus;
}

export interface TaskState {
  id?: string | null;
  status: string;
  queueWaitNum?: number;
  progress?: number;
  fileProgress?: string;
  reason?: string;
}

export interface HitLimits {
  hitLimits: string[];
}

export interface PostTask {
  taskId: string;
  uploadUrl: string;
  uploadHeaders: {
    [key: string]: string;
  };
}

export interface PostVideoTaskResponse {
  type: 'success' | 'failure';
  result: PostTask | HitLimits;
}

export interface PostBulkUpload {
  bulkUploadId: string;
  taskList: PostTask[];
}

export interface BulkUpload {
  bulkUploadId: string;
  taskList: BulkUploadTask[];
}

export interface BulkUploadReprocessTask {
  clientTaskId: string;
  outputContentType: TaskMimeType;
  aiPipeline: AiPipelineChoices;
}

export interface BulkUploadReprocessPayload {
  inputReprocessList: BulkUploadReprocessTask[];
}

export interface TaskIdChange {
  oldTaskId: string;
  taskId: string;
}

export interface BulkUploadReprocessResponse {
  bulkUploadId: string;
  taskList: TaskIdChange[];
}

export function createTaskFromVideoTaskResponse(
  videoTaskResult: any
): PostTask {
  return {
    taskId: videoTaskResult.videoTaskId,
    uploadUrl: videoTaskResult.uploadUrl,
    uploadHeaders: videoTaskResult.uploadHeaders,
  };
}

interface CreateSubmitTaskPayloadProps {
  isVideo: boolean;
  file: File;
  fileDuration?: number | null;
  user?: User;
  isPremium?: boolean;
  remoteSettings?: SettingsInfo;
  isBulkUpload?: boolean;
  userVideoLimits?: VideoLimits;
  aiPipeline: AiPipelineChoices;
  defaultUserAiPipeline?: AiPipelineChoices;
}

// TODO: split this function in proper way. Currently video can get image payload, why?
export function createSubmitTaskPayload({
  isVideo,
  file,
  fileDuration,
  isPremium,
  user,
  userVideoLimits,
  aiPipeline,
  defaultUserAiPipeline,
}: CreateSubmitTaskPayloadProps):
  | VideoTaskSubmitPayload
  | ImageTaskSubmitPayload {
  const isPremiumUser = !!user && isPremium;

  return isVideo && fileDuration !== null
    ? {
        videoContentType: file.type,
        eligibilityLimits: {
          maxSizeMb: userVideoLimits?.maxSizeMb ?? 0,
          maxLength: userVideoLimits?.maxLength ?? 0,
          period: 'weekly',
          maxPeriodSizeMb: userVideoLimits?.maxPeriodSizeMb ?? 0,
          maxPeriodLength: userVideoLimits?.maxPeriodLength ?? 0,
        },
        videoMetadata: {
          size: file.size,
          length: Math.ceil(fileDuration!),
        },
      }
    : {
        imageContentType: file.type,
        outputContentType: isPremiumUser ? TaskMimeType.PNG : TaskMimeType.JPG,
        aiPipeline: defaultUserAiPipeline || aiPipeline,
      };
}
