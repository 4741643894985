import { atom } from 'recoil';
import localStorageEffect from '@/core/stateManager/localStorageEffect';
import whenInBrowser from '@/core/environment/whenInBrowser';
import { LocData } from '@/services/localizationService/locales/en';
import {
  defaultLocalization,
  supportedLocales,
  SupportedLocale,
} from '../services/localizationService/localizationTypes';

export const parseType = <T>(x: any, array: readonly T[]) =>
  array.includes(x) ? (x as T) : undefined;

export const parseBrowserLocale = () => {
  if (typeof navigator === 'undefined') return undefined;
  return (
    parseType(navigator.language, supportedLocales) ||
    parseType(navigator.language.slice(0, 2), supportedLocales)
  );
};

export const localeState = atom<SupportedLocale>({
  key: 'localization:locale',
  default: parseBrowserLocale() || defaultLocalization.locale,
  effects_UNSTABLE: [whenInBrowser(localStorageEffect('localization:locale'))],
});

export const locDataState = atom<LocData>({
  key: 'localization:locData',
  default: defaultLocalization.data,
});

export const isReadyState = atom<boolean>({
  key: 'localization:isReady',
  default: false,
});
