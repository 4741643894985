import { PersistentInfoProvider } from '@/core/tracking/pico/providers';

type PersistAttributionParams = {
  paramsFound: { [k: string]: string };
  attributionParamKeys: readonly string[];
  persistentInfoProvider: PersistentInfoProvider;
};

export const persistAttrbution = ({
  paramsFound,
  attributionParamKeys,
  persistentInfoProvider,
}: PersistAttributionParams) => {
  const paramFoundIsNotEmpty = (key: string) => !!paramsFound[key];

  const attributionParamsFound =
    attributionParamKeys.filter(paramFoundIsNotEmpty);
  if (attributionParamsFound.length === 0) return;

  const persistentInfo = persistentInfoProvider.getInfo();

  const peristentInfoIsDifferentFromParamFound = (key: string) =>
    persistentInfo[key] !== paramsFound[key];

  const differentParameters = attributionParamsFound.filter(
    peristentInfoIsDifferentFromParamFound
  );
  if (differentParameters.length === 0) return;

  attributionParamKeys.forEach((p) => {
    persistentInfoProvider.setInfo(p, paramsFound[p] ?? '');
  });
};
