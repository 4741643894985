import React from 'react';
import errorIcon from '../../public/images/icons/error.svg';

interface Props {
  title: string;
  className?: string;
  highlight?: boolean;
  children?: React.ReactNode;
}

export default function ErrorAlert({
  className = '',
  highlight,
  title,
  children,
}: Props) {
  return (
    <div
      className={`text-center space-y-6 leading-none break-words ${className}`}
    >
      <img className="inline-block" {...errorIcon} alt="" />
      <div className="space-y-3 max-w-xs">
        <h3 className={`SB25 ${highlight ? 'text-primary-accent' : ''}`}>
          {title}
        </h3>
        <div className="M15">{children}</div>
      </div>
    </div>
  );
}
