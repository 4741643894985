import { isAnonymousActionInfo } from '@/core/tracking/tracking-schema/dist/api';

export type ActionInfoAnonymizer = {
  anonymizeActionInfo: (
    kind: string,
    info: Record<string, unknown>
  ) => Record<string, unknown>;
};

export const TrackingSchemaActionInfoAnonymizer: ActionInfoAnonymizer = {
  anonymizeActionInfo: (kind, info) =>
    Object.fromEntries(
      Object.entries(info).filter(([key]) => isAnonymousActionInfo(kind, key))
    ),
};
