import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useUser } from '@/services/userService';
import settingsState from '@/stores/settingsStore';
import { initPico } from './track';

export function useSyncUserWithPico() {
  const settings = useRecoilValue(settingsState);
  const { data: user } = useUser();

  // Sync user with Pico
  useEffect(() => {
    initPico(settings, {
      firebaseUid: user?.firebaseUid ?? undefined,
      webUserId: user?.userId,
      createdAt: user?.createdAt,
      backendId: user?.mobileOracleBackendId ?? undefined,
      isFree: user?.isFree,
    });
  }, [
    settings,
    user?.createdAt,
    user?.firebaseUid,
    user?.isFree,
    user?.mobileOracleBackendId,
    user?.userId,
  ]);
}
