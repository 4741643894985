import { authState } from '@/stores/authStore';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { api } from './httpService-new';

/**
 * Sync recoil auth token with `Authorization` header. Only needed for requests **outside useSWR**.
 *
 * Note: when using useSWR, please rely on `authFetcher` instead!
 */
export function useAuth() {
  const { token } = useRecoilValue(authState);

  useEffect(() => {
    const authInterceptor = api.interceptors.request.use((config) => {
      if (!config.headers.get('Authorization')) {
        config.headers.Authorization = token ? `Bearer ${token}` : undefined;
      }
      return config;
    });

    return () => api.interceptors.request.eject(authInterceptor);
  }, [token]);
}
