import { SpiderSenseDebugEventWithMetadata } from '@/core/monitoring/spidersense/src/event';
import {
  NetworkAdapter,
  NetworkClient,
} from '@/core/monitoring/spidersense/src/network';
import anonymizeRequest from '../privacy/anonymizeRequest';

type CreateEventSenderProps = {
  networkAdapter: NetworkAdapter;
  networkClient: NetworkClient;
};

export type EventSender = {
  send: (a: SpiderSenseDebugEventWithMetadata[]) => void;
};

export const createEventSender = ({
  networkAdapter,
  networkClient,
}: CreateEventSenderProps): EventSender => {
  const send = async (events: SpiderSenseDebugEventWithMetadata[]) => {
    const request = networkAdapter.getNetworkRequest(events);
    await networkClient.send(anonymizeRequest(request));
  };
  return {
    send,
  };
};
