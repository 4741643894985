import { TailSpin } from 'react-loader-spinner';

interface Props {
  className?: string;
  size?: number | string;
  color?: string;
  inline?: boolean;
}

export default function Spinner({
  className = '',
  size = 50,
  color = 'black',
  inline = true,
}: Props) {
  const Component = (
    <TailSpin color={color} width={size} height={size} aria-label="loading" />
  );

  return inline ? (
    Component
  ) : (
    <div
      className={`w-full flex justify-center items-center py-40 ${className}`}
    >
      {Component}
    </div>
  );
}
