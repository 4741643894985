import * as Sentry from '@sentry/nextjs';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from 'firebase/auth';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Cookies from 'js-cookie';
import GoogleOneTapLogin from 'react-google-one-tap-login';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLoginCallbacks, loginState } from '@/features/account/login';
import { isLoggedInSelector } from '@/stores/authStore';
import { useTracker } from '@/core/tracking';
import { deviceType } from '@/core/environment/deviceType';
import {
  trackFailedEvent,
  trackStartedEvent,
  trackCompletedEvent,
} from '@/core/monitoring/trackEvents';
import { FirebaseError } from 'firebase/app';
import { FirebaseLoginProvider } from './firebaseLoginProvider';

export default function GoogleOneTap() {
  const [login] = useLoginCallbacks();
  const router = useRouter();
  const { track } = useTracker();

  const [isPerformingLogin, setIsPerformingLogin] = useRecoilState(
    loginState.isPerformingLoginSelector
  );
  const isLoggedIn = useRecoilValue(isLoggedInSelector);

  const [showGoogleOneTap, setShowGoogleOneTap] = useState(false);

  useEffect(() => {
    // In dev/preprod, remove cookies which prevents OneTap to show again after closing it
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
      Cookies.remove('g_state');
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setShowGoogleOneTap(!isLoggedIn), 2000);
    return () => clearTimeout(timeout);
  }, [isLoggedIn]);

  const oneTapCallback = useCallback(
    async (response) => {
      const from = 'google_one_tap';
      setIsPerformingLogin(true);
      track('login', 'started', {
        actionValue: FirebaseLoginProvider.GOOGLE.name,
        from,
      });
      trackStartedEvent(['login'], {
        provider: FirebaseLoginProvider.GOOGLE.name,
        oneTap: true,
      });
      try {
        const credential = GoogleAuthProvider.credential(response.credential);
        const auth = getAuth();
        await signInWithCredential(auth, credential);
        const idToken = await auth.currentUser?.getIdToken(true);
        await login(idToken!);
        track('login', 'completed', {
          actionValue: FirebaseLoginProvider.GOOGLE.name,
          from,
        });
        trackCompletedEvent(['login'], {
          provider: FirebaseLoginProvider.GOOGLE.name,
          oneTap: true,
        });
      } catch (err) {
        trackFailedEvent(['login'], {
          provider: FirebaseLoginProvider.GOOGLE.name,
          oneTap: true,
          error: err instanceof FirebaseError ? err.code : err,
        });
        Sentry.captureException(err);
      }
      setIsPerformingLogin(false);
    },
    [login, setIsPerformingLogin, track]
  );

  if (
    isLoggedIn ||
    isPerformingLogin ||
    !showGoogleOneTap ||
    deviceType() !== 'desktop' ||
    router.pathname !== '/'
  ) {
    return null;
  }

  return (
    <>
      <Head>
        <style>
          {
            '#credential_picker_container { top: 60px !important; z-index: 5 !important; }'
          }
        </style>
      </Head>
      <GoogleOneTapLogin
        onError={(error) => console.log(error)}
        googleAccountConfigs={{
          client_id: process.env.NEXT_PUBLIC_GCLOUD_CLIENT_ID!,
          callback: oneTapCallback,
          cancel_on_tap_outside: false,
        }}
      />
    </>
  );
}
