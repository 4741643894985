/* eslint-disable max-lines, max-statements */
import { useCallback, useRef } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import bytesToMB from '@/core/mediaMetadata/convertFileSize';
import getVideoDuration from '@/core/mediaMetadata/getVideoDuration';
import { useTracker } from '@/core/tracking';
import { useCallToActionModal } from '@/features/callToAction/useCallToActionModal';
import { PopupSurveyModal } from '@/features/surveys/PopupSurveyModal';
import { useSubscriptionUpgradePrompts } from '@/features/upgradeSubscription/useSubscriptionUpgradePrompts';
import UploadMediaErrorModal from '@/features/uploadMedia/UploadMediaError';
import {
  useEditorService,
  usePaymentService,
  useUploaderService,
} from '@/layout/appWrapper/ServiceProvider';
import modalBackgroundImage from '@/public/images/covers/batch-processing-cover.webp';
import videoModalBackgroundImage from '@/public/images/covers/video-enhance-promo-bg.gif';
import { useEnhanceLimitService } from '@/services/enhanceLimitService/useEnhanceLimitService';
import { VideoLimits } from '@/services/settingsService';
import { useFeatureRankingSurvey } from '@/services/surveyService/useFeatureRankingSurvey';
import {
  BulkUploadReprocessPayload,
  createSubmitTaskPayload,
  createTaskFromVideoTaskResponse,
  HitLimits,
  ImageTaskSubmitPayload,
  PostBulkUpload,
  PostTask,
  TaskError,
  TaskErrorType,
  TaskResult,
  TaskStatus,
  TaskType,
  VideoTaskSubmitPayload,
} from '@/services/uploaderService';
import { UserAction, useUser } from '@/services/userService';
import { lockUISelector, unlockUISelector } from '@/stores/appStore';
import {
  bulkMediaListState,
  downloadMediaState,
  inputMediaNamesState,
  mediaInComparerIdChangeState,
  mediaShownInComparerState,
  mediaState,
} from '@/stores/mediaStore';
import { pushModalSelector } from '@/stores/modalStore';
import settingsState from '@/stores/settingsStore';
import taskStateFamily from '@/stores/taskStateFamilyStore';
import taskToFormatAssociationState from '@/stores/formatState';
import { ErrorModal } from '@/features/payment/modals';
import { surveyEvent } from '../surveys';
import { enhanceFlowTracker, reprocessTracker } from './trackers';

export default function useUploadHandler(forId: string) {
  const setTask = useSetRecoilState(taskStateFamily(forId));
  const lockUI = useSetRecoilState(lockUISelector);
  const unlockUI = useSetRecoilState(unlockUISelector);
  const setMediaName = useSetRecoilState(inputMediaNamesState);
  const pushModal = useSetRecoilState(pushModalSelector);
  const { data: user } = useUser();
  const featureRankingSurvey = useFeatureRankingSurvey();
  const { useEditorSettings, extractAiPipelineFromAiToolsChoices } =
    useEditorService();
  const { buildDefaultAiToolsChoices } = useEditorSettings();

  const { showCallToActionModal } = useCallToActionModal();
  const { isBusinessUser, isPersonalUser, isBaseUser } = usePaymentService();
  const {
    uploadMedia,
    getMediaType,
    setMediaTypeToUrl,
    reProcessBulkUpload,
    processVideoTask,
    postBulkUpload,
    postVideoTask,
    isVideoTask,
    useUploader,
  } = useUploaderService();

  const isBusiness = isBusinessUser(user);
  const isPersonal = isPersonalUser(user);

  const mainActionRef = useRef<any>(null);

  const { track } = useTracker();

  const settings = useRecoilValue(settingsState);
  const [media, setMedia] = useRecoilState(mediaState);
  const mediaInComparer = useRecoilValue(mediaShownInComparerState);
  const setMediaInComparerIdChange = useSetRecoilState(
    mediaInComparerIdChangeState
  );

  const resetMediaInComparer = useResetRecoilState(mediaShownInComparerState);
  const resetSelectedMedias = useResetRecoilState(downloadMediaState);
  const resetBulkMediaList = useResetRecoilState(bulkMediaListState);
  const { currentUserPlanLimits } = useEnhanceLimitService();
  const resetTaskToFormatAssociation = useResetRecoilState(
    taskToFormatAssociationState
  );

  const {
    setTaskInfo,
    setTaskInfoFromFiles,
    handleFileUploadErrors,
    onProcessingTask,
    bulkUploadProcessingCompletion,
    onBulkUploadComplete,
    handleBulkUploadErrors,
  } = useUploader(setTask, pushModal, mainActionRef);

  const { showUpgradeCTAOnPeriodicVideoLimitsError } =
    useSubscriptionUpgradePrompts();

  const reprocess = useCallback(
    async ({
      currentBulkUploadId,
      bulkUploadReprocessPayload,
      trigger = UserAction.FormatConversion,
    }: {
      currentBulkUploadId: string;
      bulkUploadReprocessPayload: BulkUploadReprocessPayload;
      trigger?: string;
    }): Promise<TaskResult> => {
      try {
        reprocessTracker.start();
        const oldTaskIds = bulkUploadReprocessPayload.inputReprocessList.map(
          (inputTask) => inputTask.clientTaskId
        );

        const bulkUpload = await reProcessBulkUpload({
          bulkUploadId: currentBulkUploadId,
          bulkUploadReprocessPayload,
        });

        if (oldTaskIds.includes(mediaInComparer.id!) && bulkUpload?.taskList) {
          const mediaInComparerIdChange = bulkUpload.taskList.find(
            (taskIdChange) => taskIdChange.oldTaskId === mediaInComparer.id
          );

          if (mediaInComparerIdChange) {
            setMediaInComparerIdChange(mediaInComparerIdChange);
          }
        }

        const bulkUploadResult = await bulkUploadProcessingCompletion({
          bulkUploadId: bulkUpload.bulkUploadId,
          trigger,
          taskId: '',
          taskStatus: TaskStatus.Reprocessing,
          track,
          showProgress: false,
          isReprocess: true,
        });

        reprocessTracker.complete();
        return bulkUploadResult;
      } catch (e) {
        reprocessTracker.fail(e);
        throw e;
      }
    },
    [
      bulkUploadProcessingCompletion,
      mediaInComparer.id,
      reProcessBulkUpload,
      setMediaInComparerIdChange,
      track,
    ]
  );

  const upload = useCallback(
    async (files: FileList, trigger: 'browse' | 'drag', t, data) => {
      // bulk upload blocks based on user type and number of files
      if (files.length > 1 && !isBusiness) {
        const callToActionModalConfig = isPersonal
          ? {
              id: 'user:subscribeToBusinessToBulkUpload',
              previousAction: UserAction.BatchProcessing,
              backgroundImage: modalBackgroundImage,
              title: t(data.common.batchProcessingAvailableForBusiness),
              body: t(
                data.common.batchProcessingAvailableToBusinessDescription
              ),
              showPhotoEnhanceButton: true,
              showSubscribeFlowButton: false,
              showUpgradePlanButton: true,
              hasFreeTrial: false,
              showLoginCTA: false,
              uploaderId: 'uploader-file-input-home',
            }
          : {
              id: 'user:subscribeToBusinessToBulkUpload',
              previousAction: UserAction.BatchProcessing,
              backgroundImage: modalBackgroundImage,
              title: t(data.common.batchProcessingAvailableForBusiness),
              body: t(
                data.common.batchProcessingAvailableToBusinessDescription
              ),
              showPhotoEnhanceButton: true,
              showSubscribeFlowButton: true,
              showUpgradePlanButton: false,
              hasFreeTrial: false,
              showLoginCTA: true,
              uploaderId: 'uploader-file-input-home',
            };
        track('batch_processing_cta_popup', 'shown');
        showCallToActionModal(callToActionModalConfig);
        return;
      }

      const { shouldPromptFileErrorPopup } = handleBulkUploadErrors({
        files,
        track,
      });

      if (shouldPromptFileErrorPopup) {
        return;
      }

      // video enhance logic takes one single file, the first one found
      const file: File = files[0];
      const isVideo = isVideoTask(file) && files.length === 1;
      const videoLimitsByUserPlan: VideoLimits =
        currentUserPlanLimits?.videoLimits;

      enhanceFlowTracker.setInfo({
        media_type: isVideo ? 'video' : 'image',
        from: trigger,
        has_multiple_medias: files.length > 1,
      } as const);

      if (isVideo) {
        if (isBaseUser(user)) {
          setTask({ status: TaskStatus.Pristine });

          showCallToActionModal({
            id: 'user:subscribeToEnhance',
            previousAction: UserAction.VideoUpload,
            backgroundImage: videoModalBackgroundImage,
            title: t(data.onboarding.videoEnhanceAvailability),
            body: t(data.onboarding.videoEnhanceAvailabilitySub),
            showPhotoEnhanceButton: true,
            uploaderId: 'uploader-file-input-home',
            hasFreeTrial: true,
            showSubscribeFlowButton: true,
            showLoginCTA: true,
            showUpgradePlanButton: false,
          });
          track('video_enhance_subscribe_popup', 'displayed');
          return;
        }

        const fileSize = bytesToMB(file.size);
        const fileDuration = await getVideoDuration(file);

        const uploadVideoErrorConditions =
          fileSize > videoLimitsByUserPlan?.maxSizeMb ||
          Math.ceil(fileDuration) > videoLimitsByUserPlan.maxLength;

        if (uploadVideoErrorConditions) {
          const errorMessage =
            fileSize > videoLimitsByUserPlan.maxSizeMb
              ? TaskError.FileSizeError
              : TaskError.FileDurationError;
          const errorType =
            fileSize > videoLimitsByUserPlan.maxSizeMb
              ? TaskErrorType.VideoSize
              : TaskErrorType.VideoDuration;
          handleFileUploadErrors({
            errorMessage,
            errorType,
            mediaSize: videoLimitsByUserPlan.maxSizeMb,
            mediaDuration: videoLimitsByUserPlan.maxLength,
            fileNumber: 1,
            track,
            isPersonalUser: isPersonal,
          });
          return;
        }
      }

      // survey handling
      if (
        featureRankingSurvey.shouldShowSurvey &&
        !featureRankingSurvey.isCompleted
      ) {
        await new Promise((resolve) => {
          lockUI({});
          pushModal({
            id: 'modal:feature-ranking-survey',
            trackEvent: surveyEvent,
            closeAfterMS: 2000,
            Modal: PopupSurveyModal,
            onClose: () => {
              featureRankingSurvey.complete();
              unlockUI({});
              resolve(true);
            },
          });
        });
      }

      setTaskInfoFromFiles(files);
      lockUI({});

      // store files names
      const fileArray = Object.values(files);
      const fileNameArray = fileArray
        .filter((f) => typeof f.name === 'string')
        .map((f) => {
          const imageFileName = f.name.substring(0, f.name.lastIndexOf('.'));
          return `${imageFileName}.remini-enhanced`;
        });
      setMediaName(fileNameArray);

      // here starts the real upload logic for both video and images
      let taskId: string | undefined = '';
      try {
        track('upload', TaskStatus.Started, {
          actionValue: trigger,
          mediaType: getMediaType(isVideo),
          mediaQuantity: fileArray.length,
        });

        const fileDuration = isVideo ? await getVideoDuration(file) : null;
        const aiToolsChoices = buildDefaultAiToolsChoices();
        const fullAiPipeline = extractAiPipelineFromAiToolsChoices(
          aiToolsChoices,
          settings.values,
          {}
        );

        let singleTask: PostTask;
        let bulkUpload: PostBulkUpload;

        if (isVideo) {
          const submitTaskPayload = createSubmitTaskPayload({
            isVideo,
            file,
            fileDuration,
            remoteSettings: settings,
            isBulkUpload: false,
            aiPipeline: {},
            userVideoLimits: videoLimitsByUserPlan,
          });

          const postVideoResponse = await postVideoTask(
            submitTaskPayload as VideoTaskSubmitPayload
          );

          if (postVideoResponse.type === 'failure') {
            const { hitLimits } = postVideoResponse.result as HitLimits;
            const shouldHandleErrors =
              hitLimits.includes('period_size') ||
              hitLimits.includes('period_length');

            if (!shouldHandleErrors && hitLimits.includes('size')) {
              pushModal({
                id: 'videoSizeError',
                Modal: ErrorModal,
                error: {
                  message: `Maximum allowed size for a video is ${videoLimitsByUserPlan?.maxSizeMb} MB`,
                },
              });
              return;
            }

            if (!shouldHandleErrors && hitLimits.includes('length')) {
              pushModal({
                id: 'videoLengthError',
                Modal: ErrorModal,
                error: {
                  message: `Maximum allowed length for a video is ${videoLimitsByUserPlan?.maxLength} seconds`,
                },
              });
              return;
            }

            const isDurationError = hitLimits.includes('period_length');
            const shouldShowCTAPopup = isPersonal;

            if (shouldShowCTAPopup) {
              showUpgradeCTAOnPeriodicVideoLimitsError(isDurationError);
            } else {
              pushModal({
                id: 'video:limitReached:business',
                Modal: UploadMediaErrorModal,
                error: isDurationError
                  ? TaskError.PeriodDurationErrorBusiness
                  : TaskError.PeriodSizeErrorBusiness,
                maxPeriodSizeMb: videoLimitsByUserPlan?.maxPeriodSizeMb,
                maxPeriodLength: videoLimitsByUserPlan?.maxPeriodLength,
              });
            }

            track('video_enhance_limit', 'error', {
              errorType: hitLimits.join(),
            });

            setTaskInfo({ status: TaskStatus.Pristine });
            unlockUI({});

            return;
          }

          singleTask = createTaskFromVideoTaskResponse(
            postVideoResponse.result as PostTask
          );
          setMediaTypeToUrl(TaskType.Video);
          sessionStorage.setItem('resetToolbar', 'true');

          try {
            enhanceFlowTracker.startUpload();
            await uploadMedia({
              setTaskInfo,
              file,
              singleTask,
            });
            enhanceFlowTracker.completeUpload();
          } catch (e) {
            enhanceFlowTracker.failUpload(e);
            throw e;
          }

          track('upload', TaskStatus.Completed, {
            actionValue: trigger,
            mediaType: getMediaType(isVideo),
          });

          try {
            enhanceFlowTracker.startProcess();
            await processVideoTask(singleTask.taskId);
            await onProcessingTask({
              taskId: singleTask.taskId,
              isVideoTask: isVideo,
              trigger,
              actionType: TaskStatus.Processing,
              aiPipeline: fullAiPipeline,
              track,
              setMedia,
              media,
            });
            enhanceFlowTracker.completeProcess();
          } catch (e) {
            if (!enhanceFlowTracker.isCanceled()) {
              enhanceFlowTracker.failProcess(e);
            }
            throw e;
          }

          taskId = singleTask!.taskId;
        } else {
          const submitBulkUploadPayload = fileArray.map(
            (file) =>
              createSubmitTaskPayload({
                isVideo: false, // no video payload
                file,
                fileDuration,
                remoteSettings: settings,
                isBulkUpload: fileArray.length > 1,
                aiPipeline: fullAiPipeline,
                defaultUserAiPipeline: settings.values
                  .shouldEnableSavingAiModelDefault
                  ? user?.preferences?.defaultAiPipeline
                  : undefined,
              }) as ImageTaskSubmitPayload
          );

          try {
            enhanceFlowTracker.startUpload();
            bulkUpload = await postBulkUpload({
              inputTaskList: submitBulkUploadPayload,
            });
            enhanceFlowTracker.completeUpload();
          } catch (e) {
            enhanceFlowTracker.failUpload(e);
            throw e;
          }

          setMediaTypeToUrl(TaskType.Image);
          sessionStorage.setItem('resetToolbar', 'true');

          resetBulkMediaList();

          resetMediaInComparer();
          resetSelectedMedias();
          resetTaskToFormatAssociation();

          let bulkUploadResult;
          try {
            enhanceFlowTracker.startProcess();
            bulkUploadResult = await onBulkUploadComplete({
              fileArray,
              bulkUpload,
              track,
              taskId,
              showProgress: true,
            });
            enhanceFlowTracker.completeProcess();
          } catch (e) {
            enhanceFlowTracker.failProcess(e);
            throw e;
          }

          taskId = bulkUploadResult?.bulkUploadId;
        }
      } catch (e) {
        setTaskInfo({ status: TaskStatus.Failed });
        unlockUI({});
        throw e;
      }

      unlockUI({});
      return taskId;
    },
    [
      buildDefaultAiToolsChoices,
      currentUserPlanLimits?.videoLimits,
      extractAiPipelineFromAiToolsChoices,
      featureRankingSurvey,
      getMediaType,
      handleBulkUploadErrors,
      handleFileUploadErrors,
      isBaseUser,
      isBusiness,
      isPersonal,
      isVideoTask,
      lockUI,
      media,
      onBulkUploadComplete,
      onProcessingTask,
      postBulkUpload,
      postVideoTask,
      processVideoTask,
      pushModal,
      resetBulkMediaList,
      resetMediaInComparer,
      resetSelectedMedias,
      resetTaskToFormatAssociation,
      setMedia,
      setMediaName,
      setMediaTypeToUrl,
      setTask,
      setTaskInfo,
      setTaskInfoFromFiles,
      settings,
      showCallToActionModal,
      showUpgradeCTAOnPeriodicVideoLimitsError,
      track,
      unlockUI,
      uploadMedia,
      user,
    ]
  );

  return { upload, reprocess };
}
