import useSWR, { Fetcher, Key, SWRConfiguration, SWRResponse } from 'swr';
import { useRecoilValue } from 'recoil';
import { authState } from '@/stores/authStore';
import { authFetcher, GenericAxiosError } from '@/core/http';

type AuthSWRFetcher<T> = Fetcher<T, [Key, string]>;
type AuthSWRConfig<T> = SWRConfiguration<T, GenericAxiosError>;

interface AuthSWRFunction {
  /** Custom SWR hook that automatically watches and sends auth **token** with the request.
   * @param key Provide a string to leverage the default fetcher (`authFetcher`). Otherwise, provide also a dedicated fetcher.
   * @param fetcher This fetcher will be called with an array parameter with 2 elements: `[Key, string]`. Defaults to `authFetcher`.
   * @param config SWR config.
   * */

  <Data = any>(
    key: string | null,
    fetcher?: AuthSWRFetcher<Data>,
    config?: AuthSWRConfig<Data>
  ): SWRResponse<Data, GenericAxiosError>;

  <Data = any>(
    key: Exclude<Key, string | null>,
    fetcher: AuthSWRFetcher<Data>,
    config?: AuthSWRConfig<Data>
  ): SWRResponse<Data, GenericAxiosError>;
}

export const useAuthSWR: AuthSWRFunction = <Data>(
  key: Key,
  fetcher: AuthSWRFetcher<Data> = authFetcher,
  config?: AuthSWRConfig<Data>
) => {
  const { token } = useRecoilValue(authState);

  const resp = useSWR<Data, GenericAxiosError, [Key, string] | null>(
    key && token ? [key, token] : null,
    fetcher,
    config
  );

  return resp;
};

export const useImmutableAuthSWR: typeof useAuthSWR = (...args) =>
  useAuthSWR(args[0], args[1], {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    ...args[2],
  });
