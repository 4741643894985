const data = {} as Record<string, string>;

export const memoryStorage = {
  setItem: (key: string, value: string) => {
    data[key] = value;
    return data[key];
  },
  removeItem: (key: string) => delete data[key],
  getItem: (key: string): string | null => data[key] ?? null,
  clear: () => Object.keys(data).forEach((key) => delete data[key]),
};
