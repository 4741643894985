import { serviceSingletonGetter } from '@/services/serviceGetter';
import { SurveyClassname, SurveyItem } from './surveyTypes';

export type SurveyService = {
  removeTypeformElements: (className: SurveyClassname) => void;
  openContactUsSurvery: () => void;
  nativeSurveyOptionRandomizer: (
    options: SurveyItem[],
    property: string
  ) => SurveyItem[];
};

export const createSurveyService = (): SurveyService => {
  const removeTypeformElements = (className: SurveyClassname) => {
    const typeformElements = document.getElementsByClassName(className);
    Array.from(typeformElements).forEach((element) => element.remove());
  };

  const openContactUsSurvery = () => {
    window
      .open(
        'https://ms732111.typeform.com/to/CAnzRLw8',
        '_blank',
        'noopener, noreferrer'
      )
      ?.focus();
  };

  const nativeSurveyOptionRandomizer = (
    array: SurveyItem[],
    propertyToFixate: string
  ): SurveyItem[] => {
    const randomizedOptions = array.slice();
    let currentIndex = randomizedOptions.length;
    let randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      if (
        randomizedOptions[currentIndex][propertyToFixate] === true ||
        randomizedOptions[randomIndex][propertyToFixate] === true
      ) {
        continue;
      }
      [randomizedOptions[currentIndex], randomizedOptions[randomIndex]] = [
        randomizedOptions[randomIndex],
        randomizedOptions[currentIndex],
      ];
    }
    return randomizedOptions;
  };

  return {
    nativeSurveyOptionRandomizer,
    openContactUsSurvery,
    removeTypeformElements,
  };
};

export default serviceSingletonGetter(
  Symbol('surveyService'),
  createSurveyService
);
