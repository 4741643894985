/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { trackWeTransferCreativeConversionPixelIfAny } from '@/core/campaigns/WeTransferCreative';
import useStripeMethods from '@/core/stripe/useStripeMethods';
import { useTracker } from '@/core/tracking';
import { getPersistentInfoProvider } from '@/core/tracking/pico/providers';
import warningInfo from '@/public/images/icons/warning_info.svg';
import warningInfoWhite from '@/public/images/icons/warning_info_white.svg';
import { useLocalization } from '@/services/localizationService';
import {
  PaymentPlatform,
  PaymentStrategiesIds,
} from '@/services/paymentService';
import {
  isBusySelector,
  lockUISelector,
  unlockUISelector,
} from '@/stores/appStore';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import settingsState from '@/stores/settingsStore';
import Spinner from '@/ui/content/Spinner';
import { Form } from '@/ui/form';
import * as Sentry from '@sentry/nextjs';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  trackCompletedEvent,
  trackEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '@/core/monitoring/trackEvents';
import { useReCaptcha } from '@/core/recaptcha/useReCaptcha';
import { useConsent } from '@/features/userConsent';
import {
  useMediaService,
  usePaymentService,
  usePreferencesService,
} from '@/layout/appWrapper/ServiceProvider';
import { Price } from '@/services/priceService';
import { TaskResult } from '@/services/uploaderService';
import { useUser } from '@/services/userService';
import {
  isReminderEnabledState,
  onboardingState,
  showFreeTrialSelector,
} from '@/stores/onboardingStore';
import CountryNotAllowedModal from '@/features/payment/modals/CountryNotAllowedModal';
import useDownload from '../downloadMedia/downloadSidebar/useDownload';
import maybeShowSuccessToast from './maybeShowSuccessToast';
import { ErrorModal } from './modals';

interface Props {
  selectedPrice: Price;
  onSubmit: CallableFunction;
  willDownloadAutomatically: boolean;
  isIntroPriceActive?: boolean;
  webOnboardingUserId?: string;
  webOnboardingLocalStorageId?: string;
  paywallVersion?: string;
}

export default function PaymentInformationForm({
  selectedPrice,
  onSubmit,
  willDownloadAutomatically,
  isIntroPriceActive = false,
  webOnboardingUserId,
  webOnboardingLocalStorageId,
  paywallVersion,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const lockUI = useSetRecoilState(lockUISelector);
  const unlockUI = useSetRecoilState(unlockUISelector);
  const isBusy = useRecoilValue(isBusySelector);
  const { catchStripePriceError, trackPayment } = usePaymentService();

  const router = useRouter();
  const { taskId } = router.query;

  const pushModal = useSetRecoilState(pushModalSelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { storeNewPreferences } = usePreferencesService();

  const { data: user, mutate: mutateUser } = useUser();

  const { useMedia } = useMediaService();
  const { getMediaAndUpdateStore } = useMedia();
  const { download } = useDownload();

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const { track } = useTracker();
  const { t, data } = useLocalization();
  const { isReminderEnabled, selectedPaymentStrategy } =
    useRecoilValue(onboardingState);
  const setIsReminderEnabled = useSetRecoilState(isReminderEnabledState);

  const shouldShowFreeTrial = useRecoilValue(showFreeTrialSelector);

  const [isPaymentButtonEnabled, setIsPaymentButtonEnabled] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');

  const settings = useRecoilValue(settingsState);

  const { shouldShowMarketingConsentModal } = useConsent();

  const { disableEmailReminder } = settings.values;

  useReCaptcha();

  const overrideEmailReminder = useCallback(async () => {
    if (!user || settings.values.oneTimePayment) {
      return;
    }

    if (disableEmailReminder) {
      await storeNewPreferences({
        ...user.preferences,
        wantsEmailReminder: false,
      });
      return;
    }

    await storeNewPreferences({
      ...user?.preferences,
      wantsEmailReminder: isReminderEnabled,
    });
  }, [
    user,
    settings.values.oneTimePayment,
    disableEmailReminder,
    isReminderEnabled,
    storeNewPreferences,
  ]);

  const forceFreeTrial = useMemo(() => {
    if (!webOnboardingUserId) {
      return false;
    }

    return paywallVersion === 'ft';
  }, [paywallVersion, webOnboardingUserId]);
  const { subscribe: stripeSubscribe, directPayment: stripeDirectPayment } =
    useStripeMethods(
      stripe!,
      mutateUser,
      forceFreeTrial ||
        (settings.values.isFreeTrialEnabled && shouldShowFreeTrial) ||
        false,
      isIntroPriceActive,
      selectedPrice?.id,
      selectedPrice.exports
    );

  const onStripeSubmit = useCallback(async () => {
    catchStripePriceError(stripe, elements, selectedPrice?.id);
    if (!stripe || !elements) {
      return;
    }

    await overrideEmailReminder();
    lockUI({});

    const payTrackingInfo = {
      paymentPlatform: selectedPaymentMethod,
      paymentStrategy:
        selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
          ? 'oneTime'
          : 'subscription',
    };

    try {
      setIsLoadingPayment(true);
      trackPayment({
        track,
        status: 'selected',
        paymentPlatform: selectedPaymentMethod as PaymentPlatform,
        priceId: selectedPrice?.id,
        freeTrialEnabled: shouldShowFreeTrial,
        emailReminderEnabled: isReminderEnabled,
        paymentStrategy:
          selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
            ? 'oneTime'
            : 'subscription',
        webOnboardingUserId,
        webOnboardingLocalStorageId,
        paywallVersion,
      });

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }

      const persistedLandingCampaignNameQueryValue: string | undefined =
        getPersistentInfoProvider().getInfo().t_crname;
      if (persistedLandingCampaignNameQueryValue) {
        trackWeTransferCreativeConversionPixelIfAny({
          creativeName: persistedLandingCampaignNameQueryValue,
        });
      }
      trackStartedEvent(['payment'], payTrackingInfo);
      let showSuccessToast = true;

      if (selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime) {
        // Wanted code duplication
        try {
          await stripeDirectPayment({ elements });
          trackCompletedEvent(['payment'], payTrackingInfo);
        } catch (e) {
          trackFailedEvent(['payment'], {
            ...payTrackingInfo,
            error: e.toString(),
          });
          throw e;
        }
        if (taskId) {
          const newMedia = await getMediaAndUpdateStore();
          await download({
            downloadUrl: (newMedia as TaskResult).outputUrl,
          });
        }
      } else {
        try {
          const status = await stripeSubscribe({
            elements,
            isEmailReminderEnabled: isReminderEnabled,
            paymentStrategyId: selectedPaymentStrategy?.id || 'base',
            willDownloadAutomatically,
          });

          if (status === 'incomplete') {
            showSuccessToast = false;
            pushModal({
              id: 'creationError',
              Modal: ErrorModal,
              error: {
                message: t(data.account.incompleteSubscriptionCreatedMessage),
              },
            });
          }
          trackCompletedEvent(['payment'], payTrackingInfo);
        } catch (e) {
          trackFailedEvent(['payment'], {
            ...payTrackingInfo,
            error: e.toString(),
          });
          throw e;
        }
      }

      closeModal('payment');

      trackPayment({
        track,
        status: 'completed',
        paymentPlatform: selectedPaymentMethod as PaymentPlatform,
        priceId: selectedPrice?.id,
        freeTrialEnabled: shouldShowFreeTrial,
        emailReminderEnabled: isReminderEnabled,
        paymentStrategy:
          selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
            ? 'oneTime'
            : 'subscription',
        webOnboardingUserId,
        webOnboardingLocalStorageId,
        paywallVersion,
      });

      await mutateUser();

      if (taskId) {
        await getMediaAndUpdateStore();
      }
      if (showSuccessToast) {
        maybeShowSuccessToast({
          selectedPaymentStrategy,
          willDownloadAutomatically,
          settings,
          pushModal,
          shouldShowMarketingConsentModal,
          message:
            selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
              ? data.result.oneTimePaymentSuccess
              : data.onboarding.congratsMember,
          webOnboardingUserId,
        });
      }
      onSubmit();
    } catch (e) {
      Sentry.captureException(e);

      if (e.status === 403 && e.message.startsWith('Web subscriptions')) {
        pushModal({
          id: 'forbiddenCountryError',
          Modal: CountryNotAllowedModal,
        });
      } else {
        pushModal({ id: 'paymentError', Modal: ErrorModal, error: e });
      }
    }
    setIsLoadingPayment(false);
    unlockUI({});
  }, [
    catchStripePriceError,
    closeModal,
    data.account.incompleteSubscriptionCreatedMessage,
    data.onboarding.congratsMember,
    data.result.oneTimePaymentSuccess,
    download,
    elements,
    getMediaAndUpdateStore,
    isReminderEnabled,
    lockUI,
    mutateUser,
    onSubmit,
    overrideEmailReminder,
    paywallVersion,
    pushModal,
    selectedPaymentMethod,
    selectedPaymentStrategy,
    selectedPrice?.id,
    settings,
    shouldShowFreeTrial,
    shouldShowMarketingConsentModal,
    stripe,
    stripeDirectPayment,
    stripeSubscribe,
    t,
    taskId,
    track,
    trackPayment,
    unlockUI,
    webOnboardingLocalStorageId,
    webOnboardingUserId,
    willDownloadAutomatically,
  ]);

  const onElementChange = useCallback((event) => {
    setIsPaymentButtonEnabled(!!event.complete);
    setSelectedPaymentMethod(event.value.type);
  }, []);

  useEffect(() => {
    if (!selectedPrice) {
      return;
    }

    trackEvent(['paywall', 'shown'], {
      type: 'payment_step',
    });
  }, [selectedPrice]);

  if (!selectedPrice) {
    return (
      <div className="flex justify-center items-center">
        <TailSpin color="black" height="50" width="50" aria-label="loading" />
      </div>
    );
  }

  const stripeButtonText = webOnboardingUserId
    ? data.common.continue
    : shouldShowFreeTrial
    ? data.onboarding.startForFree
    : selectedPaymentStrategy &&
      selectedPaymentStrategy.id === PaymentStrategiesIds.OneTime
    ? willDownloadAutomatically
      ? data.onboarding.proceedToDownload
      : data.common.letsGo
    : isIntroPriceActive
    ? data.common.letsGo
    : data.common.subscribe;

  const paymentElementOptions: StripePaymentElementOptions = {
    paymentMethodOrder: ['paypal', 'apple_pay', 'google_pay', 'card'],
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  return (
    <Form className="space-y-7" onSubmit={onStripeSubmit} disabled={isBusy}>
      <div className="space-y-4 pb-10">
        <PaymentElement
          className="rounded-xl M18 mt-2"
          onChange={onElementChange}
          options={paymentElementOptions}
        />
        {shouldShowFreeTrial ? (
          <div
            className={`${
              webOnboardingUserId ? 'text-gray-300' : 'text-overlay-black-60'
            } text-xs inline-flex`}
          >
            {webOnboardingUserId ? (
              <img {...warningInfoWhite} alt="Warning info" />
            ) : (
              <img {...warningInfo} alt="Warning info" />
            )}
            <span className="M12 ps-1.5">
              {t(data.onboarding.enterCardInfo)}
            </span>
          </div>
        ) : null}
        <p className={`R11 text-gray-300 `}>
          {t(data.common.recaptcha, {
            pp: (text) => (
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
            tos: (text) => (
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
          })}
        </p>
        {selectedPaymentStrategy?.id === PaymentStrategiesIds.Business &&
          !webOnboardingUserId && (
            <p className="R11 text-gray-300 mt-3">
              {t(data.common.acceptTosAndPrivacyPolicy1)}{' '}
              <a
                href="/docs/remini-business-additional-terms.pdf"
                target="_blank"
              >
                <u>{t(data.common.additionalTerms)}</u>
              </a>
              .
            </p>
          )}

        {webOnboardingUserId && (
          <div className="pt-8 w-full transition-opacity duration-300">
            <input
              className="sr-only"
              type="checkbox"
              id="enable-email-reminder"
              checked={isReminderEnabled}
              onChange={() => {
                const newVal = !isReminderEnabled;
                track(
                  'paywall_email_reminder',
                  newVal ? 'enabled' : 'disabled',
                  {
                    webOnboardingUserId,
                    webOnboardingLocalStorageId,
                    paywallVersion,
                  }
                );
                setIsReminderEnabled(newVal);
              }}
            />
            <label
              className="bg-overlay-white-10 rounded-3xl w-full min-h-18 flex items-center space-s-2 px-6 py-2 cursor-pointer"
              htmlFor="enable-email-reminder"
            >
              <div className="flex-1 space-y-1">
                <h3 className="SB15 transition-all text-white">
                  {isReminderEnabled ? (
                    t(data.onboarding.reminderEnabled)
                  ) : (
                    <>{t(data.onboarding.enableReminder)} 🔔</>
                  )}
                </h3>
                <p className="M12 transition-colors text-gray-300">
                  {isReminderEnabled
                    ? t(data.account.willNotify)
                    : t(data.account.getNotified)}
                </p>
              </div>
              <div
                role="presentation"
                className={`rounded-full transition-all ${
                  isReminderEnabled ? 'bg-green' : 'bg-gray-300 bg-opacity-50'
                }`}
                style={{ width: 38, height: 22, padding: 3 }}
              >
                <div
                  className="transition-all duration-100 ease-out"
                  style={{ width: isReminderEnabled ? '100%' : 16 }}
                >
                  <div
                    className="bg-gray-500 rounded-full ms-auto drop-shadow-sm active:!w-7 transition-all"
                    style={{ width: 16, height: 16 }}
                  />
                </div>
              </div>
            </label>
          </div>
        )}

        <Form.Button
          className={`w-full btn btn--xl shadow-dark-button ${
            webOnboardingUserId ? 'btn--white' : 'btn--black'
          }`}
          disabled={!isPaymentButtonEnabled}
        >
          <p className="SB18">
            {t(stripeButtonText)}
            {stripeButtonText === data.common.letsGo ? '!' : ''}
          </p>

          {isLoadingPayment && (
            <Spinner
              color={webOnboardingUserId ? 'black' : 'white'}
              size={20}
            />
          )}
        </Form.Button>

        {webOnboardingUserId && (
          <p className="R14 text-gray-300 text-center">
            By tapping on Continue, you accept our{' '}
            <a
              className="underline text-[#0A7AFF]"
              target="_blank"
              href={
                selectedPaymentStrategy?.id === PaymentStrategiesIds.Business
                  ? '/docs/remini-business-additional-terms.pdf'
                  : 'https://bendingspoons.com/tos.html?app=1470373330'
              }
              rel="noreferrer"
            >
              {selectedPaymentStrategy?.id === PaymentStrategiesIds.Business
                ? t(data.common.additionalTerms)
                : t(data.common.tos)}
            </a>
            .
          </p>
        )}
      </div>
    </Form>
  );
}
