import {
  useCaptchaService,
  usePaymentService,
} from '@/layout/appWrapper/ServiceProvider';
import { SWRUser } from '@/services/userService';
import { mediaShownInComparerState } from '@/stores/mediaStore';
import { selectedPaymentStrategyState } from '@/stores/onboardingStore';
import { Stripe, StripeElements } from '@stripe/stripe-js/types';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { KeyedMutator } from 'swr';
import { StripeSubscriptionStatus } from '@/services/paymentService';

const useStripeMethods = (
  stripe: Stripe,
  mutateUser: KeyedMutator<SWRUser>,
  isFreeTrialEnabled: boolean,
  withIntroPrice: boolean,
  priceId = '',
  unlockedExports?: number
) => {
  const { validateCaptcha } = useCaptchaService();
  const {
    createStripeSubscription,
    getStripeClientIntentsData,
    createStripePaymentIntents,
    confirmStripePaymentIntent,
  } = usePaymentService();
  const mediaInComparer = useRecoilValue(mediaShownInComparerState);
  const selectedPaymentStrategy = useRecoilValue(selectedPaymentStrategyState);

  const subscribe = useCallback(
    async ({
      elements,
      isEmailReminderEnabled,
      paymentStrategyId,
      willDownloadAutomatically,
    }: {
      elements: StripeElements;
      isEmailReminderEnabled: boolean;
      paymentStrategyId: string;
      willDownloadAutomatically: boolean;
    }): Promise<StripeSubscriptionStatus> => {
      const { clientSecret } = await getStripeClientIntentsData();

      await validateCaptcha();

      const redirectURL = new URL(document.URL);
      redirectURL.searchParams.append('priceId', priceId);
      redirectURL.searchParams.append(
        'freeTrialEnabled',
        String(isFreeTrialEnabled)
      );
      redirectURL.searchParams.append(
        'emailReminderEnabled',
        String(isEmailReminderEnabled)
      );
      redirectURL.searchParams.append('withIntroPrice', String(withIntroPrice));
      redirectURL.searchParams.append(
        'unlockedExports',
        String(unlockedExports)
      );
      redirectURL.searchParams.append('paymentStrategyId', paymentStrategyId);
      redirectURL.searchParams.append(
        'willDownloadAutomatically',
        String(willDownloadAutomatically)
      );

      const response = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: redirectURL.toString(),
        },
        redirect: 'if_required',
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      const result = await createStripeSubscription(
        response.setupIntent.payment_method as string,
        priceId,
        isFreeTrialEnabled,
        withIntroPrice,
        unlockedExports
      );
      await mutateUser();

      return result.status;
    },
    [
      createStripeSubscription,
      getStripeClientIntentsData,
      isFreeTrialEnabled,
      mutateUser,
      priceId,
      stripe,
      unlockedExports,
      validateCaptcha,
      withIntroPrice,
    ]
  );

  const directPayment = useCallback(
    async ({ elements }: { elements: StripeElements }) => {
      const paymentIntent = await createStripePaymentIntents({
        priceId,
        taskId: mediaInComparer.hasWatermark ? mediaInComparer.id : undefined,
        exports: selectedPaymentStrategy?.exports,
      });

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: paymentIntent.clientSecret,
        confirmParams: {
          return_url: document.URL,
        },
        redirect: 'if_required',
      });

      if (error) {
        throw new Error(error.message);
      }

      await confirmStripePaymentIntent({
        paymentIntentId: paymentIntent.paymentId,
      });
    },
    [
      confirmStripePaymentIntent,
      createStripePaymentIntents,
      mediaInComparer.hasWatermark,
      mediaInComparer.id,
      priceId,
      selectedPaymentStrategy?.exports,
      stripe,
    ]
  );

  return {
    subscribe,
    directPayment,
  };
};

export default useStripeMethods;
