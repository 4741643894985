import { TaskIdChange, TaskResult } from '@/services/uploaderService';
import { atom } from 'recoil';
import { ImageSelectorMedia } from '@/services/editorService';

export const mediaState = atom<TaskResult>({
  key: 'uploader:internalMedia',
  default: {
    id: '',
    isUnavailable: false,
    inputUrl: '',
    outputUrl: '',
    outputContentType: '',
    convertedImages: [],
    inputImage: null,
    outputImage: null,
    hasWatermark: false,
    mediaType: '',
    aiPipeline: {},
  },
});

export const bulkMediaListState = atom<ImageSelectorMedia[]>({
  key: 'media:list',
  default: [],
});

export const downloadMediaState = atom<ImageSelectorMedia[]>({
  key: 'media:downloadMedia',
  default: [],
});

export const mediaShownInComparerState = atom<ImageSelectorMedia>({
  key: 'media:comparerMedia',
  default: {
    id: '',
    outputUrl: '',
    inputUrl: '',
    convertedImages: [],
    contentType: '',
    hasWatermark: true,
    aiPipeline: {},
  },
});

export const mediaInComparerIdChangeState = atom<TaskIdChange>({
  key: 'media:comparer:id:afterReprocess',
  default: {
    oldTaskId: '',
    taskId: '',
  },
});

export const inputMediaNamesState = atom<string[]>({
  key: 'media:originalFilename',
  default: [],
});
