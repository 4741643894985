import { MutableRefObject, useEffect } from 'react';

export default function useEventListener(
  ref: MutableRefObject<any> | null,
  event: string,
  cb: CallableFunction,
  options?: any
) {
  useEffect(() => {
    const element = ref ? ref.current : window;
    if (element) {
      element.addEventListener(event, cb, options);
      return () => element.removeEventListener(event, cb);
    }
  }, [ref, cb, event, options]);
}
