import { createCookie, readCookieWithPrefix } from '../helpers/cookieHelper';

const persistentInfoPrefix = '_pico_pi_';

export type PersistentInfoProvider = {
  setInfo: (key: string, value: string) => void;
  getInfo: () => Record<string, string>;
};

let persistentInfoProvider: PersistentInfoProvider | undefined;

export const getPersistentInfoProvider = (): PersistentInfoProvider => {
  if (persistentInfoProvider) {
    return persistentInfoProvider;
  }
  persistentInfoProvider = {
    setInfo(key: string, value: any) {
      createCookie(persistentInfoPrefix + key, value);
    },
    getInfo() {
      const cookies = readCookieWithPrefix(persistentInfoPrefix);
      return Object.entries(cookies).reduce((obj, [key, value]) => {
        obj[key.replace(persistentInfoPrefix, '')] = value;
        return obj;
      }, {});
    },
  };
  return persistentInfoProvider;
};
