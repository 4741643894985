import React, { useMemo } from 'react';
import { initializeApp } from 'firebase/app';

import Context from './context';

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

function Provider({ children }: { children: React.ReactNode }) {
  const contextValue = useMemo(
    () => ({ app: initializeApp(firebaseConfig) }),
    []
  );
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export default Provider;
