import {
  EventInterceptor,
  EventType,
  PicoEventWithMetadata,
} from '@/core/tracking/pico/event';
import { FBTrackingReady } from '@/core/tracking/pico/attribution';

export const FBProcessingCompletedInterceptor: EventInterceptor = (
  e: PicoEventWithMetadata
) => {
  if (
    e.type === EventType.USER_ACTION &&
    e.kind === 'processing_completed' &&
    FBTrackingReady()
  ) {
    window.fbq(
      'track',
      'ProcessingCompleted',
      {},
      { eventID: `${e.id}:processing_completed` }
    );
  }
};
