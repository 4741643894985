import {
  Experiments,
  IDProvider,
  PersistentInfoProvider,
  Settings,
  UserMetadataProvider,
} from '@/core/tracking/pico/providers';
import { InternalConfiguration } from '@/core/tracking/pico/config';
import { EventType, PicoEventWithMetadata } from '@/core/tracking/pico/event';
import { TrackingConsentProvider } from '@/core/tracking/pico/privacy/trackingConsentProvider';
import { AnonymizationProxy } from '../privacy/anonymizationProxy';
import { AnonymousNetworkRequest } from '../privacy/anonymizeRequest';

const PRODUCTION_URL = 'https://api.pico.bendingspoonsapps.com';
const STAGING_URL = 'https://api.staging.pico.bendingspoonsapps.com';
const API_VERSION = 'v4';

type PicoNetworkAdapterParams = {
  idProvider: IDProvider;
  config: InternalConfiguration;
  userMetadataProvider: UserMetadataProvider;
  consentProvider: TrackingConsentProvider;
  persistentInfoProvider: PersistentInfoProvider;
  anonymizationProxy: AnonymizationProxy;
};

type BodyMetadata = {
  delta?: number;
  last_event_timestamp?: number;
};

export type NetworkRequestEvent = {
  id: string;
  timestamp: number;
  type: EventType;
  request_timestamp: number;
  app: string;
  user: {
    ids: {
      web_user_id?: string;
      local_storage_id: string;
      firebase_uid?: string;
      backend_id: string;
      web_onboarding_user_id?: string;
      web_onboarding_local_storage_id?: string;
    };
    info: {
      locale: string;
      device_type: string;
      browser?: string;
      app_version: string;
      experiment?: Experiments;
      landing_url: string;
      landing_page_info: Record<string, string>;
      landing_page_info_json: string;
      created_at?: string;
      user_agent: string;
      settings: Settings;
      persistent: Record<string, unknown>;
      is_free: boolean;
    };
  };
  data: {
    subtype?: string;
    action_kind?: string;
    action_info?: Record<string, unknown>;
    seconds_from_session_start: number;
    session_id: string;
    original_type?: string;
  };
};

export type NetworkRequestBody = BodyMetadata & {
  events: NetworkRequestEvent[];
};

export type NetworkRequestHeaders = {
  'Pico-Client-ID': string;
  'Pico-Version': string;
  'Pico-Tester': string;
  anonymous: string;
};

export type NetworkRequest = {
  url: string;
  body: NetworkRequestBody;
  headers: NetworkRequestHeaders;
};

export type NetworkAdapter = {
  updateBodyMetadata: (m: BodyMetadata) => void;
  getNetworkRequests: (
    a: PicoEventWithMetadata[]
  ) => (NetworkRequest | AnonymousNetworkRequest)[];
};

export const createNetworkAdapter = ({
  idProvider,
  config,
  userMetadataProvider,
  consentProvider,
  persistentInfoProvider,
  anonymizationProxy,
}: PicoNetworkAdapterParams): NetworkAdapter => {
  let bodyMetadata: BodyMetadata | Record<string, never> = {};

  const getEventRequestUrl = () => {
    const baseURL = config.isProduction ? PRODUCTION_URL : STAGING_URL;
    return `${baseURL}/${API_VERSION}/web-events`;
  };

  const adaptEventToRequest = (event) => ({
    id: event.id,
    timestamp: event.timestamp,
    type: event.type,
    request_timestamp: Math.round(Date.now() / 1000),
    app: config.appID,
    user: {
      ids: {
        web_user_id: idProvider.getUserID(),
        local_storage_id: idProvider.getLocalStorageID(),
        firebase_uid: idProvider.getFirebaseID(),
        backend_id: idProvider.getBackendID(),
        web_onboarding_user_id: idProvider.getWebOnboardingUserID(),
        web_onboarding_local_storage_id:
          idProvider.getWebOnboardingLocalStorageID(),
      },
      info: {
        locale: userMetadataProvider.getUserLocale(),
        device_type: userMetadataProvider.getDeviceType(),
        browser: userMetadataProvider.getBrowser(),
        app_version: config.appVersion,
        experiment: userMetadataProvider?.getExperiments(),
        landing_url: userMetadataProvider?.getLandingUrl(),
        landing_page_info: userMetadataProvider?.getLandingQueryParams(),
        landing_page_info_json:
          userMetadataProvider?.getLandingQueryParamsJson(),
        created_at: userMetadataProvider?.getCreatedAt(),
        user_agent: userMetadataProvider?.getUserAgent(),
        settings: userMetadataProvider?.getSettings(),
        persistent: persistentInfoProvider.getInfo(),
        is_free: userMetadataProvider?.getIsFree(),
        profiling_consent: consentProvider.userAcceptedProfilingTracking(),
      },
    },
    data: {
      subtype: 'subtype' in event ? event.subtype : undefined,
      action_kind: 'kind' in event ? event.kind : undefined,
      action_info: 'data' in event ? event.data : undefined,
      seconds_from_session_start: event.seconds_from_session_start,
      session_id: event.session_id,
      original_type:
        event.type === EventType.TECHNICAL_EVENT
          ? event.originalType
          : undefined,
    },
  });

  const getEventRequestHeaders = (): NetworkRequestHeaders => ({
    'Pico-Client-ID': idProvider?.getClientID(),
    'Pico-Version': config.picoVersion,
    'Pico-Tester': config.isTester.toString(),
    anonymous: 'false',
  });

  return {
    updateBodyMetadata({
      delta = 0,
      last_event_timestamp = 0,
    }: BodyMetadata): void {
      bodyMetadata = { delta, last_event_timestamp };
    },
    getNetworkRequests(
      events: PicoEventWithMetadata[]
    ): (NetworkRequest | AnonymousNetworkRequest)[] {
      const url = getEventRequestUrl();
      const picoHeaders = getEventRequestHeaders();

      const shouldBeAnonymized = (e: PicoEventWithMetadata) =>
        anonymizationProxy.isActive() && e.type !== EventType.TECHNICAL_EVENT;

      const requests = events
        .reduce<{ request: NetworkRequest; shouldBeAnonymized: boolean }[]>(
          (requests, event) => {
            const lastRequest = requests[requests.length - 1];
            if (lastRequest?.shouldBeAnonymized === shouldBeAnonymized(event)) {
              lastRequest.request.body.events.push(adaptEventToRequest(event));
              return requests;
            }

            const request: NetworkRequest = {
              url,
              body: {
                ...bodyMetadata,
                events: [adaptEventToRequest(event)],
              },
              headers: picoHeaders,
            };
            requests.push({
              request,
              shouldBeAnonymized: shouldBeAnonymized(event),
            });

            return requests;
          },
          []
        )
        .map(({ request, shouldBeAnonymized }) =>
          shouldBeAnonymized ? anonymizationProxy.apply(request) : request
        );

      return requests;
    },
  };
};
