import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import FormButton from './FormButton';
import FormContext from './FormContext';

interface Props {
  disabled?: boolean;
  onSubmit: () => Promise<void>;
  children?: React.ReactNode;
  [x: string]: any;
}

function Form({ disabled = false, onSubmit, children, ...rest }: Props) {
  const [loading, setLoading] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false; // Cleanup function, it runs when component unmounts
    };
  }, []);

  const internalOnSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      await onSubmit();
      if (isMounted.current) setLoading(false);
    },
    [onSubmit]
  );

  const contextValue = useMemo(
    () => ({ loading, disabled }),
    [loading, disabled]
  );
  return (
    <FormContext.Provider value={contextValue}>
      <form onSubmit={internalOnSubmit} {...rest}>
        {children}
      </form>
    </FormContext.Provider>
  );
}

Form.Button = FormButton;

export default Form;
