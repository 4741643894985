export const attributionParamKeys = [
  't_s',
  't_cid',
  't_cname',
  't_agid',
  't_agname',
  't_crid',
  't_crname',
  't_match_type',
  't_network',
  't_device',
  't_gcid',
  't_validation',
] as const;
