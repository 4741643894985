type ArgsType<T extends ((arg: unknown) => unknown) | (() => unknown)> =
  T extends (arg: infer A) => unknown ? A : never;

type OnlyOnceRunnerType<
  T extends ((arg: unknown) => unknown) | (() => unknown)
> = (arg?: ArgsType<T>) => void;

export default function onlyOnceRunner<
  T extends ((arg: unknown) => unknown) | (() => unknown) = () => void
>(fn: T): OnlyOnceRunnerType<T> {
  let executed = false;
  return (arg?: ArgsType<T>) => {
    if (executed) return;
    executed = true;
    fn(arg);
  };
}
