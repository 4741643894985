import { createContext, ReactNode, useContext, useMemo } from 'react';
import { AuthService } from '@/services/authService/authService';
import { CaptchaService } from '@/services/captchaService';
import { ConsentService } from '@/services/consentService';
import { EditorService } from '@/services/editorService/editorService';
import { FormatService } from '@/services/formatService/formatService';
import { MediaService } from '@/services/mediaService/mediaService';
import { PaymentService } from '@/services/paymentService';
import { PreferencesService } from '@/services/preferenceService';
import { PriceService } from '@/services/priceService';
import { RedeemService } from '@/services/redeemService';
import { SettingsService } from '@/services/settingsService';
import { ShareService } from '@/services/shareService/shareService';
import { SurveyService } from '@/services/surveyService';
import { UploaderService } from '@/services/uploaderService';
import { UserService } from '@/services/userService';
import { ServiceGetter } from '@/services/serviceGetter';
import { TutorialService } from '@/services/tutorialService/tutorialService';
import { VideoUsageService } from '@/services/videoUsageService/videoUsageService';
import { SupportService } from '@/services/supportService/supportService';

/* Types */
export type ProvidedServices = {
  priceService: ServiceGetter<PriceService>;
  redeemService: ServiceGetter<RedeemService>;
  consentService: ServiceGetter<ConsentService>;
  captchaService: ServiceGetter<CaptchaService>;
  surveyService: ServiceGetter<SurveyService>;
  settingsService: ServiceGetter<SettingsService>;
  preferencesService: ServiceGetter<PreferencesService>;
  formatService: ServiceGetter<FormatService>;
  mediaService: ServiceGetter<MediaService>;
  paymentService: ServiceGetter<PaymentService>;
  userService: ServiceGetter<UserService>;
  uploaderService: ServiceGetter<UploaderService>;
  shareService: ServiceGetter<ShareService>;
  editorService: ServiceGetter<EditorService>;
  authService: ServiceGetter<AuthService>;
  tutorialService: ServiceGetter<TutorialService>;
  videoUsageService: ServiceGetter<VideoUsageService>;
  supportService: ServiceGetter<SupportService>;
};

export type ProvidedServiceKey = keyof ProvidedServices;

/* Default services definition */
export class ServiceNotConfiguredError extends Error {
  constructor(serviceKey: ProvidedServiceKey) {
    super(
      `You're trying to use "${serviceKey}", but this service has not been configured.`
    );
  }
}

function notConfiguredService(serviceKey: ProvidedServiceKey) {
  return () => {
    throw new ServiceNotConfiguredError(serviceKey);
  };
}

const _defaultServices: ProvidedServices = {
  priceService: notConfiguredService('priceService'),
  redeemService: notConfiguredService('redeemService'),
  consentService: notConfiguredService('consentService'),
  captchaService: notConfiguredService('captchaService'),
  surveyService: notConfiguredService('surveyService'),
  settingsService: notConfiguredService('settingsService'),
  preferencesService: notConfiguredService('preferencesService'),
  formatService: notConfiguredService('formatService'),
  mediaService: notConfiguredService('mediaService'),
  paymentService: notConfiguredService('paymentService'),
  userService: notConfiguredService('userService'),
  uploaderService: notConfiguredService('uploaderService'),
  shareService: notConfiguredService('shareService'),
  editorService: notConfiguredService('editorService'),
  authService: notConfiguredService('authService'),
  tutorialService: notConfiguredService('tutorialService'),
  videoUsageService: notConfiguredService('videoUsageService'),
  supportService: notConfiguredService('supportService'),
};

/* Context and Provider definitions */
const ServiceContext = createContext<ProvidedServices>(_defaultServices);
export default function ServiceProvider({
  services: injectedServices = {},
  children,
}: {
  services?: Partial<ProvidedServices>;
  children: ReactNode;
}) {
  const services: ProvidedServices = useMemo(
    () => ({
      ..._defaultServices,
      ...injectedServices,
    }),
    [injectedServices]
  );

  return (
    <ServiceContext.Provider value={services}>
      {children}
    </ServiceContext.Provider>
  );
}

/* Generic hook to use a service */
export const useService = <K extends ProvidedServiceKey>(key: K) =>
  useContext(ServiceContext)[key]() as ReturnType<ProvidedServices[K]>;

/* Custom hooks */
export const usePriceService = (): PriceService => useService('priceService');
export const useRedeemService = (): RedeemService =>
  useService('redeemService');
export const useConsentService = (): ConsentService =>
  useService('consentService');
export const useCaptchaService = (): CaptchaService =>
  useService('captchaService');
export const useSurveyService = (): SurveyService =>
  useService('surveyService');
export const useSettingsService = (): SettingsService =>
  useService('settingsService');
export const usePreferencesService = (): PreferencesService =>
  useService('preferencesService');
export const useFormatService = (): FormatService =>
  useService('formatService');
export const useMediaService = (): MediaService => useService('mediaService');
export const useShareService = (): ShareService => useService('shareService');
export const useEditorService = (): EditorService =>
  useService('editorService');
export const usePaymentService = (): PaymentService =>
  useService('paymentService');
export const useUserService = (): UserService => useService('userService');
export const useUploaderService = (): UploaderService =>
  useService('uploaderService');
export const useAuthService = (): AuthService => useService('authService');
export const useTutorialService = (): TutorialService =>
  useService('tutorialService');
export const useVideoUsageService = (): VideoUsageService =>
  useService('videoUsageService');
export const useSupportService = (): SupportService =>
  useService('supportService');
