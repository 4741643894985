import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '../serviceGetter';
import { VideoLimits } from '../settingsService';
import { VideoUsageInfo } from './videoUsageTypes';

export const createVideoUsageService = ({ get }: HttpService) => {
  const getVideoUsageData = async ({
    maxSizeMb,
    maxLength,
    maxPeriodSizeMb,
    maxPeriodLength,
  }: VideoLimits): Promise<VideoUsageInfo> => {
    // need to keep values with _, translation payload is not performed by query params
    const paramObject = {
      max_size_mb: `${maxSizeMb ?? 0}`,
      max_length: `${maxLength ?? 0}`,
      period: 'weekly',
      max_period_size_mb: `${maxPeriodSizeMb ?? 0}`,
      max_period_length: `${maxPeriodLength ?? 0}`,
    };
    const result: VideoUsageInfo = await get(
      `/v1/web/users/@me/video-limits`,
      paramObject
    );
    return result;
  };

  return { getVideoUsageData };
};

export type VideoUsageService = ReturnType<typeof createVideoUsageService>;

export default serviceSingletonGetter(
  Symbol('videoUsageService'),
  createVideoUsageService
);
