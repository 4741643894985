import Script from 'next/script';
import React, { useEffect } from 'react';
import { TypeformProps } from '@/services/surveyService';
import { useTracker } from '@/core/tracking';
import { useUser } from '@/services/userService';
import { useFeatureRankingSurvey } from '../../services/surveyService/useFeatureRankingSurvey';

interface Props {
  typeformProps: TypeformProps;
  forceClose?: boolean | number;
  typeformStyle?: React.CSSProperties;
}

export const surveyEvent = 'feature_ranking_survey';

export function InAppSurvey({
  typeformProps,
  forceClose = false, // setting "true" instantly closes it after submit; setting a number (ms) delays it
  typeformStyle,
}: Props) {
  const { data: user } = useUser();
  const { complete } = useFeatureRankingSurvey();
  const { track } = useTracker();

  useEffect(() => {
    const onMessage = (event) => {
      if (event.data.type === 'form-submit' && user?.webUserId) {
        track(surveyEvent, 'submitted', { webUserId: user?.webUserId });
        complete();
      }
    };
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [track, forceClose, complete, user?.webUserId]);

  if (!user?.webUserId) return null;

  const hidden = (typeformProps.hiddenFields || []).concat(
    `uid=${user.webUserId}`
  );

  return (
    <>
      <div
        data-tf-widget={typeformProps.widget}
        data-tf-slider={typeformProps.slider}
        data-tf-sidetab={typeformProps.sidetab}
        data-tf-width={typeformProps.width}
        data-tf-height={typeformProps.height}
        data-tf-size={typeformProps.size}
        data-tf-custom-icon={typeformProps.iconUrl}
        data-tf-button-color={typeformProps.buttonColor}
        data-tf-button-text={typeformProps.buttonText}
        data-tf-hidden={hidden.join(',')}
        data-tf-open={typeformProps.openOn}
        data-tf-open-value={typeformProps.openValue}
        data-tf-iframe-props={typeformProps.iframeProps?.join(',')}
        data-tf-enable-sandbox={
          process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
            ? undefined
            : true
        }
        style={typeformStyle}
      />
      <Script src="//embed.typeform.com/next/embed.js" />
    </>
  );
}
