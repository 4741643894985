export default function getVideoDuration(file: File): Promise<number> {
  const url = URL.createObjectURL(file);

  return new Promise((resolve) => {
    const video = document.createElement('video');
    const source = document.createElement('source');
    source.src = url; // --> blob URL
    video.preload = 'metadata';
    video.appendChild(source);
    video.onloadedmetadata = function () {
      resolve(video.duration);
    };
  });
}
