import { useSetRecoilState } from 'recoil';
import { ErrorAlert } from '@/ui/content';
import { closeModalSelector } from '@/stores/modalStore';
import { ModalWrapper } from '@/core/modal';
import { useLocalization } from '@/services/localizationService';

export default function AccountLinkingFailedModal({ id }: { id: string }) {
  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();

  return (
    <ModalWrapper className="p-8 px-6 md:p-12 section-foreground rounded-3xl">
      <ErrorAlert title="Different email detected">
        <div className="space-y-4">
          <div className="space-y-3">
            <p>{t(data.onboarding.accountAnotherEmail)}</p>
            <p>{t(data.onboarding.loggedDiffEmail)}</p>
          </div>
          <button
            type="button"
            className="btn btn--large btn--primary mt-4 mx-auto"
            onClick={() => closeModal(id)}
          >
            {t(data.common.okGotIt)}
          </button>
        </div>
      </ErrorAlert>
    </ModalWrapper>
  );
}
