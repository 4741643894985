import { Interval } from '@/services/priceService';

export default function formatRecurringInterval(
  interval: Interval,
  intervalCount: number
) {
  if (interval === 'month' && intervalCount === 3) {
    return 'Quarterly';
  }
  if (interval === 'month' && intervalCount === 6) {
    return 'Semesterly';
  }
  if (interval === 'week' && intervalCount === 4) {
    return 'Lunar';
  }

  const prefixes = {
    2: 'Bi-',
    3: 'Tri-',
  };

  const prefix = prefixes[intervalCount] || '';

  const frequency = {
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly',
  };

  return `${prefix}${frequency[interval]}`;
}
