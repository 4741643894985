import { useCallback, useEffect, useRef } from 'react';

const useCallbackReference = <T>(cb: T): (() => T) => {
  const ref = useRef(cb);

  useEffect(() => {
    ref.current = cb;
  }, [cb]);
  return useCallback((...args) => {
    if (typeof ref.current === 'function') {
      return ref.current(...args);
    }
  }, []);
};
export default useCallbackReference;
