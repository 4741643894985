import { ModalWrapper } from '@/core/modal';
import { PaymentSelectionModal } from '@/features/payment/modals';
import PaymentStrategiesModal from '@/features/payment/paymentStrategies/PaymentStrategiesModal';
import lockCircleIcon from '@/public/images/icons/lock_circle.svg';
import uploadIcon from '@/public/images/icons/upload--white.svg';
import { useLocalization } from '@/services/localizationService';
import { UserAction } from '@/services/userService';
import { isBusySelector } from '@/stores/appStore';
import { isLoggedInSelector } from '@/stores/authStore';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import { Badge, badgeTypes } from '@/ui/badge';
import { CloseIcon } from '@/ui/icons';
import { StaticImageData } from 'next/image';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import OnboardingModal from '../onboarding/OnboardingModal';

interface Props {
  id: string;
  onClose?: (e: { [key: string]: any }) => any;
  previousAction?: UserAction;
  backgroundImage?: any;
  headerImage?: StaticImageData;
  title?: ReactNode;
  body?: ReactNode;
  showPhotoEnhanceButton?: boolean;
  showSubscribeFlowButton?: boolean;
  showInputSelectionButton?: boolean;
  showLoginCTA?: boolean;
  isNewFeature?: boolean;
  hasFreeTrial?: boolean;
  showUpgradePlanButton?: boolean;
  usePlainStyle?: boolean;
  subscribeToBusiness?: boolean;
  headerImageStartMargin?: string;
}

export default function CallToActionModal({
  id,
  onClose,
  previousAction,
  backgroundImage,
  headerImage,
  title,
  body,
  showPhotoEnhanceButton,
  showSubscribeFlowButton = true,
  showInputSelectionButton = false,
  showLoginCTA = true,
  isNewFeature = false,
  hasFreeTrial = true,
  showUpgradePlanButton = false,
  usePlainStyle = false,
  subscribeToBusiness = false,
  headerImageStartMargin = '',
  ...rest
}: Props) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const isBusy = useRecoilValue(isBusySelector);
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const pushModal = useSetRecoilState(pushModalSelector);

  const [openInputFile, setOpenInputFile] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState(false);
  const [subscribeNow, setSubscribeNow] = useState(false);
  const [login, setLogin] = useState(false);

  const { t, data } = useLocalization();

  const focusFirstElement = () => closeButtonRef.current?.focus();

  useEffect(focusFirstElement, []);

  const additionalStyle = usePlainStyle
    ? undefined
    : {
        background: `url('${backgroundImage.src}') no-repeat top center`,
        backgroundSize: 'cover',
      };

  return (
    <ModalWrapper
      additionalStyle={additionalStyle}
      className={`text-center flex flex-col justify-between p-10 w-auto ${
        usePlainStyle ? 'max-w-[350px]' : 'h-3/4 max-w-[400px]'
      } lg:w-2/5 relative overflow-hidden`}
      onFocusTrap={focusFirstElement}
      onClose={() =>
        onClose && onClose({ openInputFile, upgradePlan, subscribeNow, login })
      }
      {...rest}
    >
      <div
        className={`absolute w-[100%] h-[100%] top-0 start-0 z-0 ${
          usePlainStyle
            ? ''
            : 'bg-gradient-to-t from-gray-450 to-transparent opacity-60'
        }`}
      />
      <div className="flex justify-start items-center">
        <button onClick={() => closeModal(id)}>
          <CloseIcon
            className={`${
              usePlainStyle ? 'text-gray-300' : 'text-white'
            } drop-shadow`}
          />
        </button>
      </div>
      {usePlainStyle && (
        <div className="flex justify-center -mt-5">
          <img
            {...headerImage}
            className={`w-52 ${headerImageStartMargin}`}
            alt="header"
          />
        </div>
      )}
      <div className="text-start z-10">
        {isNewFeature ? (
          <Badge
            id={badgeTypes.BadgeVariants.New}
            label="new feature"
            absolutePositioned={false}
            className="mb-2"
          />
        ) : (
          !usePlainStyle && (
            <img
              {...lockCircleIcon}
              className="box-content inline-block w-54 h-54 my-4"
              alt="Lock"
            />
          )
        )}
        <p
          className={`${
            usePlainStyle
              ? 'SB20 text-black text-center mt-2'
              : 'SB25 text-white max-w-[90%]'
          }`}
        >
          {title}
        </p>
        <p
          className={`${
            usePlainStyle
              ? 'M16 text-black text-center'
              : 'R16 text-white max-w-[80%]'
          } mt-3 whitespace-pre-line`}
        >
          {body}
        </p>
        {showUpgradePlanButton && (
          <button
            ref={closeButtonRef}
            type="button"
            className={`w-full btn btn--large shadow-dark-button ${
              usePlainStyle ? 'btn--black' : 'btn--white'
            } mt-6 focus:outline-none`}
            disabled={isBusy}
            onClick={() => {
              setUpgradePlan(true);
              closeModal(id);

              pushModal({
                id: 'user:upgrade-subscription',
                Modal: PaymentStrategiesModal,
                isUpgrade: true,
              });
            }}
          >
            <span className="SB16">{t(data.common.upgradeMyPlan)}</span>
          </button>
        )}
        {showSubscribeFlowButton && (
          <button
            ref={closeButtonRef}
            type="button"
            className="w-full btn btn--large shadow-dark-button btn--white mt-6 focus:outline-none"
            disabled={isBusy}
            onClick={() => {
              if (subscribeToBusiness) {
                pushModal({
                  id: 'payment-selection',
                  Modal: PaymentSelectionModal,
                  previousAction,
                  forceBusiness: true,
                  onClose: focusFirstElement,
                });
              } else {
                pushModal({
                  id: 'user:onboarding',
                  Modal: OnboardingModal,
                  previousAction,
                });
              }

              setSubscribeNow(true);
              closeModal(id);
            }}
          >
            {hasFreeTrial
              ? t(data.common.tryForFree)
              : t(data.common.subscribe)}
          </button>
        )}
        {showLoginCTA && !isLoggedIn && (
          <p className="text-white text-center mb-4 mt-2">
            {t(data.result.alreadySubLogIn1)}
            <button
              className="M16 underline ms-2"
              onClick={() => {
                pushModal({
                  id: 'user:onboarding',
                  Modal: OnboardingModal,
                  previousAction,
                });

                setLogin(true);
                closeModal(id);
              }}
            >
              {t(data.result.alreadySubLogIn2)}
            </button>
          </p>
        )}
        {(showPhotoEnhanceButton || showInputSelectionButton) && (
          <>
            {showPhotoEnhanceButton && (
              <p className="text-overlay-white-40 text-center my-4 mb-2">
                {t(data.onboarding.notSure)}
              </p>
            )}
            <button
              ref={closeButtonRef}
              type="button"
              className={`w-full btn btn--large shadow-dark-button focus:outline-none ${
                showPhotoEnhanceButton
                  ? 'btn-secondary text-white py-2 rounded-full bg-overlay-black-20 text-[16px]'
                  : 'btn--white mt-6'
              } shadow-dark-button focus:outline-none`}
              disabled={isBusy}
              onClick={() => {
                setOpenInputFile(true);
                closeModal(id);
              }}
            >
              {showPhotoEnhanceButton && (
                <img
                  {...uploadIcon}
                  className="box-content inline-block"
                  alt="upload"
                />
              )}
              <span className="M16">
                {showPhotoEnhanceButton && hasFreeTrial
                  ? t(data.onboarding.tryPhotoForFree)
                  : showUpgradePlanButton
                  ? t(data.common.enhanceOnlyOnePhoto)
                  : t(data.common.tryItOut)}
              </span>
            </button>
          </>
        )}
      </div>
    </ModalWrapper>
  );
}
