import { getCookieBannerElements } from '@/core/cookies';
import { useTracker } from '@/core/tracking';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

export const usePrivacyConsentEventsListener = () => {
  const { track } = useTracker();

  useEffect(() => {
    getCookieBannerElements().then(
      ({
        closeBtn,
        acceptBtns,
        customizeBtn,
        customizeBtnCircle,
        rejectAllBtn,
        confirmChoicesBtn,
      }) => {
        const onCookieBannerClose = (event) => {
          if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'development') {
            Cookies.set('OptanonAlertBoxClosed', new Date().toISOString()); // Fix Cookie banner not hiding in dev
          }
          track('cookies', event);
        };
        acceptBtns.forEach((btn) =>
          btn.addEventListener('click', () => onCookieBannerClose('accepted'))
        );
        rejectAllBtn.addEventListener('click', () =>
          onCookieBannerClose('rejected')
        );
        confirmChoicesBtn.addEventListener('click', () =>
          onCookieBannerClose('customized')
        );
        customizeBtnCircle.addEventListener('click', () =>
          onCookieBannerClose('customizing')
        );

        // Cookie banner (CTAs), rendered only at the first time
        if (closeBtn) {
          closeBtn.addEventListener('click', () =>
            onCookieBannerClose('rejected')
          );
        }
        if (customizeBtn) {
          customizeBtn.addEventListener('click', () =>
            onCookieBannerClose('customizing')
          );
        }
      }
    );
  }, [track]);
};
