import { NetworkRequest } from '../network';
import { ActionInfoAnonymizer } from './actionInfoAnonymizer';
import { TrackingConsentProvider } from './trackingConsentProvider';
import { anonymizeRequest, AnonymousNetworkRequest } from './anonymizeRequest';
import { AnonymousInfoProvider } from './anonymousInfoProvider';

export type AnonymizationProxy = {
  apply: (request: NetworkRequest) => NetworkRequest | AnonymousNetworkRequest;
  setActive: (active: boolean) => void;
  isActive: () => boolean;
};

type AnonymizationProxyParams = {
  consentProvider: TrackingConsentProvider;
  anonymousInfoProvider: AnonymousInfoProvider;
  actionInfoAnonymizer: ActionInfoAnonymizer;
};

export const createAnonymizationProxy = ({
  consentProvider,
  anonymousInfoProvider,
  actionInfoAnonymizer,
}: AnonymizationProxyParams): AnonymizationProxy => {
  let isActive = false;

  const setActive = (active: boolean) => {
    isActive = active;
  };

  const apply = (request: NetworkRequest) =>
    !consentProvider.userAcceptedAnalyticsTracking() && isActive
      ? anonymizeRequest(request, anonymousInfoProvider, actionInfoAnonymizer)
      : request;

  return {
    apply,
    setActive,
    isActive: () => isActive,
  };
};
