import { HttpService } from '@/core/http/httpService';

type RequestBody = {
  device: string;
  browser: string;
  subject: string;
  body: string;
  name: string;
  email: string;
  source?: string;
};

export default function sendCustomerSupportRequest({ post }: HttpService) {
  return (body: RequestBody) => post('/v1/web/zendesk/tickets', body);
}
