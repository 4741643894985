import { useLocalization } from '@/services/localizationService';

export default function LoginBenefits() {
  const { t, data } = useLocalization();

  return (
    <div className="space-y-4">
      <h3 className="SB30 text-inherit">
        {t(data.onboarding.getTheMostOfReminiWeb)}
      </h3>
      <p className="R14 leading-5">
        {t(data.onboarding.getTheMostOfReminiWebSubtitle)}
      </p>
    </div>
  );
}
