import { ModalWrapper } from '@/core/modal';
import { useTracker } from '@/core/tracking';
import { useConsentService } from '@/layout/appWrapper/ServiceProvider';
import helpUsImprove from '@/public/images/showcase/help-us-improve.webp';
import { useLocalization } from '@/services/localizationService';
import { useUser } from '@/services/userService';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import LinkWithQuery from '@/ui/content/LinkWithQuery';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { ConsentEvent } from './modalsTypes';

interface Props {
  id: string;
  [x: string]: any;
}

function ImageTrainingConsentRejectedModal({ id, ...rest }: Props) {
  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();

  return (
    <ModalWrapper
      className="text-center relative w-96 mt-auto ms-auto"
      {...rest}
    >
      <div className="inline-flex">
        <img className="w-24" src={helpUsImprove.src} alt="" />
      </div>

      <p className="SB23 text-black mb-6">
        {t(data.account.imageTrainingConsentNotAcceptedTitle)}
      </p>

      <p className="R14 text-gray-450 mt-3 mx-5">
        <span className="whitespace-pre-line">
          {t(data.account.imageTrainingConsentNotAcceptedContent)}
        </span>
      </p>

      <button
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6"
        onClick={() => closeModal(id)}
      >
        {t(data.common.gotIt)}
      </button>
    </ModalWrapper>
  );
}

export default function ImageTrainingConsentModal({ id, ...rest }: Props) {
  const pushModal = useSetRecoilState(pushModalSelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { mutate: mutateUser } = useUser();
  const { t, data } = useLocalization();
  const { track } = useTracker();
  const { grantImageTrainingConsent } = useConsentService();

  useEffect(() => {
    track(ConsentEvent.ImageTraining, 'shown');
  }, [track]);

  return (
    <ModalWrapper
      className="text-center relative w-96 mt-auto ms-auto"
      {...rest}
    >
      <div className="inline-flex">
        <img className="w-24" src={helpUsImprove.src} alt="" />
      </div>

      <p className="SB23 text-black mb-6">
        {t(data.account.imageTrainingConsentTitle)}
      </p>

      <p className="R14 text-gray-450 mt-3 mx-5">
        <span className="whitespace-pre-line">
          {t(data.account.imageTrainingConsentLegal, {
            buttonYes: <>&quot;{t(data.account.agree)}&quot;</>,
            buttonNo: <>&quot;{t(data.account.notNow)}&quot;</>,
            link: (
              <u>
                <LinkWithQuery path="/privacy-and-cookie-policy">
                  <a
                    className="underline text-black font-bold"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(data.common.privacyCookiePolicy)}
                  </a>
                </LinkWithQuery>
              </u>
            ),
          })}
        </span>
      </p>

      <button
        type="button"
        className="w-11/12 btn btn--large shadow-dark-button btn--black mt-7 inline-flex"
        onClick={() => {
          grantImageTrainingConsent(
            window.location.origin + window.location.pathname
          ).then(() => mutateUser());
          track(ConsentEvent.ImageTraining, 'accepted');
          closeModal(id);
        }}
      >
        {t(data.account.agree)}
      </button>
      <button
        type="button"
        className="M18 text-black mt-5 mb-2"
        onClick={() => {
          track(ConsentEvent.ImageTraining, 'not_accepted');
          closeModal(id);
          pushModal({
            id: 'image-training-consent-rejected-modal',
            Modal: ImageTrainingConsentRejectedModal,
          });
        }}
      >
        {t(data.account.notNow)}
      </button>
    </ModalWrapper>
  );
}
