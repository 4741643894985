import * as Sentry from '@sentry/nextjs';
import { memoryStorage } from './memoryStorage';

const getIsLocalStorageAvailable = () => {
  const test = 'checkAvailability';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    Sentry.captureException(new Error('Local storage not available'));
    return false;
  }
};

const isLocalStorageAvailable = getIsLocalStorageAvailable();

export const safeLocalStorage = isLocalStorageAvailable
  ? localStorage
  : memoryStorage;
