import { useReCaptcha } from '@/core/recaptcha/useReCaptcha';
import useCallbackReference from '@/core/stateManager/useCallbackReference';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';
import caretLeft from '@/public/images/icons/caret-left.svg';
import paymentWarning from '@/public/images/icons/payment-warning.png';
import { useLocalization } from '@/services/localizationService';
import {
  FREE_TRIAL_DAYS,
  PaymentStrategiesIds,
  PaymentType,
  STRIPE_APPEARANCE,
} from '@/services/paymentService';
import { UserAction, useUser } from '@/services/userService';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import {
  isFreeTrialEnabledState,
  isReminderEnabledState,
  selectedPaymentStrategyState,
  selectedPeriodState,
} from '@/stores/onboardingStore';
import settingsState from '@/stores/settingsStore';
import Spinner from '@/ui/content/Spinner';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useStripeProvider } from '@/core/stripe/useStripeProvider';
import { FreeTrialButton } from '.';
import PaymentInformationForm from './PaymentInformationForm';
import { PaymentSelectionModal } from './modals';
import usePaymentStrategies from './paymentStrategies/usePaymentStrategies';

export default function PaymentForm({
  onSubmit,
  paymentType,
}: {
  onSubmit: () => void;
  paymentFormScrollRef: React.RefObject<any>;
  paymentType?: PaymentType;
}) {
  const settings = useRecoilValue(settingsState);
  const { useFormatRecurringPrice, usePaywallPrices } = usePriceService();
  const { stripePromise } = useStripeProvider();

  const { data: user } = useUser();

  const [isFreeTrialEnabled, setIsFreeTrialEnabled] = useRecoilState(
    isFreeTrialEnabledState
  );
  const [isReminderEnabled, setIsReminderEnabled] = useRecoilState(
    isReminderEnabledState
  );

  // TODO handle this period in the renaming as well
  const period =
    paymentType === PaymentType.OneTimePayment ? 'singleExport' : 'weekly';

  const [selectedPeriod, setSelectedPeriod] =
    useRecoilState(selectedPeriodState);
  const { t, data } = useLocalization();
  const paymentStrategies = usePaymentStrategies({
    selectedPeriod,
    remotePaymentStrategies: settings.values.paymentStrategies,
  });
  const setSelectedPaymentStrategy = useSetRecoilState(
    selectedPaymentStrategyState
  );

  const { disableEmailReminder } = settings.values;

  const paywallPrices = usePaywallPrices();

  const isFreeTrialAvailable =
    user?.monetization?.trialAvailable && settings.values.isFreeTrialEnabled;

  const selectedPrice = paywallPrices
    ? paymentType === PaymentType.OneTimePayment
      ? paywallPrices.oneTime[selectedPeriod]
      : paywallPrices.base[selectedPeriod]
    : null;
  const formattedPrice = useFormatRecurringPrice(selectedPrice);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const pushModal = useSetRecoilState(pushModalSelector);
  const onModalClose = useCallback(async () => {
    buttonRef.current?.focus();
  }, []);
  const callOnModalClose = useCallbackReference(onModalClose);
  const closeModal = useSetRecoilState(closeModalSelector);

  useReCaptcha();

  useEffect(() => {
    setSelectedPeriod(period);
  }, [period, setSelectedPeriod]);

  useEffect(() => {
    if (paymentType === PaymentType.OneTimePayment) {
      const selectedPaymentStrategy = paymentStrategies.find(
        (t) => t.id === PaymentStrategiesIds.OneTime
      );
      setSelectedPaymentStrategy(selectedPaymentStrategy!);
    }
  }, [
    paymentStrategies,
    selectedPeriod,
    paymentType,
    setSelectedPaymentStrategy,
  ]);

  if (!paywallPrices || !selectedPrice) return <Spinner inline={false} />;

  const isOneTimePayment = paymentType === PaymentType.OneTimePayment;
  const options: StripeElementsOptions = {
    mode: isOneTimePayment ? 'payment' : 'setup',
    currency: selectedPrice.currency,
    amount: isOneTimePayment ? selectedPrice.amountCents : undefined,
  };

  return (
    <div className="space-y-6">
      {paymentType === PaymentType.OneTimePayment ? (
        <>
          <div className="rounded-[20px] shadow-md overflow-hidden">
            <div className="bg-white flex items-center px-7 py-5">
              <img
                className="me-4 cursor-pointer rtl:scale-x-[-1]"
                {...caretLeft}
                alt=""
                onClick={() => {
                  closeModal('payment');
                  pushModal({
                    id: 'payment-selection',
                    Modal: PaymentSelectionModal,
                    previousAction: UserAction.Download,
                    onClose: callOnModalClose,
                  });
                }}
              />
              <div>
                <p className="M16 mb-1">{t(data.onboarding.oneTime)}</p>
                <div className="flex items-end">
                  <p className="SB20 text-black">
                    {formattedPrice.split('/')[0]}
                  </p>
                  <p className="SB20 text-gray-300 lowercase">
                    /{t(data.common.download)}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-7 py-5 flex items-center">
              <img className="block me-2 w-4 h-4" {...paymentWarning} alt="" />
              <p className="M12">{t(data.onboarding.oneTimeWarning)}</p>
            </div>
          </div>
          <hr className="my-8" />
        </>
      ) : (
        <>
          <div className="space-y-2 text-center">
            {isFreeTrialAvailable ? (
              <h2 className="SB25">
                {t(data.onboarding.activateYour)}{' '}
                <span className="text-primary-accent">
                  {settings.values.oneTimePayment
                    ? t(data.account.subscription, null, 'lowercase')
                    : t(data.common.freeTrial, null, 'lowercase')}
                </span>
                <br />
                {t(data.onboarding.andGetStarted)} 🎉
              </h2>
            ) : (
              <h2 className="SB25">{t(data.onboarding.getStarted)} 🎉</h2>
            )}
          </div>
          {isFreeTrialAvailable && !settings.values.oneTimePayment && (
            <FreeTrialButton
              isFreeTrialEnabled={isFreeTrialEnabled}
              setIsFreeTrialEnabled={setIsFreeTrialEnabled}
              isReminderEnabled={isReminderEnabled}
              setIsReminderEnabled={setIsReminderEnabled}
              showReminder
            />
          )}
          {isFreeTrialAvailable && !settings.values.oneTimePayment ? (
            <p
              className={`M18 text-center pb-2 ${
                !disableEmailReminder ? '!mt-3' : ''
              }`}
            >
              {isFreeTrialEnabled && (
                <span>
                  {t(data.onboarding.daysFree, { n: FREE_TRIAL_DAYS })},{' '}
                  {t(data.common.then, null, 'lowercase')}{' '}
                </span>
              )}
              {formattedPrice}
            </p>
          ) : (
            <p className="M18 text-center pb-2">{formattedPrice}</p>
          )}
        </>
      )}

      <Elements
        stripe={stripePromise}
        options={{
          ...options,
          appearance: STRIPE_APPEARANCE,
        }}
      >
        <PaymentInformationForm
          selectedPrice={selectedPrice}
          onSubmit={onSubmit}
          willDownloadAutomatically={false}
        />
      </Elements>
    </div>
  );
}
