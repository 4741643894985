import { ReactNode, useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRecoilState } from 'recoil';
import {
  isReadyState,
  localeState,
  locDataState,
} from '@/stores/localizationStore';
import dynamic from 'next/dynamic';
import { SupportedLocale } from './localizationTypes';

const ParsedTranslation = dynamic(() => import('./ParsedTranslation'), {
  ssr: false,
});

export const useLocalization = () => {
  const [locale, setLocale] = useRecoilState(localeState);
  const [data, setData] = useRecoilState(locDataState);
  const [isReady, setIsReady] = useRecoilState(isReadyState);
  const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);

  const loadTranslations = useCallback(async () => {
    setIsLoadingTranslations(true);
    try {
      const newData = (await import(`./locales/${locale}`)).default; // Lazy-load only needed translations in the client
      if (newData) setData(newData);
    } catch (e) {
      Sentry.captureException(e);
    }
    setIsLoadingTranslations(false);
    setIsReady(true);
  }, [locale, setData, setIsReady]);

  useEffect(() => {
    loadTranslations();
  }, [loadTranslations]);

  /**
   * Pass a translation string from `data`.
   * @example t(data.common.title)
   */
  const t = useCallback(
    (
      string: string,
      object?: Record<
        string,
        ReactNode | ((str: ReactNode) => ReactNode)
      > | null,
      textTransform?: 'lowercase' | 'uppercase' | 'capitalize'
    ) => (
      <ParsedTranslation
        string={string}
        object={object}
        className={textTransform}
      />
    ),
    []
  );

  const changeLocale = useCallback(
    (newLocale: SupportedLocale) => {
      if (!isLoadingTranslations) setLocale(newLocale);
    },
    [isLoadingTranslations, setLocale]
  );

  return { data, t, isReady, locale, changeLocale, isLoadingTranslations };
};
