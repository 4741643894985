import equal from 'fast-deep-equal';
import { PicoClient } from '..';
import { EventMetadata } from '../providers';
import { PicoEventWithMetadata } from './eventBuffer';
import { EventType, TechnicalPicoEvent } from './eventManager';
import { EventInterceptor } from './eventSender';

type EventMatcher =
  | Partial<PicoEventWithMetadata>
  | Partial<PicoEventWithMetadata>[];

type TechnicalEventsInterceptorOpts = {
  eventMatcher: EventMatcher;
};

export const TechnicalEventsInterceptor: EventInterceptor<
  TechnicalEventsInterceptorOpts
> = (
  event: PicoEventWithMetadata,
  opts: TechnicalEventsInterceptorOpts,
  picoClient: PicoClient
): void => {
  if (isTechnicalEvent(event)) {
    return;
  }

  if (eventDoesMatch(event, opts.eventMatcher)) {
    picoClient.eventManager.trackEvent({
      ...event,
      originalType: event.type,
      type: EventType.TECHNICAL_EVENT,
    } as TechnicalPicoEvent);
  }
};

function eventDoesMatch(
  event: PicoEventWithMetadata,
  eventMatcher: EventMatcher
) {
  const matchers = isSingleEventMatcher(eventMatcher)
    ? [eventMatcher]
    : eventMatcher;

  return matchers.some((matcher) =>
    // Note: this match strategy doesn't work for nested properties
    equal(pickPropertiesToMatch(event, matcher), matcher)
  );
}

function pickPropertiesToMatch(
  event: PicoEventWithMetadata,
  matcher: Partial<PicoEventWithMetadata>
) {
  return Object.fromEntries(
    Object.entries(event).filter(([key]) => Object.keys(matcher).includes(key))
  );
}

function isTechnicalEvent(
  e: PicoEventWithMetadata
): e is TechnicalPicoEvent & EventMetadata {
  return e.type === EventType.TECHNICAL_EVENT;
}

function isSingleEventMatcher(
  em: EventMatcher
): em is Partial<PicoEventWithMetadata> {
  return !Array.isArray(em);
}
