import { deviceType } from '@/core/environment/deviceType';
import {
  Severity,
  SpiderSenseDebugEventWithMetadata,
} from '@/core/monitoring/spidersense/src/event';
import { secondTimestamp } from '@/core/monitoring/spidersense/src/helpers';

const getProductionUrl = (appId: string) =>
  `https://spidersense.bendingspoons.com/v2/public/apps/${appId}/events`;
const getStagingUrl = (appId: string) =>
  `https://staging.spidersense.bendingspoons.com/v2/public/apps/${appId}/events`;

type NetworkRequestBody = {
  user_id: string;
  sent_at: number;
  payloads: {
    id: string;
    created_at: number;
    severity: Severity;
    categories: string[];
    description?: string;
    error_code?: string;
    info: { app_version: string } & Record<string, unknown>;
  }[];
};

export type NetworkRequest = {
  url: string;
  body: NetworkRequestBody;
};

export type NetworkAdapter = {
  getNetworkRequest: (a: SpiderSenseDebugEventWithMetadata[]) => NetworkRequest;
};

type CreateNetworkAdapterOptions = {
  isProduction: boolean;
  appId: string;
  appVersion: string;
  userId: string;
};

export const createNetworkAdapter = (
  config: CreateNetworkAdapterOptions
): NetworkAdapter => {
  const getEventRequestUrl = () =>
    config.isProduction
      ? getProductionUrl(config.appId)
      : getStagingUrl(config.appId);

  const getEventRequestBody = (
    events: SpiderSenseDebugEventWithMetadata[]
  ): NetworkRequestBody => {
    const payloads = events.map((event) => ({
      id: event.id,
      severity: event.severity,
      categories: event.categories,
      description: event.description,
      error_code: event.errorCode,
      info: {
        app_version: config.appVersion,
        device_type: deviceType(),
        ...event.info,
      },
      created_at: event.createdAt,
    }));
    return { user_id: config.userId, sent_at: secondTimestamp(), payloads };
  };

  return {
    getNetworkRequest(
      events: SpiderSenseDebugEventWithMetadata[]
    ): NetworkRequest {
      const url = getEventRequestUrl();
      const body = getEventRequestBody(events);
      return { url, body };
    },
  };
};
