import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';

export type AnonymousTimestampGenerator = {
  generateTimestamp: () => number;
};

const T_DELTA_KEY = '_pico_t_delta';
const T_MONTH_KEY = '_pico_t_month';

const safeToNumber = (s?: string | null) => {
  if (!s) return 0;
  try {
    return Number(s);
  } catch (e) {
    return 0;
  }
};

const generateTimestampDelta = (monthTimestamp: number): number => {
  const savedMonth = safeLocalStorage.getItem(T_MONTH_KEY);
  if (!savedMonth || savedMonth !== monthTimestamp.toString()) {
    safeLocalStorage.setItem(T_MONTH_KEY, monthTimestamp.toString());
    safeLocalStorage.setItem(T_DELTA_KEY, '0');
    return 0;
  }
  const savedDelta = safeToNumber(safeLocalStorage.getItem(T_DELTA_KEY));
  const newDelta = savedDelta + 1;
  safeLocalStorage.setItem(T_DELTA_KEY, newDelta.toString());
  return newDelta;
};

export const createAnonymousTimestampGenerator =
  (): AnonymousTimestampGenerator => ({
    generateTimestamp: () => {
      const now = new Date();
      const monthTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth());
      const delta = generateTimestampDelta(monthTimestamp);
      return monthTimestamp + delta * 1000;
    },
  });
