import checkYellowEmptyIcon from '@/public/images/icons/check--yellow-empty.svg';
import { useEditorSettings } from '@/services/editorService';
import { useEnhanceLimitService } from '@/services/enhanceLimitService/useEnhanceLimitService';
import { useLocalization } from '@/services/localizationService';
import { PaymentStrategiesIds } from '@/services/paymentService';
import List from '@/ui/list/List';

export default function BusinessSubscriptionBenefits() {
  const { t, data } = useLocalization();
  const { getSubscriptionPlanLimits } = useEnhanceLimitService();

  const { isAnyAiModelBusinessOnly } = useEditorSettings();

  const businessPlanLimit = getSubscriptionPlanLimits(
    PaymentStrategiesIds.Business,
    'week'
  );

  const listItems = [
    isAnyAiModelBusinessOnly
      ? {
          id: 'ai-models',
          icon: checkYellowEmptyIcon,
          label: t(data.tiers.featAllAiModels),
        }
      : null,
    {
      id: 'business-limits',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.featLimitBusiness),
    },
    {
      id: 'business-outputs',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.featOutputBusiness),
    },
    {
      id: 'bulk-upload',
      icon: checkYellowEmptyIcon,
      label: t(data.common.batchProcessing),
    },
    {
      id: 'personal-limits',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.individualVideoLimits, {
        mediaSize: businessPlanLimit.videoLimits.maxSizeMb,
      }),
    },
    {
      id: 'video-limits',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.cumulativeVideoLimits, {
        mediaSize: businessPlanLimit.videoLimits.maxPeriodSizeMb,
      }),
    },
    {
      id: 'watermark',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.downloadWithoutWatermark),
    },
    {
      id: 'commercial',
      icon: checkYellowEmptyIcon,
      label: t(data.tiers.featCommercialBusiness),
    },
  ];

  return (
    <div className="space-y-4">
      <h3 className="SB30 text-inherit">
        {t(data.onboarding.getTheMostOfReminiWeb)}
      </h3>
      <List items={listItems} />
    </div>
  );
}
