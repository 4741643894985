import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { MakeCustomValueType, Target } from 'framer-motion/types/types';
import { AnimatePresence, motion } from 'framer-motion';
import { SplitModal } from '@/core/modal';
import { useTracker } from '@/core/tracking';
import modalCover from '@/public/images/covers/pink-girl.webp';
import { isLoggedInSelector } from '@/stores/authStore';
import { closeModalSelector } from '@/stores/modalStore';

import { DeviceIds, deviceType } from '@/core/environment/deviceType';
import { PaymentForm, SubscriptionBenefits } from '@/features/payment';
import PaymentStrategiesModal from '@/features/payment/paymentStrategies/PaymentStrategiesModal';
import { useConsent } from '@/features/userConsent';
import { UserAction, useUser } from '@/services/userService';
import settingsState from '@/stores/settingsStore';
import {
  usePaymentService,
  usePriceService,
} from '@/layout/appWrapper/ServiceProvider';
import LoginForm from '../account/login/LoginForm';
import { LoginBenefits } from '../account/login';

const fadeHidden: MakeCustomValueType<Target> = {
  opacity: 0,
  position: 'absolute',
};
const fadeVisible: MakeCustomValueType<Target> = {
  opacity: 1,
  position: 'relative',
};

const slideHidden = { x: 100, ...fadeHidden };
const slideVisible = { x: 0, ...fadeVisible };

type Props = {
  previousAction?: UserAction;
  id: string;
  onClose?: () => void;
  forceLogin?: boolean;
  stepIndex?: string;
  loginOnly?: boolean;
};

export interface Step {
  name: 'login' | 'paywall';
  guard?: () => Promise<boolean>;
  Aside: typeof LoginBenefits | typeof SubscriptionBenefits;
  Form: typeof LoginForm | typeof PaymentForm;
}

export default function OnboardingModal({
  previousAction,
  id,
  onClose,
  forceLogin,
  loginOnly,
  ...rest
}: Props) {
  const closeModal = useSetRecoilState(closeModalSelector);
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const { data: user } = useUser();
  const { usePaywallPrices, getPricesSeenByUser } = usePriceService();
  const paywallPrices = usePaywallPrices();
  const router = useRouter();
  const hasBeenTrackedRef = useRef(false);
  const paymentFormScrollRef = useRef({ el: null, scrolled: false });
  const { track, setCategoryParams } = useTracker();
  const { shouldShowMarketingConsentModal } = useConsent();
  const [stepIndex, setStepIndex] = useState(isLoggedIn && !forceLogin ? 1 : 0);
  const [nextStepIndex, setNextStepIndex] = useState(0);
  const settings = useRecoilValue(settingsState);
  const isDesktop = useMemo(() => deviceType() === DeviceIds.Desktop, []);
  const { isBaseUser } = usePaymentService();
  const steps: Step[] = useMemo(
    () => [
      {
        name: 'login',
        Aside: LoginBenefits,
        Form: LoginForm,
      },
      {
        name: 'paywall',
        // I could move the guard to the component itself, but that would force me to
        // move the tracker calls (e.g. 'paywall_shown') to the component too. Besides,
        // the SubscriptionForm component is enough difficult to read as it is. It's
        // best not to add stuff in there.
        guard: async () =>
          !!user && isBaseUser(user) && !router.query.redeemCode,
        Aside: SubscriptionBenefits,
        Form: PaymentForm,
      },
    ],
    [user, isBaseUser, router.query.redeemCode]
  );

  const Step = useMemo(
    () => steps[rest.stepIndex || stepIndex],
    [steps, stepIndex, rest.stepIndex]
  );

  const priceIds = useMemo(() => {
    if (Step.name !== 'paywall' || !paywallPrices) return undefined;

    return getPricesSeenByUser(
      paywallPrices,
      settings.values.oneTimePayment || false
    );
  }, [
    Step.name,
    getPricesSeenByUser,
    paywallPrices,
    settings.values.oneTimePayment,
  ]);

  useEffect(() => {
    if (hasBeenTrackedRef.current) return;
    if (Step.name === 'paywall' && !priceIds) return; // Track paywall only after prices infos have loaded

    track(Step.name, 'shown', { previousAction, priceIds });
    hasBeenTrackedRef.current = true;
  }, [Step.name, track, previousAction, priceIds]);

  const proceedToStep = useCallback(
    (nextStep: number) => {
      if (nextStep < steps.length && !(Step.name === 'login' && loginOnly)) {
        setNextStepIndex(nextStep);
      } else {
        closeModal(id);
      }
    },
    [steps, closeModal, id, loginOnly, Step.name]
  );

  useEffect(() => {
    if (nextStepIndex > stepIndex) {
      const { guard } = steps[nextStepIndex];

      if (typeof guard !== 'function') {
        return setStepIndex(nextStepIndex);
      }

      const waitForGuard = async () => {
        const canProceed = await guard();
        if (canProceed) {
          setStepIndex(nextStepIndex);
        } else {
          // Skip the step
          proceedToStep(nextStepIndex + 1);
        }
      };

      waitForGuard();
    }
  }, [nextStepIndex, stepIndex, proceedToStep, steps]);

  const onModalClose = useCallback(async () => {
    track(Step.name, 'dismissed', { priceIds });
    if (typeof onClose === 'function') onClose();

    if (Step.name === 'paywall') {
      // Show consent modal if user has been created from less than 60 seconds
      const isUserCreatedFromLessThan60sec =
        user && Math.abs(new Date().getTime() - +user.createdAt) < 60000;

      if (user?.createdAt && isUserCreatedFromLessThan60sec) {
        shouldShowMarketingConsentModal();
      }
    }
  }, [
    Step.name,
    track,
    onClose,
    priceIds,
    shouldShowMarketingConsentModal,
    user,
  ]);

  // Yes, I'm lazy and I want to avoid any prop drilling
  useEffect(() => {
    const affectedCategories = ['login', 'paywall', 'payment', 'free_trial'];
    for (const category of affectedCategories) {
      setCategoryParams(category, (categoryParams) => ({
        ...categoryParams,
        previousAction,
      }));
    }
    return () => {
      for (const category of affectedCategories) {
        setCategoryParams(category, ({ previousAction, ...rest }) => rest);
      }
    };
  }, [setCategoryParams, previousAction]);

  const enablePaymentWithTrial = useMemo(
    () =>
      Step.name === 'paywall' &&
      previousAction === UserAction.FreeTrial &&
      settings.values.shouldForceHomeFreeTrial,
    [user, settings.values.shouldForceHomeFreeTrial, previousAction]
  );

  return Step.name === 'paywall' ? (
    <PaymentStrategiesModal
      onSubmit={() => proceedToStep(stepIndex + 1)}
      id={id}
      onClose={onModalClose}
      autoEnableTrial={enablePaymentWithTrial}
      {...rest}
    />
  ) : (
    <SplitModal
      className={`max-w-lg ${
        Step.name === 'paywall'
          ? 'md:max-w-3.5xl'
          : 'md:max-w-3xl md:min-h-[612px]'
      } min-h-[420px]`}
      label="Get started"
      id={id}
      onClose={onModalClose}
      {...rest}
    >
      <SplitModal.LeftPanel
        mobileBackground={modalCover}
        desktopBackground={modalCover}
        className="lg:min-w-100 lg:w-100 lg:!pe-11"
        rootChildren={<div className="absolute top-0 start-0 w-full h-full" />}
      >
        <div className="relative w-full">
          <AnimatePresence initial={false}>
            <motion.div
              className="w-full start-0 bottom-0"
              key={Step.name}
              initial={slideHidden}
              animate={slideVisible}
              exit={slideHidden}
            >
              {isDesktop && <Step.Aside />}
            </motion.div>
          </AnimatePresence>
        </div>
      </SplitModal.LeftPanel>
      <SplitModal.RightPanel
        className={`overflow-y-auto ${
          Step.name === 'paywall'
            ? 'lg:!px-17 md:short:!py-8'
            : 'flex flex-col justify-center'
        }`}
        sectionRef={(ref) => {
          paymentFormScrollRef.current.el = ref;
        }}
      >
        <div className="relative w-full">
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
              className="w-full top-0 start-0"
              key={Step.name}
              initial={fadeHidden}
              animate={fadeVisible}
              exit={fadeVisible}
            >
              <Step.Form
                onSubmit={() => proceedToStep(stepIndex + 1)}
                paymentFormScrollRef={paymentFormScrollRef}
                paymentType={previousAction}
              />
            </motion.div>
          </AnimatePresence>
        </div>
      </SplitModal.RightPanel>
    </SplitModal>
  );
}
