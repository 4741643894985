export enum TaskStatus {
  Started = 'started',
  Uploading = 'uploading',
  Processing = 'processing',
  Reprocessing = 'reprocessing',
  Completed = 'completed',
  Failed = 'failed',
  Invalid = 'invalid',
  Pristine = 'pristine',
  Cancelled = 'cancelled',
  Exported = 'exported',
}

export enum TaskError {
  TooFewFiles = 'too_few_files',
  TooManyFiles = 'too_many_files',
  WrongFileFormat = 'wrong_file_format',
  FileDurationError = 'duration',
  FileSizeError = 'size',
  PeriodDurationErrorBusiness = 'duration_business',
  PeriodSizeErrorBusiness = 'size_business',
}

export enum TaskErrorType {
  VideoDuration = 'videoEnhanceDuration',
  VideoSize = 'videoEnhanceSize',
  TooManyFiles = 'tooManyFiles',
  WrongFileFormat = 'wrongFileFormat',
}

export enum TaskType {
  Image = 'image',
  Video = 'video',
}

export const ID_PREFIX = 'uploader-file-input';

export const MaxFileQuantityForUpload = 15;
