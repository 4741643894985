import {
  trackCompletedEvent,
  trackEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '@/core/monitoring/trackEvents';
import { useTracker } from '@/core/tracking';
import useUploadHandler from '@/features/uploadMedia/useUploadHandler';
import {
  useEditorService,
  useFormatService,
  useMediaService,
  usePaymentService,
  useSurveyService,
  useUploaderService,
} from '@/layout/appWrapper/ServiceProvider';
import { ImageSelectorMedia } from '@/services/editorService';
import { Format } from '@/services/formatService/formatTypes';
import { SurveyClassname } from '@/services/surveyService';
import { TaskStatus, TaskType } from '@/services/uploaderService';
import { useUser } from '@/services/userService';
import taskToFormatAssociationState from '@/stores/formatState';
import { isReprocessingMediaState } from '@/stores/isReprocessingMediaStore';
import { downloadMediaState } from '@/stores/mediaStore';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AiPipelineChoices } from '@/services/settingsService/aiTypes';

export default function useDownload() {
  const router = useRouter();
  const { taskId: bulkUploadId } = router.query;
  const { track } = useTracker();

  const { isPersonalUser } = usePaymentService();

  const { includeAdditionalParamsInPipeline } = useEditorService();
  const { removeTypeformElements } = useSurveyService();

  const [isReprocessingMedia, setIsReprocessingMedia] = useRecoilState(
    isReprocessingMediaState
  );
  const { isPremiumUser } = usePaymentService();
  const { data: user, mutate: mutateUser } = useUser();

  const shouldEnableDownload = useMemo(
    () => !!user && isPremiumUser(user),
    [isPremiumUser, user]
  );

  const { useMedia } = useMediaService();
  const { getBulkUploadWithPolling } = useMedia();

  const { exportTask } = useUploaderService();
  const { getMediaAndUpdateStore } = useMedia();

  const mediasToDownload = useRecoilValue(downloadMediaState);
  const taskToFormatAssociation = useRecoilValue(taskToFormatAssociationState);

  const { formatFromMimeTypeAndPipeline } = useFormatService();
  const { reprocess } = useUploadHandler('result');

  const generateFormatFromOutput = useCallback(
    (aiPipeline: AiPipelineChoices, contentType: string): Format =>
      formatFromMimeTypeAndPipeline(contentType, aiPipeline),
    [formatFromMimeTypeAndPipeline]
  );

  const mediasToReprocess = useMemo((): ImageSelectorMedia[] => {
    if (isReprocessingMedia) {
      return [];
    }

    return mediasToDownload.filter((selectedMedia) => {
      if (!selectedMedia.aiPipeline) {
        return false;
      }

      const currentFormat = generateFormatFromOutput(
        selectedMedia.aiPipeline!,
        selectedMedia.contentType!
      );
      const desiredFormat = taskToFormatAssociation[selectedMedia.id];

      return currentFormat.label !== desiredFormat?.label;
    });
  }, [
    generateFormatFromOutput,
    isReprocessingMedia,
    mediasToDownload,
    taskToFormatAssociation,
  ]);

  const reprocessIfNeeded = useCallback(async (): Promise<{
    [taskId: string]: {
      finalOutputUrl: string;
      newTaskId: string;
      hasWatermark: boolean;
    };
  }> => {
    const isReprocessingNeeded = mediasToReprocess.length > 0;
    const defaultResponse = Object.fromEntries(
      mediasToDownload.map((selectedMedia) => [
        selectedMedia.id!,
        {
          finalOutputUrl: selectedMedia.outputUrl,
          newTaskId: selectedMedia.id!,
          hasWatermark: !!selectedMedia.hasWatermark,
        },
      ])
    );

    if (!shouldEnableDownload || !isReprocessingNeeded) {
      return defaultResponse;
    }

    const inputReprocessList = mediasToReprocess.map((selectedMedia) => {
      const outputFormat = taskToFormatAssociation[selectedMedia.id!];
      const updatedAiPipeline = includeAdditionalParamsInPipeline(
        selectedMedia.aiPipeline!,
        outputFormat.additionalPipelineParams
      );

      return {
        aiPipeline: updatedAiPipeline,
        clientTaskId: selectedMedia.id!,
        outputContentType: outputFormat.mimeType,
      };
    });

    const bulkUploadResult = await reprocess({
      currentBulkUploadId: bulkUploadId as string,
      bulkUploadReprocessPayload: {
        inputReprocessList,
      },
    });

    let reprocessedTasks = mediasToReprocess.map(
      (m) => bulkUploadResult.taskList![m.bulkPosition!]
    );

    let taskListCompleted = bulkUploadResult.taskList?.every(
      (t) =>
        t.status === TaskStatus.Completed || t.status === TaskStatus.Exported
    );

    while (!taskListCompleted) {
      const newBulkUploadCompleted = await getBulkUploadWithPolling({
        isBulkProcessingCompleted: false,
      });

      taskListCompleted = newBulkUploadCompleted.taskList?.every(
        (t) =>
          t.status === TaskStatus.Completed ||
          t.status === TaskStatus.Exported ||
          t.status === TaskStatus.Failed
      );

      reprocessedTasks = mediasToReprocess.map(
        (m) => newBulkUploadCompleted.taskList![m.bulkPosition!]
      );

      if (taskListCompleted) {
        track(TaskStatus.Reprocessing, TaskStatus.Completed, {
          actionValue: 'browse',
          mediaType: TaskType.Image,
          bulkUploadId: newBulkUploadCompleted.bulkUploadId,
          mediaQuantity: newBulkUploadCompleted.taskList.length,
          aiPipelines: newBulkUploadCompleted.taskList.map(
            (t) => t.result.outputs[0].aiPipeline
          ),
        });
      }
    }

    setIsReprocessingMedia(false);

    const reprocessedTasksResponse = Object.fromEntries(
      reprocessedTasks
        .filter((t) => t.status !== TaskStatus.Failed)
        .map((reprocessedMedia, index) => [
          mediasToReprocess[index].id!,
          {
            finalOutputUrl: reprocessedMedia.result.outputs[0].url!,
            newTaskId: reprocessedMedia.taskId,
            hasWatermark: reprocessedMedia.result.outputs[0].hasWatermark,
          },
        ])
    );

    return { ...defaultResponse, ...reprocessedTasksResponse };
  }, [
    bulkUploadId,
    getBulkUploadWithPolling,
    includeAdditionalParamsInPipeline,
    mediasToDownload,
    mediasToReprocess,
    reprocess,
    setIsReprocessingMedia,
    shouldEnableDownload,
    taskToFormatAssociation,
    track,
  ]);

  const saveFile = (object: Blob | MediaSource, fileName: string) => {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(object);

    const a = document.createElement('a');

    a.setAttribute('download', fileName);
    a.href = imageUrl;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  const download = useCallback(
    async ({
      downloadUrl,
      blob,
      fileName = 'remini',
    }: {
      downloadUrl?: string;
      blob?: Blob;
      fileName?: string;
    }) => {
      if (!blob && !downloadUrl) {
        return;
      }

      let fileToSave: Blob | undefined;

      if (downloadUrl) {
        const downloadUrlEventPayload = { type: 'single' };
        const downloadUrlSingleEventPayload = { type: 'single_fetch' };

        trackStartedEvent(['download', 'url'], downloadUrlEventPayload);
        try {
          trackStartedEvent(['download', 'url'], downloadUrlSingleEventPayload);
          fileToSave = await fetch(downloadUrl).then((resp) => {
            if (resp.status === 200) {
              trackCompletedEvent(
                ['download', 'url'],
                downloadUrlSingleEventPayload
              );
              return resp.blob();
            }

            trackFailedEvent(['download', 'url'], {
              ...downloadUrlSingleEventPayload,
              error: resp.statusText,
            });
            return Promise.reject(new Error(resp.statusText));
          });
          trackCompletedEvent(['download', 'url'], downloadUrlEventPayload);
        } catch (e) {
          trackFailedEvent(['download', 'url'], {
            ...downloadUrlEventPayload,
            error: e?.toString?.(),
          });
        }
      } else if (blob) {
        fileToSave = blob;
      }

      if (fileToSave) {
        trackEvent(['download', 'file', 'triggered'], {
          type: !blob ? 'url' : 'blob',
        });
        saveFile(fileToSave, fileName);
      }
      removeTypeformElements(SurveyClassname.Slider);
    },
    [removeTypeformElements]
  );

  const onExportMedia = useCallback(
    async (taskId: string): Promise<string> => {
      const outputUrl = await exportTask(taskId);

      await mutateUser();
      await getMediaAndUpdateStore();

      return outputUrl;
    },
    [exportTask, getMediaAndUpdateStore, mutateUser]
  );

  const needsExportBeforeDownload = useMemo(
    () => isPersonalUser(user) || (!!user && user.availableCredits! > 0),
    [isPersonalUser, user]
  );

  return {
    download,
    generateFormatFromOutput,
    needsExportBeforeDownload,
    onExportMedia,
    reprocessIfNeeded,
  };
}
