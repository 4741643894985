import { useEffect } from 'react';

const src = `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`;

export const useReCaptcha = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
      document.querySelector('script[src*="gstatic.com/recaptcha/"]')?.remove();
      document.querySelector('.grecaptcha-badge')?.parentElement?.remove();
    };
  }, []);
};
