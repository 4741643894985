import { StaticImageData } from 'next/image';
import React from 'react';
import questionIcon from '@/public/images/icons/question-mark.svg';
import { StickyTooltip } from '@/ui/stickyTooltip';
import { ToggleEvent } from './toggleTypes';

interface Props {
  id?: string;
  name?: string;
  additionalStyle?: string;
  label?: string;
  icon?: StaticImageData;
  iconPath?: string | null;
  checked: boolean;
  disabled?: boolean;
  brightColors?: boolean;
  tooltip?: { title: string; body: string; mediaUrl: string };
  onToggleChange: (event: ToggleEvent) => void;
}

export default function Toggle({
  id = 'toggleId',
  name = 'toggleName',
  additionalStyle,
  label,
  icon,
  iconPath,
  checked = false,
  disabled = false,
  brightColors = false,
  tooltip,
  onToggleChange,
}: Props) {
  return (
    <label
      htmlFor={id}
      className={`inline-flex relative justify-between gap-[21px] items-center cursor-pointer h-fit py-[0.5px] ps-1 ${
        disabled ? 'pointer-events-none' : ''
      } ${additionalStyle}`}
    >
      {(icon || iconPath || label) && (
        <div className="inline-flex gap-[6px]">
          {icon && <img className="w-6 h-6" {...icon} alt={`model-${id}`} />}
          {iconPath && (
            <img className="w-6 h-6" src={iconPath} alt={`model-${id}`} />
          )}
          {label && (
            <span className="font-semibold cursor-pointer text-white">
              {label}
            </span>
          )}
          {tooltip && (
            <StickyTooltip
              placement="right-center"
              tooltipContent={
                <div className="translate-y-[-30%] bg-white text-black fixed w-[200px] start-[310px] z-[55] rounded-xl p-1.5">
                  <div className="w-[100%] h-[124px] rounded-[8px] mb-2 overflow-hidden flex justify-center items-center">
                    <video
                      height="100%"
                      width="auto"
                      autoPlay
                      muted
                      loop
                      className="max-w-none w-auto h-[100%]"
                      src={tooltip.mediaUrl}
                    />
                  </div>
                  <div className="p-1.5 mb-1">
                    <p className="SB15 text-gray-450 mb-1">{tooltip.title}</p>
                    <p className="text-gray-400 M12">{tooltip.body}</p>
                  </div>
                </div>
              }
            >
              <img {...questionIcon} className="ms-1 mt-0.5" alt="help icon" />
            </StickyTooltip>
          )}
        </div>
      )}
      <div role="presentation" className="relative transition-all">
        <input
          onChange={(e) => onToggleChange({ checked: e.target.checked })}
          type="checkbox"
          id={id}
          name={name}
          className="sr-only peer rtl:peer"
          disabled={disabled}
          checked={checked}
        />
        <div
          className={`
            w-[44px]
            h-[25px]
            ${
              !checked || disabled
                ? 'bg-gray-300 bg-opacity-50'
                : brightColors
                ? 'bg-green'
                : 'bg-white'
            }
            rounded-full
            peer
            peer-checked:after:translate-x-full
            rtl:peer-checked:after:-translate-x-full
            after:absolute
            after:top-[3px]
            ltr:after:left-[3px]
            rtl:after:right-[3px]
            ${brightColors ? 'after:bg-white' : 'after:bg-gray-500'}
            after:rounded-full
            after:h-[19px]
            after:w-[19px]
            after:transition-all
            after:duration-100
            after:ease-out
            `}
        />
      </div>
    </label>
  );
}
