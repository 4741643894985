import { v4 as uuidv4 } from 'uuid';
import { SessionManager } from '@/core/tracking/pico/session';

export type EventMetadata = {
  id: string;
  timestamp: number;
  seconds_from_session_start: number;
  session_id: string;
};

export type EventMetadataProvider = {
  getEventMetadata: () => EventMetadata;
};

type CreateMetatadaProviderOptions = {
  sessionManager: SessionManager;
};

export const createEventMetadataProvider = ({
  sessionManager,
}: CreateMetatadaProviderOptions): EventMetadataProvider => ({
  getEventMetadata(): EventMetadata {
    return {
      id: uuidv4(),
      timestamp: Math.round(Date.now() / 1000),
      seconds_from_session_start: sessionManager.getSecondsFromSessionStart(),
      session_id: sessionManager.getSessionID(),
    };
  },
});
