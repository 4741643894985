import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { closeModalSelector } from '@/stores/modalStore';
import { CloseIcon } from '@/ui/icons';
import { isBusySelector } from '@/stores/appStore';
import ModalWrapper from '../ModalWrapper';

type TabPanelTabDef = { id: string; label: string | JSX.Element };

interface Props {
  id: string;
  children: (React.ReactElement | false | null)[];
  className?: string;
  onTabChange?: CallableFunction;
  tabs: TabPanelTabDef[];
  label?: string;
  startTabId?: string;
}

function TabPanelsModal({
  className = '',
  onTabChange,
  children,
  startTabId,
  tabs,
  id,
  ...rest
}: Props) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const idSuffix = useMemo(() => Math.floor(Math.random() * 10_000), []);

  const isBusy = useRecoilValue(isBusySelector);
  const closeModal = useSetRecoilState(closeModalSelector);

  const [activeTab, setActiveTab] = useState(startTabId ?? tabs[0]?.id);

  const focusFirstElement = () => closeButtonRef.current?.focus();

  useEffect(focusFirstElement, []);

  return (
    <ModalWrapper
      className={`p-0 flex flex-col md:flex-row overflow-hidden w-full ${className}`}
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      <nav className="px-6 py-4 md:py-8 md:w-64 space-s-6 md:space-s-0 md:space-y-6 bg-gray-100 flex flex-shrink-0 md:block">
        <button
          type="button"
          className="contrast-high"
          ref={closeButtonRef}
          disabled={isBusy}
          onClick={() => closeModal(id)}
        >
          <CloseIcon />
        </button>
        <div
          role="tablist"
          className="space-s-3 md:space-s-0 md:space-y-4 flex md:block overflow-x-auto"
        >
          {tabs.map(({ id: tabId, label }) => (
            <button
              type="button"
              className={`block md:w-full text-center py-4 px-6 rounded-full M16 contrast-high hover:bg-white ${
                activeTab === tabId ? 'bg-white' : ''
              }`}
              key={tabId}
              role="tab"
              aria-selected={activeTab === tabId}
              aria-controls={`${tabId}-${idSuffix}`}
              onClick={() => {
                setActiveTab(tabId);
                if (id !== activeTab && typeof onTabChange === 'function') {
                  onTabChange(tabId);
                }
              }}
            >
              {label}
            </button>
          ))}
        </div>
      </nav>
      <div className="bg-white flex-grow p-6 md:p-10 overflow-y-auto">
        {React.Children.map(children, (child) => {
          if (!child) {
            return null;
          }

          return React.cloneElement(child, {
            ...child.props,
            key: child.props.tab,
            id: `${child.props.tab}-${idSuffix}`,
            activeTab,
          });
        })}
      </div>
    </ModalWrapper>
  );
}

TabPanelsModal.TabPanel = function TabPanelsModalTabPanel({
  children,
  activeTab,
  id,
  tab,
}: {
  children?: React.ReactNode;
  activeTab?: string;
  id?: string;
  tab?: string;
}) {
  return (
    <div
      className={`h-full ${activeTab !== tab ? 'hidden' : ''}`}
      role="tabpanel"
      id={id}
    >
      {children}
    </div>
  );
};

export default TabPanelsModal;
