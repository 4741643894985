import getDeviceType from '@/services/supportService/_private/useSendCustomerSupportRequest/getDeviceType';
import getBrowser from '@/services/supportService/_private/useSendCustomerSupportRequest/getBrowser';
import { useUser } from '@/services/userService';

export default function useContactFormUrl({ source }: { source: string }) {
  const device = getDeviceType();
  const browser = getBrowser();

  const { data } = useUser();
  if (!data) {
    return '';
  }

  const isSubscribedWithWebProvider =
    data.monetization.subscriptionProvider === 'stripe' ||
    data.monetization.subscriptionProvider === 'paypal';
  const stripeSubscriptionId =
    data.monetization.subscriptionProvider === 'stripe'
      ? data.monetization.subscriptionId
      : '';
  const paypalSubscriptionId =
    data.monetization.subscriptionProvider === 'paypal'
      ? data.monetization.subscriptionId
      : '';

  let trialType = '';
  if (
    isSubscribedWithWebProvider &&
    data.monetization.subscriptionIncludesTrial
  ) {
    trialType = data.monetization.subscriptionWithIntroPrice
      ? 'intro price'
      : 'free';
  }

  const customerSupportPayload = `User ID: ${data.webUserId}
Device: ${device}
Browser: ${browser}
Login provider: ${data.source}
Email of the account (also Stripe, while on PayPal it can be different): ${
    data.email
  }
Firebase UID: ${data.id}
Where the user came from: ${source}
------------------------------------
Has active subscription with Stripe or PayPal: ${isSubscribedWithWebProvider}
Payment provider: ${data.monetization.subscriptionProvider}
Stripe subscription ID: ${stripeSubscriptionId}
PayPal subscription ID: ${paypalSubscriptionId}
Had trial on currently active subscription: ${
    data.monetization.subscriptionIncludesTrial
  }
Was the trial free or with intro price: ${trialType}
------------------------------------
Has gift code active: ${data.giftCode?.isActive}
Is free: ${data.isFree}
Is mobile & web user (redeemed the Remini Web sub thanks to the mobile one): ${
    data.monetization.subscriptionProvider === 'mobile'
  }
Oracle Backend ID (if any): ${data.mobileOracleBackendId}
`;

  const encodedPayload = encodeURIComponent(customerSupportPayload);

  return `https://remini-web.zendesk.com/hc/en-us/requests/new?tf_26371121638801=${encodedPayload}`;
}
