import { ModalWrapper } from '@/core/modal';
import { closeModalSelector } from '@/stores/modalStore';
import { useSetRecoilState } from 'recoil';

export default function LoginNotSupportedModal({
  id,
  ...rest
}: {
  id: string;
}) {
  const closeModal = useSetRecoilState(closeModalSelector);

  return (
    <ModalWrapper className="text-center" {...rest}>
      <p className="SB20 text-black">Login not supported</p>
      <p className="M16 text-gray-400 mt-3">
        We recommend not to use Incognito mode
        <br />
        and allow the necessary cookies.
      </p>
      <button
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6"
        onClick={() => closeModal(id)}
      >
        Ok, Got It
      </button>
    </ModalWrapper>
  );
}
