import { useLocalization } from '@/services/localizationService';
import { Interval } from '@/services/priceService';

export interface RecurringPriceOptions {
  amountCents: number;
  currency: string;
  recurringInterval: Interval;
  recurringIntervalCount: number;
}

export const useFormatRecurringPrice = (props?: RecurringPriceOptions) => {
  const { data } = useLocalization();

  if (!props) return '';
  const { amountCents, currency, recurringInterval, recurringIntervalCount } =
    props;

  const currenciesFormats = {
    usd: '${0}.{1}',
    eur: '{0},{1}€',
  };

  const decimalPart = Math.floor(amountCents / 100);
  const fractionalPart = amountCents - decimalPart * 100;

  const currencyFormat = currenciesFormats[currency] || currenciesFormats.usd;
  const formattedAmount = currencyFormat
    .replace('{0}', decimalPart)
    .replace(
      '{1}',
      fractionalPart < 10 ? `0${fractionalPart}` : fractionalPart
    );

  const formattedInterval =
    recurringIntervalCount > 1
      ? ` every ${recurringIntervalCount} ${recurringInterval}s`
      : `/${data.common[recurringInterval] || data.common.download}`;

  return `${formattedAmount}${formattedInterval}`;
};
