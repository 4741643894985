import { ErrorAlert } from '@/ui/content';
import { useLocalization } from '@/services/localizationService';
import useContactFormUrl from '@/services/supportService/useContactFormUrl';

type GenericErrorAlertProps = {
  eventId: string;
};

export function GenericErrorAlert({ eventId }: GenericErrorAlertProps) {
  const { t, data } = useLocalization();
  const contactFormUrl = useContactFormUrl({
    source: 'generic_error_alert',
  });

  return (
    <div className="h-full flex">
      <ErrorAlert title={data.common.somethingWentWrong} className="m-auto">
        <div className="space-y-3">
          <p>
            {t(data.account.contactIfIssue, {
              accent: (text) => (
                <a
                  className="text-primary-accent-100 underline"
                  href={contactFormUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            })}
          </p>
          <p>
            <code className="text-primary-accent-100">{eventId}</code>
          </p>
        </div>
      </ErrorAlert>
    </div>
  );
}
