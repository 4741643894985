import { readCookie } from '../helpers';

export type TrackingConsentProvider = {
  userAcceptedAnalyticsTracking: () => boolean;
  userAcceptedProfilingTracking: () => boolean;
};

export const OneTrustAnalyticsConsentProvider: TrackingConsentProvider = {
  userAcceptedAnalyticsTracking: () => {
    const consentCode = readCookie('OptanonConsent');
    return consentCode?.includes('C0002%3A1') ?? false;
  },
  userAcceptedProfilingTracking: () => {
    const consentCode = readCookie('OptanonConsent');
    return consentCode?.includes('C0004%3A1') ?? false;
  },
};
