import { NetworkAdapter, NetworkClient } from '@/core/tracking/pico/network';
import { PicoEventWithMetadata } from '@/core/tracking/pico/event';
import { PicoClient } from '..';

export type EventInterceptor<T = any> = {
  (e: PicoEventWithMetadata, opts?: T, picoClient?: PicoClient): void;
};

type CreateEventSenderProps = {
  networkAdapter: NetworkAdapter;
  networkClient: NetworkClient;
  eventInterceptors?: EventInterceptor[];
};

export type EventSender = {
  addInterceptor: (a: EventInterceptor) => void;
  send: (a: PicoEventWithMetadata[]) => void;
};

export const createEventSender = ({
  networkAdapter,
  networkClient,
  eventInterceptors = [],
}: CreateEventSenderProps): EventSender => {
  const send = async (events: PicoEventWithMetadata[]) => {
    const requests = networkAdapter.getNetworkRequests(events);
    for (const request of requests) {
      // note: the order of requests is preserved
      const networkMetadata = await networkClient.send(request);
      networkAdapter.updateBodyMetadata(networkMetadata);
    }
    events.forEach((e) =>
      eventInterceptors.forEach((intercept) => intercept(e))
    );
  };
  const addInterceptor = (interceptor: EventInterceptor) => {
    eventInterceptors.push(interceptor);
  };
  return {
    addInterceptor,
    send,
  };
};
