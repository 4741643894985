import { useAuthSWR } from '@/core/http/useAuthSWR';
import { PriceFromBackend } from './priceTypes';

export function usePricesByIds(priceIds?: string[]) {
  const resp = useAuthSWR<PriceFromBackend[]>(
    priceIds?.length
      ? `/stripe/prices?price_id=${priceIds
          .filter((el, i) => priceIds.indexOf(el) === i) // remove duplicates to prevent errors
          .join('&price_id=')}`
      : null
  );

  return resp;
}
