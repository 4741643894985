import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import { serviceSingletonGetter } from '@/services/serviceGetter';

export type UserService = {
  removeUserCache: () => void;
  isRedirectedFromMobile: () => boolean;
  clearIsRedirectedFromMobile: () => void;
  saveIsRedirectedFromMobile: () => void;
};

export const createUserService = (): UserService => {
  const removeUserCache = () => {
    safeLocalStorage.removeItem('user:cachedData');
  };

  const REDIRECTED_FROM_MOBILE_KEY = 'isRedirectedFromMobile' as const;

  const isRedirectedFromMobile = (): boolean =>
    Boolean(
      JSON.parse(
        window.sessionStorage.getItem(REDIRECTED_FROM_MOBILE_KEY) as string
      )
    );

  const clearIsRedirectedFromMobile = () => {
    window.sessionStorage.setItem(
      REDIRECTED_FROM_MOBILE_KEY,
      JSON.stringify(false)
    );
  };

  const saveIsRedirectedFromMobile = () => {
    window.sessionStorage.setItem(
      REDIRECTED_FROM_MOBILE_KEY,
      JSON.stringify(true)
    );
  };

  return {
    removeUserCache,
    isRedirectedFromMobile,
    clearIsRedirectedFromMobile,
    saveIsRedirectedFromMobile,
  };
};

export default serviceSingletonGetter(Symbol('userService'), createUserService);
