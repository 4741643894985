import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '@/services/serviceGetter';

export type CaptchaService = {
  validateCaptcha: (action?) => Promise<void>;
};

export const createCaptchaService = ({ post }: HttpService): CaptchaService => {
  const _getRecaptchaToken = (action: string): Promise<string> =>
    new Promise((resolve, reject) => {
      const { grecaptcha } = window;
      if (!grecaptcha) {
        reject(new Error('Captcha not loaded yet.'));
        return;
      }

      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, { action })
          .then((token: string) => resolve(token))
          .catch((err: Error) => reject(err));
      });
    });

  const validateCaptcha = async (action = 'submit'): Promise<void> => {
    const recaptchaToken = await _getRecaptchaToken(action);
    const recaptchaResponse = await post('/v1/web/recaptcha/verify', {
      recaptchaToken,
    });
    if (!recaptchaResponse.valid) throw new Error(recaptchaResponse.reasons);
    return recaptchaResponse;
  };

  return { validateCaptcha };
};

export default serviceSingletonGetter(
  Symbol('captchaService'),
  createCaptchaService
);
