import React, { useEffect } from 'react';
import { GenericModal } from '@/ui/content';
import { useLocalization } from '@/services/localizationService';
import { useSetRecoilState } from 'recoil';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import paperIcon from '@/public/images/icons/paper.svg';
import { trackEvent } from '@/core/monitoring/trackEvents';
import AccountModal from '../../AccountModal';

interface Props {
  id: string;
  [x: string]: any;
}

const trackShownModal = () =>
  trackEvent(['cc_plugin', 'plugin_key', 'your_plugin_key_modal', 'shown']);
const trackDismissedModal = () =>
  trackEvent(['cc_plugin', 'plugin_key', 'your_plugin_key_modal', 'dismissed']);
const trackClickedButton = () =>
  trackEvent([
    'cc_plugin',
    'plugin_key',
    'your_plugin_key_modal',
    'retrieve_it_button',
    'clicked',
  ]);

export default function YourPluginKeyModal({ id, ...rest }: Props) {
  const pushModal = useSetRecoilState(pushModalSelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();

  useEffect(() => {
    trackShownModal();
    return () => {
      trackDismissedModal();
    };
  }, []);

  const onRetrieve = () => {
    trackClickedButton();
    closeModal(id);
    pushModal({
      id: 'user:account',
      startTabId: 'pluginKey',
      Modal: AccountModal,
      onClose: () => {},
    });
  };

  return (
    <GenericModal
      id={id}
      className="px-6 pt-6 pb-2 w-[313px] relative"
      {...rest}
      showCloseIcon={false}
    >
      <div className="flex flex-col items-center text-black text-center tracking-[-0.01em]">
        <img {...paperIcon} alt="Secret" />
        <p className="mt-5 SB20">{t(data.account.yourPhotoshopPluginKey)}</p>
        <p className="mt-2.5 M16">
          {t(data.account.yourPhotoshopPluginKeyDescription)}
        </p>
        <button
          type="button"
          className="mt-6 w-full py-3.5 SB16 btn btn--black btn--large"
          onClick={onRetrieve}
        >
          {t(data.common.retrieveIt)}
        </button>
        <button
          type="button"
          className="w-full mt-4 py-3.5 SB16 btn btn--black btn--large bg-transparent text-black border-none"
          onClick={() => closeModal(id)}
        >
          {t(data.common.later)}
        </button>
      </div>
    </GenericModal>
  );
}
