import { useCallback, useRef } from 'react';

function useValueReference<T>(value: T): () => T {
  const ref = useRef(value);

  ref.current = value;

  return useCallback(() => ref.current, []);
}

export default useValueReference;
