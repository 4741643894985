import { useEffect } from 'react';

import { useLocalization } from './useLocalization';

export const useIsRTL = () => {
  const { locale } = useLocalization();

  const isRTL = locale === 'ar';

  useEffect(() => {
    document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
  }, [isRTL]);

  return isRTL;
};
