import { serviceSingletonGetter } from '@/services/serviceGetter';
import { AiPipelineChoices } from '@/services/settingsService/aiTypes';
import { fromCamelCaseToSnakeCaseRecursive } from '@/core/objectManipulation/objectManipulation';
import { TaskMimeType } from '../uploaderService';
import { Format } from './formatTypes';

export type FormatService = {
  getAllFormats: () => Format[];
  getDefaultFormat: () => Format;
  formatFromMimeTypeAndPipeline: (
    mimeType: string,
    aiPipeline: AiPipelineChoices
  ) => Format;
};

export const createFormatService = (): FormatService => {
  const PNG: Format = {
    label: 'PNG',
    mimeType: TaskMimeType.PNG,
    extension: 'png',
    isBusinessOnly: true,
  };
  const JPEG_90: Format = {
    label: 'JPG 90%',
    mimeType: TaskMimeType.JPG,
    extension: 'jpg',
    isBusinessOnly: false,
    additionalPipelineParams: {
      jpeg_quality: '90',
    },
  };
  const JPEG_100: Format = {
    label: 'JPG 100%',
    mimeType: TaskMimeType.JPG,
    extension: 'jpg',
    isBusinessOnly: false,
    additionalPipelineParams: {
      jpeg_quality: '100',
    },
  };

  const TIFF: Format = {
    label: 'TIFF',
    mimeType: TaskMimeType.TIFF,
    extension: 'tiff',
    isBusinessOnly: true,
  };

  const getAllFormats = (): Format[] => [JPEG_90, JPEG_100, PNG, TIFF];

  const formatFromMimeTypeAndPipeline = (
    mimeType: string,
    aiPipeline: AiPipelineChoices
  ): Format => {
    const aiPipelineTransformed = fromCamelCaseToSnakeCaseRecursive(aiPipeline);

    const compatibleFormats = getAllFormats().filter(
      (format) => format.mimeType === mimeType
    );

    if (!compatibleFormats.length) return PNG;

    const format = compatibleFormats.find((format) =>
      Object.entries(format.additionalPipelineParams || {}).every(
        ([key, value]) => aiPipelineTransformed[key] === value
      )
    );

    // TODO remove after all tasks with JPG_80 have expired
    return format || compatibleFormats[0];
  };

  const getDefaultFormat = () => PNG;
  return { formatFromMimeTypeAndPipeline, getAllFormats, getDefaultFormat };
};

export default serviceSingletonGetter(
  Symbol('formatService'),
  createFormatService
);
