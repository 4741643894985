import { DeviceIds, deviceType } from '@/core/environment/deviceType';
import { SplitModal } from '@/core/modal';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';
import otpModalCover from '@/public/images/covers/modal.webp';
import businessCover from '@/public/images/covers/upgrade-cover.webp';
import creditIcon from '@/public/images/icons/credit.svg';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { trackEvent } from '@/core/monitoring/trackEvents';
import { useTracker } from '@/core/tracking';
import { OnboardingModal } from '@/layout/menuActions';
import { useLocalization } from '@/services/localizationService';
import { PaymentStrategiesIds } from '@/services/paymentService';
import { Price, PricePeriod } from '@/services/priceService';
import { UserAction, useUser } from '@/services/userService';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import {
  isFreeTrialEnabledState,
  isReminderEnabledState,
  onboardingState,
  selectedPaymentStrategyState,
} from '@/stores/onboardingStore';
import settingsState from '@/stores/settingsStore';
import { LoginBenefits } from '../../../account/login';
import BusinessSubscriptionBenefits from '../../BusinessSubscriptionBenefits';
import { HighlightedPaymentCard } from '../../highlightedPayment';
import usePaymentStrategies from '../../paymentStrategies/usePaymentStrategies';
import OtherPlansCaption from './OtherPlansCaption';

enum PaywallSelection {
  HighlightedItem,
  OtherItems,
}

export default function PaymentSelectionModal({
  id,
  onClose,
  forceBusiness,
}: {
  id: string;
  onClose: () => void;
  forceBusiness: boolean;
}) {
  const ref = useRef({ el: null, scrolled: false });
  const { t, data } = useLocalization();
  const closeModal = useSetRecoilState(closeModalSelector);
  const { usePaywallPrices, useFormatRecurringPrice } = usePriceService();
  const paywallPrices = usePaywallPrices();
  const settings = useRecoilValue(settingsState);
  const [price, setPrice] = useState<Price | undefined>(undefined);
  const [selection, setSelection] = useState<PaywallSelection | undefined>(
    undefined
  );
  const isDesktop = useMemo(() => deviceType() === DeviceIds.Desktop, []);
  const { track } = useTracker();
  const pushModal = useSetRecoilState(pushModalSelector);

  const shouldUseBusinessTier = useMemo(
    () => forceBusiness || settings.values.businessTierWeeklyHighlighted,
    [forceBusiness, settings.values.businessTierWeeklyHighlighted]
  );

  useEffect(() => {
    if (paywallPrices) {
      setPrice(
        shouldUseBusinessTier
          ? paywallPrices.business.weekly
          : settings.values.highlightedOtpStrategy === 'twentyExports'
          ? paywallPrices.oneTime.twentyExports
          : paywallPrices.oneTime.singleExport
      );
    }
  }, [
    paywallPrices,
    settings.values.highlightedOtpStrategy,
    shouldUseBusinessTier,
  ]);

  const formattedPrice = useFormatRecurringPrice(price);
  const { data: user } = useUser();
  const isFreeTrialAvailable =
    user?.monetization?.trialAvailable && settings.values.isFreeTrialEnabled;
  const isIntroPriceEnabled =
    user?.monetization?.trialAvailable &&
    !settings.values.isFreeTrialEnabled &&
    settings.values.isIntroPriceEnabled;

  const setOnboarding = useSetRecoilState(onboardingState);
  const isFreeTrialEnabled = useRecoilValue(isFreeTrialEnabledState);
  const isReminderEnabled = useRecoilValue(isReminderEnabledState);

  const setSelectedPaymentStrategy = useSetRecoilState(
    selectedPaymentStrategyState
  );

  const paymentStrategies = usePaymentStrategies({
    selectedPeriod: 'weekly',
    remotePaymentStrategies: settings.values.paymentStrategies,
    isUpgrade: false,
    userPricePeriod: shouldUseBusinessTier
      ? 'weekly'
      : settings.values.highlightedOtpStrategy,
  });

  const paymentStrategyToUse =
    paymentStrategies.find((strategy) =>
      shouldUseBusinessTier
        ? strategy.id === PaymentStrategiesIds.Business
        : strategy.id === PaymentStrategiesIds.OneTime
    ) || null;

  const onModalClose = useCallback(() => {
    if (selection === PaywallSelection.HighlightedItem) {
      track('paywall_selection', 'highlighted_selected');
      pushModal({
        id: 'payment',
        Modal: OnboardingModal,
        previousAction: UserAction.Subscription,
        stepIndex: 1,
        onClose,
      });
    }
    if (selection === PaywallSelection.OtherItems) {
      track('paywall_selection', 'other_selected');
      pushModal({
        id: 'payment',
        Modal: OnboardingModal,
        previousAction: UserAction.Subscription,
        stepIndex: 1,
        onClose,
      });
    }
    if (selection === undefined) {
      track('paywall_selection', 'dismissed');
    }
    closeModal(id);
  }, [selection, closeModal, id, track, pushModal, onClose]);

  const highlightedPaymentData = shouldUseBusinessTier
    ? {
        title: t(data.tiers.business),
        subtitle: t(data.tiers.businessSubtitle),
        billingPeriod: t(data.common.week),
        badgeText: null,
        hasFreeTrial: isFreeTrialAvailable,
        hasIntroPrice: isIntroPriceEnabled,
        selectedPeriod: 'weekly',
      }
    : {
        title: t(data.common.payAsYouGo),
        subtitle: t(data.common.otpDownloadPackSubtitle),
        billingPeriod:
          price && price.exports! > 1
            ? `x${price.exports} downloads`
            : 'download',
        badgeText: null,
        hasFreeTrial: false,
        hasIntroPrice: false,
        selectedPeriod: settings.values.highlightedOtpStrategy,
      };

  useEffect(() => {
    trackEvent(['paywall', 'shown'], {
      type: shouldUseBusinessTier ? 'force_business' : 'oneTime',
    });
  }, [id, shouldUseBusinessTier]);

  return (
    <SplitModal
      id={id}
      onClose={onModalClose}
      className="min-h-[420px] max-w-[714px]"
    >
      <SplitModal.LeftPanel
        mobileBackground={shouldUseBusinessTier ? businessCover : otpModalCover}
        desktopBackground={
          shouldUseBusinessTier ? businessCover : otpModalCover
        }
        rootChildren={
          <div
            className="absolute top-0 start-0 w-full h-full"
            style={{
              backgroundImage:
                'linear-gradient(0deg, rgba(0,0,0,0.5),rgba(0,0,0,0) 68.62%)',
            }}
          />
        }
      >
        {isDesktop && (
          <div className="relative w-full">
            {settings.values.businessTierWeeklyHighlighted ? (
              <BusinessSubscriptionBenefits />
            ) : (
              <LoginBenefits />
            )}
          </div>
        )}
      </SplitModal.LeftPanel>
      <SplitModal.RightPanel className="overflow-y-auto" sectionRef={ref}>
        <div className="relative w-full">
          <div className="flex flex-col justify-center items-center space-y-6 w-full">
            <HighlightedPaymentCard
              title={highlightedPaymentData.title}
              subtitle={highlightedPaymentData.subtitle}
              price={formattedPrice}
              billingPeriod={highlightedPaymentData.billingPeriod}
              badgeText={highlightedPaymentData.badgeText}
              hasFreeTrial={highlightedPaymentData.hasFreeTrial}
              hasIntroPrice={highlightedPaymentData.hasIntroPrice}
              onPaymentSelected={() => {
                setSelection(PaywallSelection.HighlightedItem);
                setSelectedPaymentStrategy(paymentStrategyToUse);
                setOnboarding({
                  isFreeTrialEnabled,
                  isReminderEnabled,
                  selectedPeriod:
                    highlightedPaymentData.selectedPeriod as PricePeriod,
                  selectedPaymentStrategy: paymentStrategyToUse,
                });
                closeModal(id);
              }}
            />

            <div className="flex items-center w-[100%]">
              <span className="border-t-2 w-[100%] border-gray-100" />
              <p className="mx-2 M16 text-gray-400">or</p>
              <span className="border-t-2 w-[100%] border-gray-100" />
            </div>
            <div className="flex">
              <img {...creditIcon} alt="credit" />
              <p className="R16 text-black ms-1">
                <OtherPlansCaption
                  isSubscriptionHighlighted={Boolean(shouldUseBusinessTier)}
                />
              </p>
            </div>
            <button
              type="button"
              className="btn btn--large btn--white w-[100%] !mt-4 shadow-dark-button cursor-pointer"
              onClick={() => {
                setSelection(PaywallSelection.OtherItems);
                closeModal(id);
              }}
            >
              {t(data.onboarding.seeOtherPlans)}
            </button>
          </div>
        </div>
      </SplitModal.RightPanel>
    </SplitModal>
  );
}
