import { useTracker } from '@/core/tracking';
import { useEffect } from 'react';

export const usePageNameTracking = (pageName: string) => {
  const { setGlobalParams } = useTracker();

  useEffect(() => {
    setGlobalParams((globalParams) => ({
      ...globalParams,
      page: pageName,
    }));
  }, [pageName, setGlobalParams]);
};
