import { atom } from 'recoil';

export const editorLoadingState = atom({
  key: 'editorLoadingState',
  default: false,
});

export const canApplyNewPipeline = atom({
  key: 'pipeline:edited',
  default: false,
});

export const shouldApplyPipelineChanges = atom({
  key: 'pipeline:apply',
  default: false,
});

export const shouldResetToolbarToImageData = atom({
  key: 'toolbar:reset',
  default: false,
});
