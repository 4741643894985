import { InAppSurvey } from '@/features/surveys';

export function SurveyRoot() {
  return (
    <InAppSurvey
      typeformProps={{
        widget: 'DalJjwBL',
        iframeProps: ['title="Feature ranking"'],
      }}
      typeformStyle={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    />
  );
}
