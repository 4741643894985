import { createHttpService, HttpService } from '@/core/http/httpService';
import createTokenService from '@/services/tokenService';

interface ServiceBuilder<T> {
  (httpService: HttpService): T;
}

export type ServiceGetter<T> = () => T;

const tokenService = createTokenService();
const httpService = createHttpService(tokenService);

const _servicesSingletons: Record<symbol, any> = {};

export function serviceSingletonGetter<T>(
  serviceIdentifier: symbol,
  serviceBuilder: ServiceBuilder<T>
): ServiceGetter<T> {
  return () => {
    if (!_servicesSingletons[serviceIdentifier]) {
      _servicesSingletons[serviceIdentifier] = serviceBuilder(httpService);
    }

    return _servicesSingletons[serviceIdentifier];
  };
}
