import {
  contactFormBody,
  contactFormEmail,
  contactFormName,
  contactFormSubject,
} from '@/stores/contactFormStore';
import { useSetRecoilState } from 'recoil';

export default function useResetRequest() {
  const setName = useSetRecoilState(contactFormName);
  const setEmail = useSetRecoilState(contactFormEmail);
  const setSubject = useSetRecoilState(contactFormSubject);
  const setBody = useSetRecoilState(contactFormBody);
  return () => {
    setName('');
    setEmail('');
    setSubject('');
    setBody('');
  };
}
