import { deviceType } from '@/core/environment/deviceType';
import { generateTracker } from '@/core/monitoring/trackEvents';
import { fromCamelCaseToSnakeCaseRecursive } from '@/core/objectManipulation/objectManipulation';
import { trackUserSettingsAndExperiments, useTracker } from '@/core/tracking';
import { getPico } from '@/core/tracking/pico';
import { useSettingsService } from '@/layout/appWrapper/ServiceProvider';
import { authState } from '@/stores/authStore';
import { localeState } from '@/stores/localizationStore';
import settingsState from '@/stores/settingsStore';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';

const remoteSettingsTracker = generateTracker(['setup', 'remote-settings'], {
  trackEventsOnlyOnce: true,
});
const settingsTracker = generateTracker(['setup', 'settings'], {
  trackEventsOnlyOnce: true,
});

export function useSettings() {
  const locale = useRecoilValue(localeState);
  const setSettingsInfo = useSetRecoilState(settingsState);
  const { token } = useRecoilValue(authState);
  const {
    getDefaultSettings,
    retrieveSettings,
    retrieveExperiments,
    forceExperiments,
  } = useSettingsService();

  const router = useRouter();
  const { version } = router.query;

  const { track } = useTracker();
  useEffect(() => {
    if (!token) return;

    const fetchSettings = async () => {
      let settings = getDefaultSettings();

      const device = deviceType();
      const localStorageId = getPico().getLocalStorageId();

      setSettingsInfo((prev) => ({ ...prev, areReady: false }));

      let introPriceSettings = {
        isFreeTrialEnabled: settings.isFreeTrialEnabled,
        isIntroPriceEnabled: settings.isIntroPriceEnabled,
      };

      try {
        remoteSettingsTracker.start();
        const identityResponse = await retrieveSettings(
          localStorageId,
          device,
          locale
        );
        settings = identityResponse.settings;

        if (version !== null && version !== undefined) {
          if (version === 'ip') {
            introPriceSettings = {
              isFreeTrialEnabled: false,
              isIntroPriceEnabled: true,
            };
          }
          if (version === 'ft') {
            introPriceSettings = {
              isFreeTrialEnabled: true,
              isIntroPriceEnabled: false,
            };
          }

          settings = {
            ...settings,
            ...introPriceSettings,
          };
        }

        remoteSettingsTracker.complete();

        const picoExperiments: { [key: string]: number } = {};
        Object.entries(identityResponse.segmentations).forEach(
          ([experimentName, segment]) => {
            if (segment.state === 'running' || segment.state === 'observing') {
              picoExperiments[experimentName] = segment.index;
            }
          }
        );

        trackUserSettingsAndExperiments({
          settings,
          experiments: fromCamelCaseToSnakeCaseRecursive(picoExperiments),
        });
      } catch (error) {
        remoteSettingsTracker.fail(error);
        console.log(error);
      }

      setSettingsInfo({ areReady: true, values: settings });
      track('settings', 'loaded');
    };
    settingsTracker.start();
    fetchSettings()
      .then(() => settingsTracker.complete())
      .catch((e) => {
        console.error(e);
        settingsTracker.fail(e);
      });
  }, [
    getDefaultSettings,
    locale,
    retrieveSettings,
    setSettingsInfo,
    token,
    track,
    retrieveExperiments,
    forceExperiments,
    version,
  ]);
}
