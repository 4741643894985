import {
  PaymentStrategiesIds,
  PaymentStrategy,
} from '@/services/paymentService';
import { useCallback } from 'react';
import { selectedPaymentStrategyState } from '@/stores/onboardingStore';
import { useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { useUser } from '@/services/userService';
import { usePaymentService } from '@/layout/appWrapper/ServiceProvider';
import DownloadsAvailableWarningModal from './DownloadsAvailableWarningModal';

export default function useMaybeOpenCreditLossWarning() {
  const setSelectedPaymentStrategy = useSetRecoilState(
    selectedPaymentStrategyState
  );
  const pushModal = useSetRecoilState(pushModalSelector);
  const { data: user } = useUser();

  const { isBaseUser } = usePaymentService();

  return useCallback(
    (selectedPaymentStrategy: PaymentStrategy) => {
      const opened =
        selectedPaymentStrategy.id !== PaymentStrategiesIds.OneTime &&
        user &&
        isBaseUser(user) &&
        (user?.availableCredits ?? 0) > 0;
      if (opened) {
        pushModal({
          id: 'downloadsAvailableWarning',
          Modal: DownloadsAvailableWarningModal,
          onClose: (canProceed: { proceed: boolean }) => {
            if (canProceed) {
              setSelectedPaymentStrategy(selectedPaymentStrategy);
            }
          },
        });
      }
      return opened;
    },
    [pushModal, setSelectedPaymentStrategy]
  );
}
