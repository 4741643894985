import { SessionManager } from '@/core/tracking/pico/session';
import { EventBuffer } from '@/core/tracking/pico/event';
import {
  EventMetadata,
  EventMetadataProvider,
} from '@/core/tracking/pico/providers';

export enum EventType {
  SESSION = 'Session',
  USER_ACTION = 'UserAction',
  TECHNICAL_EVENT = 'TechnicalEvent',
}

export enum SessionSubtype {
  START = 'session_start',
  END = 'session_end',
}

type CreateEventManagerProps = {
  sessionManager: SessionManager;
  eventMetadataProvider: EventMetadataProvider;
  eventBuffer: EventBuffer;
};

export type UserActionPicoEvent = {
  kind: string;
  data: Record<string, unknown>;
  type: EventType.USER_ACTION;
};

export type SessionPicoEvent = {
  type: EventType.SESSION;
  subtype: SessionSubtype;
};

export type UserActionTechnicalEvent = Omit<UserActionPicoEvent, 'type'> & {
  type: EventType.TECHNICAL_EVENT;
  originalType: EventType.USER_ACTION;
};
export type SessionTechnicalEvent = Omit<SessionPicoEvent, 'type'> & {
  type: EventType.TECHNICAL_EVENT;
  originalType: EventType.SESSION;
};
export type TechnicalPicoEvent =
  | UserActionTechnicalEvent
  | SessionTechnicalEvent;

export type PicoEvent =
  | UserActionPicoEvent
  | SessionPicoEvent
  | TechnicalPicoEvent;

export type EventManager = {
  trackEvent: (a: PicoEvent & Partial<EventMetadata>) => void;
};

export const createEventManager = ({
  eventMetadataProvider,
  sessionManager,
  eventBuffer,
}: CreateEventManagerProps): EventManager => {
  const trackEvent = (event: PicoEvent & Partial<EventMetadata>) => {
    const eventMetadata = eventMetadataProvider.getEventMetadata();
    eventBuffer.enqueueEvent({
      ...eventMetadata,
      ...event,
    });
  };

  const trackSessionLifecycle = (subtype: SessionSubtype) => () => {
    const event = { type: EventType.SESSION as const, subtype };
    trackEvent(event);
  };

  sessionManager.setOnStartCallback(
    trackSessionLifecycle(SessionSubtype.START)
  );
  sessionManager.startSession();

  return {
    trackEvent,
  };
};
