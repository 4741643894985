import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import useEventListener from '@/core/listeners/useEventListener';
import { useTracker } from '@/core/tracking';
import { useLocalization } from '@/services/localizationService';
import accentCheckIcon from '@/public/images/icons/check.svg';
import { useIsRTL } from '@/services/localizationService/useIsRTL';
import { Price } from '@/services/priceService';
import settingsState from '@/stores/settingsStore';
import { deviceType } from '@/core/environment/deviceType';
import { usePriceService } from '@/layout/appWrapper/ServiceProvider';

interface Props {
  isFreeTrialEnabled: boolean;
  setIsFreeTrialEnabled: Dispatch<SetStateAction<boolean>>;
  isReminderEnabled?: boolean;
  setIsReminderEnabled?: Dispatch<SetStateAction<boolean>>;
  className?: string;
  labelClassName?: string;
  selectedPrice?: Price;
  showReminder: boolean;
}

export default function FreeTrialButton({
  isFreeTrialEnabled,
  setIsFreeTrialEnabled,
  isReminderEnabled = false,
  setIsReminderEnabled = () => {},
  className = '',
  labelClassName = '',
  showReminder = true,
}: Props) {
  const { usePaywallPrices, getPricesSeenByUser } = usePriceService();
  const paywallPrices = usePaywallPrices();
  const settings = useRecoilValue(settingsState);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [reminderWrapperWidth, setReminderWrapperWidth] = useState(0);
  const enableFreeTrialInputRef = useRef(null);
  const reminderWrapperRef = useRef<HTMLDivElement>(null);
  const { track } = useTracker();
  const { t, data } = useLocalization();

  useEffect(() => {
    if (!isFreeTrialEnabled) setIsReminderEnabled(false);
  }, [isFreeTrialEnabled, setIsReminderEnabled]);

  const syncReminderWrapperWidth = useCallback(
    () =>
      setReminderWrapperWidth(
        reminderWrapperRef.current?.getBoundingClientRect().width || 0
      ),
    []
  );

  useEffect(() => {
    syncReminderWrapperWidth();
    // Fix minor edge cases (i.e. when "freeTrial" is already selected at first render)
    const timeout = setTimeout(syncReminderWrapperWidth, 300);
    return () => clearTimeout(timeout);
  }, [isFreeTrialEnabled, reminderWrapperWidth, syncReminderWrapperWidth]);

  const { disableEmailReminder } = settings.values;

  const priceIds = paywallPrices
    ? getPricesSeenByUser(paywallPrices, !!settings.values.oneTimePayment)
    : [];

  const onInputFocus = useCallback(() => {
    setIsInputFocused(true);
  }, []);
  const onInputBlur = useCallback(() => {
    setIsInputFocused(false);
  }, []);

  useEventListener(enableFreeTrialInputRef, 'focus', onInputFocus);
  useEventListener(enableFreeTrialInputRef, 'blur', onInputBlur);

  const isDesktop = useMemo(() => deviceType() === 'desktop', []);

  const isTiersWithReminder = !disableEmailReminder && isDesktop;

  const isRTL = useIsRTL();

  const freeTrialSelectedAnimation = `${
    isFreeTrialEnabled
      ? `${isRTL ? '' : '-'}${reminderWrapperWidth / 2}px`
      : '0'
  }`;

  return (
    <div
      className={`${isTiersWithReminder ? 'relative flex items-center' : ''} ${
        reminderWrapperWidth ? 'transition-transform duration-300' : ''
      }`}
      style={{
        transform: `translateX(${freeTrialSelectedAnimation})`,
      }}
    >
      <div
        className={`rounded-3xl shadow-white-button bg-white ${
          isTiersWithReminder ? 'flex-1 min-h-18' : ''
        } ${className}`}
      >
        <input
          className="sr-only"
          type="checkbox"
          id="enable-free-trial"
          ref={enableFreeTrialInputRef}
          checked={isFreeTrialEnabled}
          onChange={() => {
            setIsFreeTrialEnabled(!isFreeTrialEnabled);
            track(
              'free_trial',
              isFreeTrialEnabled ? 'disabled' : 'enabled',
              priceIds.length ? { priceIds } : {}
            );
          }}
        />
        <label
          className={`flex items-center space-s-2 py-5 px-6 h-20 ${
            !isDesktop ? 'short:!h-auto' : ''
          } cursor-pointer ${labelClassName}`}
          htmlFor="enable-free-trial"
        >
          <div className="flex-grow space-y-1">
            {isFreeTrialEnabled ? (
              <h3 className="SB16">{t(data.onboarding.freeTrialEnabled)}</h3>
            ) : (
              <p className="SB16 text-black">
                {t(data.onboarding.enableFreeTrial)}
              </p>
            )}
          </div>
          <div
            role="presentation"
            className={`flex-shrink-0 w-8 h-8 border-2 border-gray-200 rounded-lg ${
              isInputFocused ? 'ring' : ''
            }`}
          >
            <img
              className={`w-full h-full transition-transform duration-100 rounded-lg ease-out ${
                isFreeTrialEnabled ? 'scale-100 bg-black' : 'scale-0'
              }`}
              {...accentCheckIcon}
              alt=""
            />
          </div>
        </label>
      </div>
      {!isDesktop && !disableEmailReminder && (
        <div
          className={`bg-section rounded-3xl border-2 !mt-3 transition-all ${
            isFreeTrialEnabled
              ? 'border-gray-100 hover:bg-overlay-white-30'
              : 'bg-gray-100 bg-opacity-50 border-transparent hidden lg:block'
          } ${className}`}
        >
          <input
            className="sr-only"
            type="checkbox"
            id="enable-email-reminder"
            checked={isReminderEnabled}
            onChange={() => {
              const newVal = !isReminderEnabled;
              track('paywall_email_reminder', newVal ? 'enabled' : 'disabled');
              setIsReminderEnabled(newVal);
            }}
            disabled={!isFreeTrialEnabled}
          />
          <label
            className={`-m-0.5 flex items-center space-s-2 py-5 px-6 ${
              isFreeTrialEnabled ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            htmlFor="enable-email-reminder"
          >
            <div className="flex-1 space-y-1">
              <h3
                className={`SB16 md:SB14 lg:SB16 transition-all ${
                  isFreeTrialEnabled
                    ? 'text-black-background'
                    : 'text-gray-400 opacity-70'
                }`}
              >
                {isReminderEnabled ? (
                  t(data.onboarding.reminderEnabled)
                ) : (
                  <>{t(data.onboarding.enableReminder)} 🔔</>
                )}
              </h3>
              <p
                className={`M14 md:M13 lg:M14 transition-colors ${
                  isFreeTrialEnabled ? 'text-gray-300' : 'text-gray-200'
                }`}
              >
                {isReminderEnabled
                  ? t(data.onboarding.willNotifyInDays)
                  : t(data.onboarding.getNotified)}
              </p>
            </div>
            <div
              role="presentation"
              className={`rounded-full transition-all ${
                isFreeTrialEnabled ? '' : '!bg-opacity-20'
              } ${
                isReminderEnabled ? 'bg-green' : 'bg-gray-300 bg-opacity-50'
              }`}
              style={{ width: 50, height: 30, padding: 3.57 }}
            >
              <div
                className="transition-all duration-100 ease-out"
                style={{ width: isReminderEnabled ? '100%' : 22.62 }}
              >
                <div
                  className="bg-white rounded-full ms-auto drop-shadow-sm active:!w-7 transition-all"
                  style={{ width: 22.62, height: 22.62 }}
                />
              </div>
            </div>
          </label>
        </div>
      )}
      {isTiersWithReminder && showReminder && (
        <div
          className={`flex-1 ps-3 absolute start-full transition-opacity duration-300 ${
            isFreeTrialEnabled
              ? 'opacity-1'
              : 'opacity-0 select-none pointer-events-none'
          }`}
          ref={reminderWrapperRef}
        >
          <input
            className="sr-only"
            type="checkbox"
            id="enable-email-reminder"
            checked={isReminderEnabled}
            onChange={() => {
              const newVal = !isReminderEnabled;
              track('paywall_email_reminder', newVal ? 'enabled' : 'disabled');
              setIsReminderEnabled(newVal);
            }}
            disabled={!isFreeTrialEnabled}
          />
          <label
            className={`bg-white shadow-white-button rounded-3xl min-w-80 w-max max-w-100 min-h-18 flex items-center space-s-2 px-6 py-2 ${
              isFreeTrialEnabled ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            htmlFor="enable-email-reminder"
          >
            <div className="flex-1 space-y-1">
              <h3 className="SB16 md:SB14 lg:SB16 transition-all text-black-background">
                {isReminderEnabled ? (
                  t(data.onboarding.reminderEnabled)
                ) : (
                  <>{t(data.onboarding.enableReminder)} 🔔</>
                )}
              </h3>
              <p className="M14 md:M13 lg:M14 transition-colors text-gray-300">
                {isReminderEnabled
                  ? t(data.onboarding.willNotifyInDays)
                  : t(data.onboarding.getNotified)}
              </p>
            </div>
            <div
              role="presentation"
              className={`rounded-full transition-all ${
                isReminderEnabled ? 'bg-green' : 'bg-gray-300 bg-opacity-50'
              }`}
              style={{ width: 50, height: 30, padding: 3.57 }}
            >
              <div
                className="transition-all duration-100 ease-out"
                style={{ width: isReminderEnabled ? '100%' : 22.62 }}
              >
                <div
                  className="bg-white rounded-full ms-auto drop-shadow-sm active:!w-7 transition-all"
                  style={{ width: 22.62, height: 22.62 }}
                />
              </div>
            </div>
          </label>
        </div>
      )}
    </div>
  );
}
