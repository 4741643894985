import { useSupportService } from '@/layout/appWrapper/ServiceProvider';

import {
  contactFormBody,
  contactFormEmail,
  contactFormLoading,
  contactFormName,
  contactFormSubject,
} from '@/stores/contactFormStore';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { customerSupportRequestTracker } from './customerSupportRequestTracker';
import useErrorToast from './errorToast';
import getBrowser from './getBrowser';
import getDeviceType from './getDeviceType';
import useSuccessToast from './successToast';

export default function useSendCustomerSupportRequest() {
  const {
    useIsRequestDataComplete,
    sendCustomerSupportRequest,
    useResetRequest,
  } = useSupportService();
  const name = useRecoilValue(contactFormName);
  const email = useRecoilValue(contactFormEmail);
  const subject = useRecoilValue(contactFormSubject);
  const body = useRecoilValue(contactFormBody);
  const source = useRouter().query.source?.slice(0, 50) as string;
  const [isLoading, setIsLoading] = useRecoilState(contactFormLoading);
  const isRequestDataComplete = useIsRequestDataComplete();
  const triggerSuccessToast = useSuccessToast();
  const triggerErrorToast = useErrorToast();
  const resetFields = useResetRequest();
  return async () => {
    if (!isRequestDataComplete || isLoading) return;
    try {
      setIsLoading(true);
      customerSupportRequestTracker.start();
      await sendCustomerSupportRequest({
        device: getDeviceType(),
        browser: getBrowser(),
        name,
        email,
        subject,
        body,
        source,
      });
      customerSupportRequestTracker.complete();
      triggerSuccessToast();
      resetFields();
    } catch (e) {
      customerSupportRequestTracker.fail(e);
      triggerErrorToast();
    }
    setIsLoading(false);
  };
}
