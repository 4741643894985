import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTracker } from '@/core/tracking';
import useEventListener from '@/core/listeners/useEventListener';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { isBusySelector } from '@/stores/appStore';
import { SecretMenuModal } from '@/features/settings/secretMenu';

export function useSecretMenu() {
  const [isSecretMenuOpen, setIsSecretMenuOpen] = useState<boolean>(false);

  const isBusy = useRecoilValue(isBusySelector);
  const { track } = useTracker();

  const keyLog = useMemo(() => Object(), []);
  const handleKeyboardPresses = useCallback(
    ({ repeat, key, type }: KeyboardEvent) => {
      if (isBusy || repeat) {
        return;
      }

      if (type === 'keydown') {
        keyLog[key] = true;
        if (
          keyLog.Meta &&
          keyLog.Shift &&
          keyLog['7'] &&
          keyLog.u &&
          !isSecretMenuOpen
        ) {
          Object.keys(keyLog).forEach((key) => delete keyLog[key]);
          setIsSecretMenuOpen(true);
          track('secret_menu', 'opened');
        }
      }

      // Remove the key from the log on keyup.
      if (type === 'keyup') delete keyLog[key];
    },
    [isBusy, keyLog, track, isSecretMenuOpen]
  );

  useEventListener(null, 'keydown', handleKeyboardPresses);
  useEventListener(null, 'keyup', handleKeyboardPresses);

  const pushModal = useSetRecoilState(pushModalSelector);
  useEffect(() => {
    if (!isSecretMenuOpen) {
      return;
    }

    pushModal({
      id: 'secret-menu',
      Modal: SecretMenuModal,
      onClose: () => setIsSecretMenuOpen(false),
    });
  }, [isSecretMenuOpen, pushModal]);
}
