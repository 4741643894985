import { atom } from 'recoil';

export const contactFormName = atom({
  key: 'contactForm:name',
  default: '',
});
export const contactFormEmail = atom({
  key: 'contactForm:email',
  default: '',
});
export const contactFormSubject = atom({
  key: 'contactForm:subject',
  default: '',
});

export const contactFormBody = atom({
  key: 'contactForm:body',
  default: '',
});

export const contactFormLoading = atom({
  key: 'contactForm:loading',
  default: false,
});
