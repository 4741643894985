import { useLocalization } from '@/services/localizationService';

type PaymentRatioProps = {
  price: string;
  hasCredits: boolean;
  creditCount?: number | null;
  className: string;
  hasIntroPrice: boolean;
  firstSpanClassName: string;
  isWebOnboarding?: boolean;
};

export default function PaymentRatio({
  price,
  className,
  firstSpanClassName,
  hasCredits,
  hasIntroPrice,
  creditCount = null,
  isWebOnboarding = false,
}: PaymentRatioProps) {
  const { t, data } = useLocalization();

  return (
    <>
      {hasIntroPrice || isWebOnboarding ? (
        <div className="flex">
          <p className="SB25 text-gray-400 line-through ">
            {price.split('/')[0]}
          </p>
          {hasIntroPrice && isWebOnboarding && (
            <p className="SB25 text-black ms-1">$0.99</p>
          )}
          {hasIntroPrice && !isWebOnboarding && (
            <p className="SB25 text-black ms-1">
              {t(data.onboarding.daysIntro)}
            </p>
          )}
          {!hasIntroPrice && isWebOnboarding && (
            <p className="SB25 text-black ms-1">$0</p>
          )}
        </div>
      ) : (
        price.split('/').map((text, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i} className={i === 0 ? firstSpanClassName : className}>
            {i ? (hasCredits ? `/x${creditCount} ` : '/') : ''}
            {text}
          </span>
        ))
      )}
    </>
  );
}
