import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import * as Sentry from '@sentry/nextjs';
import { getAuth, signInWithPopup } from 'firebase/auth';
import { useTracker } from '@/core/tracking';
import { useLoginCallbacks, loginState } from '@/features/account/login';
import { FirebaseLoginProvider } from '@/features/account/login/firebaseLoginProvider';
import LinkWithQuery from '@/ui/content/LinkWithQuery';
import { useLocalization } from '@/services/localizationService';

import facebookBrand from '@/public/images/brands/facebook.svg';
import googleBrand from '@/public/images/brands/google.svg';
import appleBrand from '@/public/images/brands/apple.svg';
import { pushModalSelector } from '@/stores/modalStore';
import {
  trackCanceledEvent,
  trackCompletedEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '@/core/monitoring/trackEvents';
import { FirebaseError } from 'firebase/app';
import useLogin from './useLogin';
import {
  isPerformingLoginSelector,
  providerNameSelector,
} from '../../../stores/loginStore';
import LoginProviderButton from './LoginProviderButton';
import useAccountLinking from './useAccountLinking';

export default function LoginForm({ onSubmit }: { onSubmit: () => void }) {
  const [isPerformingLogin, setIsPerformingLogin] = useRecoilState(
    isPerformingLoginSelector
  );
  const [selectedProviderName, setSelectedProviderName] =
    useRecoilState(providerNameSelector);
  const { t, data } = useLocalization();

  const [login] = useLoginCallbacks();
  const { track, setGlobalParams } = useTracker();

  const resetProviderName = useResetRecoilState(
    loginState.providerNameSelector
  );

  const handleAccountLinking = useAccountLinking();

  const { performLogin } = useLogin();
  const pushModal = useSetRecoilState(pushModalSelector);

  const onLoginProviderClick = useCallback(
    async (provider: FirebaseLoginProvider) => {
      if (
        !FirebaseLoginProvider.allValues
          .map((provider) => provider.name)
          .includes(provider.name)
      ) {
        throw new Error(`Provider not found: ${provider.name}`);
      }

      setSelectedProviderName(provider.name);
      setIsPerformingLogin(true);
      track('login', 'started', { actionValue: provider.name });

      const providerName = provider.name; // store selection. Perform login may reset provider name.
      trackStartedEvent(['login'], { provider: providerName, oneTap: false });

      try {
        const auth = getAuth();

        await signInWithPopup(auth, provider.externalProvider);
        await performLogin(
          auth,
          pushModal,
          setGlobalParams,
          track,
          provider.name,
          resetProviderName,
          login
        );
        trackCompletedEvent(['login'], {
          provider: providerName,
          oneTap: false,
        });

        setIsPerformingLogin(false);

        onSubmit();
      } catch (e) {
        if (handleAccountLinking(e)) return;
        if (
          e instanceof FirebaseError &&
          e.code === 'auth/popup-closed-by-user'
        ) {
          trackCanceledEvent(['login'], {
            provider: providerName,
            oneTap: false,
          });
        } else {
          trackFailedEvent(['login'], {
            provider: providerName,
            oneTap: false,
            error: e instanceof FirebaseError ? e.code : e,
          });
          Sentry.captureException(e);
        }
      }
      // Cleanup performing login, on both success or error
      resetProviderName();
      setIsPerformingLogin(false);
    },
    [
      setSelectedProviderName,
      setIsPerformingLogin,
      track,
      onSubmit,
      login,
      performLogin,
      pushModal,
      resetProviderName,
      setGlobalParams,
    ]
  );

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <h2 className="SB25">{t(data.header.logInSignUp)}</h2>
        <p className="M18">{t(data.onboarding.signInTo)}</p>
      </div>
      <div className="space-y-6">
        <div className="space-y-4">
          <LoginProviderButton
            data-cy="login-provider-btn"
            className="bg-white"
            icon={facebookBrand}
            isLoading={isPerformingLogin}
            isSelected={
              selectedProviderName === FirebaseLoginProvider.FACEBOOK.name
            }
            name="Facebook"
            onClick={() => onLoginProviderClick(FirebaseLoginProvider.FACEBOOK)}
          />
          <LoginProviderButton
            data-cy="login-provider-btn"
            className="bg-white"
            icon={googleBrand}
            isLoading={isPerformingLogin}
            isSelected={
              selectedProviderName === FirebaseLoginProvider.GOOGLE.name
            }
            name="Google"
            onClick={() => onLoginProviderClick(FirebaseLoginProvider.GOOGLE)}
          />
          <LoginProviderButton
            data-cy="login-provider-btn"
            className="bg-white"
            icon={appleBrand}
            isLoading={isPerformingLogin}
            isSelected={
              selectedProviderName === FirebaseLoginProvider.APPLE.name
            }
            name="Apple"
            onClick={() => onLoginProviderClick(FirebaseLoginProvider.APPLE)}
          />
        </div>
        <p className="M12 leading-4">
          {t(data.common.byContinuingAcceptTosAndPrivacy, {
            tos: (text) => (
              <a
                className="contrast-high"
                href="https://bendingspoons.com/tos.html?app=1470373330"
                target="_blank"
                rel="noreferrer nofollow"
              >
                {text}
              </a>
            ),
            privacy: (text) => (
              <LinkWithQuery path="/privacy-and-cookie-policy">
                <a className="contrast-high" target="_blank">
                  {text}
                </a>
              </LinkWithQuery>
            ),
          })}
        </p>
      </div>
    </div>
  );
}
