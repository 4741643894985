import { InternalConfiguration } from '@/core/tracking/pico/config';
import { AnonymousNetworkRequest } from '../privacy/anonymizeRequest';
import { NetworkRequest } from './networkAdapter';

export type NetworkClient = {
  send: (req: NetworkRequest | AnonymousNetworkRequest) => Promise<object>;
};

export const createNetworkClient = (
  config: InternalConfiguration
): NetworkClient => ({
  async send(request) {
    if (config.mockRequest) {
      console.log(request);
      return {};
    }
    const response = await fetch(request.url, {
      method: 'POST',
      body: JSON.stringify(request.body),
      headers: request.headers,
    });
    return response;
  },
});
