export default function Check({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.8142L5.06432 12.8785L6.47853 11.4643L10 14.9858L17.75 7.23574L19.1643 8.64996L10 17.8142Z"
      />
    </svg>
  );
}
