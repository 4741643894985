import { v4 as uuidv4 } from 'uuid';
import { secondTimestamp } from '@/core/monitoring/spidersense/src/helpers';

export type EventMetadata = {
  id: string;
  createdAt: number;
};

export type EventMetadataProvider = {
  getEventMetadata: () => EventMetadata;
};

export const createEventMetadataProvider = (): EventMetadataProvider => ({
  getEventMetadata(): EventMetadata {
    return {
      id: uuidv4(),
      createdAt: secondTimestamp(),
    };
  },
});
