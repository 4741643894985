import { Severity } from '@/core/monitoring/spidersense/src/event';
import { SpiderSenseClient } from '../spidersense';

export const generateMonitoringAPI = (client: SpiderSenseClient) => {
  const logPicoEventSent = (actionKind: string) =>
    client.trackDebugEvent({
      severity: Severity.INFO,
      categories: ['tracking', 'pico'],
      description: 'Event has been sent to Pico',
      info: { action_kind: actionKind },
    });

  const logTrackingMonitoringMultipleActivations = () =>
    client.trackDebugEvent({
      severity: Severity.ERROR,
      categories: ['tracking', 'pico', 'monitoring'],
      description: 'Tracking monitoring activation requested multiple times!',
      info: {},
    });

  const logCookieBannerElementsMissing = () =>
    client.trackDebugEvent({
      severity: Severity.WARNING,
      categories: ['privacy', 'cookie'],
      description: 'Cookie banner elements are not found',
      info: {},
    });

  return {
    logPicoEventSent,
    logTrackingMonitoringMultipleActivations,
    logCookieBannerElementsMissing,
    /**
     * TODO: refactor this to make it globally available in some other way
     *  - Just for the record: we didn't like this, but returning it here was the fastest way to make it globally available,
     *    and at the same time be sure to not break anything else. Thank you for your understanding :)
     */
    client,
  };
};
