export enum DeviceIds {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export function deviceType(): DeviceIds {
  if (typeof navigator === 'undefined') return DeviceIds.Desktop;
  const ua = navigator.userAgent;

  return deviceTypeByUserAgent(ua);
}

export function deviceTypeByUserAgent(ua: string): DeviceIds {
  switch (true) {
    case /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua):
      return DeviceIds.Tablet;
    case /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    ):
      return DeviceIds.Mobile;
    default:
      return DeviceIds.Desktop;
  }
}
