import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '@/services/serviceGetter';

export type ShareService = {
  getShareUrlForTask: (taskId: string) => Promise<string>;
};

export const createShareService = ({ post }: HttpService): ShareService => {
  const getShareUrlForTask = async (taskId: string): Promise<string> => {
    const result: { url: string } = await post(`/v1/web/tasks/${taskId}/share`);
    return result.url;
  };

  return { getShareUrlForTask };
};

export default serviceSingletonGetter(
  Symbol('shareService'),
  createShareService
);
