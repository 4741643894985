import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import settingsState from '@/stores/settingsStore';
import { useUser } from '../userService/useUser';
import { PaymentDetails, VideoLimits } from '../settingsService';
import { PaymentStrategiesIds } from '../paymentService/paymentTypes';
import { Interval, PricePeriod } from '../priceService/priceTypes';
import { useUserPrice } from '../priceService/useUserPrice';

export type PlanLimits = { exports?: number | null; videoLimits: VideoLimits };

// as function to keep object safe from edits
export function getDefaultVideoLimits(): VideoLimits {
  return {
    maxLength: 0,
    maxSizeMb: 0,
    maxPeriodLength: 0,
    maxPeriodSizeMb: 0,
  };
}

// as function to keep object safe from edits
export function getDefaultPlanLimits(): PlanLimits {
  return {
    exports: 0,
    videoLimits: getDefaultVideoLimits(),
  };
}

export function useEnhanceLimitService() {
  const { data: user } = useUser();
  const userPrice = useUserPrice();
  const settings = useRecoilValue(settingsState);

  const getSubscriptionPlanLimits = useCallback(
    (userType: PaymentStrategiesIds, period: Interval) => {
      const { paymentStrategies } = settings.values || {};

      const userTypeId = userType?.toLowerCase(); // convert to lowercase form, settings are using it
      const periodPrice: PricePeriod | undefined = (() => {
        switch (period) {
          case 'week':
            return 'weekly';
          case 'month':
            return 'monthly';
          case 'year':
            return 'yearly';
          case 'oneTime':
            return 'singleExport';
          default:
            return undefined;
        }
      })();

      if (!paymentStrategies || !userTypeId || !periodPrice) {
        return getDefaultPlanLimits();
      }

      // check in settings
      // NB: here we are loosing capacity to detect type definition.
      // We should unify the object values somehow.
      // PaymentStrategy is resolved each time as any object
      const paymentStrategy = paymentStrategies[userTypeId];
      const paymentPlan = paymentStrategy?.[periodPrice] as
        | PaymentDetails
        | undefined;
      if (paymentPlan) {
        // * return cloned object (videoLimits, if set, are in a settings state that should be mutate)
        return {
          exports: paymentPlan?.exports, // exports are just a number. If not present that means unlimited (find another way to identify it?!)
          videoLimits: {
            // video limits must always be padded with the default one. If no video limits detected that implies user cannot upload video.
            ...getDefaultVideoLimits(),
            ...(paymentPlan.videoLimits ?? {}),
          },
        };
      }
      return getDefaultPlanLimits();
    },
    [settings.values]
  );

  const getUserPlanLimits = useCallback(() => {
    if (!user) return getDefaultPlanLimits();

    if (user.isFree || user.giftCode?.isActive) {
      // we should behave like a business yearly plan
      return getSubscriptionPlanLimits(PaymentStrategiesIds.Business, 'year');
    }

    if (
      user.monetization &&
      user.monetization.isSubscribed &&
      user.monetization.subscriptionProvider === 'mobile'
    ) {
      return getSubscriptionPlanLimits(PaymentStrategiesIds.Business, 'year');
    }

    const { paymentStrategy } = user;
    const { recurringInterval } = userPrice ?? {};
    if (!paymentStrategy || !recurringInterval) return getDefaultPlanLimits();
    return getSubscriptionPlanLimits(paymentStrategy, recurringInterval);
  }, [user, userPrice, getSubscriptionPlanLimits]);

  const currentUserPlanLimits: Readonly<PlanLimits> = useMemo(
    () => getUserPlanLimits(),
    [getUserPlanLimits]
  );

  return {
    currentUserPlanLimits,
    getUserPlanLimits,
    getSubscriptionPlanLimits,
  };
}
