import { useLocalization } from '@/services/localizationService';
import { FREE_TRIAL_DAYS } from '@/services/paymentService';
import {
  isFreeTrialEnabledState,
  isReminderEnabledState,
} from '@/stores/onboardingStore';
import { useRecoilState, useSetRecoilState } from 'recoil';
import FreeTrialButton from '../FreeTrialButton';

interface Props {
  title: JSX.Element;
  subtitle: JSX.Element;
  price: string;
  billingPeriod: JSX.Element | string;
  badgeText?: JSX.Element | null;
  hasFreeTrial?: boolean;
  hasIntroPrice?: boolean;
  showGradientBorder?: boolean;
  onPaymentSelected: () => void;
}

export default function HighlightedPaymentCard({
  title,
  subtitle,
  price,
  billingPeriod,
  badgeText,
  hasFreeTrial,
  hasIntroPrice,
  showGradientBorder = false,
  onPaymentSelected,
}: Props) {
  const { t, data } = useLocalization();

  const [isFreeTrialEnabled, setIsFreeTrialEnabled] = useRecoilState(
    isFreeTrialEnabledState
  );
  const setIsReminderEnabled = useSetRecoilState(isReminderEnabledState);

  return (
    <div
      className={`rounded-[20px] w-[306px] p-[2px] ${
        showGradientBorder
          ? 'bg-gradient-to-br from-[#FF3A5C] via-[#EDEBF3] to-[#EDEBF3]'
          : 'bg-[#EDEBF3]'
      }`}
    >
      <div className="relative flex flex-col justify-between h-full bg-white rounded-[18px] p-6 pt-4">
        {badgeText && (
          <div className="absolute top-[-12px] start-[24px] bg-primary-accent font-bold text-white text-[8px] py-[4px] px-[8px] rounded-[6px] uppercase">
            {badgeText}
          </div>
        )}
        <h1 className="text-[28px] font-semibold">{title}</h1>
        <p className="M14 text-black mt-2">{subtitle}</p>
        {hasFreeTrial && (
          <div className="my-5 lg:mx-auto lg:w-content">
            <FreeTrialButton
              isFreeTrialEnabled={isFreeTrialEnabled}
              setIsFreeTrialEnabled={setIsFreeTrialEnabled}
              isReminderEnabled={isFreeTrialEnabled}
              setIsReminderEnabled={setIsReminderEnabled}
              labelClassName="!h-auto"
              showReminder={false}
              className="w-[240px]"
            />
          </div>
        )}
        {!hasFreeTrial && !hasIntroPrice && <hr className="my-6" />}
        {isFreeTrialEnabled && (
          <p className="mb-2 B12 text-primary-accent py-[6px] px-3 bg-primary-accent-150 bg-opacity-50 rounded-full w-content">
            🎁{' '}
            {t(
              data.onboarding.daysFreeAlt,
              { n: FREE_TRIAL_DAYS },
              'uppercase'
            )}
          </p>
        )}
        {hasIntroPrice && (
          <>
            <p className="mt-6 mb-5 B14 text-primary-accent py-[6px] px-3 bg-primary-accent-150 bg-opacity-50 rounded-lg w-content">
              🎁 {t(data.onboarding.limitedOffer, {}, 'uppercase')}
            </p>
            <div className="flex">
              <p className="SB20 text-gray-400 line-through">
                {price.split('/')[0]}
              </p>
              <p className="SB20 text-black ms-1">
                {t(data.onboarding.daysIntro)}
              </p>
            </div>
          </>
        )}
        {!hasIntroPrice && (
          <div className="flex">
            <p className="SB20 text-black">{price.split('/')[0]}</p>
            <p className="SB20 text-gray-300 lowercase">/{billingPeriod}</p>
          </div>
        )}
        <button
          type="button"
          className="btn btn--large btn--black mt-5 cursor-pointer font-semibold text-[14px] p-2"
          onClick={onPaymentSelected}
        >
          {t(data.common.letsGo)}
        </button>
        {hasIntroPrice && (
          <p className="mt-6 mb-2 R14 w-content text-gray-300">
            {t(data.onboarding.limitedOfferExplanation, {
              price: price.split('/')[0],
              period: billingPeriod,
            })}
          </p>
        )}
      </div>
    </div>
  );
}
