import { v4 as uuidv4 } from 'uuid';
import Router from 'next/router';

export async function generateViralityParam() {
  if (typeof window !== 'undefined') {
    return new Promise((res) => {
      const interval = setInterval(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const param = urlSearchParams.get('v');
        if (param) return;
        const v = `${uuidv4()}-${Date.now()}`;
        if (Router.router) {
          Router.replace(
            { pathname: Router.pathname, query: { ...Router.query, v } },
            undefined,
            {
              shallow: true,
              scroll: false,
            }
          );
          clearInterval(interval);
          res(v);
        }
      }, 400);
    });
  }
}
