import LinkWithQuery from '@/ui/content/LinkWithQuery';
import { CloseIcon } from '@/ui/icons';
import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ModalWrapper } from '@/core/modal';
import { useTracker } from '@/core/tracking';
import { useConsentService } from '@/layout/appWrapper/ServiceProvider';
import emailGathering from '@/public/images/showcase/email-gathering.webp';
import { useLocalization } from '@/services/localizationService';
import { isBusySelector } from '@/stores/appStore';
import { closeModalSelector } from '@/stores/modalStore';
import { ModalWrapperProps } from '@/core/modal/ModalWrapper';
import { ConsentEvent } from './modalsTypes';

interface MarketingConsentModalProps extends ModalWrapperProps {
  id: string;
}

export default function MarketingConsentModal({
  id,
  ...rest
}: MarketingConsentModalProps) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const isBusy = useRecoilValue(isBusySelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { track } = useTracker();
  const { t, data } = useLocalization();

  const focusFirstElement = () => closeButtonRef.current?.focus();
  const { grantMarketingConsent } = useConsentService();
  useEffect(focusFirstElement, []);

  useEffect(() => {
    track(ConsentEvent.Marketing, 'shown');
    return () => track(ConsentEvent.Marketing, 'closed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper
      className="text-center relative w-76"
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      <button
        type="button"
        className="absolute top-5 block text-gray-500 z-10"
        ref={closeButtonRef}
        disabled={isBusy}
        onClick={() => closeModal(id)}
      >
        <CloseIcon />
      </button>
      <div className="-me-5 -mt-4">
        <img src={emailGathering.src} alt="" />
      </div>
      <p className="SB20 text-black">{t(data.account.marketingConsentTitle)}</p>
      <button
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6"
        disabled={isBusy}
        onClick={() => {
          grantMarketingConsent(
            window.location.origin + window.location.pathname
          );
          track(ConsentEvent.Marketing, 'accepted');
          closeModal(id);
        }}
      >
        {t(data.common.yesLetsDoIt)}
      </button>
      <p className="M13 text-black mt-6">
        {t(data.account.marketingConsentLegal, {
          button: <>&quot;{t(data.common.yesLetsDoIt)}&quot;</>,
          link: (
            <u>
              <LinkWithQuery path="/privacy-and-cookie-policy">
                <a target="_blank" rel="noreferrer">
                  {t(data.common.privacyCookiePolicy)}
                </a>
              </LinkWithQuery>
            </u>
          ),
        })}
      </p>
    </ModalWrapper>
  );
}
