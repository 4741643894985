import onlyOnceRunner from '@/core/concurrency/onlyOnceRunner';
import { useAuth } from '@/core/http';
import { logAppVersion } from '@/core/log/logAppVersion';
import { removeRecoilWarnings } from '@/core/log/removeRecoilWarnings';
import { useMonitoringInfo } from '@/core/monitoring';
import { trackEvent } from '@/core/monitoring/trackEvents';
import { usePrivacyConsentEventsListener } from '@/core/privacy/usePrivacyConsentEventsListener';
import { useSyncUserWithPico } from '@/core/tracking/useSyncUserWithPico';
import { useSecretMenu } from '@/features/settings/secretMenu/useSecretMenu';
import { useSettings } from '@/features/settings/useSettings';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useUserService } from './ServiceProvider';

removeRecoilWarnings();

const trackSetupStart = onlyOnceRunner((metadata: { route: string }) =>
  trackEvent(['setup', 'start'], metadata)
);

export const useAppWrapper = () => {
  const { route } = useRouter();
  trackSetupStart({ route });
  useAuth();
  useSyncUserWithPico();
  useMonitoringInfo();
  const { removeUserCache } = useUserService();
  useSettings();
  useSecretMenu();
  usePrivacyConsentEventsListener();
  useEffect(() => {
    logAppVersion();
    removeUserCache();
  }, [removeUserCache]);
};
