import { useSubscriptionUpgradePrompts } from '@/features/upgradeSubscription/useSubscriptionUpgradePrompts';
import UploadMediaErrorModal from '@/features/uploadMedia/UploadMediaError';
import {
  useMediaService,
  useUploaderService,
} from '@/layout/appWrapper/ServiceProvider';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { SetterOrUpdater } from 'recoil';
import { AiPipelineChoices } from '@/services/settingsService/aiTypes';
import {
  MaxFileQuantityForUpload,
  TaskError,
  TaskErrorType,
  TaskStatus,
  TaskType,
} from './uploaderConstants';
import {
  BulkUploadTask,
  PostBulkUpload,
  TaskInfo,
  TaskMimeType,
  TaskResult,
  TaskState,
  VideoTaskMimeType,
  allMediaTaskMimeType,
} from './uploaderTypes';

export type SetTaskCallback = (taskInfo: TaskInfo) => void;

export const useUploader = (
  setTask?: SetTaskCallback | SetterOrUpdater<TaskInfo | TaskState>,
  pushModal?: any,
  mainActionRef?: any
) => {
  const { showUpgradeCTAOnSingleVideoError } = useSubscriptionUpgradePrompts();
  const {
    getMediaType,
    getTaskResult,
    isTaskCompleted,
    getBulkUpload,
    processBulkUpload,
    getFirstCompletedTask,
    uploadMedia,
    isMaxMediaQuantityReached,
    isVideoTaskInFileList,
  } = useUploaderService();

  const router = useRouter();
  const { taskId } = router.query;

  const { useMedia } = useMediaService();
  const { getMediaAndUpdateStore } = useMedia();

  const setTaskInfo = useCallback(
    (info: TaskInfo) => setTask!(info),
    [setTask]
  );

  const setTaskInfoFromFiles = useCallback(
    (files: FileList) => {
      if (files.length === 0) {
        return setTaskInfo({
          status: TaskStatus.Invalid,
          reason: TaskError.TooFewFiles,
        });
      }

      if (
        !/^image\/.*/.test(files[0].type) &&
        !/^video\/.*/.test(files[0].type)
      ) {
        return setTaskInfo({
          status: TaskStatus.Invalid,
          reason: TaskError.WrongFileFormat,
        });
      }
    },
    [setTaskInfo]
  );

  const handleFileUploadErrors = useCallback(
    ({
      errorMessage,
      errorType,
      mediaSize,
      mediaDuration,
      track,
      fileNumber,
      filesFormats,
      isPersonalUser,
    }: {
      errorMessage: TaskError;
      errorType: TaskErrorType;
      mediaSize?: number;
      mediaDuration?: number;
      track: (category, action, params) => void;
      fileNumber: number;
      filesFormats?: string[];
      isPersonalUser?: boolean;
    }) => {
      setTask!({ status: TaskStatus.Pristine });

      if (
        isPersonalUser &&
        (errorType === TaskErrorType.VideoDuration ||
          errorType === TaskErrorType.VideoSize)
      ) {
        showUpgradeCTAOnSingleVideoError(
          errorType === TaskErrorType.VideoDuration
        );
      } else {
        pushModal({
          id: 'task:uploadError',
          Modal: UploadMediaErrorModal,
          error: errorMessage,
          mediaSize,
          mediaDuration,
          maxFileQuantityLeftForUpload: MaxFileQuantityForUpload,
          onClose: () => mainActionRef.current?.focus(),
        });
      }

      if (mediaSize || mediaDuration) {
        track('video_enhance_limit', 'error', { errorType });
      } else if (errorType === TaskErrorType.TooManyFiles) {
        track('bulk_upload_limit', 'error', { errorType, fileNumber });
      } else {
        track('bulk_upload_format', 'error', { errorType, filesFormats });
      }
    },
    [mainActionRef, pushModal, setTask, showUpgradeCTAOnSingleVideoError]
  );

  const onProcessingTask = useCallback(
    async ({
      taskId,
      isVideoTask,
      trigger,
      actionType,
      aiPipeline,
      track,
      showProgress = true,
      setMedia,
    }: {
      taskId: string;
      isVideoTask: boolean;
      trigger: string;
      actionType: TaskStatus.Reprocessing | TaskStatus.Processing;
      aiPipeline?: AiPipelineChoices;
      track: CallableFunction;
      showProgress?: boolean;
      setMedia: (media: TaskResult) => void;
      media: TaskResult;
    }) => {
      if (showProgress) {
        setTaskInfo({
          id: taskId,
          status: TaskStatus.Processing,
        });
      }

      let completed = false;
      const mediaType = getMediaType(isVideoTask);

      track(actionType, TaskStatus.Started, {
        actionValue: trigger,
        mediaType,
      });

      while (!completed) {
        await new Promise((resolve) => {
          window.setTimeout(resolve, 2000);
        });

        const task = await getTaskResult({
          taskId,
          isVideo: isVideoTask,
        });

        setMedia({ ...task, mediaType });

        completed = await isTaskCompleted((task as TaskResult).status!, taskId);
        if (showProgress) {
          setTaskInfo({ id: taskId, status: (task as TaskResult).status });
        }
      }

      track(actionType, TaskStatus.Completed, {
        actionValue: trigger,
        mediaType: getMediaType(isVideoTask),
        aiPipeline,
      });
    },
    [getMediaType, getTaskResult, isTaskCompleted, setTaskInfo]
  );

  const onProcessingBulkUpload = useCallback(
    async ({
      bulkUploadId,
      trigger,
      actionType,
      track,
      showProgress,
      isReprocess = false,
    }: {
      bulkUploadId: string;
      trigger: string;
      actionType: TaskStatus.Reprocessing | TaskStatus.Processing;
      track: (category, action, params) => void;
      showProgress: boolean;
      isReprocess: boolean;
    }): Promise<BulkUploadTask[]> => {
      let taskList: BulkUploadTask[] = [];

      if (showProgress) {
        setTaskInfo({
          id: bulkUploadId,
          status: TaskStatus.Processing,
        });
      }

      let isBulkProcessingCompleted = false;

      track(actionType, TaskStatus.Started, {
        actionValue: trigger,
        mediaType: TaskType.Image,
      });

      while (!isBulkProcessingCompleted) {
        await new Promise((resolve) => {
          window.setTimeout(resolve, 2000);
        });

        const bulk = await getBulkUpload({
          bulkUploadId,
        });

        const bulkStatus = bulk.taskList.every(
          (t) =>
            t.status === TaskStatus.Completed ||
            t.status === TaskStatus.Failed ||
            t.status === TaskStatus.Exported
        )
          ? TaskStatus.Completed
          : TaskStatus.Processing;

        isBulkProcessingCompleted = bulkStatus === TaskStatus.Completed;

        const firstCompletedTaskFound = getFirstCompletedTask(bulk);

        if (
          (firstCompletedTaskFound.task.taskId && !isReprocess) ||
          (isReprocess && isBulkProcessingCompleted)
        ) {
          taskList = bulk.taskList;
          if (taskId) {
            await getMediaAndUpdateStore();
          }
          if (showProgress) {
            setTaskInfo({
              id: bulkUploadId,
              status: bulkStatus,
            });
          }

          track(actionType, TaskStatus.Completed, {
            actionValue: trigger,
            mediaType: TaskType.Image,
          });
          return taskList;
        }

        if (showProgress) {
          setTaskInfo({
            id: bulkUploadId,
            status: bulkStatus,
          });
        }
      }

      track(actionType, TaskStatus.Completed, {
        actionValue: trigger,
        mediaType: TaskType.Image,
      });

      return taskList;
    },
    [
      getBulkUpload,
      getFirstCompletedTask,
      getMediaAndUpdateStore,
      setTaskInfo,
      taskId,
    ]
  );

  const bulkUploadProcessingCompletion = useCallback(
    async ({
      bulkUploadId,
      trigger,
      taskId,
      taskStatus,
      track,
      showProgress,
      isReprocess = false,
    }: {
      bulkUploadId: string;
      trigger: string;
      taskId: string;
      taskStatus: TaskStatus;
      track: (category, action, event) => void;
      showProgress: boolean;
      isReprocess: boolean;
    }): Promise<TaskResult> => {
      const taskList = await onProcessingBulkUpload({
        bulkUploadId,
        trigger,
        actionType: isReprocess
          ? TaskStatus.Reprocessing
          : TaskStatus.Processing,
        track,
        showProgress,
        isReprocess,
      });

      let currTaskId = taskId;
      if (taskList.length > 0) {
        currTaskId = bulkUploadId;
        if (showProgress) {
          setTask!({ id: currTaskId, status: TaskStatus.Completed });
        }
      } else {
        setTask!({ id: currTaskId, status: taskStatus });
      }

      return { bulkUploadId: currTaskId, taskList };
    },
    [onProcessingBulkUpload, setTask]
  );

  const onBulkUploadComplete = useCallback(
    async ({
      fileArray,
      bulkUpload,
      track,
      taskId,
      showProgress,
    }: {
      fileArray: File[];
      bulkUpload: PostBulkUpload;
      track: (category, action, params) => void;
      taskId: string;
      showProgress: boolean;
    }): Promise<TaskResult | undefined> => {
      const bulkPromises = await uploadMedia({
        setTaskInfo,
        file: fileArray,
        bulkUpload,
      });

      const bulkPromisesStatus = await Promise.all(bulkPromises!);

      const isBulkUploadUploadingComplete = bulkPromisesStatus.every(
        (p) => p === 'success'
      );
      if (isBulkUploadUploadingComplete) {
        track('upload', TaskStatus.Completed, {
          actionValue: 'addPhotos',
          mediaType: TaskType.Image,
        });

        await processBulkUpload({
          bulkUploadId: bulkUpload.bulkUploadId,
        });

        return bulkUploadProcessingCompletion({
          bulkUploadId: bulkUpload.bulkUploadId,
          trigger: 'addPhotos',
          taskId: taskId as string,
          taskStatus: TaskStatus.Processing,
          track,
          showProgress,
          isReprocess: false,
        });
      }
    },
    [
      bulkUploadProcessingCompletion,
      processBulkUpload,
      setTaskInfo,
      uploadMedia,
    ]
  );

  const handleBulkUploadErrors = useCallback(
    ({
      files,
      track,
    }: {
      files: FileList;
      track: (category, action, params) => void;
    }) => {
      const tooManyFiles = isMaxMediaQuantityReached(files.length);
      const fileListContainsVideoFile =
        files.length > 1 && isVideoTaskInFileList(files);

      const filesFormats = Object.values(files).map((f) => f.type);

      const areFileListFormatsValid = filesFormats.some((format) =>
        Object.values(allMediaTaskMimeType).includes(
          format as TaskMimeType | VideoTaskMimeType
        )
      );

      let error: { message?: TaskError; type?: TaskErrorType } = {};

      if (tooManyFiles) {
        error = {
          message: TaskError.TooManyFiles,
          type: TaskErrorType.TooManyFiles,
        };
      }
      if (fileListContainsVideoFile || !areFileListFormatsValid) {
        error = {
          message: TaskError.WrongFileFormat,
          type: TaskErrorType.WrongFileFormat,
        };
      }

      if (error.type && error.message) {
        handleFileUploadErrors({
          errorMessage: error.message,
          errorType: error.type,
          track,
          fileNumber: files.length,
          filesFormats,
        });
      }

      return {
        shouldPromptFileErrorPopup: !!error.type,
      };
    },
    [handleFileUploadErrors, isMaxMediaQuantityReached, isVideoTaskInFileList]
  );

  return {
    setTaskInfo,
    setTaskInfoFromFiles,
    handleFileUploadErrors,
    onProcessingTask,
    onProcessingBulkUpload,
    bulkUploadProcessingCompletion,
    onBulkUploadComplete,
    handleBulkUploadErrors,
  };
};
