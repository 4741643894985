import {
  SpiderSenseDebugEventWithMetadata,
  EventSender,
} from '@/core/monitoring/spidersense/src/event';

type CreateEventBufferProps = {
  eventSender: EventSender;
  bufferDuration?: number;
};

export type EventBuffer = {
  enqueueEvent: (a: SpiderSenseDebugEventWithMetadata) => void;
  startFlushing: () => void;
  endFlushing: () => void;
};

export const createEventBuffer = ({
  eventSender,
  bufferDuration = 1000,
}: CreateEventBufferProps): EventBuffer => {
  let queue: SpiderSenseDebugEventWithMetadata[] = [];
  let flushJob: NodeJS.Timer;
  const flush = () => {
    if (queue.length > 0) {
      const toSend = [...queue];
      queue = [];
      eventSender.send(toSend);
    }
  };
  return {
    enqueueEvent(event: SpiderSenseDebugEventWithMetadata) {
      queue.push(event);
    },
    startFlushing() {
      flushJob = setInterval(flush, bufferDuration);
    },
    endFlushing() {
      clearInterval(flushJob);
    },
  };
};
