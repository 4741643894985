import { BadgeVariants } from './badgeTypes';

interface Props {
  id: BadgeVariants;
  label: string | JSX.Element;
  hidden?: boolean;
  absolutePositioned?: boolean;
  className?: string;
}

export default function Badge({
  id,
  label,
  hidden,
  absolutePositioned = true,
  className,
}: Props) {
  return (
    <div
      className={`badge ${id} ${hidden ? 'opacity-0 hidden' : 'opacity-1'} ${
        absolutePositioned ? 'badge--absolute' : ''
      } ${className}`}
    >
      <p>{label}</p>
    </div>
  );
}
