import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { ErrorAlert } from '@/ui/content';
import { ModalWrapper } from '@/core/modal';
import { closeModalSelector } from '@/stores/modalStore';
import { useLocalization } from '@/services/localizationService';
import useContactFormUrl from '@/services/supportService/useContactFormUrl';

export default function ErrorModal({
  /* eventId, */ error,
  id,
  ...rest
}: {
  error: Error;
  id: string;
}) {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();
  const contactFormUrl = useContactFormUrl({
    source: 'payment_error_alert',
  });

  const focusFirstElement = () => linkRef.current?.focus();

  useEffect(focusFirstElement, []);

  return (
    <ModalWrapper
      className="p-8 px-6 md:p-12 section-foreground rounded-3xl"
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      <ErrorAlert title={data.common.somethingWentWrong}>
        <div className="space-y-4">
          <div className="space-y-3">
            <p className="M18">{error.message}</p>
            <p>
              {t(data.common.ifIssuePersists, {
                accent: (text) => (
                  <a
                    className="text-primary-accent underline"
                    href={contactFormUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {text}
                  </a>
                ),
              })}{' '}
              {t(data.common.includeError)}
            </p>
          </div>
          <button
            type="button"
            className="btn btn--large btn--primary mt-4 mx-auto"
            onClick={() => closeModal(id)}
          >
            {t(data.common.retry)}
          </button>
        </div>
      </ErrorAlert>
    </ModalWrapper>
  );
}
