export const PROVIDERS_DATA = {
  paypal: {
    label: 'Paypal',
    value: 'paypal',
    id: 'paypal',
  },
  googlePay: {
    label: 'Google Pay',
    value: 'GOOGLE_PAY',
    id: 'google',
  },
  applePay: {
    label: 'Apple Pay',
    value: 'APPLE_PAY',
    id: 'apple',
  },
} as const;

export const STRIPE_ACCOUNT_ID = 'DK' as const;

export const FREE_TRIAL_DAYS = 7 as const;

export const STRIPE_APPEARANCE = {
  theme: 'none',
  variables: {
    fontSizeBase: '16px',
    fontFamily: 'system-ui, sans-serif',
  },
  rules: {
    '.CheckboxInput': {
      border: '1.5px solid #DCDCDC',
    },
    '.Tab': {
      backgroundColor: 'white',
      border: '2px solid #DCDCDC',
      borderRadius: '0.75rem',
    },
    '.Input': {
      backgroundColor: 'white',
      border: '2px solid #DCDCDC',
      borderRadius: '0.75rem',
    },
    '.Input::placeholder': {
      color: '#6D6D6D',
    },
  },
} as const;
