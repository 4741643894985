import { DeviceIds } from '@/core/environment/deviceType';
import { HttpService } from '@/core/http/httpService';
import { fromSnakeCaseToCamelCaseRecursive } from '@/core/objectManipulation/objectManipulation';
import { serviceSingletonGetter } from '@/services/serviceGetter';
import { Experiment } from './experimentsTypes';
import { IdentityResponse, PaymentStrategies, Settings } from './settingsTypes';

export type SettingsService = {
  getDefaultSettings: () => Settings;
  retrieveSettings: (
    localStorageId: string,
    deviceType: DeviceIds,
    language?: string
  ) => Promise<IdentityResponse>;
  checkSecretMenuPassword: (password: string) => Promise<void>;
  retrieveExperiments: (
    password: string,
    localStorageId: string,
    deviceType: DeviceIds,
    language?: string
  ) => Promise<Experiment[]>;
  forceExperiments: (
    password: string,
    experiments: Record<string, number>,
    localStorageId: string,
    deviceType: DeviceIds,
    language?: string
  ) => Promise<void>;
  changeSubscriptionStatus: (
    password: string,
    isFree: boolean
  ) => Promise<void>;
};

const defaultPaymentStrategies: PaymentStrategies =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
    ? {
        base: {
          weekly: {
            price: 'price_1PVFxUAFin5TBrHAoPi46BZA',
          },
          monthly: {
            price: 'price_1PVFxTAFin5TBrHAPAQVAcPa',
          },
          yearly: {
            price: 'price_1PVFxQAFin5TBrHAjZlcRKXX',
          },
        },
        personal: {
          weekly: {
            price: 'price_1PVFxUAFin5TBrHAJvMMNesR',
            exports: 20,
            videoLimits: {
              maxSizeMb: 60,
              maxLength: 60,
              maxPeriodSizeMb: 240,
              maxPeriodLength: 240,
            },
            deprecatedPrices: [
              'price_1PVFxWAFin5TBrHAz6pZEGdL',
              'price_1PVFxVAFin5TBrHAiGxBIfeq',
            ],
          },
          monthly: {
            price: 'price_1PVFxPAFin5TBrHAtx1fSGho',
            exports: 80,
            videoLimits: {
              maxSizeMb: 60,
              maxLength: 60,
              maxPeriodSizeMb: 240,
              maxPeriodLength: 240,
            },
            deprecatedPrices: [
              'price_1PVFxTAFin5TBrHAiKkLwo2z',
              'price_1PVFxPAFin5TBrHAuMaX5HYP',
            ],
          },
          yearly: {
            price: 'price_1PVFxOAFin5TBrHAuyAmlWoJ',
            exports: 1040,
            videoLimits: {
              maxSizeMb: 60,
              maxLength: 60,
              maxPeriodSizeMb: 240,
              maxPeriodLength: 240,
            },
            deprecatedPrices: [
              'price_1PVFxRAFin5TBrHAfrJj7crp',
              'price_1PVFxOAFin5TBrHAuyAmlWoJ',
            ],
          },
        },
        business: {
          weekly: {
            price: 'price_1PVFxUAFin5TBrHAoPi46BZA',
            videoLimits: {
              maxSizeMb: 120,
              maxLength: 120,
              maxPeriodSizeMb: 480,
              maxPeriodLength: 480,
            },
            deprecatedPrices: ['price_1PVFxSAFin5TBrHAoG9xxboY'],
          },
          monthly: {
            price: 'price_1PVFxTAFin5TBrHAPAQVAcPa',
            videoLimits: {
              maxSizeMb: 120,
              maxLength: 120,
              maxPeriodSizeMb: 480,
              maxPeriodLength: 480,
            },
            deprecatedPrices: ['price_1PVFxSAFin5TBrHAoG9xxboY'],
          },
          yearly: {
            price: 'price_1PVFxQAFin5TBrHAjZlcRKXX',
            videoLimits: {
              maxSizeMb: 120,
              maxLength: 120,
              maxPeriodSizeMb: 480,
              maxPeriodLength: 480,
            },
            deprecatedPrices: ['price_1PVFxRAFin5TBrHARiKyfRki'],
          },
        },
        oneTime: {
          singleExport: {
            price: 'price_1PVFxMAFin5TBrHA1s3DN0ia',
            exports: 1,
          },
          threeExports: {
            price: 'price_1PVFxLAFin5TBrHAJnVWg8i2',
            exports: 3,
          },
          tenExports: {
            price: 'price_1PVFxKAFin5TBrHAT4qmgv52',
            exports: 10,
          },
          twentyExports: {
            price: 'price_1PVFxKAFin5TBrHAbqNIdUu4',
            exports: 20,
          },
        },
      }
    : {
        base: {
          weekly: {
            price: 'price_1PTkRuAFin5TBrHAiXXVfiHe',
          },
          monthly: {
            price: 'price_1PTkRwAFin5TBrHAkC7dXfu8',
          },
          yearly: {
            price: 'price_1PTkRyAFin5TBrHAWmYUZxiu',
          },
        },
        personal: {
          weekly: {
            price: 'price_1PTkRuAFin5TBrHAzt65De7R',
            exports: 20,
            videoLimits: {
              maxSizeMb: 60,
              maxLength: 60,
              maxPeriodSizeMb: 240,
              maxPeriodLength: 240,
            },
            deprecatedPrices: [
              'price_1LFawwKDa5D7mFKwe1l0ulx3',
              'price_1JZHTYL1sHEipnarZAWbJBWc',
              'price_1LFawjKDa5D7mFKw41uJdOsn',
            ],
          },
          monthly: {
            price: 'price_1PTkRrAFin5TBrHAuw4Z1mef',
            exports: 80,
            videoLimits: {
              maxSizeMb: 60,
              maxLength: 60,
              maxPeriodSizeMb: 240,
              maxPeriodLength: 240,
            },
            deprecatedPrices: [
              'price_1LZvE9KDa5D7mFKwN2chJaxX',
              'price_1LFaw7KDa5D7mFKwE421Qcus',
              'price_1M94C4KDa5D7mFKwqtrOyZNh',
            ],
          },
          yearly: {
            price: 'price_1PTkRrAFin5TBrHA9i1MhFfk',
            exports: 1040,
            videoLimits: {
              maxSizeMb: 60,
              maxLength: 60,
              maxPeriodSizeMb: 240,
              maxPeriodLength: 240,
            },
            deprecatedPrices: [
              'price_1LFavKKDa5D7mFKw6rtsJRQu',
              'price_1M94AoKDa5D7mFKwQSFqaa8x',
            ],
          },
        },
        business: {
          weekly: {
            price: 'price_1PTkRuAFin5TBrHAiXXVfiHe',
            videoLimits: {
              maxSizeMb: 120,
              maxLength: 120,
              maxPeriodSizeMb: 480,
              maxPeriodLength: 480,
            },
            deprecatedPrices: ['price_1LFawTKDa5D7mFKwfvZq2MwK'],
          },
          monthly: {
            price: 'price_1PTkRwAFin5TBrHAkC7dXfu8',
            videoLimits: {
              maxSizeMb: 120,
              maxLength: 120,
              maxPeriodSizeMb: 480,
              maxPeriodLength: 480,
            },
            deprecatedPrices: [
              'price_1LFavgKDa5D7mFKwc429q1ME',
              'price_1LFavrKDa5D7mFKwlcoTqhsP',
            ],
          },
          yearly: {
            price: 'price_1PTkRyAFin5TBrHAWmYUZxiu',
            videoLimits: {
              maxSizeMb: 120,
              maxLength: 120,
              maxPeriodSizeMb: 480,
              maxPeriodLength: 480,
            },
            deprecatedPrices: [
              'price_1LFausKDa5D7mFKwlBZFJJ1Z',
              'price_1LFauGKDa5D7mFKwoaOz8ccV',
            ],
          },
        },
        oneTime: {
          singleExport: {
            price: 'price_1PTkRpAFin5TBrHAWHrSCgot',
            exports: 1,
          },
          threeExports: {
            price: 'price_1PTkRkAFin5TBrHAnR7wTvzf',
            exports: 3,
          },
          tenExports: {
            price: 'price_1PTkRkAFin5TBrHAfCM2HTay',
            exports: 10,
          },
          twentyExports: {
            price: 'price_1PTkRjAFin5TBrHAMMFLUVuF',
            exports: 20,
          },
        },
      };

export const createSettingsService = ({
  post,
  put,
}: HttpService): SettingsService => {
  /* There could be situations in which the calls to the settings fail and the
   * product is unusable because of the spinning wheel waiting for the API to
   * receive a successful response. Having default settings means that at least
   * users could see the website, even if the calls don't work. */
  const getDefaultSettings = (): Settings => ({
    paymentStrategies: defaultPaymentStrategies,
    oneTimePayment: false,
    shouldForceHomeFreeTrial: false,
    shouldEnableSavingAiModelDefault: true,
    shouldShowNewModelsTutorial: true,
    bipaDisclaimerEnabled: false,
    shouldShowPrivacySection: false,
    isFreeTrialEnabled: true,
    removeCap: false,
    jpegQuality: 90,
    faceDetectorMaxInputRes: 800,
    isIntroPriceEnabled: false,
    aiPipelineConfig: [
      {
        tool: 'face_enhance',
        availableModels: ['base', 'v1'],
        defaultModel: 'base',
        isTooltipAvailable: false,
      },
      {
        tool: 'face_lifting',
        title: 'beautify',
        availableModels: ['movie', 'glam', 'cute', 'natural'],
        defaultModel: null,
        isTooltipAvailable: true,
      },
      {
        tool: 'background_enhance',
        availableModels: ['base', 'v1'],
        defaultModel: 'v1',
        isTooltipAvailable: false,
      },
      {
        tool: 'bokeh',
        title: 'backgroundBlur',
        availableModels: ['low', 'medium', 'high'],
        defaultModel: 'low',
        isTooltipAvailable: true,
      },
      {
        tool: 'color_enhance',
        title: 'autoColor',
        availableModels: [
          'golden',
          'steady',
          'balanced',
          'orange',
          'silky',
          'muted',
          'teal',
          'softWarm',
        ],
        defaultModel: null,
        isTooltipAvailable: true,
      },
    ],
    remoteAiPipelineConfig: [
      {
        mainSection: 'face',
        tool: 'face_enhance',
        models: [
          {
            label: 'base',
            value: {
              model: 'remini',
            },
          },
          {
            label: 'v1',
            value: {
              model: 'remini',
              pre_blur: 1.8,
            },
          },
          {
            label: 'v2',
            value: {
              model: 'gfpgan',
            },
          },
        ],
      },
      {
        mainSection: 'face',
        tool: 'face_lifting',
        models: [
          {
            label: 'movie',
            value: {
              model: 'movie-style',
            },
          },
          {
            label: 'glam',
            value: {
              model: 'glam-style',
            },
          },
          {
            label: 'cute',
            value: {
              model: 'stylegan-v1',
            },
          },
          {
            label: 'natural',
            value: {
              model: 'bendai-style',
            },
          },
        ],
      },
      {
        mainSection: 'background',
        tool: 'background_enhance',
        models: [
          {
            label: 'base',
            value: {
              model: 'remini-tensorrt',
            },
          },
          {
            label: 'v1',
            value: {
              model: 'rhino-tensorrt',
            },
          },
        ],
      },
      {
        mainSection: 'background',
        tool: 'bokeh',
        models: [
          {
            label: 'low',
            value: {
              aperture_radius: '0.30',
              highlights: '0.25',
              vivid: '0.75',
              group_picture: 'true',
              rescale_kernel_for_small_images: 'true',
              apply_front_bokeh: 'false',
            },
          },
          {
            label: 'medium',
            value: {
              aperture_radius: '0.65',
              highlights: '0.50',
              vivid: '0.40',
              group_picture: 'true',
              rescale_kernel_for_small_images: 'true',
              apply_front_bokeh: 'false',
            },
          },
          {
            label: 'high',
            value: {
              aperture_radius: '0.80',
              highlights: '0.60',
              vivid: '0.60',
              group_picture: 'true',
              rescale_kernel_for_small_images: 'true',
              apply_front_bokeh: 'false',
            },
          },
        ],
      },
      {
        mainSection: 'color',
        tool: 'color_enhance',
        models: [
          {
            label: 'golden',
            value: {
              model: 'prism-expert-c',
            },
          },
          {
            label: 'steady',
            value: {
              model: 'prism-blend',
            },
          },
          {
            label: 'balanced',
            value: {
              model: 'prism-expert-a',
            },
          },
          {
            label: 'orange',
            value: {
              model: 'orange-teal',
            },
          },
          {
            label: 'silky',
            value: {
              model: 'silky',
            },
          },
          {
            label: 'muted',
            value: {
              model: 'muted',
            },
          },
          {
            label: 'teal',
            value: {
              model: 'orange-teal_v2',
            },
          },
          {
            label: 'softWarm',
            value: {
              model: 'lit_soft_warm',
            },
          },
        ],
      },
    ],
    businessTierWeeklyHighlighted: true,
    expFreeTrialPaywallCtaLabel: false,
    expFreeTrialPaywallPayNowLabel: false,
    highlightedOtpStrategy: undefined,
    psPlugin: true,
  });

  /* Take the compatible settings from the backend. They are already mixed
   * and correctly overridden with experiments data. */
  const retrieveSettings = async (
    localStorageId: string,
    deviceType: DeviceIds,
    language = 'en'
  ): Promise<IdentityResponse> => {
    const response = await post('/v1/web/settings', {
      localStorageId,
      deviceType,
      language,
    });
    return fromSnakeCaseToCamelCaseRecursive(response);
  };

  const _getSecretMenuHeaders = (
    password: string
  ): {
    [key: string]: string;
  } => ({
    'Secret-Menu-Password': password,
  });

  /* Check whether the password inserted for the secret menu is wrong or correct. */
  const checkSecretMenuPassword = async (password: string) => {
    await post(
      '/v1/web/secret-menu/check',
      {},
      _getSecretMenuHeaders(password)
    );
  };

  /* Retrieve all the available experiments. It
   * returns both the experiments in which the user is correctly segmented and
   * the experiments to which they didn't take part because of a low
   * segmentation chance. */
  const retrieveExperiments = async (
    password: string,
    localStorageId: string,
    deviceType: DeviceIds,
    language = 'en'
  ): Promise<Experiment[]> => {
    const response = await post(
      '/v1/web/secret-menu/experiments/retrieve',
      {
        localStorageId,
        deviceType,
        language,
      },
      _getSecretMenuHeaders(password)
    );

    const { experiments } = response;
    return experiments;
  };

  /* Force experiments on Orion for a user. New segmentations into
   * experiments will be stored. */
  const forceExperiments = async (
    password: string,
    experiments: Record<string, number>,
    localStorageId: string,
    deviceType: DeviceIds,
    language = 'en'
  ) => {
    await put(
      '/v1/web/secret-menu/experiments/force',
      {
        modifiedExperiments: experiments,
        localStorageId,
        deviceType,
        language,
      },
      _getSecretMenuHeaders(password)
    );
  };

  /* Easily change the UX from subscribed to unsubscribed, and vice-versa. */
  const changeSubscriptionStatus = async (
    password: string,
    isFree: boolean
  ) => {
    await put(
      '/v1/web/secret-menu/users/is-free',
      {
        isFree,
      },
      _getSecretMenuHeaders(password)
    );
  };

  return {
    getDefaultSettings,
    retrieveSettings,
    checkSecretMenuPassword,
    retrieveExperiments,
    forceExperiments,
    changeSubscriptionStatus,
  };
};

export default serviceSingletonGetter(
  Symbol('settingsService'),
  createSettingsService
);
