import { useRecoilValue, useSetRecoilState } from 'recoil';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import { unlockUISelector } from '@/stores/appStore';
import { useCallback } from 'react';
import { Toast } from '@/ui/toast';
import { useTracker } from '@/core/tracking';
import { useLocalization } from '@/services/localizationService';
import { OrderResponseBody } from '@paypal/paypal-js';
import { useUser } from '@/services/userService';
import * as Sentry from '@sentry/nextjs';
import settingsState from '@/stores/settingsStore';
import {
  useMediaService,
  usePaymentService,
} from '@/layout/appWrapper/ServiceProvider';
import { useConsent } from '@/features/userConsent';
import YourPluginKeyModal from '@/features/account/accountModal/pluginKeySection/modals/YourPluginKeyModal';
import { selectedPaymentStrategyState } from '@/stores/onboardingStore';
import { PaymentStrategiesIds } from '@/services/paymentService';
import checkIcon from '../../public/images/icons/check--success.png';

export const usePayPalButton = (unlockedExports?: number) => {
  const pushModal = useSetRecoilState(pushModalSelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const unlockUI = useSetRecoilState(unlockUISelector);
  const selectedPaymentStrategy = useRecoilValue(selectedPaymentStrategyState);

  const { useMedia } = useMediaService();
  const { getMediaAndUpdateStore } = useMedia();
  const {
    createPaypalSubscription,
    upgradePaypalSubscription,
    createPaypalCharge,
  } = usePaymentService();

  const { shouldShowMarketingConsentModal } = useConsent();

  const { track } = useTracker();
  const { data } = useLocalization();

  const { data: user, mutate: mutateUser } = useUser();
  const settings = useRecoilValue(settingsState);

  const onSubscriptionApprove = useCallback(
    async (subscriptionId: string) => {
      await createPaypalSubscription(subscriptionId, unlockedExports);
      mutateUser();

      unlockUI({});

      // the PayPal hover screen lasts some seconds before disappearing
      setTimeout(() => {
        pushModal({
          id: 'subscriptionToast',
          Modal: Toast,
          message: data.onboarding.congratsMember,
          duration: 2000,
          onEnd: () => shouldShowMarketingConsentModal(),
          onClose: () =>
            settings.values.psPlugin &&
            selectedPaymentStrategy?.id === PaymentStrategiesIds.Business &&
            pushModal({
              id: 'generate-plugin-key-modal',
              Modal: YourPluginKeyModal,
            }),
        });
      }, 2400);
    },
    [
      createPaypalSubscription,
      settings.values.psPlugin,
      unlockedExports,
      mutateUser,
      unlockUI,
      pushModal,
      data.onboarding.congratsMember,
      shouldShowMarketingConsentModal,
      selectedPaymentStrategy?.id,
    ]
  );

  const onUpgradeApprove = useCallback(
    async (subscriptionId?: string | null) => {
      if (!subscriptionId) {
        Sentry.captureException(
          new Error("PayPal's data.subscriptionID not set")
        );
        return;
      }

      await upgradePaypalSubscription(subscriptionId);
      mutateUser();

      // the PayPal hover screen lasts some seconds before disappearing
      setTimeout(() => {
        pushModal({
          id: 'subscriptionToast',
          Modal: Toast,
          message: data.account.congratsBusiness,
          duration: 2000,
          onClose: () =>
            settings.values.psPlugin &&
            pushModal({
              id: 'generate-plugin-key-modal',
              Modal: YourPluginKeyModal,
            }),
        });
      }, 2400);
    },
    [
      upgradePaypalSubscription,
      mutateUser,
      pushModal,
      data.account.congratsBusiness,
      settings.values.psPlugin,
    ]
  );

  const onOneTimePaymentApprove = useCallback(
    async (
      orderData: OrderResponseBody,
      taskId: string,
      amountCents: number,
      currency: string,
      exports: number
    ) => {
      const paypalEmailAddress = orderData.payer.email_address;
      const chargeId = orderData.purchase_units[0].payments?.captures?.[0].id;

      await createPaypalCharge({
        chargeId,
        amountCents,
        currency,
        taskId,
        paypalEmailAddress,
        exports,
      });

      await mutateUser();

      unlockUI({});
      if (taskId) {
        await getMediaAndUpdateStore();
      }
      closeModal('payment');

      pushModal({
        id: 'subscriptionToast',
        Modal: Toast,
        message: data.result.oneTimePaymentSuccess,
        icon: checkIcon,
        duration: 2000,
        onEnd: () => shouldShowMarketingConsentModal(),
      });
    },
    [
      createPaypalCharge,
      unlockUI,
      getMediaAndUpdateStore,
      closeModal,
      pushModal,
      data.result.oneTimePaymentSuccess,
      shouldShowMarketingConsentModal,
      mutateUser,
    ]
  );

  return {
    pushModal,
    unlockUI,
    onSubscriptionApprove,
    onUpgradeApprove,
    onOneTimePaymentApprove,
    track,
    webUserId: user?.webUserId,
    paymentDescriptionText: data.account.oneTimePaymentDescription,
  };
};
