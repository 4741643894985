import { atom, DefaultValue, selector } from 'recoil';
import {
  PaymentStrategiesIds,
  PaymentStrategy,
} from '@/services/paymentService';
import { PricePeriod } from '@/services/priceService';

export const onboardingEndedState = atom({
  key: 'onboarding:onboardingEndedState',
  default: false,
});

export const isFreeTrialEnabledState = atom({
  key: 'onboarding:isFreeTrialEnabledState',
  default: false,
});

export const isReminderEnabledState = atom({
  key: 'onboarding:isReminderEnabledState',
  default: false,
});

export const selectedPeriodState = atom<PricePeriod>({
  key: 'onboarding:selectedPeriodState',
  default: 'weekly',
});

export const selectedPaymentStrategyState = atom<PaymentStrategy | null>({
  key: 'onboarding:selectedPaymentStrategyState',
  default: null,
});

export const showFreeTrialSelector = selector({
  key: 'onboarding:showFreeTrial',
  get: ({ get }) => {
    const isFreeTrialEnabled = get(isFreeTrialEnabledState);
    const selectedPaymentStrategy = get(selectedPaymentStrategyState);
    return (
      isFreeTrialEnabled &&
      selectedPaymentStrategy?.id !== PaymentStrategiesIds.OneTime
    );
  },
});

export const onboardingState = selector({
  key: 'onboarding:onboardingState',
  get: ({ get }) => {
    const isFreeTrialEnabled = get(isFreeTrialEnabledState);
    const isReminderEnabled = get(isReminderEnabledState);
    const selectedPeriod = get(selectedPeriodState);
    const selectedPaymentStrategy = get(selectedPaymentStrategyState);
    return {
      isFreeTrialEnabled,
      isReminderEnabled,
      selectedPeriod,
      selectedPaymentStrategy,
    };
  },
  set: ({ set }, newVal) => {
    if (newVal instanceof DefaultValue) return;
    set(isFreeTrialEnabledState, newVal.isFreeTrialEnabled);
    set(isReminderEnabledState, newVal.isReminderEnabled);
    set(selectedPeriodState, newVal.selectedPeriod);
    set(selectedPaymentStrategyState, newVal.selectedPaymentStrategy);
  },
});
