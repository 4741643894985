import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ReactNode } from 'react';

export function PayPalProvider({ children }: { children: ReactNode }) {
  return (
    <PayPalScriptProvider
      options={{
        'client-id': process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID!,
        components: 'buttons',
        intent: 'subscription',
        vault: true, // default on subscription
      }}
    >
      {children}
    </PayPalScriptProvider>
  );
}
