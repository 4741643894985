import { ModalWrapper } from '@/core/modal';
import { closeModalSelector } from '@/stores/modalStore';
import { ReactNode, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { CloseIcon } from '../icons';

interface Props {
  id: string;
  text?: string;
  btnText?: string;
  className?: string;
  showCloseIcon?: boolean;
  closeIconClassName?: string;
  children?: ReactNode;
  onBtnClick?: CallableFunction;
}

export default function GenericModal({
  id,
  text,
  btnText = 'Ok, Got It',
  className = '',
  showCloseIcon = true,
  closeIconClassName = '',
  children,
  onBtnClick,
  ...rest
}: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const closeModal = useSetRecoilState(closeModalSelector);

  const focusFirstElement = () => buttonRef.current?.focus();

  useEffect(focusFirstElement, []);

  return (
    <ModalWrapper
      className={`p-8 section-foreground rounded-3xl max-w-2xl ${className}`}
      onFocusTrap={focusFirstElement}
      {...rest}
    >
      {!!showCloseIcon && (
        <button
          ref={buttonRef}
          className={`mb-3 text-white drop-shadow ${closeIconClassName}`}
          onClick={() => closeModal(id)}
        >
          <CloseIcon />
        </button>
      )}
      {children || (
        <div className="space-y-5">
          <p className="M20 text-black text-center break-words">{text}</p>
          <button
            type="button"
            className="btn btn--large shadow-dark-button btn--black mt-5 mx-auto"
            onClick={() => {
              closeModal(id);
              if (onBtnClick) onBtnClick();
            }}
          >
            {btnText}
          </button>
        </div>
      )}
    </ModalWrapper>
  );
}
