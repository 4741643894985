const searchParamsEffect =
  (searchParam: string) =>
  ({ setSelf }) => {
    const params = new URLSearchParams(window.location.search);
    const currentValue = params.get(searchParam);

    if (currentValue !== null) {
      setSelf(currentValue);
    }
  };

export default searchParamsEffect;
