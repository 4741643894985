export default function RightCaret() {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="rtl:scale-x-[-1] contrast-low"
    >
      <path
        d="M1.32384 1.42857L5.89526 6L1.32384 10.5714"
        stroke="#B1B1B8"
        strokeWidth="2"
      />
    </svg>
  );
}
