import { OneTrustAnalyticsConsentProvider } from '@/core/tracking/pico/privacy/trackingConsentProvider';

/** The props for a WeTransfer creative.
 *
 * @param name The name of the creative.
 * @param pixelUrl The URL of the creative pixel, if any.
 * */
type WeTransferCreative = {
  name: string;
  pixelUrl: string | undefined;
};

/** The available WeTransfer creatives. */

/** The available WeTransfer creatives. */
const WeTransferCreatives: WeTransferCreative[] = [
  buildWeTransferCreative({
    name: 'WT_Desktop_Consent_Creative',
    basePixelUrl:
      'https://e-10220.adzerk.net/e.gif?e=eyJ2IjoiMS4xMiIsImF2Ijo0Mjg3NjAsImF0Ijo5NTksImJ0IjowLCJjbSI6NDMzODEyMTI3LCJjaCI6MzQ5MDIsImNrIjp7fSwiY3IiOjQ4MzU2NzYxNSwiZGkiOiI2NDIzYTY3MzY0YTI0NDRjYWQyNTg5MjBmZmI2OTIwNiIsImRqIjowLCJpaSI6IjBmNmY1Mzc5NGVmMzQ1MGM5ODdkOGViNTRmNTY2MzE5IiwiZG0iOjMsImZjIjo2NTgzODc2ODUsImZsIjo2MzYyNDcwNjUsImlwIjoiOTMuNDIuNDUuMTY3IiwibnciOjEwMjIwLCJwYyI6MCwib3AiOjAsIm1wIjowLCJlYyI6MCwiZ20iOjAsImVwIjpudWxsLCJwciI6MTQ5Njg3LCJydCI6MSwicnMiOjUwMCwic2EiOiI1NSIsInNiIjoiaS0wOTIxMDUwMzczNGIyMGYzMyIsInNwIjoxNTc0MTk3LCJzdCI6MTA1NTkyMiwidHIiOnRydWUsInVrIjoidWUxLWYzNjhjMjkxNjdjNjQ2MzA5ZjZhYzg2NTllMjUwY2Y3IiwidHMiOjE3Mjg0MzAzMDk3MjcsInBuIjoiaUZyYW1lIiwiZ2MiOmZhbHNlLCJnQyI6ZmFsc2UsImdpIjp0cnVlLCJncyI6Im5vbmUiLCJ0eiI6IlVUQyIsImV0IjoyfQ&s=2D3TvV3kbgPUJko_YJzZPh7O1Rs',
  }),
  buildWeTransferCreative({
    name: 'WT_Desktop_Pro_Creative',
    basePixelUrl: 'https://e-10220.adzerk.net/e/10220/428760/e.gif',
    addRandomness: true,
  }),
  buildWeTransferCreative({
    name: 'WT_MWeb_Creative',
    basePixelUrl:
      'https://e-10220.adzerk.net/e.gif?e=eyJ2IjoiMS4xMiIsImF2Ijo0Mjg3NjAsImF0Ijo1LCJidCI6MCwiY20iOjQzMzgxMjEyNywiY2giOjU3MzAzLCJjayI6e30sImNyIjo0Nzc1MzYzNDcsImRpIjoiM2NkNmM4N2IxMDY3NGMzM2JiZTVhYzcwOTAwMGQwZmUiLCJkaiI6MCwiaWkiOiIzNGRiODk0MDE0MzU0MzNjYjgzMjU2NjQ2NzJkMzA5NyIsImRtIjozLCJmYyI6NjUyMzkyNTQyLCJmbCI6NjM2MjU2MzYyLCJpcCI6IjkzLjQyLjQ1LjE2NyIsIm53IjoxMDIyMCwicGMiOjAsIm9wIjowLCJtcCI6MCwiZWMiOjAsImdtIjowLCJlcCI6bnVsbCwicHIiOjIyODEyMCwicnQiOjEsInJzIjo1MDAsInNhIjoiNTUiLCJzYiI6ImktMDI0NDVlNjA2NjU2NTEzMmUiLCJzcCI6MzY3MTQ4NCwic3QiOjEyMDQ4NjQsInRyIjp0cnVlLCJ1ayI6InVlMS1jMzNkNDNkMzQyMmI0ODIzYTk2MmE0YzM5MGI4Zjk3YSIsInRzIjoxNzI4NDMwNDEyMjEwLCJwbiI6ImlGcmFtZSIsImdjIjpmYWxzZSwiZ0MiOmZhbHNlLCJnaSI6dHJ1ZSwiZ3MiOiJub25lIiwidHoiOiJVVEMiLCJldCI6Mn0&s=XZyDUzpbsWWIDLpF3LVtlRNw6Do',
  }),
  buildWeTransferCreative({
    name: 'WT_iOS_Creative',
    basePixelUrl:
      'https://e-10220.adzerk.net/e.gif?e=eyJ2IjoiMS4xMiIsImF2Ijo0Mjg3NjAsImF0Ijo1ODQyLCJidCI6MCwiY20iOjQzMzgxMjEyNywiY2giOjU3MzAzLCJjayI6e30sImNyIjo0NzQwMjA4MjgsImRpIjoiOWZkMDM4YTZhOTE4NDdlYzgwYzc2MDJhMDA3NmJkOTAiLCJkaiI6MCwiaWkiOiJhOWUwNzkxY2Y5MzQ0Zjg2YmUxZTFjOTM0OGM1ZjM5MCIsImRtIjozLCJmYyI6NjQ4ODk0OTczLCJmbCI6NjM2MjU2MjA5LCJpcCI6IjkzLjQyLjQ1LjE2NyIsIm53IjoxMDIyMCwicGMiOjAsIm9wIjowLCJtcCI6MCwiZWMiOjAsImdtIjowLCJlcCI6bnVsbCwicHIiOjIyODEyMywicnQiOjEsInJzIjo1MDAsInNhIjoiNTUiLCJzYiI6ImktMGZmYTFjZjdjOGRjMzUyYWMiLCJzcCI6MjY3Mzk2NSwic3QiOjExODE0NDgsInRyIjp0cnVlLCJ1ayI6InVlMS1mMzcwZGI2Nzc0NDE0NWRlOTA4NzU4NWMzYmYxMjJmNyIsInRzIjoxNzI4NDc4ODc4NzY5LCJnYyI6ZmFsc2UsImdDIjpmYWxzZSwiZ2kiOnRydWUsImdzIjoibm9uZSIsInR6IjoiVVRDIiwiYWciOjEsImV0IjoyfQ&s=30tb-GGSky81XLAxSRoZjiwz4Rc',
  }),
];

/** Builds a default WeTransfer creative, with the enhanced flow variant as default.
 *
 * @param name The name of the creative.
 * @param pixelUrl The URL of the creative pixel, if any.
 * @param addRandomness Whether to add a random query parameter to the pixel URL. Defaults to false, used only for
 *   specific pixels (e.g., the UI ones).
 * */
function buildWeTransferCreative({
  name,
  basePixelUrl,
  addRandomness = false,
}: {
  name: string;
  basePixelUrl: string | undefined;
  addRandomness?: boolean;
}): WeTransferCreative {
  let finalPixelUrl = basePixelUrl;
  if (addRandomness && finalPixelUrl) {
    const currentDate = new Date().getTime();
    finalPixelUrl = `${basePixelUrl}?_=${currentDate}`;
  }
  return {
    name,
    pixelUrl: finalPixelUrl,
  };
}

/** Tracks the WeTransfer creative pixel, if any.
 *
 * @param creativeName The name of the creative.
 * */
export function trackWeTransferCreativeConversionPixelIfAny({
  creativeName,
}: {
  creativeName: string;
}): void {
  if (!OneTrustAnalyticsConsentProvider.userAcceptedProfilingTracking()) {
    return;
  }
  const wtCreative = WeTransferCreatives.find(
    (creative) => creative.name.toLowerCase() === creativeName.toLowerCase()
  );
  if (wtCreative?.pixelUrl) {
    // uses the Kevel cache - a simple fetch wouldn't send the cache in the request
    new Image().src = wtCreative.pixelUrl;
  }
}
