import {
  AuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';

export class FirebaseLoginProvider {
  static APPLE = new FirebaseLoginProvider(
    new OAuthProvider('apple.com'),
    'apple.com'
  );

  static FACEBOOK = new FirebaseLoginProvider(
    new FacebookAuthProvider().addScope('email'),
    'facebook.com'
  );

  static GOOGLE = new FirebaseLoginProvider(
    new GoogleAuthProvider(),
    'google.com'
  );

  static allValues = [
    FirebaseLoginProvider.APPLE,
    FirebaseLoginProvider.FACEBOOK,
    FirebaseLoginProvider.GOOGLE,
  ];

  static fromName(name: string) {
    if (name === 'google.com') return new GoogleAuthProvider();
    if (name === 'facebook.com') {
      const facebookProvider = new FacebookAuthProvider();
      facebookProvider.addScope('email');
      return facebookProvider;
    }
    if (name === 'apple.com') return new OAuthProvider('apple.com');
    throw new Error(`Provider not found: ${name}`);
  }

  constructor(
    public externalProvider: GoogleAuthProvider | AuthProvider | OAuthProvider,
    public name: string
  ) {}
}
