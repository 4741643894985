import { SpiderSense } from '@/core/monitoring/spidersense/src/spidersense';
import { v4 as uuidv4 } from 'uuid';
import { generateMonitoringAPI } from './monitoring-schema/api';
import { safeLocalStorage } from '../safeLocalStorage/safeLocalStorage';

let monitoringAPI: ReturnType<typeof generateMonitoringAPI>;

const getOrCreateUserId = () => {
  const SPIDERSENSE_USER_ID_KEY = 'spidersense_user_id';
  const userId = safeLocalStorage.getItem(SPIDERSENSE_USER_ID_KEY);
  if (userId) return userId;
  const newUserId = uuidv4();
  safeLocalStorage.setItem(SPIDERSENSE_USER_ID_KEY, newUserId);
  return newUserId;
};

export const getMonitoringAPI = () => {
  if (monitoringAPI) return monitoringAPI;

  const spiderSense = SpiderSense({
    appId: 'remini_website',
    mockRequest: !!process.env.NEXT_PUBLIC_SPIDERSENSE_MOCK,
    isProduction: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production',
    userId: getOrCreateUserId(),
    appVersion: process.env.appVersion ?? '',
  });
  monitoringAPI = generateMonitoringAPI(spiderSense);
  return monitoringAPI;
};
