import { atom, selector } from 'recoil';

export interface AppInterface {
  locksCount?: number;
}

export const appState = atom<AppInterface>({
  key: 'global:appState',
  default: {
    locksCount: 0,
  },
});

export const isBusySelector = selector({
  key: 'global:isBusy',
  get: ({ get }) => {
    const { locksCount } = get(appState);
    return locksCount! > 0;
  },
});

export const lockUISelector = selector({
  key: 'global:lockUI',
  get: () => appState,
  set: ({ get, set }) => {
    const { locksCount, ...rest } = get(appState);
    set(appState, { ...rest, locksCount: locksCount! + 1 });
  },
});

export const unlockUISelector = selector({
  key: 'global:unlockUI',
  get: () => appState,
  set: ({ set, get }) => {
    const { locksCount, ...rest } = get(appState);
    set(appState, { ...rest, locksCount: locksCount! - 1 });
  },
});
