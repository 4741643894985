import { createHttpService } from '@/core/http/httpService';
import { createSettingsService } from '@/services/settingsService';
import { SettingsInfo } from '@/services/settingsService/settingsTypes';
import createTokenService from '@/services/tokenService';
import { atom, selector } from 'recoil';

const defaultSettingsSelector = selector({
  key: 'defaultSettings',
  get: () => ({
    areReady: false,
    values: createSettingsService(
      createHttpService(createTokenService())
    ).getDefaultSettings(),
  }),
});

const settingsState = atom<SettingsInfo>({
  key: 'user:settings',
  default: defaultSettingsSelector,
});

export default settingsState;
