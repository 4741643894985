import { atom } from 'recoil';
import whenInBrowser from '@/core/environment/whenInBrowser';
import localStorageSearchParamsEffect from '@/core/stateManager/localStorageSearchParamsEffect';
import localStorageEffect from '@/core/stateManager/localStorageEffect';
import searchParamsEffect from '@/core/stateManager/searchParamsEffect';

export const webOnboardingUserIdState = atom({
  key: 'webOnb:userId',
  default: '',
  effects_UNSTABLE: [
    whenInBrowser(localStorageSearchParamsEffect('webonb_uid', 'user_id')),
  ],
});

export const webOnboardingLocalStorageIdState = atom({
  key: 'webOnb:localStorageId',
  default: '',
  effects_UNSTABLE: [
    whenInBrowser(
      localStorageSearchParamsEffect('webonb_lsid', 'local_storage_id')
    ),
  ],
});

export const webOnboardingPaywallVersionState = atom({
  key: 'webOnb:paywallVersion',
  default: '',
  effects_UNSTABLE: [
    whenInBrowser(localStorageSearchParamsEffect('webonb_pv', 'version')),
  ],
});

export const webOnboardingBulkUploadIdState = atom({
  key: 'webOnb:bulkUpload',
  default: '',
  effects_UNSTABLE: [whenInBrowser(searchParamsEffect('bulk_upload_id'))],
});

export const webOnboardingCompletedBulkUploadsState = atom<string[]>({
  key: 'webOnb:bulkUpload:completed',
  default: [],
  effects_UNSTABLE: [whenInBrowser(localStorageEffect('webonb_bid_completed'))],
});

export const webOnboardingBulkUploadProcessing = atom<boolean>({
  key: 'webOnb:bulkUploadProcessing',
  default: false,
  effects_UNSTABLE: [whenInBrowser(localStorageEffect('webonb_bu_processing'))],
});
