import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import { serviceSingletonGetter } from '../serviceGetter';

export type TutorialService = {
  setTutorialCompletedInLocalStorage: (tutorialId?: string) => void;
  getTutorialCompletedFromLocalStorage: (tutorialId?: string) => boolean;
};

export const createTutorialService = (): TutorialService => {
  const setTutorialCompletedInLocalStorage = (tutorialId?: string) => {
    safeLocalStorage.setItem(`${tutorialId || ''}tutorialCompleted`, 'true');
  };

  const getTutorialCompletedFromLocalStorage = (tutorialId?: string) =>
    !!safeLocalStorage.getItem(`${tutorialId || ''}tutorialCompleted`);

  return {
    setTutorialCompletedInLocalStorage,
    getTutorialCompletedFromLocalStorage,
  };
};

export default serviceSingletonGetter(
  Symbol('tutorialService'),
  createTutorialService
);
