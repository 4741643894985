import common from './common.json';
import header from './header.json';
import footer from './footer.json';
import home from './home.json';
import result from './result.json';
import account from './account.json';
import onboarding from './onboarding.json';
import surveys from './surveys.json';
import processing from './processing.json';
import support from './support.json';
import tiers from './tiers.json';

const en = {
  common,
  header,
  footer,
  home,
  result,
  account,
  onboarding,
  surveys,
  processing,
  support,
  tiers,
} as const;

export default en;
export type LocData = typeof en; // "en" will be the source of truth for all JSONs structure
