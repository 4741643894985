import track from '@/core/tracking/track';
import { selector, atom } from 'recoil';

export const paramsState = atom({
  key: 'tracking/params',
  default: {
    categoryParams: {},
    globalParams: {},
  },
});

export const trackingSelector = selector({
  key: 'tracking/tracking',
  get: ({ getCallback }) =>
    getCallback(
      ({ snapshot }) =>
        async (
          category: string,
          action: string,
          additionalParams?: Record<string, any>
        ) => {
          const params = await snapshot.getPromise(paramsState);
          track(category, action, params, additionalParams);
        }
    ),
});
