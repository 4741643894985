import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '@/services/serviceGetter';

export type RedeemService = {
  postRedeem: (code: string) => Promise<any>;
  formatRedeemGiftCodeExpiryDate: (expiryDate: Date) => string;
  linkMobileWebSub: (backendId: string) => Promise<any>;
};

export const createRedeemService = ({ post }: HttpService): RedeemService => {
  const postRedeem = async (code: string) =>
    post('/v1/web/gift-codes/redeem', {
      code,
    });

  const formatRedeemGiftCodeExpiryDate = (expiryDate: Date) =>
    new Date(expiryDate).toLocaleDateString('en-GB'); // dd/mm/yyyy;
  const linkMobileWebSub = async (backendId: string) =>
    post('/v1/web/link-mobile', {
      oracleBackendId: backendId,
    });

  return { postRedeem, formatRedeemGiftCodeExpiryDate, linkMobileWebSub };
};

export default serviceSingletonGetter(
  Symbol('redeemService'),
  createRedeemService
);
