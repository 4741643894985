import axios, { AxiosError } from 'axios';
import {
  fromCamelCaseToSnakeCaseRecursive,
  fromSnakeCaseToCamelCaseRecursive,
} from '../objectManipulation/objectManipulation';

const baseDomain =
  process.env.NEXT_PUBLIC_BACKEND_DOMAIN || typeof document === 'undefined'
    ? `https://${process.env.NEXT_PUBLIC_BACKEND_DOMAIN}`
    : document.location.origin;

const instance = axios.create({ baseURL: `${baseDomain}/api/v1/web` });

instance.interceptors.request.use(
  (config) => {
    config.data = fromCamelCaseToSnakeCaseRecursive(config.data);
    return config;
  },
  (err) => {
    console.error('Request interceptor failed: ', err);
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (config) => {
    config.data = fromSnakeCaseToCamelCaseRecursive(config.data);
    return config;
  },
  (err) => {
    console.error('Response interceptor failed: ', err);
    return Promise.reject(fromSnakeCaseToCamelCaseRecursive(err));
  }
);

export const api = instance;

export type GenericAxiosError<ResponseData = any> = AxiosError<ResponseData>;

/** Default fetcher for useSWR. */
export const fetcher = (url: string) => api.get(url).then(({ data }) => data);

/** Fetcher for useSWR which ensures auth token to be present in the api request. */
export const authFetcher = ([url, token]: [string, string]) =>
  api
    .get(url, { headers: { Authorization: `Bearer ${token}` } })
    .then(({ data }) => data);
