export interface TypeformProps {
  widget?: string;
  iframeProps?: `${string}=${string}`[];
  width?: string;
  height?: string;
  size?: number;
  buttonText?: string;
  buttonColor?: string;
  iconUrl?: string;
  sidetab?: string;
  slider?: string;
  openOn?: string;
  openValue?: string;
  hiddenFields?: `${string}=${string}`[];
}

export type SurveyItem = {
  id: string | number;
  type: string;
  text: string;
  image?: string;
  placeholder?: string;
  fixed?: boolean;
};

export enum SurveyClassname {
  Slider = 'tf-v1-slider',
  Sidebar = 'tf-v1-sidebar',
}

// New Popup Survey implementation starts
export enum SurveyState {
  Start = 'start',
  Ongoing = 'ongoing',
  End = 'end',
}

export enum SurveyKind {
  Native = 'native',
  Typeform = 'typeform',
}

export interface NativeSurveyProps {
  skippable: boolean;
  onQuestionsEnd: () => void;
  onSurveyEnds: () => void;
}

export interface NativeSurveyOptionEvent {
  questionId: string;
  id: string;
  text?: string;
}

export interface NativeSurveyResponse {
  surveyId: string;
  responses: { questionId: string; id: string; text?: string }[] | null;
}
// New Popup Survey implementation ends
