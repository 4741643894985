import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '../serviceGetter';
import useIsRequestDataComplete from './_private/useIsRequestDataComplete';
import sendCustomerSupportRequest from './_private/sendCustomerSupportRequest';
import useSendCustomerSupportRequest from './_private/useSendCustomerSupportRequest/useSendCustomerSupportRequest';
import useResetRequest from './_private/useResetRequest';
import useIsEmailValid from './_private/useIsEmailValid';

export type SupportService = ReturnType<typeof createSupportService>;

export const createSupportService = (httpService: HttpService) => ({
  useIsRequestDataComplete,
  useSendCustomerSupportRequest,
  useResetRequest,
  useIsEmailValid,
  sendCustomerSupportRequest: sendCustomerSupportRequest(httpService),
});

export default serviceSingletonGetter(
  Symbol('supportService'),
  createSupportService
);
