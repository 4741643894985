import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '@/services/serviceGetter';
import { Preferences } from './preferencesTypes';

export type PreferencesService = {
  getDefaultPreferences: () => Preferences;
  storeNewPreferences: (preferences: Preferences) => Promise<void>;
};

export const createPreferencesService = ({
  put,
}: HttpService): PreferencesService => {
  /* Get the default preferences that apply to all users. This isn't fixed
   * in time, it could vary with the smooth addition or the removal of keys. */
  const getDefaultPreferences = (): Preferences => ({
    wantsEmailReminder: true,
  });

  /* Useful to change user's preferences. These properties are subject to the
   * user's choice. */
  const storeNewPreferences = async (
    preferences: Preferences
  ): Promise<void> => {
    await put('/v1/web/users/@me/preferences/override', {
      preferences,
    });
  };

  return { getDefaultPreferences, storeNewPreferences };
};

export default serviceSingletonGetter(
  Symbol('preferencesService'),
  createPreferencesService
);
