import { trackEvent } from '@/core/monitoring/trackEvents';
import { useTracker } from '@/core/tracking';
import { FreeTrialButton } from '@/features/payment';
import {
  usePaymentService,
  usePriceService,
  useSurveyService,
} from '@/layout/appWrapper/ServiceProvider';
import { useLocalization } from '@/services/localizationService';
import {
  PaymentStrategiesIds,
  PaymentStrategy,
} from '@/services/paymentService';
import { PricePeriod } from '@/services/priceService';
import { useUser } from '@/services/userService';
import {
  isFreeTrialEnabledState,
  isReminderEnabledState,
  selectedPaymentStrategyState,
  selectedPeriodState,
} from '@/stores/onboardingStore';
import settingsState from '@/stores/settingsStore';
import Spinner from '@/ui/content/Spinner';
import { SelectionButtons } from '@/ui/selectionButtons';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useMaybeOpenCreditLossWarning from '../../credits/creditLossWarning/useMaybeOpenCreditLossWarning';
import PaymentStrategyPriceCard from '../PaymentStrategyPriceCard';
import usePaymentStrategies from '../usePaymentStrategies';

const divStyle = { backgroundColor: '#F4F3F8' };

export default function PaymentStrategiesStep1({
  isUpgrade = false,
}: {
  isUpgrade: boolean;
}) {
  const settings = useRecoilValue(settingsState);

  const { usePaywallPrices, useUserPrice } = usePriceService();
  const userPrice = useUserPrice();
  const paywallPrices = usePaywallPrices();
  const { data: user } = useUser();
  const { isPremiumUser } = usePaymentService();

  const subscriptionsIfAlreadyPremium = (strategy: PaymentStrategy) =>
    !isPremiumUser(user) || strategy.id !== PaymentStrategiesIds.OneTime;

  const { t, data } = useLocalization();
  const router = useRouter();
  const { track } = useTracker();
  const { openContactUsSurvery } = useSurveyService();
  const [isFreeTrialEnabled, setIsFreeTrialEnabled] = useRecoilState(
    isFreeTrialEnabledState
  );
  const [isReminderEnabled, setIsReminderEnabled] = useRecoilState(
    isReminderEnabledState
  );
  const [selectedPeriod, setSelectedPeriod] =
    useRecoilState(selectedPeriodState);
  const setSelectedPaymentStrategy = useSetRecoilState(
    selectedPaymentStrategyState
  );

  const { taskId } = router.query;

  const userPricePeriod = useMemo(
    () => `${userPrice?.recurringInterval}ly` as PricePeriod,
    [userPrice?.recurringInterval]
  );

  const paymentStrategies = usePaymentStrategies({
    selectedPeriod,
    remotePaymentStrategies: settings.values.paymentStrategies,
    isUpgrade,
    userPricePeriod,
  });

  const maybeOpenCreditLossModal = useMaybeOpenCreditLossWarning();

  const onBtnClick = useCallback(
    (selectedPaymentStrategy: PaymentStrategy) => {
      track('paywall_tier', 'confirmed', {
        selectedTier: selectedPaymentStrategy.id,
        isUpgrade,
      });
      if (selectedPaymentStrategy.customPrice) openContactUsSurvery();
      const opened = maybeOpenCreditLossModal(selectedPaymentStrategy);
      if (!opened) setSelectedPaymentStrategy(selectedPaymentStrategy);
    },
    [
      track,
      isUpgrade,
      openContactUsSurvery,
      maybeOpenCreditLossModal,
      setSelectedPaymentStrategy,
    ]
  );

  useEffect(() => {
    if (!paywallPrices || !user) {
      // do not track spinner
      return;
    }
    trackEvent(['paywall', 'shown'], {
      type: isUpgrade ? 'upgrade_tiers' : 'tiers',
    });
  }, [isUpgrade, paywallPrices, user]);

  const isFreeTrialAvailable =
    user?.monetization.trialAvailable && settings.values.isFreeTrialEnabled;
  const isIntroPriceAvailable =
    user?.monetization.trialAvailable &&
    !settings.values.isFreeTrialEnabled &&
    settings.values.isIntroPriceEnabled &&
    !isUpgrade;

  const PaymentStrategies = useMemo(
    () =>
      paymentStrategies
        .filter(subscriptionsIfAlreadyPremium)
        .map((paymentStrategy) => {
          if (!taskId || !settings.values.oneTimePayment) {
            return (
              paymentStrategy.id !== PaymentStrategiesIds.OneTime && (
                <PaymentStrategyPriceCard
                  key={paymentStrategy.id}
                  paymentStrategy={paymentStrategy}
                  defaultPeriod={selectedPeriod}
                  isFreeTrialEnabled={isFreeTrialEnabled}
                  onBtnClick={onBtnClick}
                  isUpgrade={isUpgrade}
                  setSelectedPeriod={setSelectedPeriod}
                  userPricePeriod={userPricePeriod}
                  hasIntroPrice={isIntroPriceAvailable || false}
                />
              )
            );
          }

          return (
            <PaymentStrategyPriceCard
              key={paymentStrategy.id}
              paymentStrategy={paymentStrategy}
              defaultPeriod={
                paymentStrategy.id === PaymentStrategiesIds.OneTime
                  ? settings.values.highlightedOtpStrategy!
                  : selectedPeriod
              }
              otpVariants={settings.values.paymentStrategies.oneTime}
              isFreeTrialEnabled={
                paymentStrategy.id === PaymentStrategiesIds.OneTime
                  ? false
                  : isFreeTrialEnabled
              }
              onBtnClick={onBtnClick}
              isUpgrade={isUpgrade}
              setSelectedPeriod={setSelectedPeriod}
              userPricePeriod={userPricePeriod}
              hasIntroPrice={isIntroPriceAvailable}
            />
          );
        }),
    [
      paymentStrategies,
      taskId,
      settings.values.oneTimePayment,
      settings.values.highlightedOtpStrategy,
      settings.values.paymentStrategies.oneTime,
      selectedPeriod,
      isFreeTrialEnabled,
      onBtnClick,
      isUpgrade,
      setSelectedPeriod,
      userPricePeriod,
    ]
  );

  if (!paywallPrices) {
    return <Spinner inline={false} className="p-96" />;
  }

  return (
    <div className="w-full text-black" style={divStyle}>
      <div
        className={`relative pt-10 px-10 md:px-14 ${
          isFreeTrialAvailable ? '-mb-9' : '-mb-7'
        }`}
      >
        <p className="B25 sm:B32 md:B40+LH text-center px-4 md:px-0">
          {t(data.tiers.tiersTitle)}
        </p>
        {isUpgrade && (
          <p className="M16 text-center text-overlay-black-80 mt-2.5 px-4 md:px-0 md:pb-52">
            {t(data.tiers.tiersSubtitle)}
          </p>
        )}
        {!isUpgrade && (
          <SelectionButtons
            options={[
              { key: 'weekly', label: data.common.weekly },
              { key: 'monthly', label: data.common.monthly, offBadge: 35 },
              { key: 'yearly', label: data.common.yearly, offBadge: 50 },
            ]}
            mainDivClassName="max-w-xs mx-auto relative w-full bg-gray-100 rounded-full mt-10 p-1 flex justify-between text-center"
            selectedOptionExternal={selectedPeriod}
            setSelectedOptionExternal={setSelectedPeriod}
            buttonSelectionClassName="bg-white bg-opacity-100 border-2 border-black"
            labelTextClassName="SB14 text-black"
            additionalOnClick={() => {
              track('paywall_price', 'selected', {
                selectedPeriod,
                isUpgrade,
              });
            }}
          />
        )}
        {isIntroPriceAvailable && !isUpgrade && (
          <div className="w-full flex justify-center">
            <p className="mt-6 mb-5 B32 text-primary-accent py-[6px] px-3 bg-primary-accent-100 rounded-lg w-auto">
              🎁 {t(data.onboarding.limitedOffer, {}, 'uppercase')}
            </p>
          </div>
        )}
        {isFreeTrialAvailable && !isUpgrade && (
          <div className="mt-5 lg:mx-auto lg:w-content">
            <FreeTrialButton
              isFreeTrialEnabled={isFreeTrialEnabled}
              setIsFreeTrialEnabled={setIsFreeTrialEnabled}
              isReminderEnabled={isReminderEnabled}
              setIsReminderEnabled={setIsReminderEnabled}
              labelClassName="!h-auto"
              showReminder
              className="lg:min-w-80"
            />
          </div>
        )}
      </div>
      <div
        className={`w-full bg-white pt-21 pb-12.5 px-5 md:px-14 lg:px-10 flex ${
          isUpgrade ? '' : 'space-y-4 md:space-y-0'
        } md:space-s-6 flex-col md:flex-row`}
      >
        {PaymentStrategies}
      </div>
    </div>
  );
}
