export default function External({ ...rest }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2L14 8L12 8L12 5.41421L7.70711 9.70711L6.29289 8.29289L10.5858 4L8 4L8 2L14 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 14L2 5L4 5L4 12L11 12L11 14L2 14Z"
        fill="currentColor"
      />
    </svg>
  );
}
