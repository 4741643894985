import {
  PaymentStrategiesIds,
  PaymentStrategy,
} from '@/services/paymentService';
import { Toast } from '@/ui/toast';
import checkIcon from '@/public/images/icons/check--success.png';
import { SettingsInfo } from '@/services/settingsService';
import YourPluginKeyModal from '../account/accountModal/pluginKeySection/modals/YourPluginKeyModal';

type ShowSuccessToastProps = {
  selectedPaymentStrategy: PaymentStrategy | null;
  willDownloadAutomatically: boolean;
  shouldShowMarketingConsentModal: () => void;
  pushModal: (modal: any) => void;
  settings: SettingsInfo;
  message: string;
  webOnboardingUserId?: string;
};

export default function maybeShowSuccessToast({
  selectedPaymentStrategy,
  willDownloadAutomatically,
  shouldShowMarketingConsentModal,
  pushModal,
  message,
  settings,
  webOnboardingUserId,
}: ShowSuccessToastProps) {
  if (
    selectedPaymentStrategy?.id !== PaymentStrategiesIds.OneTime ||
    (selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime &&
      willDownloadAutomatically)
  ) {
    pushModal({
      id: 'subscriptionToast',
      Modal: Toast,
      message,
      icon:
        selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
          ? checkIcon
          : null,
      duration: 2000,
      onEnd: () => shouldShowMarketingConsentModal(),
      onClose: () =>
        settings.values.psPlugin &&
        selectedPaymentStrategy?.id === PaymentStrategiesIds.Business &&
        !webOnboardingUserId &&
        pushModal({
          id: 'generate-plugin-key-modal',
          Modal: YourPluginKeyModal,
        }),
    });
  }
}
