import { EventType } from '../event';
import {
  NetworkRequest,
  NetworkRequestEvent,
  NetworkRequestHeaders,
} from '../network';
import { Experiments, Settings } from '../providers';
import { ActionInfoAnonymizer } from './actionInfoAnonymizer';
import {
  AnonymousInfoProvider,
  AnonymousUUIDType,
} from './anonymousInfoProvider';

export type AnonymousNetworkRequestEvent = {
  id: string;
  timestamp: number;
  type: EventType;
  request_timestamp: number;
  app: string;
  user: {
    ids: {
      local_storage_id: AnonymousUUIDType;
      anonymous_id: string;
    };
    info: {
      locale: string;
      device_type: string;
      browser?: string;
      app_version: string;
      experiment?: Experiments;
      settings: Settings;
      is_free: boolean;
    };
  };
  data: {
    subtype?: string;
    action_kind?: string;
    action_info?: Record<string, unknown>;
  };
};

type AnonymousNetworkRequestBody = {
  events: AnonymousNetworkRequestEvent[];
};

export type AnonymousNetworkRequest = {
  url: string;
  body: AnonymousNetworkRequestBody;
  headers: NetworkRequestHeaders;
};

export const anonymizeRequest = (
  e: NetworkRequest,
  anonymousInfoProvider: AnonymousInfoProvider,
  actionInfoAnonymizer: ActionInfoAnonymizer
): AnonymousNetworkRequest => {
  const anonymizeHeaders = (
    e: NetworkRequestHeaders
  ): NetworkRequestHeaders => ({
    'Pico-Client-ID': anonymousInfoProvider.getClientID(),
    'Pico-Version': e['Pico-Version'],
    'Pico-Tester': e['Pico-Tester'],
    anonymous: 'true',
  });

  const anonymizeEventIfNotTechnical = (
    e: NetworkRequestEvent
  ): AnonymousNetworkRequestEvent => {
    const anonymizedTimestamp = anonymousInfoProvider.getIncrementalTimestamp();
    return {
      id: e.id,
      timestamp: anonymizedTimestamp / 1000,
      type: e.type,
      request_timestamp: anonymizedTimestamp / 1000,
      app: e.app,
      user: {
        ids: {
          local_storage_id: anonymousInfoProvider.getLocalStorageId(),
          anonymous_id: anonymousInfoProvider.getAnonymousID(),
        },
        info: {
          locale: e.user.info.locale,
          device_type: e.user.info.device_type,
          browser: e.user.info.browser,
          app_version: e.user.info.app_version,
          experiment: e.user.info.experiment,
          settings: e.user.info.settings,
          is_free: e.user.info.is_free,
        },
      },
      data: {
        subtype: e.data.subtype,
        action_kind: e.data.action_kind,
        action_info:
          e.data.action_kind && e.data.action_info
            ? actionInfoAnonymizer.anonymizeActionInfo(
                e.data.action_kind,
                e.data.action_info
              )
            : undefined,
      },
    };
  };

  return {
    url: e.url,
    body: {
      events: e.body.events.map(anonymizeEventIfNotTechnical),
    },
    headers: anonymizeHeaders(e.headers),
  };
};
