import { ModalWrapper } from '@/core/modal';
import { trackStartedEvent } from '@/core/monitoring/trackEvents';
import { useLocalization } from '@/services/localizationService';
import { closeModalSelector } from '@/stores/modalStore';
import {
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithRedirect,
} from 'firebase/auth';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { FirebaseLoginProvider } from '../firebaseLoginProvider';

export default function AccountLinkingNeededModal({
  id,
  email,
  ...rest
}: {
  id: string;
  email: string;
}) {
  const closeModal = useSetRecoilState(closeModalSelector);
  const { t, data } = useLocalization();

  const buttonClick = useCallback(async () => {
    const auth = getAuth();

    const providers = await fetchSignInMethodsForEmail(auth, email);
    // The first provider is the original one.
    if (providers && providers.length > 0) {
      const existingProvider = FirebaseLoginProvider.fromName(providers[0]);
      existingProvider.setCustomParameters({ login_hint: email });
      // track login tentative with the existing provider
      trackStartedEvent(['login'], { provider: providers[0], oneTap: false });
      await signInWithRedirect(auth, existingProvider);
    }
    closeModal(id);
  }, [email, closeModal, id]);

  return (
    <ModalWrapper className="text-center" {...rest}>
      <p className="SB20 text-black">{t(data.onboarding.emailDiffProvider)}</p>
      <p className="M16 text-gray-400 mt-3">
        {t(data.onboarding.redirectingPrevProvider)}
      </p>
      <button
        type="button"
        className="w-full btn btn--large shadow-dark-button btn--black mt-6"
        onClick={buttonClick}
      >
        {t(data.common.okGotIt)}
      </button>
    </ModalWrapper>
  );
}
